import React from 'react';
import styles from './WorkAddEditControls.module.css';
import SelectSingleDropDown from '../SelectSingleDropDown'
import InputText from '../InputText'
import Icon from '../Icon'
import DateTimePicker from '../DateTimePicker'
import SelectBetweenLists from '../SelectBetweenLists'
import { guidEmpty } from '../../utils/GuidValidate'

export default (props) => {
	const {
		work,
		filePenspringChapter,
		workNameError,
		handleWorkName,
		setMoreInfo,
		moreInfo,
		setWork,
		handleLanguageId,
		languageIdError,
		setShowWorkOwners,
		showWorkOwners,
		languageList,
		chapters
	} = props

	return (
		<div>
			<div>
				<InputText
					value={work?.workName || ''}
					size={"medium-long"}
					name={"workName"}
					label={`Document name`}
					inputClassName={styles.textWhite}
					disabled={filePenspringChapter && filePenspringChapter.workId !== guidEmpty && chapters !== 'NEWPENSPRING'}
					required={true}
					whenFilled={work?.workName}
					error={workNameError}
					onChange={(event) => handleWorkName(event.target.value)} />
			</div>
			<div className={styles.rowMoreInfo} onClick={() => setMoreInfo(!moreInfo)}>
				<Icon pathName={'chevron_right'} premium={false} fillColor={'white'} className={moreInfo ? styles.lessInfo : styles.moreInfo}/>
				{moreInfo ? 'Less Info' : 'More Info'}
			</div>
			<div className={moreInfo ? styles.showMoreInfo : styles.hide}>
				<div className={styles.description}>
					<span className={styles.labelHigher}>Description (optional)</span>
					<textarea rows={5} cols={42} value={work?.description || ''} id={`description`} className={styles.messageBox}
										onChange={(event) => setWork({ ...work, description: event.target.value})}></textarea>
				</div>
				<div>
					<SelectSingleDropDown
						label={`Native Text Language`}
						value={work?.languageId || ''}
						options={languageList || []}
						height={`medium`}
						className={styles.singleDropDown}
						labelClass={styles.textWhite}
						required={true}
						whenFilled={work?.languageId}
						error={languageIdError}
						onChange={(event) => handleLanguageId(event.target.value)}/>
				</div>
					<div>
						<InputText
							value={work?.internalId || ''}
							inputClassName={styles.textWhite}
							size={"medium"}
							name={"internalId"}
							label={"Internal id"}
							onChange={(event) => setWork({ ...work, internalId: event.target.value})}/>
					</div>
				<div>
					<div className={styles.dueDate}>
						<DateTimePicker label={'Due date'}
							value={work?.dueDate || ''}
														labelClass={styles.textWhite}
														onChange={(event) => setWork({ ...work, dueDate: event.target.value})}/>
					</div>
					<div className={styles.dueDate}>
						<DateTimePicker label={'Active date'}
														value={work?.activeDate || ''}
														labelClass={styles.textWhite}
														onChange={(event) => setWork({ ...work, activeDate: event.target.value})}/>
					</div>
				</div>
			</div>
			<div className={styles.rowMoreInfo} onClick={() => setShowWorkOwners(!showWorkOwners)}>
				<Icon pathName={'chevron_right'} premium={false} fillColor={'white'} className={showWorkOwners ? styles.lessInfo : styles.moreInfo} />
				{showWorkOwners ? 'Hide other document owners' : 'View other document owners'}
			</div>
			<div className={showWorkOwners ? styles.showWorkOwners : styles.hide}>
				<SelectBetweenLists 
					leftLabel={'My contacts'} 
					rightLabel={'Document owners'} 
					onChange={setWork} 
					work={work}
					values={work?.workOwners} 
					options={props.contactOptions} 
					labelClass={styles.documentOwners} />
			</div>
		</div>
	)
};
