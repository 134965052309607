import React, { useState, useEffect } from 'react';
import styles from './ToggleBoardMobile.module.css'
import classes from 'classnames'

function ToggleBoardMobile(props) {
	const { isAuthor, clearAllEditTypes, setMoveSentences, moveSentences, setAddParagraphBreak, editTrackChanges, setEditTrackChanges,
		addParagraphSentence, setAddParagraphSentence, addSentence, setAddSentence, deleteSentence, setDeleteSentence, addListItem, setAddListItem, addParagraphBreak,
		setDeleteParagraphBreak, deleteParagraphBreak, changeStyle, setChangeStyle, setGoToNextSentence, goToNextSentence, 
		deleteListItem, setDeleteListItem, reorderListItems, setReorderListItems, hasListStructure,
		isTranslation, moveTranslationToEditor, setMoveTranslationToEditor, keepCommentOn,
		setKeepCommentOn } = props

	const [choice, setChoice] = useState()

	useEffect(() => {
		if (addListItem) {
			setChoice("ADDLISTITEM")
		} else if (addParagraphBreak) {
			setChoice("ADDPARAGRAPH")
		} else if (addParagraphSentence) {
			setChoice("ADDPARAGRAPHSENTENCE")
		} else if (addSentence) {
			setChoice("ADDSENTENCE")
		} else if (deleteListItem) {
			setChoice("DELETELISTITEM")
		} else if (deleteParagraphBreak) {
			setChoice("DELETEPARAGRAPH")
		} else if (deleteSentence) {
			setChoice("DELETESENTENCE")
		} else if (reorderListItems) {
			setChoice("REORDERLISTITEMS")
		} else if (moveSentences) {
			setChoice("MOVESENTENCE")
		} else if (changeStyle) {
			setChoice("CHANGESTYLE")
			//The ones below are switches which can be turned off and on so we don't want these to be chosen in the list. We just want to launch the function that toggles the setting.
			//So we set them to 0 as chosen.
		} else if (keepCommentOn) {
			setChoice("0")
		} else if (editTrackChanges) {
			setChoice("0")
		// } else if (showEditorFullText) {  //This doesn't make sense for mobile since we have to switch between editors anyway.
		// 	setChoice("0")
		} else if (moveTranslationToEditor) {
			setChoice("0")
		} else if (goToNextSentence) {
			setChoice("0")
		} else {
			setChoice("0")
		}
	}, [moveSentences, editTrackChanges, addParagraphSentence, addSentence, deleteSentence, addListItem, addParagraphBreak, deleteParagraphBreak, goToNextSentence,
		deleteListItem, reorderListItems, changeStyle, moveTranslationToEditor, keepCommentOn])

	const handleSetChoice = (choice) => {
		if (!choice || choice == 0) {
			clearAllEditTypes()
		} else if (choice === "ADDLISTITEM") {
			setAddListItem(true)
			setChoice(choice)
		} else if (choice === "ADDPARAGRAPH") {
			setAddParagraphBreak(true)
			setChoice(choice)
		} else if (choice === "ADDPARAGRAPHSENTENCE") {
			setAddParagraphSentence(true)
			setChoice(choice)
		} else if (choice === "ADDSENTENCE") {
			setAddSentence(true)
			setChoice(choice)
		} else if (choice === "DELETELISTITEM") {
			setDeleteListItem(true)
			setChoice(choice)
		} else if (choice === "DELETEPARAGRAPH") {
			setDeleteParagraphBreak(true)
			setChoice(choice)
		} else if (choice === "DELETESENTENCE") {
			setDeleteSentence(true)
			setChoice(choice)
		} else if (choice === "REORDERLISTITEMS") {
			setReorderListItems(true)
			setChoice(choice)
		} else if (choice === "MOVESENTENCE") {
			setMoveSentences(true)
			setChoice(choice)
		} else if (choice === "CHANGESTYLE") {
			setChangeStyle(true)
			setChoice(choice)
		} else if (choice === "KEEPCOMMENTON") {
			setKeepCommentOn(!keepCommentOn)
		} else if (choice === "SHOWTRACKCHANGES") {
			setEditTrackChanges(!editTrackChanges)
		// } else if (choice === "EDITORFULLTEXT") {  //This doesn't make sense for mobile since we have to switch between editors anyway.
		// 	setShowEditorFullText(!showEditorFullText)
		} else if (choice === "MOVETRANSLATION") {
			setMoveTranslationToEditor(!moveTranslationToEditor)
		} else if (choice === "GOTONEXTSENTENCE") {
			setGoToNextSentence(!goToNextSentence)
		}
	}

	return (
		<div className={styles.container}>
			<select
				value={choice}
				onChange={(event) => handleSetChoice(event.target.value)}
				className={classes(styles.editControl, styles.sizeMediumShort, styles.narrowList) }
			>
				<option value="0">Edit <span>&#9660;</span></option>
				{!isTranslation && hasListStructure &&
					<option value="ADDLISTITEM">Add list item</option>
				}
				{!isTranslation && !isAuthor &&
					<option value="ADDPARAGRAPH">Add paragraph break</option>
				}
				{!isTranslation && !isAuthor &&
					<option value="ADDPARAGRAPHSENTENCE">Add paragraph and sentence</option>
				}
				{!isTranslation && !isAuthor &&
					<option value="ADDSENTENCE">Add sentence</option>
				}
				{!isTranslation && hasListStructure &&
					<option value="DELETELISTITEM">Delete list item</option>
				}
				{!isTranslation && !isAuthor &&
					<option value="DELETEPARAGRAPH">Delete paragraph break</option>
				}
				{hasListStructure &&
					<option value="REORDERLISTITEMS">Reorder a list level</option>
				}
				{!isTranslation &&
					<option value="DELETESENTENCE">Delete sentence</option>
				}
				{!isTranslation && 
					<option value="MOVESENTENCE">Move one or more sentences</option>
				}
				{!isTranslation && 
					<option value="CHANGESTYLE">Change style</option>
				}
				<optgroup label={'Switches - on or off'}>
					<option value="KEEPCOMMENTON">{props.personConfig.keepCommentOn ? 'ON - ' : 'OFF - '} Keep Comment On</option>
					<option value="SHOWTRACKCHANGES">{props.personConfig.editTrackChanges ? 'ON - ' : 'OFF - '} Show Track Changes</option>
					{/* <option value="EDITORFULLTEXT">{showEditorFullText ? 'ON - ' : 'OFF - '} Show Editor Full Text</option> //This doesn't make sense for mobile since we have to switch between editors anyway.*/}
					{isTranslation && 
						<option value="MOVETRANSLATION">{moveTranslationToEditor ? 'ON - ' : 'OFF - '} Move Translation to Editor</option>
					}
					{isTranslation &&
						<option value="GOTONEXTSENTENCE">{goToNextSentence ? 'ON - ' : 'OFF - '} Go to Next Sentence</option>
					}
				</optgroup>
			</select>
		</div>
	)
}

export default ToggleBoardMobile
