import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router'
import { useMediaQuery } from 'react-responsive';
import styles from './LoginControls.module.css';
import { Link } from 'react-router-dom';
import InputText from '../../components/InputText'
import MessageModal from '../../components/MessageModal'
import ButtonWithIcon from '../../components/ButtonWithIcon'
import PenspringInitial from '../../assets/logos/homescreen48.png'
import PenspringSmall from '../../assets/penspring_medium_noreg.png'
import PenspringMedium from '../../assets/penspring_large.png'
import classes from 'join-classnames'
import jwtDecode from 'jwt-decode'
import { LoginSocialFacebook} from 'reactjs-social-login'
import { FacebookLoginButton } from 'react-social-login-buttons'

function LoginControls(props) {
  const { loginData } = props
  const navigate = useNavigate()
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
  const containerRef = useRef(null);
  
  const [contacts, setContacts] = useState()
  const [accessTokenApi, setAccessTokenApi] = useState({})
  const [autoLogin, setAutoLogin] = useState()
  const [errors, setErrors] = useState({})
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [isShowingFailedLogin, setIsShowingFailedLogin] = useState(false)
  const [isShowingMatchingRecord, setIsShowingMatchingRecord] = useState(false)
  const [isShowingLoginError, setIsShowingLoginError] = useState(false)
  const [openLoginControls, setOpenLoginControls] = useState()
  const [user, setUser] = useState({
    isNewAccount: false,
    googleId: '',
    socialMediaToken: '',
    firstName: '',
    lastName: '',
    emailAddress: '',
    emailAddressConfirm: '',
    clave: '',
    claveConfirm: '',
    recaptchaResponse: '',
  })

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setOpenLoginControls(false)
      }
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && containerRef.current) { // ESC key
        setOpenLoginControls(false)
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [openLoginControls]);

  const handleFacebookCallbackResponse = (response) => {
    if (response && response.data) {
      localStorage.setItem("facebookToken", JSON.stringify(response.data.accessToken)) //This is not a valid JWT token, though, for whatever reason.
      props.login({
        isNewAccount: false,
        facebookId: response.data.userID,
        socialMediaToken: response.authToken,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        emailAddress: response.data.email,
        emailAddressConfirm: response.data.email,
        username: response.data.email,
        photoUrl: response.data.picture.data.url,
        clave: '',
        claveConfirm: '',
        recaptchaResponse: '',
        inviteCode: props.inviteCodeShort,
      }, props.inviteCodeShort, false);
    }
  }

  function handleGoogleCallbackResponse(response) {
    const userJwt = jwtDecode(response.credential)
    localStorage.setItem("googleToken", JSON.stringify(response.credential))
    if (response) props.login({
      isNewAccount: false,
      googleId: userJwt.sub,
      socialMediaToken: response.credential,
      firstName: userJwt.given_name,
      lastName: userJwt.family_name,
      emailAddress: userJwt.email,
      emailAddressConfirm: userJwt.email,
      photoUrl: userJwt.picture,
      username: userJwt.email,
      clave: '',
      claveConfirm: '',
      recaptchaResponse: '',
      inviteCode: props.inviteCodeShort,
    }, props.inviteCodeShort, false);
  }

  useEffect(() => {
    /* global google */
    const google = window.google
    if (google) google.accounts.id.initialize({
      client_id: "612044266489-9jesmmipkgj4c6fqjnso5muo12ikg3cm.apps.googleusercontent.com",
      callback: handleGoogleCallbackResponse,
    })

    if (google) google.accounts.id.renderButton(
      document.getElementById('signInDiv'),
      {theme: "outline", size: "large"}
    )

    if (google) {
      setAccessTokenApi(google.accounts.oauth2.initTokenClient({
        client_id: "612044266489-9jesmmipkgj4c6fqjnso5muo12ikg3cm.apps.googleusercontent.com",
        scope: "https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/contacts.readonly",
        callback: (tokenResponse) => {
          if (tokenResponse && tokenResponse.access_token) {
            fetch("https://people.googleapis.com/v1/people/me/connections?personFields=names,emailAddresses", {
              method: 'GET',
              headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${tokenResponse.access_token}`
              }
            })
              .then(response => {
                if (response.status >= 200 && response.status < 300) {
                  return response.json()
                } else {
                  const error = new Error(response.statusText)
                  error.response = response
                  throw error
                }
              })
              .then(response => {
                if (response && response.connections && response.connections.length > 0) {
                  setContacts(response.connections)
                }
            })
          }
        }
      }))
    }

    if (google && !isMobile) google.accounts.id.prompt()
  }, [])

  useEffect(() => {
    const { inviteResponse, params } = props
    if (params && (params.createNew === "c" || params.createNew === "p")) {  //c means to create new
      setUser({ ...user, isNewAccount: true })
    }
    if (inviteResponse) {
      setUser({
        isNewAccount: inviteResponse.createNew === "c" ? true : false, //c means to create new
        firstName: inviteResponse.firstName,
        lastName: inviteResponse.lastName,
        emailAddress: validateEmail(inviteResponse.emailAddress) ? inviteResponse.emailAddress : '',
        emailAddressConfirm: validateEmail(inviteResponse.emailAddress) ? inviteResponse.emailAddress : '',
        clave: '',
        claveConfirm: '',
      })
    } else {
      if (document.getElementById("username")) document.getElementById("username").value = localStorage.getItem("loginUsername") || ''
      if (document.getElementById("clave")) document.getElementById("clave").value = ''
      if (document.getElementById("username")) document.getElementById("username").focus()
    }
    let username = localStorage.getItem("loginUsername")
    if (username) setUser({ ...user, username })
  }, [])

  useEffect(() => {
    const { loginData } = props

    if (autoLogin) {
      setAutoLogin(false)
      processForm()
    }

    if (!isShowingFailedLogin && !isShowingMatchingRecord && loginData && loginData.error) {
      if (loginData.error === "MATCHING EMAIL ADDRESS FOUND") {
        handleMatchingRecordOpen()
      } else {
        handleFailedLoginOpen()
      }
    }
  }, [loginData])

  const changeUser = (event) => {
    const field = event.target.name;
    let changedUser = { ...user };
    changedUser[field] = event.target.value;
    setUser(changedUser);
  }

  const handleEnterKey = (event) => {
    event.key === "Enter" && processForm();
  }

  const registerNewAccount = () => {
    navigate(`/myProfile/c`) //c is for create (which is used this way because we were working at making the invitation link as short as possible.)
  }

  const validateEmail = (email) => {
    if (email) {
      var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i; //eslint-disable-line
      return re.test(email.toLowerCase());
    }
  }

  const processForm = (event) => {
    const { login, inviteResponse } = props;
    event && event.preventDefault();
    let errors = {};
    let hasError = false;

    if (user.isNewAccount && !user.firstName) {
      errors.firstName = "First name is required";
      hasError = true;
    }

    if (!user.emailAddress) {
      errors.emailAddress = "Please enter your username";
      hasError = true;
      // } else if (!validateEmail(user.emailAddress)) {
      //     errors.emailAddress = "Email address appears to be invalid.";
      //     hasError = true;
    }

    if (!user.clave) {
      errors.clave = "Please enter a password";
      hasError = true;
    } else if (user.isNewAccount && user.clave !== user.claveConfirm) {
      errors.clave = "The password and confirmation do not match";
      hasError = true;
    }

    if (user.isNewAccount && !user.recaptchaResponse) {
      errors.recaptcha = "Please verify that you are not a robot";
      hasError = true;
    }
    setErrors(errors);

    if (!hasError) {
      let sendUser = { ...user }
      sendUser = { ...sendUser, username: user.username || user.emailAddress, password: user.clave }
      if (props.inviteCodeShort) sendUser = { ...sendUser, inviteCode: props.inviteCodeShort, username: user.username || user.emailAddress }
      props.login(sendUser, props.inviteCodeShort, false);
      setSubmitSuccess(true)
    }
  }

  const handleFailedLoginClose = () => {
    setIsShowingFailedLogin(false)
    props.logout(); //This is used to clear the error
  }

  const handleFailedLoginOpen = () => setIsShowingFailedLogin(true)

  const handleMatchingRecordClose = () => {
    setIsShowingMatchingRecord(false)
    props.logout(); //This is used to clear the error
  }

  const handleMatchingRecordOpen = () => setIsShowingMatchingRecord(true)

  const handleLoginErrorClose = () => {
    setIsShowingLoginError(false)
  }

  const penspringButton = () =>
    <div className={styles.penspringLogin} onClick={() => setOpenLoginControls(!openLoginControls)}> 
      <img src={PenspringInitial} height={24} />
      <div className={styles.penspringText}>Log in with</div>
      <img src={PenspringSmall} height={20} className={styles.penspringBackground}/>
    </div>

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.centered}>
        <div className={styles.row}>
        <div className={styles.columm}>
          <div id="signInDiv" style={{maxWidth: '197px'}}></div>
          <div className={styles.fbButton}>
            <LoginSocialFacebook
              appId="863947255658593"
              onResolve={(response) => handleFacebookCallbackResponse(response)}
              //onReject={(error) => consol e.log('Error:', error)}
            >
              <FacebookLoginButton />
            </LoginSocialFacebook>
          </div>
          {penspringButton(true)}
        </div>
      </div>

      </div>
      <div className={classes(styles.boxShadow, openLoginControls ? isMobile ? styles.showMobile : styles.show : styles.hide)}>
        <div className={styles.formPosition}>
          <form>
            <img src={PenspringMedium} height={30} />
            <div>
              <InputText
                size={"medium"}
                name={"emailAddress"}
                label={`User name`}
                value={user.emailAddress}
                onChange={changeUser}
                onEnterKey={handleEnterKey}
                error={errors.emailAddress}
                autoComplete={"on"} />
            </div>
            <div>
              <InputText
                isPasswordType={true}
                size={"medium"}
                value={user.clave}
                name={"clave"}
                onChange={changeUser}
                onEnterKey={handleEnterKey}
                label={"Password"}
                error={errors.clave}
                autoComplete={"on"} />
            </div>
            {loginData && loginData.error === "Invalid Login" &&
              <div className={styles.errorMessage}>
                {loginData.error}
              </div>
            }
            <div className={styles.buttonPosition}>
              <span className={styles.cancelButton} onClick={() => setOpenLoginControls(false)} tabIndex={0}>
                Cancel
              </span>
              <ButtonWithIcon icon={'checkmark0'} label={'Login'} onClick={processForm} submitSuccess={submitSuccess}
                resetOnError={errors.emailAddress || isShowingFailedLogin || isShowingMatchingRecord} />
            </div>
            <div className={styles.row}>
              or
              {!user.isNewAccount &&
                <span onClick={registerNewAccount} className={styles.signUp}>
                  create a new account
                </span>
              }
            </div>
            <div className={styles.column}>
              <Link to={`/forgotUsername`} className={styles.forgotPassword}>Forgot your username?</Link>
              <Link to={`/forgotPassword`} className={styles.forgotPassword}>Forgot your password?</Link>
            </div>
          </form>
        </div>
        {contacts && contacts.length > 0 && contacts.map(m => {
          if (!(m.emailAddresses && m.emailAddresses.length > 0) || !(m.names && m.names.length > 0)) {
            return null;
          }
          return (
            <div className={styles.row}>
              <div>{m.names[0].givenName} {m.names[0].familyName} - {m.emailAddresses[0].value}</div>
            </div>
          )
        })}
        <MessageModal show={isShowingFailedLogin} handleClose={handleFailedLoginClose} heading={`Login`}
          explain={`Your username or password does not match our records.  Please check your entry and try again or create a new account.`}
          onClick={handleFailedLoginClose}/>
        <MessageModal show={isShowingMatchingRecord} handleClose={handleMatchingRecordClose} heading={`Login`}
          explain={`A new account has been requested but there is a matching email address in our records.  Please choose 'Forgot your password?' option to reset your password and to get access to the existing account.`}
          onClick={handleMatchingRecordClose} />
        <MessageModal show={isShowingLoginError} handleClose={handleLoginErrorClose} heading={`Login`}
          explain={`There was a problem with the login. Please check your entry and try again.`}
          onClick={handleLoginErrorClose} />
      </div>
    </div>
  )
}

export default LoginControls