import React, {useState, useEffect, useRef} from 'react';  //PropTypes
import styles from './DownloadFileModal.module.css';
import ButtonWithIcon from '../ButtonWithIcon'
import {Alert, AlertTitle} from '@mui/material';

function DownloadFileModal(props) {
  const { show, handleClose, heading, explain, downloadFileName, downloadOptions } = props;
  const containerRef = useRef(null);
  const clickOnDownload = useRef(null)

  const [saveToGoogleDrive, setSaveToGoogleDrive] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      let isMessage = false
      let element = event.target
      let loop = 0
      while (element && loop < 10 && !isMessage) {
        isMessage = element.classList && Array.from(element.classList).some(className => className.includes('dialogButtons'))
        element = element.nextSibling
        loop++
      }
      if (containerRef.current && (!containerRef.current.contains(event.target) || isMessage)) {
        handleClose()
      }
    }
    const handleKeyDown = (event) => {
      if (event.key === 'Escape' && containerRef.current) { // ESC key
        handleClose()
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, [show]);

  function handleGoogleCallbackResponse(response) {
    localStorage.setItem("googleToken", JSON.stringify(response.credential))
  }

  useEffect(() => {
    if (saveToGoogleDrive) {
      /* global google */
      google.accounts.id.initialize({
        client_id: "612044266489-9jesmmipkgj4c6fqjnso5muo12ikg3cm.apps.googleusercontent.com",
        callback: handleGoogleCallbackResponse,
      })

      // Initialize the token client for OAuth 2.0
      const tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: "612044266489-9jesmmipkgj4c6fqjnso5muo12ikg3cm.apps.googleusercontent.com",
        scope: "https://www.googleapis.com/auth/drive.readonly", // Request correct scope
        callback: (tokenResponse) => {
          if (tokenResponse && tokenResponse.access_token) {
            // Now upload the file to Google Drive
            uploadServerFileToDrive(`/wordDocs/${downloadFileName}`, tokenResponse.access_token);
          } else {
            console.error('No access token obtained');
          }
        },
      });

      // Request the access token
      tokenClient.requestAccessToken();
    }
  }, [saveToGoogleDrive])

  const uploadServerFileToDrive = (fileUrl, accessToken) => {
    const fileExtension = fileUrl.substring(fileUrl.lastIndexOf('.')+1)
    const downloadOption = downloadOptions.filter(m => m.id === fileExtension)[0]
    const mimeType = downloadOption ? downloadOption.mimeType : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    fetch(fileUrl)
      .then(response => response.blob())
      .then(fileBlob => {
        const metadata = {
          name: downloadFileName, 
          mimeType, 
        };

        const formData = new FormData();
        formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
        formData.append('file', fileBlob);

        // Use the fetch API to upload to Google Drive
        fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id', {
          method: 'POST',
          headers: new Headers({
            'Authorization': 'Bearer ' + accessToken
          }),
          body: formData
        })
          .then(res => res.json())
          .then(() => {
            handleClose()
          })
          .catch(err => {
            console.error('Error uploading file to Google Drive:', err);
          });
      })
      .catch(error => {
        console.error('Error fetching the file from server:', error);
      });
  }

  return !show ? null : (
    <div className={styles.container} ref={containerRef}>
      <Alert severity='info' style={{borderRadius: '0 25px 0 25px', backgroundColor: 'white', boxShadow: '-9px 16px 69px 2px rgba(118,122,122,1)', zIndex: '999', width: '300px'}}>
        <AlertTitle>{heading}</AlertTitle>
        <div style={{width: '350px !important'}} dangerouslySetInnerHTML={{__html: explain}}/>
        <a ref={clickOnDownload} href={`/wordDocs/${downloadFileName}`} download/>
        <div className={styles.centered}>
          <div className={styles.dialogButtons}>
            <a className={styles.noButton}  onClick={handleClose}>cancel</a>
            <div className={styles.button}>
              <ButtonWithIcon 
                icon={'plus_document'} 
                label={'Local'}
                onClick={() => { clickOnDownload.current.click(); handleClose(); }}
                addClassName={styles.buttonWidth} smaller/>
            </div>
            <div className={styles.buttonGoogle}>
              <ButtonWithIcon
                icon={'googleDrive'} label={'Google'}
                onClick={() => setSaveToGoogleDrive(true)}
                addClassName={styles.buttonWidthGoogle} smaller />
            </div>
          </div>
        </div>
      </Alert>
    </div>
  )
}

export default DownloadFileModal