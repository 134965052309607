import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router'
import styles from './WorkFolderExplorerOptions.module.css';
import MessageModal from '../MessageModal';
import ToastInputText from '../ToastInputText';
import Icon from '../Icon';
import Target from '../../assets/Inline Icons/move-target.svg'
import {guidEmpty} from '../../utils/GuidValidate'
import classes from 'join-classnames'

function WorkFolderExplorerOptions(props) {
  const {
    personId,
    isMoveWorkId,
    mineOrOthers,
    moveWorkToFolder,
    thisWorkFolderId,
    thisWorkFolderName,
    group,
    isOriginatingEditorFolder,
  } = props;

  const navigate = useNavigate()
  const iconColor = 'white'
  const [enterFolderTitle, setEnterFolderTitle] = useState(false);
  const [showDeleteFolderWarning, setShowDeleteFolderWarning] = useState(false);
  const [showDeleteGroupMemberWarning, setShowDeleteGroupMemberWarning] = useState(false);
  const [showDeleteEditorFolderWarning, setShowDeleteEditorFolderWarning] = useState(false);
  const [isShowingModal_chooseWork, setIsShowingModal_chooseWork] = useState(false);
  const [showGroupAccessMessage, setShowGroupAccessMessage] = useState(false);
  const [showFolderGroupMessage, setShowFolderGroupMessage] = useState(false);
  const [hasFolderGroup, setHasFolderGroup] = useState(false);

  useEffect(() => {
      setHasFolderGroup(findHasFolderGroupHierarchy(props.fullFileTreeExplorer, false))
  }, [props.fullFileTreeExplorer])

  const findHasFolderGroupHierarchy = (folders, foundGroupInHierarchy = false) => {
    return folders && folders.length > 0 && folders.some(folder => {
      const currentFolderHasGroup = folder.group && folder.group.groupId && folder.group.groupId !== guidEmpty;
      if (currentFolderHasGroup || foundGroupInHierarchy) {
        if (folder.workFolderId === thisWorkFolderId) return true;
        if (folder.subFolders) {
          return findHasFolderGroupHierarchy(folder.subFolders, true);
        }
      } else {
        if (folder.subFolders) {
          return findHasFolderGroupHierarchy(folder.subFolders, false);
        }
      }
    });
  };


  const submitAddWorkFolder = (folderTitle) => {
    props.addWorkFolder(personId, thisWorkFolderId, folderTitle, mineOrOthers)
    setEnterFolderTitle(false)
  }

  const submitDeleteFolder = () => {
    const checkboxElement = document.getElementById('deleteFilesAlso')
    props.deleteWorkFolder(personId, thisWorkFolderId, checkboxElement && checkboxElement.checked)
    setShowDeleteFolderWarning(false)
  }
  
  const submitDeleteGroupMember = () => {
    props.removeMemberByWorkFolderId(personId, thisWorkFolderId)
    setShowDeleteGroupMemberWarning(false)
  }
  
  const submitDeleteEditorFolder = () => {
    const authorPersonId = props.fileTreeExplorer[0].otherPersonId
    props.removeEditorByAuthor(personId, authorPersonId, thisWorkFolderId)
    setShowDeleteEditorFolderWarning(false)
  }

  const sendToEditReport = () => {
    navigate(`/workEditReport/folder/${thisWorkFolderId}`)
  }

  const sendToAccessWorks = () => {
    //if (group && group.groupName) {
    if (hasFolderGroup) {
      setShowGroupAccessMessage(true)
    } else {
      navigate(`/giveAccessToWorks/folder/${thisWorkFolderId}`)
    }
  }

  const handleChooseWorkClose = () => setIsShowingModal_chooseWork(false)

  // const hasGroupInHierarchy = () > {
  //   let hasGroup = false
  //   fileTreeExplorer && fileTreeExplorer.length > 0 && fileTreeExplorer.forEach(m => {

  //   })
  //   return hasGroup
  // }

  const displayGroupAction = () => {
    if (mineOrOthers === 'Mine') {
      if (group && group.groupName) {
        return (
          <a onClick={mineOrOthers === 'Mine' ? () => navigate(`/groupChooseExisting/${thisWorkFolderId}/${group && group.groupId}`) : () => { }} title={'View or modify this group'} className={styles.editGroupIcon}>
            <Icon pathName={'users2'} premium={true} superscript={'pencil'} supFillColor={'white'} fillColor={'white'} />
          </a>
        )
      } else {
        return (
          <div className={styles.rowRightSpace}>
            <a onClick={mineOrOthers === 'Mine' ? hasFolderGroup ? () => setShowFolderGroupMessage(true) : () => navigate(`/groupChooseExisting/${thisWorkFolderId}`) : () => { }} title={'Choose an existing group for this folder'}>
              <Icon pathName={'users2'} premium={true} superscript={'plus'} supFillColor={'white'} fillColor={'white'} />
            </a>
          </div>
        )
      }
    }
  }

  return (
    <div>
      <div className={styles.container}>
        {thisWorkFolderId !== guidEmpty &&
          <a onClick={() => mineOrOthers === 'Mine' ? setShowDeleteFolderWarning(true) : group && group.groupId ? setShowDeleteGroupMemberWarning(true) : setShowDeleteEditorFolderWarning(true)}
             title={'Delete this folder'} data-testid={'linkDeleteFolder'}
             className={classes(styles.addNewFolderIcon)}>
            <Icon pathName={'folder_only'} premium={true} superscript={'minus'} supFillColor={'red'}
                  fillColor={iconColor}/>
          </a>
        }
        {!isOriginatingEditorFolder && mineOrOthers === 'Mine' && thisWorkFolderId !== guidEmpty &&
          <a onClick={() => setEnterFolderTitle(true)}
             title={'Add new folder'} data-testid={'linkAddFolder'}
             className={classes(styles.addNewFolderIcon)}>
            <Icon pathName={'folder_only'} premium={true} superscript={'plus'} supFillColor={iconColor}
                  fillColor={iconColor}/>
          </a>
        }
        {mineOrOthers === 'Mine' &&
          <a onClick={hasFolderGroup ? () => setShowGroupAccessMessage(true) : sendToAccessWorks}
             title={`Give access to editors for this folder`}
             className={classes(styles.menuItem)}>
            <Icon pathName={'share'} premium={true} fillColor={iconColor}/>
          </a>
        }
        <a onClick={sendToEditReport}
           title={`Editor reports for this folder`}
           className={classes(styles.menuItem)}>
          <Icon pathName={'graph_report'} premium={true} fillColor={iconColor}/>
        </a>
        {!isOriginatingEditorFolder && mineOrOthers === 'Mine' && isMoveWorkId &&
          <a onClick={() => { props.toggleExpandedLocal(thisWorkFolderId, thisWorkFolderName, true); moveWorkToFolder('end', thisWorkFolderId);}}
               className={styles.menuItem}
               title={'Move document to this folder'}>
            <img src={Target} alt={'Target'} height={19} width={20}/>
          </a>
        }
        {displayGroupAction()}
        {!isOriginatingEditorFolder && mineOrOthers === 'Mine' &&
          <div onClick={() => { props.toggleExpandedLocal(thisWorkFolderId, thisWorkFolderName, true); navigate(`/workAddNew/folder/${thisWorkFolderId}`); }}
            title={'Add new document to this folder'} data-testid={'divAddDocument'}
            className={classes(styles.menuItem)}>
            <Icon pathName={'document0'} premium={true} superscript={'plus'} supFillColor={iconColor}
              fillColor={iconColor} />
          </div>
        }
      </div>
      <MessageModal show={showDeleteFolderWarning} handleClose={() => setShowDeleteFolderWarning(false)} heading={`Delete this folder?`}
                    explainJsx={<div>
                                  <div>Are you sure you want to delete this folder?</div>
                                  <div>Do you want any documents in this folder to be<br />moved to the parent folder?</div>
                                  <div style={{marginTop: '10px'}}>
                                    <input type='checkbox' id='deleteFilesAlso'/> <label htmlFor='deleteFilesAlso'>Delete the files also.</label>
                                  </div>
                                </div>}
                    isConfirmType={true} onClick={submitDeleteFolder} testid={'messageDeleteFolder'}/>
      <MessageModal show={showDeleteGroupMemberWarning} handleClose={() => setShowDeleteGroupMemberWarning(false)} heading={`Remove me from this group?`}
                    explain={`Are you sure you want to be removed<br/>from this group?`}
                    isConfirmType={true} onClick={submitDeleteGroupMember} />
      <MessageModal show={showDeleteEditorFolderWarning} handleClose={() => setShowDeleteEditorFolderWarning(false)} heading={`Remove me as an invited editor?`}
                    explain={`Are you sure you want to be removed<br/>as an invited editor from this folder?`}
                    isConfirmType={true} onClick={submitDeleteEditorFolder} />
      <ToastInputText show={enterFolderTitle} label={`Enter Folder Title`} message="New Folder" onSubmit={submitAddWorkFolder} />
      <MessageModal show={isShowingModal_chooseWork} handleClose={handleChooseWorkClose} heading={`Choose a document`}
                    explain={`Click on a document name and then the<br/>tools will become available for use.`}
                    onClick={handleChooseWorkClose}/>
      <MessageModal show={showGroupAccessMessage} handleClose={() => setShowGroupAccessMessage(false)} heading={`Group Document Access`}
                    explain={`Access to documents in a group folder are already<br/>available to members of the group.<br/><br/>However, documents in a non-group folder must be<br/>assigned directly to an individual.`}
                    onClick={() => setShowGroupAccessMessage(false)}/>
      <MessageModal show={showFolderGroupMessage} handleClose={() => setShowFolderGroupMessage(false)} heading={`Has Group Assigned Already`}
        explain={`There is a group assigned to this folder<br/>either directly or in a parent folder. `}
        onClick={() => setShowFolderGroupMessage(false)} />
      
    </div>
  )
}

export default WorkFolderExplorerOptions;

