import React, {useEffect, useState} from 'react'
import MyWorksView from '../views/MyWorksView'
import {connect} from 'react-redux'
import {doSort} from '../utils/sort.js'
import * as actionWorkFilter from '../actions/work-filter.js'
import * as actionWorks from '../actions/works.js'
import * as actionFileTreeSubContents from '../actions/work-file-tree-sub-contents.js'
import * as actionPersonConfig from '../actions/person-config.js'
import * as actionGradebook from '../actions/grade-book.js'
import * as actionGroups from "../actions/groups";
import * as actionEditorAssign from "../actions/editor-assign";
import {
	selectMe,
	selectWorkFilter,
	selectWorkSummaryCurrent,
	selectGroups,
	selectPersonConfig,
	selectWorkFileTree,
	selectWorks
} from '../store.js'

const mapStateToProps = (state, props) => {
	//1. Filter the works list, if any filters are chosen.
	//2. Loop through the work records, marking the currently chosen Work (so that it might be designated with some background color in a list later).
	let me = selectMe(state)
  const groups = selectGroups(state)
	let group = props && props.params && props.params.groupChosen && groups && groups.length > 0 && groups.filter(m => m.groupId === props.params.groupChosen)[0]
	const workSummary = selectWorkSummaryCurrent(state)
	let works = selectWorks(state)
	let workFilterList = selectWorkFilter(state)
	let workFilterOptions = workFilterList && workFilterList.length > 0 && workFilterList.filter(m => !m.scratchFlag)
	workFilterOptions = workFilterOptions && workFilterOptions.length > 0
		&& workFilterOptions.map(m => ({
			id: m.workFilterId,
			label: m.savedSearchName.length > 25 ? m.savedSearchName.substring(0, 25) + '...' : m.savedSearchName
		}))
	let filterScratch = workFilterList && workFilterList.length > 0 && workFilterList.filter(m => m.scratchFlag)[0]

	if (!!group) {
		works = works.filter(w => w.groupId === group.groupId)
	}

	if (works && filterScratch.searchText) {
		works = works.filter(w => w.title && w.title.toLowerCase().indexOf(filterScratch.searchText.toLowerCase()) > -1)
	}

	if (works && !filterScratch.mine) {
		works = works.filter(w => w.personId !== me.personId)
	}

	if (works && !filterScratch.others) {
		works = works.filter(w => w.personId === me.personId)
	}

	if (works && !filterScratch.active) {
		works = works.filter(w => !w.active)
	}

	if (works && !filterScratch.completed) {
		works = works.filter(w => !w.completed)
	}

	if (works && filterScratch.dueDateFrom && filterScratch.dueDateTo) {
		works = works.filter(w => w.dueDate >= filterScratch.dueDateFrom && w.dueDate <= filterScratch.dueDateTo)
	} else if (works && filterScratch.dueDateFrom) {
		works = works.filter(w => w.dueDate >= filterScratch.dueDateFrom)
	} else if (works && filterScratch.dueDateTo) {
		works = works.filter(w => w.dueDate <= filterScratch.dueDateTo)
	}

	let sortByHeadings = {
		sortField: filterScratch.orderByChosen,
		isAsc: filterScratch.orderSortChosen === 'asc',
		isNumber: false //None of the options are numbers in this case
	}

	works = doSort(works, sortByHeadings)
	return {
		personId: me.personId,
		group,
    groups,
		workFilterOptions,
		filterScratch,
		savedFilterIdCurrent: filterScratch && filterScratch.savedFilterIdCurrent,
		workSummaries: works,
		workSummary,
		fileTreeExplorer: selectWorkFileTree(state),
		personConfig: selectPersonConfig(state),
	}
}

const bindActionsToDispatch = dispatch => ({
	getMyWorks: (personId, includeOriginatingEditor) => dispatch(actionFileTreeSubContents.getMyWorks(personId, includeOriginatingEditor)),
	getGroups: (personId) => dispatch(actionGroups.getGroups(personId)),
	assignWorkToWorkFolder: (personId, workId, workFolderId) => dispatch(actionFileTreeSubContents.assignWorkToWorkFolder(personId, workId, workFolderId)),
	addWorkFolder: (personId, parentWorkFolderId, folderTitle, mineOrOthers) => dispatch(actionFileTreeSubContents.addWorkFolder(personId, parentWorkFolderId, folderTitle, mineOrOthers)),
	deleteWorkFolder: (personId, workFolderId, deleteFilesAlso) => dispatch(actionFileTreeSubContents.deleteWorkFolder(personId, workFolderId, deleteFilesAlso)),
	deleteWork: (personId, workId) => dispatch(actionWorks.deleteWork(personId, workId)),
	deleteWorkFromFolder: (mineOrOthers, personId, levelType, id) => dispatch(actionWorks.deleteWorkFromFolder(mineOrOthers, personId, levelType, id)),
	removeMemberByWorkFolderId: (personId, workFolderId) => dispatch(actionGroups.removeMemberByWorkFolderId(personId, workFolderId)),
	removeEditorByAuthor: (editorPersonId, authorPersonId, workFolderId) => dispatch(actionEditorAssign.removeEditorByAuthor(editorPersonId, authorPersonId, workFolderId)),
	clearFilters: (personId) => dispatch(actionWorkFilter.clearFilters(personId)),
	saveNewSavedSearch: (personId, savedSearchName) => dispatch(actionWorkFilter.saveNewSavedSearch(personId, savedSearchName)),
	updateSavedSearch: (personId, workFilterId) => dispatch(actionWorkFilter.updateSavedSearch(personId, workFilterId)),
	deleteSavedSearch: (personId, workFilterId) => dispatch(actionWorkFilter.deleteSavedSearch(personId, workFilterId)),
	chooseSavedSearch: (personId, workFilterId) => dispatch(actionWorkFilter.chooseSavedSearch(personId, workFilterId)),
	updateFilterByField: (personId, field, value) => dispatch(actionWorkFilter.updateFilterByField(personId, field, value)),
	updateFilterDefaultFlag: (personId, savedFilterIdCurrent, setValue) => dispatch(actionWorkFilter.updateFilterDefaultFlag(personId, savedFilterIdCurrent, setValue)),
	updatePersonConfig: (personId, field, value) => dispatch(actionPersonConfig.updatePersonConfig(personId, field, value)),
	setPenspringHomeworkSubmitted: (personId, workId) => dispatch(actionWorks.setPenspringHomeworkSubmitted(personId, workId)),
	setGradebookScoreByPenspring: (personId, studentAssignmentResponseId, score) => dispatch(actionGradebook.setGradebookScoreByPenspring(personId, studentAssignmentResponseId, score)),
	toggleExpanded: (personId, workFolderId) => dispatch(actionFileTreeSubContents.toggleExpanded(personId, workFolderId)),
	toggleAllExpanded: (personId, expandAll) => dispatch(actionFileTreeSubContents.toggleAllExpanded(personId, expandAll)),
	setPenspringDistributeSubmitted: (personId, workId) => dispatch(actionWorks.setPenspringDistributeSubmitted(personId, workId)),
})

const storeConnector = connect(
	mapStateToProps,
	bindActionsToDispatch,
)

function Container(props) {
	const {personId, getMyWorks, getGroups} = props
  const [isInit, setIsInit] = useState(false)

	useEffect(() => {
		if (!isInit && personId) {
			getMyWorks(personId)
			getGroups(personId)
			setIsInit(true)
		}
	}, [personId])

	return <MyWorksView {...props} />
}

export default storeConnector(Container)
