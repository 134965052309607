import React, {useState} from 'react';  //PropTypes
import styles from './ColorPickerModal.module.css';
import ButtonWithIcon from '../ButtonWithIcon';
import {SketchPicker} from 'react-color';
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";

export default function ColorPickerModal({iconAvatar, onSelect, saveSelection}) {
	const [anchorEl, setAnchorEl] = useState(null);
	const [color, setColor] = useState('#fff');
	const open = Boolean(anchorEl);

	const handleChangeComplete = (color) => {
		setColor(color.hex);
	};

	const handleClick = (event) => {
		event.preventDefault()
		event.stopPropagation()
		saveSelection()
		setAnchorEl(event.currentTarget)
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className={styles.container}>
			<IconButton
				onClick={handleClick}
				size="small"
				sx={{ width: '25px', position: 'relative', top: '-3px' }}
				aria-controls={open ? 'drop-down-list' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
			>
				{iconAvatar}
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				id="drop-down-list"
				open={open}
				onClose={handleClose}
				transformOrigin={{ horizontal: 'right', vertical: 'top' }}
				anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
				style={{zIndex: 99}}
			>
				<SketchPicker color={color} onChangeComplete={handleChangeComplete} />
				<div className={styles.dialogButtons}>
					<a className={styles.cancelButton}  onClick={handleClose}>Cancel</a>
					<ButtonWithIcon label={`Save`} icon={'checkmark0'} onClick={(event) => {
						onSelect(event, color)
						handleClose()
					}}/>
				</div>
			</Menu>
		</div>
	)
}
