import * as types from './actionTypes';
import {apiHost} from '../api_host.js';

export const getMyWorks = (personId, includeOriginatingEditor) => {  //the includeOriginatingEditor is used on the WorkAddView page when the user wants to add a chapter onto a file that they have already originated for someone else. Otherwise, it should not show up in MyWorksView
    return dispatch => {
				let storage = localStorage.getItem("workFileTreeExplorer");
				storage && dispatch && dispatch({ type: types.WORK_FILE_TREE_INIT, payload: JSON.parse(storage) });

        return fetch(`${apiHost}ebi/myWorkFileTreeExplorer/${personId}/${includeOriginatingEditor}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                return response.json();
            } else {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .then(response => {
            dispatch({ type: types.WORK_FILE_TREE_INIT, payload: response });
						localStorage.setItem("workFileTreeExplorer", JSON.stringify(response));
        })
        //.catch(error => { console.l og('request failed', error); });
    }
}

export const assignWorkToWorkFolder = (personId, workId, workFolderId) => {
    return dispatch => {
        return fetch(`${apiHost}ebi/myWorkFileTreeExplorer/assignWorkToFolder/${personId}/${workId}/${workFolderId}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
          .then(response => {
              if (response.status >= 200 && response.status < 300) {
                  return response.json();
              } else {
                  const error = new Error(response.statusText);
                  error.response = response;
                  throw error;
              }
          })
          .then(response => {
              dispatch({ type: types.WORK_FILE_TREE_INIT, payload: response });
              localStorage.setItem("workFileTreeExplorer", JSON.stringify(response));
          })
    }
}

export const addWorkFolder = (personId, parentWorkFolderId, folderTitle, mineOrOthers) => {
    return dispatch => {
        return fetch(`${apiHost}ebi/myWorkFileTreeExplorer/addWorkFolder/${personId}/${parentWorkFolderId}/${encodeURIComponent(folderTitle)}/${mineOrOthers}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
          .then(response => {
              if (response.status >= 200 && response.status < 300) {
                  return response.json();
              } else {
                  const error = new Error(response.statusText);
                  error.response = response;
                  throw error;
              }
          })
          .then(response => {
              dispatch({ type: types.WORK_FILE_TREE_INIT, payload: response });
              localStorage.setItem("workFileTreeExplorer", JSON.stringify(response));
          })
    }
}

export const deleteWorkFolder = (personId, workFolderId, deleteFilesAlso) => {
    return dispatch => {
        return fetch(`${apiHost}ebi/myWorkFileTreeExplorer/deleteWorkFolder/${personId}/${workFolderId}/${deleteFilesAlso}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
          .then(response => {
              if (response.status >= 200 && response.status < 300) {
                  return response.json();
              } else {
                  const error = new Error(response.statusText);
                  error.response = response;
                  throw error;
              }
          })
          .then(response => {
              dispatch({ type: types.WORK_FILE_TREE_INIT, payload: response });
              localStorage.setItem("workFileTreeExplorer", JSON.stringify(response));
          })
    }
}

export const toggleExpanded = (personId, workFolderId) => {
    return dispatch => {
        return fetch(`${apiHost}ebi/myWorkFileTreeExplorer/toggleExpanded/${workFolderId}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
         // .then(dispatch({ type: types.WORK_FILE_TREE_TOGGLE_EXPANDED, payload: workFolderId }))  //This is mutating state and I can't figure out why since I was doing a clean copy of state.
          .then(dispatch(getMyWorks(personId)))
    }
}

export const toggleAllExpanded = (personId, expandAll) => {
		expandAll = !!expandAll ? expandAll : false;
    return dispatch => {
        return fetch(`${apiHost}ebi/myWorkFileTreeExplorer/toggleExpanded/all/${personId}/${expandAll}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Credentials' : 'true',
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,POST,DELETE,HEAD,PUT,OPTIONS",
                "Access-Control-Allow-Headers": "Content-type,Accept,X-Custom-Header",
                "Authorization": "Bearer " + localStorage.getItem("authToken"),
            },
        })
        .then(() => {
						dispatch(getMyWorks(personId));
				})
    }
}
