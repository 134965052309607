import React, {useState} from 'react'
import {useNavigate} from 'react-router'
import styles from './WorkFileTreeSubContents.module.css'
import Icon from '../Icon'
import FileTreeSubContents_copy from './'
import WorkSummariesReport from '../WorkSummariesReport'
import FolderPlus from '../../assets/folder/folder-plus.png'
import FolderMinus from '../../assets/folder/folder-minus.png'
import WorkFolderExplorerOptions from '../WorkFolderExplorerOptions'
import MenuDocumentPopup from '../MenuDocumentPopup'
import MessageModal from '../MessageModal'
import GroupFolder from '../GroupFolder'
import {useMediaQuery} from "react-responsive"
import classes from 'join-classnames'
import {guidEmpty} from '../../utils/GuidValidate'

const workIndentPixels = 14

function WorkFileTreeSubContents(props) {
  const {
    isWorkAddView,
    fileTreeExplorer,
    fullFileTreeExplorer,
    isParentExpanded = true,
    toggleExpanded,
    toggleExpandedLocal,
    isExpanded,
    personId,
    mineOrOthers,
    setPenspringHomeworkSubmitted,
    getMyWorks,
    fetchingRecord,
    //hasChosenWorkInFolder,
    deleteWorkFolder,
    removeMemberByWorkFolderId,
    removeEditorByAuthor,
    deleteWorkFromFolder,
    chosenWork,
    setChosenWork,
    chosenFolder,
    setChosenFolder,
    //setGradebookScoreByPenspring,
    //setPenspringDistributeSubmitted,
    indent = 8,
    isMoveWorkId,
    moveWorkToFolder,
    addWorkFolder,
    showDocumentDetails,
    darkerBackground,
    showTempControls,
    setShowFolderControls,
    groups,
  } = props

  const isMobile = useMediaQuery({query: '(max-width: 500px)'})
  const navigate = useNavigate()

  const [isShowingPenspringDistribute, setIsShowingPenspringDistribute] = useState(false)
  const [isShowingPenspringHomework, setIsShowingPenspringHomework] = useState(false)
  const [workId, setWorkId] = useState()

  const folderNameLength = isMobile ? 35 : 75

  const sendToReview = (workId, chapterId, languageId) => {
    navigate(`/editReview/${workId}/${chapterId}/${languageId}`)
  }

  // const handlePenspringHomeworkOpen = (workId) => {
  //   setWorkId(workId)
  //   setIsShowingPenspringHomework(true)
  // }

  const handlePenspringHomeworkClose = () => {
    setWorkId('')
    setIsShowingPenspringHomework(false)
  }

  const handlePenspringHomework = () => {
    const {personId, setPenspringHomeworkSubmitted, getMyWorks,} = props
    setPenspringHomeworkSubmitted(personId, workId)
    handlePenspringHomeworkClose()
    getMyWorks(personId)
  }

  const handlePenspringDistributeOpen = (workId) => {
    setWorkId(workId)
    setIsShowingPenspringDistribute(true)
  }

  const handlePenspringDistributeClose = () => {
    setWorkId('')
    setIsShowingPenspringDistribute(false)
  }

  const handlePenspringDistribute = () => {
    const {personId, setPenspringDistributeSubmitted} = props
    setPenspringDistributeSubmitted(personId, workId)
    handlePenspringDistributeClose()
  }

  return !isParentExpanded ? null : (
    <div className={styles.indent}>
      {fileTreeExplorer && fileTreeExplorer.length > 0 && fileTreeExplorer.map((m, i) => {
        return (
          <div className={styles.topDiv} key={i}
               onMouseEnter={() => setShowFolderControls(m.workFolderId)}
               onMouseLeave={() => setShowFolderControls('')}
          >
            {m.folderName && m.folderName.length > 0 &&
              <div className={styles.topSpace}>
                <div key={i} className={styles.folderRow} style={{marginLeft: `${indent}px`}}>
                  <div onClick={() => toggleExpandedLocal(m.workFolderId, m.folderName)} className={styles.link}>
                    <Icon pathName={isExpanded(m.workFolderId, m.folderName) ? 'chevron_down' : 'chevron_right'} premium={true}
                          className={styles.iconSmaller} cursor={'pointer'}/>
                  </div>
                  <div key={i} className={classes(styles.subFolder, darkerBackground ? styles.darkerBackground: '')}>
                    <div onClick={() => toggleExpandedLocal(m.workFolderId, m.folderName)} className={styles.row}>
                      <img src={isExpanded(m.workFolderId, m.folderName) ? FolderMinus : FolderPlus} width={21} height={16} className={styles.folderSpace}/>
                      <div className={styles.folderNameColumn} title={m.folderName}>
                        {m.folderOwnerName && 
                          <div className={styles.folderOwner}>{m.folderOwnerName}</div>
                        }
                        <div className={styles.folderName}>
                          {m.folderName && m.folderName.length > folderNameLength ? m.folderName.substring(0, folderNameLength) + '...' : m.folderName}
                          <div className={styles.workCount}>{!m.workCount ? '' : m.workCount}</div>
                        </div>  
                      </div>
                    </div>
                    <div className={isMobile ? styles.absoluteRightMobile : styles.absoluteRight}>
                      <MenuDocumentPopup {...props} thisWorkFolderId={m.workFolderId}>
                        <WorkFolderExplorerOptions 
                          thisWorkFolderId={m.workFolderId} 
                          thisWorkFolderName={m.folderName} 
                          isOriginatingEditorFolder={m.isOriginatingEditorFolder}
                          {...props} group={m.group} />
                      </MenuDocumentPopup>
                    </div>
                  </div>
                </div>
                <div>
                  {m.workFolderId && m.workFolderId !== guidEmpty &&
                    <GroupFolder mineOrOthers={mineOrOthers} workFolderId={m.workFolderId} group={m.group} groups={groups} />
                  }
                </div>
              </div>
            }
            <div className={isExpanded(m.workFolderId, m.folderName) ? styles.subDiv : styles.hideDiv} key={i + 'sub'}>
              <FileTreeSubContents_copy 
                isWorkAddView
                fileTreeExplorer={m.subFolders} isParentExpanded={isExpanded(m.workFolderId, m.folderName)}
                fullFileTreeExplorer={fullFileTreeExplorer}
                indent={indent + 10 * 1}
                toggleExpanded={toggleExpanded} personId={personId}
                toggleExpandedLocal={toggleExpandedLocal}
                isExpanded={isExpanded}
                mineOrOthers={m.mineOrOthers}
                setPenspringHomeworkSubmitted={setPenspringHomeworkSubmitted}
                getMyWorks={getMyWorks} 
                fetchingRecord={fetchingRecord}
                //hasChosenWorkInFolder={hasChosenWorkInFolder}
                chosenWork={chosenWork} 
                setChosenWork={setChosenWork}
                chosenFolder={chosenFolder} 
                setChosenFolder={setChosenFolder}
                showTempControls={showTempControls} 
                setShowFolderControls={setShowFolderControls}
                //setPenspringDistributeSubmitted={setPenspringDistributeSubmitted}
                //setGradebookScoreByPenspring={setGradebookScoreByPenspring}
                deleteWorkFromFolder={props.deleteWorkFromFolder}
                isMoveWorkId={isMoveWorkId} 
                moveWorkToFolder={moveWorkToFolder}
                deleteWorkFolder={deleteWorkFolder} 
                removeMemberByWorkFolderId={removeMemberByWorkFolderId}
                removeEditorByAuthor={removeEditorByAuthor}
                addWorkFolder={addWorkFolder}
                assignWorkToWorkFolder={props.assignWorkToWorkFolder}
                deleteWork={props.deleteWork}
                showDocumentDetails={showDocumentDetails}
                groups={groups} />

              <WorkSummariesReport 
                isWorkAddView={isWorkAddView}
                data={m.files}
                group={m.group}
                isVisible={isExpanded(m.workFolderId, m.folderName)}
                personId={personId}
                subFolders={m.subFolders}
                indent={indent + workIndentPixels}
                mineOrOthers={mineOrOthers}
                sendToReview={sendToReview}
                setChosenWork={setChosenWork}
                chosenWork={chosenWork}
                //handlePenspringHomeworkOpen={handlePenspringHomeworkOpen}
                //setGradebookScoreByPenspring={setGradebookScoreByPenspring}
                showDocumentDetails={showDocumentDetails}
                moveWorkToFolder={props.moveWorkToFolder}
                isMoveWorkId={isMoveWorkId}
                thisWorkFolderId={m.workFolderId}
                deleteWorkFromFolder={deleteWorkFromFolder}
                isMobile={isMobile}
                {...props} />
            </div>
          </div>
        )}
      )}

      <MessageModal show={isShowingPenspringHomework} handleClose={handlePenspringHomeworkClose} heading={`Submit this homework?`}
                    explain={`Are you sure you want to submit this homework.  The teacher will be able to see your penspring file with your assignment in eCademyApp.`}
                    isConfirmType={true}
                    onClick={handlePenspringHomework}/>
      <MessageModal show={isShowingPenspringDistribute} handleClose={handlePenspringDistributeOpen} heading={`Distribute this homework?`}
                    explain={`Are you sure you want to distribute this homework?  The assignment will be copied to each student as if they were the oroginal authors.  You will become an editor to each assignment.  You will be able to see the penspring file in the eCademyApp gradebook when the student completes and submits their work.`}
                    isConfirmType={true} onClick={handlePenspringDistribute}/>
    </div>
  )
}

export default WorkFileTreeSubContents