import ParagraphQuestionMinus from '../assets/Inline Icons/paragraph-question-minus.svg'
import ParagraphQuestionPlus from '../assets/Inline Icons/paragraph-question-plus.svg'
import ParagraphSentenceQuestionPlus from '../assets/Inline Icons/paragraph-sentence-question-plus-2.png'
import SentenceQuestionPlus from '../assets/Inline Icons/sentence-question-plus.svg'
import SentenceQuestionMinusStart from '../assets/Inline Icons/sentence-question-minus-start.svg'
import SentenceQuestionMinusEnd from '../assets/Inline Icons/sentence-question-minus-end.svg'
import ListItemQuestionPlus from '../assets/Inline Icons/list-item-question-plus.svg'
import ListItemQuestionMinus from '../assets/Inline Icons/list-item-question-minus.svg'
import MoveStart from '../assets/Inline Icons/move-start.svg'
import MoveEnd from '../assets/Inline Icons/move-end.svg'
import MoveTarget from '../assets/Inline Icons/move-target.svg'
import ChangeStyleEnd from '../assets/Inline Icons/change-style-question-end.png'
import * as sentenceService from "./sentence-splitter";
import * as editListStructure from "./edit-list-structure";
import backgroundColors from '../utils/backgroundColors.js'
import backgroundGradients from '../utils/backgroundGradients.js'
import {guidEmpty} from '../utils/GuidValidate.js'
import doSort from '../utils/sort.js'
import { X } from '@mui/icons-material'

const addMarginLeft = 36


export const getLeftRightSidesOfClickedIn = (containerEl) => {
  try {
    let loop = 0
    while (containerEl.nodeName !== 'SPAN' && !containerEl.id && loop < 10) {
      containerEl = containerEl.parentElement
      loop++
    }
    if (containerEl.nodeName === 'SPAN' && !isNaN(containerEl.id)) {
      let target = document.createTextNode('\u0001')
      document.getSelection().getRangeAt(0).insertNode(target)
      let leftSideOfClickedIn
      let rightSideOfClickedIn
      if (containerEl.innerHTML.indexOf('\u0001') > -1) {
        let test = containerEl.innerHTML.indexOf('\u0001')
        leftSideOfClickedIn = containerEl.innerHTML.substring(0, containerEl.innerHTML.indexOf('\u0001'))
        rightSideOfClickedIn = containerEl.innerHTML.substring(containerEl.innerHTML.indexOf('\u0001') + 1)
      } else {
        leftSideOfClickedIn = containerEl.innerHTML
        rightSideOfClickedIn = '&nbsp;'
      }
      //Clear the right side of any end-tags that are at the beginning of the text
      let loop = 0
      while(rightSideOfClickedIn && rightSideOfClickedIn.indexOf('</') === 0 && loop < 10) {
        rightSideOfClickedIn = rightSideOfClickedIn.substring(rightSideOfClickedIn.indexOf('>')+1)
        loop++
      }

      target.parentNode.removeChild(target);
      return {
        leftSideOfClickedIn,
        rightSideOfClickedIn
      }
    }
    return {
      leftSideOfClickedIn: '',
      rightSideOfClickedIn: ''
    }
  } catch (e) {
    console.error('Error - getLeftRightSidesOfClickedIn', e)
  }
}


export const saveCursorLocation = (containerEl) => {
  try {
    let range = window.getSelection().getRangeAt(0)
    let preSelectionRange = range.cloneRange()
    let start = ''
    if (containerEl) {
      preSelectionRange.selectNodeContents(containerEl)
      preSelectionRange.setEnd(range.startContainer, range.startOffset)
      start = preSelectionRange.toString().length
    }
    let spanWithId = preSelectionRange.endContainer.parentElement
    let loop = 0
    while(spanWithId.nodeName === 'SPAN' && !spanWithId.id && loop < 7) {
      spanWithId = spanWithId.parentElement
      loop++
    }
    return {
      start: start,
      end: start + range.toString().length,
      newElement: spanWithId
    }
  } catch (e) {
    console.warn('Error - saveCursorLocation', e)
  }
}

export const getElementCursorOffset = (containerEl) => {
  try {
    let selection = window.getSelection();
    let range = selection.getRangeAt(0)
    range.setStart(range.startContainer, range.startOffset);  //There is an error with these two lines, but if we use a try-catch, it will not work properly. It still works, but the console will have an error.
    range.setEnd(range.startContainer, range.startOffset);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
    return range.startOffset
  } catch (e) {
    console.warn('Error - saveCursorLocation', e)
  }
}

export const restoreCursorLocation = (containerEl, savedSel) => {
  try {
    if (!!containerEl) { // && containerEl.dataset.type !== 'TEXT') { //I don't know if this code is accurate but it seemed to take care of a setStart error that I was getting when starting the editor click (then the tab edits were also added so the cursor wouldn't go into them)
      let charIndex = 0, range = document.createRange();
      range.setStart(containerEl, 0);
      range.collapse(true);
      let nodeStack = [containerEl], node, foundStart = false, stop = false;

      while (!stop && (node = nodeStack.pop())) { //eslint-disable-line
        if (Number(node.nodeType) === 3) {  //3 is text
          let nextCharIndex = charIndex + node.length;
          if (!foundStart && savedSel && savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
            range.setStart(node, savedSel && savedSel.start - charIndex);
            foundStart = true;
          }
          if (foundStart && savedSel && savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
            range.setEnd(node, savedSel && savedSel.end - charIndex);
            stop = true;
          }
          charIndex = nextCharIndex;
        } else {
          let i = node.childNodes.length;
          while (i--) {
            nodeStack.push(node.childNodes[i]);
          }
        }
      }

      let sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
      range.collapse(true);
    }
  } catch (e) {
    console.error('Error - restoreCursorLocation', e)
  }
}

export const enterEditorNewListItem = (element, personId, chapterId, editLanguageId, addOrUpdateEdit, getEditSegments, handleSetChosenSegment, handleSetCurrentElement) => {
  //1. Get the current elementId where the Enter key is pressed
  //2. Get the left and right side text
  //3. Create the ADDLISTITEM editSegment record
  //4. If there is a rightSide then the leftSide has changed
  //    a. Write a second editSegment record for the change of the TEXT record of the given elementId
  //5. Force the rewrite of edits to the screen with these new editSegment records
  let leftSide
  let rightSide

  //1. Get the current elementId where the Enter key is pressed
  if (element) {
    //2. Get the left and right side text
    const {leftSideOfClickedIn, rightSideOfClickedIn} = getLeftRightSidesOfClickedIn(element)
    leftSide = leftSideOfClickedIn
    rightSide = rightSideOfClickedIn

    //3. Create the ADDLISTITEM editSegment record
    addOrUpdateEdit({
      editSegmentId: 0,
      editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
      personId,
      chapterId,
      languageId: editLanguageId,
      elementId: Number(element.id),
      addListItemSequence: element.dataset.addListItemSequence ? Number(element.dataset.addListItemSequence) + 1 : '',
      text: rightSide ? rightSide : '&nbsp;____&nbsp;',
      type: 'ADDLISTITEM',
      authorTextSnapshot: '',
      isNewAddListItemSequence: true, //This is a new one that may need to have a hole made in the sequence if it is in the middle or if it is at the end this addlistSequence wlil be last. 
    }, () => setTimeout(() => setCursorPositionByRecallAddListItem(element.id, element.dataset.addListItemSequence ? Number(element.dataset.addListItemSequence) + 1 : 1), 1000), handleSetChosenSegment)

    //4. If there is a rightSide then the leftSide has changed
    //    a. Write a second editSegment record for the change of the TEXT record of the given elementId
    //5. Force the rewrite of edits to the screen with these new editSegment records
    if (rightSide || leftSide) {
      addOrUpdateEdit({
        editSegmentId: 0,
        personId,
        chapterId,
        elementId: Number(element.id),
        languageId: editLanguageId,
        editSegmentTypeId: 0, //This will be f  illed in on the server side by the type entered below
        text: leftSide,
        type: element.dataset.addListItemSequence ? 'ADDLISTITEM' : 'TEXT',
        authorTextSnapshot: element.innerHtml,
        addListItemSequence: element.dataset.addListItemSequence,
      })
    }
    setTimeout(() => getEditSegments(personId, null, chapterId, editLanguageId), 500)
    setTimeout(() => {
      let newSpanCreated = document.querySelector(`span[id="${element.id}"][data-type="ADDLISTITEM"][data-add-list-item-sequence="${element.dataset.addListItemSequence ? Number(element.dataset.addListItemSequence) + 1 : 1}"]`)
      if (newSpanCreated) {
        handleSetChosenSegment(newSpanCreated)
        handleSetCurrentElement(newSpanCreated)
      }
    }, 1000)
  }
}

export const createNewListItem = (element, getNextId, chapterListLevels) => {
  //1. Get the current elementId where the Enter key is pressed
  //    a. Get the nextSibling of the original listItem
  //2. Get any lower siblings of the current element (this includes additional spans and then the UL/OL of a collection of children
  //3. If the element is a span
  //    get the left and right side text
  //    get the paragraph elementId
  //  else if the element is a listItem
  //    get the listItem elementId
  //4. Create the new listItem element (set a new Id and copy the style from the existing LI)
  //5. Set this new listItem in place
  //6. Create a new span and give it the next elementId
  //    Finish off the end tags on the leftside back on the original span
  //    Include the extra text on the right of the previous span if it exists and include any tags on the left side which were cut off.
  //7. Add the span to the new listItem element and remove the temporary textNode
  //8. Move the rest of the lower siblings after this new span
  //9. Assign the left side back to the original element where the text might have been split with an Enter key position.
  //10. Set the cursor in the new span.

  //Help Todo: ERROR: For some reason there is a span with only a <br> in it which is being created so that two new listItems are being created.  I can't find it!!!1
  let leftSide
  let rightSide
  let siblingAndChildren = []
  let elementListItem

  //1. Get the current elementId where the Enter key is pressed
  if (element) {
    //2. Get any lower siblings of the current element
    let nextSibling = element.nextSibling
    while (nextSibling) {
      siblingAndChildren.push(nextSibling)
      nextSibling = nextSibling.nextSibling
    }

    //This is strongly assuming that the element is a span or paragraph and not the parent editorDiv
    //3. If the element is a span
    //    get the left and right side text
    //    get the paragraph elementId
    //  else if the element is a paragraph
    //    get the paragraph elementId
    if (element.nodeName === 'SPAN') {
      const {leftSideOfClickedIn, rightSideOfClickedIn} = getLeftRightSidesOfClickedIn(element)
      leftSide = leftSideOfClickedIn
      rightSide = rightSideOfClickedIn
      if (rightSide && rightSide.lastIndexOf('&nbsp;') === rightSide.length - 6) rightSide = rightSide.substring(0, rightSide.length - 6)
      elementListItem = element.parentElement
      let loop = 0
      while (!(elementListItem.nodeName === 'LI' && elementListItem.id) && loop < 10) {
        elementListItem = elementListItem.parentElement
        loop++
      }
    } else if (element.nodeName === 'LI') {
      elementListItem = element
    }
    let elementList = elementListItem.parentElement
    let nextElementListSibling = elementListItem.nextSibling

    //4. Create the new listItem element
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId()
    newListItem.setAttribute('style', elementListItem.style.cssText)

    //5. Set this new listItem in place
    if (nextElementListSibling) {
      elementList.insertBefore(newListItem, nextElementListSibling)
    } else {
      elementList.append(newListItem)
    }
    //editListStructure.setLevelStyles(newListItem, chapterListLevels, elementListItem, 1)

    //6. Create a new span and give it the next elementId
    //    Finish off the end tags on the leftside back on the original span
    //    Include the extra text on the right of the previous span if it exists and include any tags on the left side which were cut off.
    let newSpan = document.createElement('span')
    newSpan.id = getNextId()
    newSpan.setAttribute('data-type', 'TEXT')
    newSpan.setAttribute('style', element.style.cssText)
    let textNode = document.createTextNode('\u00A0') //This is important in order to set the cursor inside the span.
    newSpan.append(textNode)
    newSpan.append(!rightSide || rightSide === '&nbsp;' || rightSide === ' ' ? '\u00A0' : rightSide + '\u00A0')

    //7. Add the span to the new listItem element and remove the temporary textNode
    newListItem.append(newSpan)
    textNode.remove()

    //8. Move the rest of the lower siblings after this new span
    for (let i = 0; i < siblingAndChildren.length; i++) {
      newListItem.append(siblingAndChildren[i])
    }

    //9. Assign the left side back to the original element where the text might have been split with an Enter key position.
    element.innerHTML = !leftSide || leftSide === '&nbsp;' || leftSide === ' ' ? '\u00A0' : leftSide + '\u00A0'

    setCursorPosition(newSpan, newSpan, 0, 0)
    return newSpan
  }
}

export const createNewParagraphOnEnterKey = ({
  element, 
  getNextId, 
  isAuthor, 
  addOrUpdateEdit, 
  personId, 
  editorName, 
  workId, 
  chapterId, 
  languageId, 
  editLanguageId, 
  getEditSegments, 
  setCursorPosition, 
}) => {
  //1. Be sure that the element is a SPAN or P tag. If it doesn't have an ID, then seek upward to the parent until you find it.
  //2. Get the next span so that the new paragraph and the new span can be pegged to that element so it will be displayed before that element when the edits are displayed.
  //3. Get the current elementId where the Enter key is pressed
  //    But this might already be an ADDPARAGRAPHSENTENCE edit where the editor wants to add another ADDPARAGRAPHSENTENCE.
  //    This situation might be as simple as getting the author's elementId that the first ADDSENTENCE belongs to and then getting the current subSequence 
  //       number in order to keep adding these various ADDPARAGRAPHSENTENCE edits in order to keep adding them on. Which means that saving the new eidt
  //       to the database, we are going to need to increase the subSequence-s of any ADDPARAGRAPHSENTENCE edit below the new edit in the middle of the subsequence-s.
  //4. If the element is a span
  //    get the left and right side text
  //    get the paragraph elementId
  //  else if the element is a paragraph
  //    get the paragraph elementId
  //5. If this has a blank leftSide and the element is an author's original sentence (so that the element.dataset.type === 'TEXT') then this is really just a Create a paragraph break.
  //     If we let this go through the rest of this function, it would create a DELETESENTENCE and a new ADDPARAGRAPHSENTENCE which isn't what we intend to happen.
  //6. Create the new paragraph element (with the existing author's elementId)
  //7. Create a new span (with the existing author's elementId)
  //    Finish off the end tags on the leftside back on the original span
  //    Include the extra text on the right of the previous span if it exists and include any tags on the left side which were cut off.
  //8. Add the span to the paragraph element and the paragraph to the editorDiv
  //9. Move the rest of the children of the previous paragraph.
  //10. Add the edits to the database.
  //    But watch out for the text change from where the ENTER key was hit:
  //    This is essential here that the text is updated for either the author's original and existing segment OR the change of an ADDPARAGRAPHSENTENCE by the editor so that there isn't an additional edit entered when this is an update.
  //    The way that we determine that it is an editor edit is by including the subSequence as well setting updateTextAddParagraphSentence: element.dataset.type === 'ADDPARAGRAPHSENTENCE' which will be true when it is the editor's edit.
  //11. This is essential here that the text is updated for either the author's original and existing segment OR the change of an ADDPARAGRAPHSENTENCE by the editor so that there isn't an additional edit entered when this is an update.
  //  The way that we determine that it is an editor edit is by including the subSequence as well setting updateTextAddParagraphSentence: element.dataset.type === 'ADDPARAGRAPHSENTENCE' which will be true when it is the editor's edit.

  let leftSide
  let rightSide
  let currentParagraph
  let grandParentParagraph = getMainElement()
  
  //1. Be sure that the element is a SPAN or P tag. If it doesn't have an ID, then seek upward to the parent until you find it.
  let loop = 0
  while (!(element && element.nodeName === 'SPAN' && element.id) && loop < 7) {
    element = element.parentElement
    loop++
  }

  //2. Get the next span so that the new paragraph and the new span can be pegged to that element so it will be displayed before that element when the edits are displayed.
  //   This is going to require a sensitive search that will know that it is at the end of it's current paragraph so that it will go to the next one.
  //No. We are going to go with the span that is the target element so we are listing span/paragraph pairs AFTER the target element. ADDSENTENCES used to be before the target element.
  const nextSpan = getNextSpan(element)

  //3. Get the current elementId where the Enter key is pressed
  //    But this might already be an ADDPARAGRAPHSENTENCE edit where the editor wants to add another ADDPARAGRAPHSENTENCE.
  //    This situation might be as simple as getting the author's elementId that the first ADDSENTENCE belongs to and then getting the current subSequence 
  //       number in order to keep adding these various ADDPARAGRAPHSENTENCE edits in order to keep adding them on. Which means that saving the new eidt
  //       to the database, we are going to need to increase the subSequence-s of any ADDPARAGRAPHSENTENCE edit below the new edit in the middle of the subsequence-s.
  if (element) {
    //This is strongly assuming that the element is a span or paragraph and not the parent editorDiv
    //4. If the element is a span
    //    get the left and right side text
    //    get the paragraph elementId
    //  else if the element is a paragraph
    //    get the paragraph elementId
    const {leftSideOfClickedIn, rightSideOfClickedIn} = getLeftRightSidesOfClickedIn(element)
    leftSide = leftSideOfClickedIn
    rightSide = rightSideOfClickedIn
    currentParagraph = element.parentElement
    loop = 0
    while (!(currentParagraph.nodeName === 'P' && currentParagraph.id) && loop < 10) {
      currentParagraph = currentParagraph.parentElement
      loop++
    }

    //5. If this has a blank leftSide and the element is an author's original sentence (so that the element.dataset.type === 'TEXT') then this is really just a Create a paragraph break.
    //  If we let this go through the rest of this function, it would create a DELETESENTENCE and a new ADDPARAGRAPHSENTENCE which isn't what we intend to happen.
    if (!leftSide && element.dataset.type === 'TEXT') {
      const prevSpan = getPrevSpan(element)

      addOrUpdateEdit({
        editSegmentId: 0,
        personId: personId,
        firstName: editorName && editorName.firstName,
        lastName: editorName && editorName.lastName,
        chapterId: chapterId,
        elementId: Number(prevSpan.id), //Help ToDo: Is this right? Or should it be prevSpan or nextSpan?
        languageId: editLanguageId,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        type: 'ADDPARAGRAPH',
        authorTextSnapshot: getNewParagraphSegmentsBeforeAfter(element),
      }, () => {
        setTimeout(() => getEditSegments(personId, guidEmpty, chapterId, editLanguageId), 500)
        setTimeout(() => setCursorPositionByRecall(element.id, 'TEXT'), 1000)
      })
      let parentParagraph = element.parentElement
      const img = createParagraphPlusEditor(`/inline/paragraph-plus-${backgroundColors.currentEditorColor}.svg`, editorName, prevSpan)
      parentParagraph.insertBefore(img, prevSpan)

      // Create the new paragraph and append it to the left paragraph
      let newParagraph = document.createElement('p')
      newParagraph.id = element.id
      newParagraph.setAttribute('style', parentParagraph.style.cssText)
      let grandParent = parentParagraph.parentElement
      let nextParagraph = parentParagraph.nextElementSibling
      grandParent.insertBefore(newParagraph, nextParagraph)

      // Move any children from the left paragraph beginning with the element and children that follow, if any.
      let foundElement = false
      let children = parentParagraph.children
      for (let i = 0; i < children.length;) {
        if (!foundElement) {
          if (children[i].nodeName === 'SPAN' && children[i].id === element.id) {
            foundElement = true
            newParagraph.append(children[i])
            let space = document.createTextNode("\u00A0")
            newParagraph.append(space)
          } else {
            i++
          }
        } else {
          newParagraph.append(children[i])
        }
      }
      return //Don't go any further since this is a csae that is not going to be an ADDPARAGRAPHSENTENCE but just an ADDPARAGRAPH
    }

    //6. Create the new paragraph element (if it is the author, it will get the nextId otherwise the editor's version will get the existing author's elementId)
    let newParagraph = document.createElement('p')
    newParagraph.id = isAuthor ? getNextId() : element.id 
    newParagraph.setAttribute('data-subsequence', getElementSubSequence(element, 1)) 
    newParagraph.setAttribute('data-type', isAuthor ? 'PARAGRAPH' : 'ADDPARAGRAPHSENTENCE')
    if (currentParagraph && currentParagraph.style && currentParagraph.style.cssText) newParagraph.setAttribute('style', currentParagraph.style.cssText)

    //7. Create a new span (with the existing author's elementId)
    //    Finish off the end tags on the leftside back on the original span
    //    Include the extra text on the right of the previous span if it exists and include any tags on the left side which were cut off.
    let newSpan = document.createElement('span')
    newSpan.id = isAuthor ? getNextId() : element.id 
    newSpan.setAttribute('data-type', isAuthor ? 'TEXT' : 'ADDPARAGRAPHSENTENCE')
    newSpan.setAttribute('data-subsequence', getElementSubSequence(element, 1))
    newSpan.setAttribute('style', element.style.cssText)
    newSpan.style.backgroundColor = backgroundColors.normal //We do this here because if the current element has a colored background as a pending edit, then that would be inherited here which is a new elementId and would no longer be tied to that pending edit.
    let textNode = document.createTextNode('\u00A0') //This is important in order to set the cursor inside the span.
    newSpan.append(textNode)
    rightSide = rightSide && rightSide.replace("<o:p></o:p>", "").replace("&nbsp;", "")
    rightSide = cleanExtraEndSpans(rightSide)  
    if (!rightSide && !isAuthor) rightSide = '&nbsp;____&nbsp;'
    newSpan.append(!rightSide || rightSide === '&nbsp;' ? '\u00A0' : rightSide)
    //8. Add the span to the paragraph element and the paragraph to the editorDiv
    newParagraph.appendChild(newSpan)
    let textNodeAfter = document.createTextNode('\u00A0')
    newParagraph.appendChild(textNodeAfter)

    if (currentParagraph && currentParagraph.nextSibling) {
      grandParentParagraph.insertBefore(newParagraph, currentParagraph.nextSibling)
    } else {
      grandParentParagraph.appendChild(newParagraph)
    }
    setCursorPosition(newSpan, newSpan, 0, 0)
    textNode.remove()

    //9. Move the rest of the children of the previous paragraph.
    let firstMove = true
    if (element.nodeName === 'SPAN') {
      let foundOriginalSpan = false
      for (let i = 0; i < currentParagraph.children.length;) {
        if (!foundOriginalSpan) {
          if (currentParagraph.children[i].id === element.id) {
            foundOriginalSpan = true
          }
          i++  //Notice that we only increment here because down below where the nodes are moved, the length is decrementing
        } else {
          if (firstMove) {
            firstMove = false
          }
          newParagraph.appendChild(currentParagraph.children[i])
          let textNode = document.createTextNode('\u00A0')
          newParagraph.appendChild(textNode)
        }
      }
      element.innerHTML = !leftSide || leftSide === '' || leftSide === '&nbsp;' ? '\u00A0' : leftSide
    }
    //If the newParagraph has more children than the newSpan, then let's delete the newSpan since it is going to just make space that is unnecessary
    let editElementId
    let firstChild
    firstChild = newParagraph.firstChild
    loop = 0
    while (!(firstChild && firstChild.id) && loop < 7) {
      firstChild = firstChild.nextSibling
      loop++
    }
    editElementId = firstChild.id

    //10. Add the edits to the database.
    if (!isAuthor) {
      const newSubSequence = getElementSubSequence(element, 1)

      addOrUpdateEdit({
        editSegmentId: 0,
        personId: personId,
        firstName: editorName && editorName.firstName,
        lastName: editorName && editorName.lastName,
        chapterId: chapterId,
        elementId: Number(element.id),
        languageId: editLanguageId,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        type: 'ADDPARAGRAPHSENTENCE',
        text: rightSide ? rightSide : isAuthor ? '' : '&nbsp;____&nbsp;',
        authorTextSnapshot: getNewParagraphSegmentsBeforeAfter(element),
        styleSnapshot: element.parentElement.style.cssText,
        isEditorAddParagraph: true,
        subSequence: newSubSequence,
      }, () => {
        setTimeout(() => getEditSegments(personId, workId, chapterId, editLanguageId), 500)
        setTimeout(() => setCursorPositionByRecallAddParagraphSentence(element.id, newSubSequence), 1000)
      })
    }

    //11. This is essential here that the text is updated for either the author's original and existing segment OR the change of an ADDPARAGRAPHSENTENCE by the editor so that there isn't an additional edit entered when this is an update.
    //  The way that we determine that it is an editor edit is by including the subSequence as well setting updateTextAddParagraphSentence: element.dataset.type === 'ADDPARAGRAPHSENTENCE' which will be true when it is the editor's edit.
    if (!isAuthor) {
      //Change the original sentence (if there is a rightSide taken from it.)
      if (rightSide) { //Then the left side has changed
        addOrUpdateEdit({
          editSegmentId: 0,
          personId: personId,
          firstName: editorName && editorName.firstName,
          lastName: editorName && editorName.lastName,
          chapterId: chapterId,
          elementId: Number(element.id),
          languageId: editLanguageId,
          editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
          text: leftSide,
          type: element.dataset.type,  //This could be 'TEXT' or 'ADDPARAGRAPHSENTENCE', which would mean the author's original segment or if it is a stacked ADDPARAGRAPHSENTENCE edit that is changing, respectively.
          authorTextSnapshot: element.innerHtml,
          subSequence: getElementSubSequence(element), //if there is a subSequence, then this is the record that needs to be found to update the text. Also, the type should be set to 'ADDSENTENCE' to match the edit 
          updateTextAddParagraphSentence: element.dataset.type === 'ADDPARAGRAPHSENTENCE' //If we don't do this check here, then this will add another entry to the subsequence when the user has hit enter inside a previously entered ADDPARAGRAPHSENTENCE
        }, () => setTimeout(() => getEditSegments(personId, workId, chapterId, languageId), 500))
      }
    }
    return newSpan
  }
}

export const setSegments = (divDisplayId, segments, workSummary, edits = [], isAuthor = true, personId, editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab, isTranslation) => {
  let divDisplay = document.getElementById(divDisplayId)
  if (divDisplay) {
    divDisplay.innerHTML = '';
    let parent; //Keep the current paragraph (or UL/OL/LI) as a parent to be used when creating the sentences and images
    setEditorDivStyles(workSummary)

    segments && segments.length > 0 && segments.forEach((segment) => {
      if (segment.type === 'HEAD') {
        divDisplay.innerHTML = segment.text
        divDisplay.spellCheck = 'true'

      } else if (segment.type === 'BODY') {
        let body = document.createElement('div') //Make this a div because it has document-wide styles, most likely, and the contenteditable will cut out a "body" tag.
        if (segment.styleClass) body.setAttribute('class', segment.styleClass)
        if (segment.styleInline) body.setAttribute('style', segment.styleInline)
        body.id = divDisplayId === 'tabView' ? segment.elementId + '~tabView' : segment.elementId
        body.setAttribute('data-main-body-tag', divDisplayId === 'tabView' ? 'yes~tabView' : 'yes') //Word conversion files have a div tag with this data attribute. The parent/child loop below will not work without this div
        body.spellCheck = 'true'
        divDisplay.append(body)
        parent = body

      } else if (segment.type === 'TEXT' || segment.type === 'TAB' || segment.type === 'ADDTAB') {
        parent = setSegmentSpan(parent, segment, divDisplayId, edits, isAuthor, personId, editorName, tabsData, getNextId)
        //I changed this so that the parent is not returned since we are not surgically altering the segments here with paragraph edits. We do this in the insertAddSentenceAndParagraphIcons below.

      } else if (segment.type === 'PARAGRAPH') {
        parent = setSegmentParagraph(divDisplayId, segment, edits, isAuthor, parent, personId, editorName, tabsData, isTranslation)

      } else if (segment.type === 'OL' || segment.type === 'UL' || segment.type === 'LI') {
        parent = setSegmentListItem(divDisplayId, segment, edits, isAuthor, parent, personId, editorName, tabsData)

      } else if (segment.type === 'IMAGE') {
        setSegmentImage(parent, segment)
      }
    })

    showCommentBubbles(personId, divDisplayId, edits, segments, chosenTab, tabsData, editorName)
    if (!isTranslation) {
      setMoveIcons(personId, divDisplayId, edits, chosenTab, tabsData, isAuthor)
      //Above, the move icons are created, but in setSegmentsWithEdits the moved sentences are placed for the editor owner: moveSentencesInSegments(newSegments, editMoves[i])
      setChangeStyleIcons(personId, divDisplayId, edits, chosenTab, tabsData, isAuthor)
      edits && edits.length > 0 && edits.filter(m => m.type === 'CHANGESTYLE' && ((m.personId === personId && divDisplayId === 'editorDiv') || (m.personId === chosenTab && divDisplayId === 'tabView'))).map(edit => {
        setChangeStylesForEditor_DOM(divDisplayId, segments, edit.textStyleChangeEntries, edit.changeStyleEntireDoc, edit.changeStyleSegmentsArray, edit.chapterId) 
      })
      insertAddSentenceAndParagraphIcons(personId, divDisplay, edits, tabsData, editorName, chosenTab)
      insertAddSentenceIcons(personId, divDisplayId, edits, tabsData, editorName, chosenTab)
      insertDeleteSentenceIcons(personId, divDisplay, edits, tabsData, editorName)
      insertTabsIconsAndAdjustParagraphStyles(divDisplay.id, edits, tabsData, personId, editorName)
      fillInEmptyParagraphsWithSpan(divDisplay.id, edits, tabsData, personId, editorName)
      highlightSpanTextEdits(personId, chosenTab, edits)
      insertAddListItemIcons(personId, divDisplayId, edits, tabsData, editorName, chosenTab)
      insertDeleteListItemIcons(personId, divDisplay, edits, tabsData, editorName)
      highlightAndAdjustListItemEdits({
        divDisplay,
        edits,
        tabsData,
        personId,
        chapterId: workSummary ? workSummary.chapterId_current && workSummary.chapterId_current !== guidEmpty ? workSummary.chapterId_current : workSummary.chapterOptions && workSummary.chapterOptions.length > 0 && workSummary.chapterOptions[0].chapterId : guidEmpty,
        editorName,
        getNextId,
        chapterListLevels,
        listLevelGeneral,
        addChapterListLevels,
        chosenTab,
      })
      setReorderListItemsMovesAndIcons_DOM(personId, divDisplayId, edits, tabsData, editorName, chosenTab)  //The reorder has to happen after the listItems are moved in case there is a left or right move for a list item that is also be reordered.
      setParagraphSingleStyles(personId, divDisplay, edits, tabsData, editorName)
    }
  }
}

export const setSegmentsWithEdits = (divDisplayId, segments, edits = [], personId, workSummary, editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab) => {
  let isAuthor = false
  let newSegments = []

  segments && segments.length > 0 && segments.forEach(m => {
    let segment = {...m}
    let editText = getThisUserEditsForText(segment.elementId, personId, edits)
    if (editText) {
      segment.text = editText.text ? editText.text : segment.text //Because this could be a comment only if this text is blank
    }
    newSegments.push(segment)
  })
  //Redirect an editor's MOVE segments
  if (newSegments && newSegments.length > 0) {
    let editMoves = edits && edits.length > 0 && edits.filter(e => ((divDisplayId === 'tabView' && e.personId === chosenTab) || (e.personId === personId)) && e.type === 'MOVE')
    for (let i = 0; i < editMoves.length; i++) {
      newSegments = moveSentencesInSegments(newSegments, editMoves[i])
    }
    setSegments(divDisplayId, [...newSegments], workSummary, edits, isAuthor, personId, editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab)
  }
}

const insert = (arr, index, ...newItems) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted items
  ...newItems,
  // part of the array after the specified index
  ...arr.slice(index)
]

export const setSegmentImage = (paragraph, segment) => {
  try {
    let image = document.createElement('img');
    image.id = segment.elementId;
    image.src = segment.imageSource;
    if (segment.imageHeight) image.height = segment.imageHeight;
    if (segment.imageWidth) image.width = segment.imageWidth;
    if (segment.imageBorder) image.style.border = segment.imageBorder;
    //imageLink
    //imageStyle

    if (paragraph.isOrderedList || paragraph.isUnorderedList) { //ToDo this isn't right here. If there is a paragraph in the segments, it would have been saved with the workSegments. THere also needs to be an Id here for the element so that the saving to DB will work since an Id is expected when finding parent relationships.
      let listItem = document.createElement('li');
      listItem.append(image);
    } else {
      paragraph.append(image);
    }
  } catch (e) {
    console.error('Error - setSegmentImage', e)
  }
}

export const setCursorPosition = (startNode, endNode, startOffset, endOffset) => {
  let range = document.createRange();
  let selection = window.getSelection();

  if (startNode instanceof Node && endNode instanceof Node && document.contains(startNode) && document.contains(endNode)) {
    range.setStart(startNode, startOffset);
    range.setEnd(endNode, endOffset);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  // } else {
  //   console.l og("Skipped error: One or both nodes are not valid or not in the document");
  }
}

export const addWebsiteEntryElement = (websiteLink, linkDisplayText) => {
  try {
    let selection = window.getSelection();
    if (selection.rangeCount) {
      for (let i = 0, len = selection.rangeCount; i < len; ++i) {
        selection.getRangeAt(i).cloneContents();
      }
    }

    let parentElement = selection.anchorNode;
    while (parentElement && !(parentElement.nodeName === 'SPAN' && parentElement.id)) {
      parentElement = parentElement.parentElement;
    }
    if (parentElement) {
      let anchorTag = document.createElement('a')
      anchorTag.href = websiteLink
      anchorTag.setAttribute('data-href-link', websiteLink) //We need this here because the clickable value is overwritten in penspring so if we called up anchorNode.href, it would give us a Penspring link to the page - not the href that is intended in the end to be the link.
      let textNode = document.createTextNode(linkDisplayText)
      anchorTag.append(textNode)
      let sel = window.getSelection();
      if (sel.rangeCount) {
        let range = sel.getRangeAt(0);
        range.collapse(false);
        range.insertNode(anchorTag);
        range = range.cloneRange();
        range.selectNodeContents(anchorTag);
        //range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);
      }
      //setCursorPosition(span, span, position, position)
    }
  } catch (e) {
    console.error('Error - addWebsiteEntryElement', e)
  }
}

export const setEditorDivStyles = (workSummary) => {
  try {
    let editorDiv = document.getElementById('editorDiv')
    if (editorDiv) {
      if (workSummary.defaultFontName) {
        editorDiv.style.fontFamily = workSummary.defaultFontName
      }
      if (workSummary.defaultFontSize && workSummary.defaultFontSize > 0) {
        editorDiv.style.fontSize = `${workSummary.defaultFontSize}px`;
      }
      if (workSummary.defaultFontColor) {
        editorDiv.style.color = workSummary.defaultFontColor;
      }
    }
  } catch (e) {
    console.error('Error - setEditorDivStyles', e)
  }
}

export const setSegmentSpan = (paragraph, segment, divDisplayId, edits = [], isAuthor, personId, editorName, tabsData = [], getNextId) => {
  // try {
  let editorDiv = document.getElementById(divDisplayId)
  let span = document.createElement('span')
  let edit = edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(segment.elementId) && m.type === 'TEXT')[0]
  //let addSentenceEdit = edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(segment.elementId) && m.type === 'ADDSENTENCE')[0]
  let editIsEditorAddParagraph = edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(segment.elementId) && m.type === 'ADDPARAGRAPH' && m.personId === personId)[0]  //m.isEditorAddParagraph  I'm not sure what this value is for? It seems like it might be for ADDSENTENCE?
  span.id = divDisplayId === 'editorDiv' ? segment.elementId : segment.elementId + '~' + divDisplayId
  if (!!segment.text) { //This could be a comment only if the text is blank.
    span.innerHTML = segment.text
    if (span.innerHTML !== '') span.innerHTML += " " //This needs a space to separate it from the next sentence.
  }
  if (segment.styleClass) span.setAttribute('class', segment.styleClass)
  if (segment.styleInline) span.setAttribute('style', segment.styleInline)
  span.style['margin-right'] = '0px'
  let isTextTabSpan = segment.type === 'TEXT' && (span.innerHTML === '&nbsp;' || !span.innerHTML) && span.style['width'] === '36pt' //Sometimes a tabSpan can be hidden by being called a 'TEXT' type in the segment rather than 'TAB'. Somehow it got missed.
  span.setAttribute('data-type', segment.isAddSentence ? 'ADDSENTENCE' : isTextTabSpan ? 'TAB' : segment.type)
  span.style.backgroundColor = setBackgroundColor(span, edit, segment)
  span.style.borderRadius = '3px'
  span.spellCheck = 'true'

  //ADDPARAGRAPH
  //If this is the editor who has made an ADDPARAGRAPH edit, then create the paragraph and put in the icon
  //For other editors (and the author) put in the icon without creating the new paragraph
  //Yet, we have some additional logic that is an exception to the previous standard: An editor's new paragraph with a new sentence which is marked as isEditorAddParagraph flag in the EditSegment record.
  //  In this case, a split sentence will need the leftSide to stay in the old paragraph, the addParagraph icon to follow, and then the new Paragraph will have a new sentence which is the rightSide of that original sentence.
  //  When we say the leftSide stays, this is different logic because the leftSide is, essentially, the anchor sentence which the AddParagraph is attached to BEFORE the anchor. So now the AddParagraph icon is going AFTER the anchor.
  //  So, one of the significant logic changes here will be that any ADDSENTENCE marked as isEditorAddParagraph will also be added here and then ignored in the ADDSENTENCE insert logic elsewhere.
  if (editIsEditorAddParagraph) {
    paragraph.append(span) //This is the leftSide of the isEditorAddParagraph TEXT change to be assigned to the end of the previous paragraph where the Editor would have hit the ENTER key to split off that sentence while making a new paragraph.
  }
  let { returnParagraph, removeSpace } = insertAddParagraphIcons(personId, editorDiv, paragraph, span, segment, edits, tabsData, editorName, getNextId, editIsEditorAddParagraph)
  if (returnParagraph) paragraph = returnParagraph
  //The rightSide (if any) of the isEditorAddParagraph ADDSENTENCE needs to go at the beginning of the new paragraph (not at the beginning of the anchor sentence which is going to hold the leftSide of the (potentially) split sentence instigated by the editor.

  //This is the more common logic where the paragraph starts BEFORE the anchor sentence when this is not an isEditorAddParagraph
  if (paragraph && !(editIsEditorAddParagraph && editIsEditorAddParagraph.personId === personId)) {
    paragraph.append(span)
    if (removeSpace) paragraph.firstChild.remove() //There is a space put on an ADDPARAGRAPH paragraph to get it started until the first span can be appended. Then it can be removed otherwise it will put a space at the beginning which will be noticeable and causing the paragraph to be misaligned.
  }

  return paragraph
}

export const setSegmentParagraph = (divDisplayId, segment = [], edits = [], isAuthor, previousParagraph, personId, editorName, tabsData = [], isTranslation) => {
  try {
    //DELETEPARAGRAPH:
    //If this is the editor who has made a DELETEPARAGRAPH edit, then take out the paragraph and put in the icon
    //However, the other editor's need to be able to see the other editors' DELETEPARAGRAPH edit icons
    //  For the current editor, cut out the paragraph but still line up the others' icons (so they can see that others agreed)
    //  For the other editors, put in the icon although the paragraph is still there.
    const editDeleteParagraph = edits && edits.length > 0 && edits.filter(e => e.personId === personId && Number(e.elementId) === Number(segment.elementId) && e.type === 'DELETEPARAGRAPH')[0]
    const editAddParagraph = edits && edits.length > 0 && edits.filter(e => e.personId === personId && Number(e.elementId) === Number(segment.elementId) && e.type === 'ADDPARAGRAPH')[0]

    //DELETEPARAGRAPH for the owner of the edit
    if (!(isTranslation && isTranslation.languageName) && editDeleteParagraph) {
      //We are skipping the paragraph break and putting in this image and sending back the previousParagraph element rather than the one that we are skipping
      let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === personId)[0]) || { id: '', label: '', editorColor: '', editorName: '' }
      let currentEditorName = editor && editor.editorName && editor.editorName.firstName ? editor.editorName : editorName
      let editorColor = getEditorColor(personId, tabsData, 'withoutSymbol')
      let img = createParagraphMinusEditor(`/inline/paragraph-minus-${editorColor}.svg`, currentEditorName, segment.elementId)
      previousParagraph.append(img)
      let otherDeleteParagraphs = edits && edits.length > 0 && edits.filter(e => e.personId !== personId && Number(e.elementId) === Number(segment.elementId) && e.type === 'DELETEPARAGRAPH')
      otherDeleteParagraphs && otherDeleteParagraphs.length > 0 && otherDeleteParagraphs.forEach(m => {
        let editorColor = getEditorColor(m.personId, tabsData, 'withoutSymbol')
        let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === m.personId)[0]) || {
          id: '',
          label: '',
          editorColor: '',
          editorName: ''
        }
        let img = createParagraphMinusEditor(`/inline/paragraph-minus-${editorColor}.svg`, editor.editorName, segment.elementId)
        previousParagraph.append(img)
      })
      return previousParagraph
    } else {
      let paragraph = document.createElement('p')
      paragraph.id = divDisplayId === 'tabView' ? segment.elementId + '~tabView' : segment.elementId
      if (segment.styleClass) paragraph.setAttribute('class', segment.styleClass)
      if (segment.styleInline) paragraph.setAttribute('style', segment.styleInline)
      //if (editAddParagraph) paragraph.setAttribute('data-subsequence', editAddParagraph.subSequence)  //Help ToDo: I don't think this needs to happen. An editor can keep writing sentences in the original ADDSENTENCE. We are not going to keep track of consecutive ADDSENTENCES. That is not the case with ADDPARAGRAPHSENTENCE which is why we bother with subsequence.  

      if (!(isTranslation && isTranslation.languageName)) {
        //DELETEPARAGRAPH
        let otherDeleteParagraphs = edits && edits.length > 0 && edits.filter(e => e.personId !== personId && Number(e.elementId) === Number(segment.elementId) && e.type === 'DELETEPARAGRAPH')
        otherDeleteParagraphs && otherDeleteParagraphs.length > 0 && otherDeleteParagraphs.forEach(m => {
          let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === m.personId)[0]) || {
            id: '',
            label: '',
            editorColor: '',
            editorName: ''
          }
          let editorColor = (editor.editorColor && editor.editorColor.replace('#', '')) || backgroundColors.currentEditorColor
          let img = createParagraphMinusEditor(`/inline/paragraph-minus-${editorColor}.svg`, m.firstName + ' ' + m.lastName , paragraph.id)
          previousParagraph.append(img)
        })
      }

      let parent
      if (segment.parentElementId) parent = document.getElementById(divDisplayId === 'tabView' ? segment.parentElementId + '~tabView' : segment.parentElementId)
      if (parent) {
        parent.append(paragraph)
      } else {
        getMainElement(divDisplayId).append(paragraph)
      }
      return paragraph
    }
  } catch (e) {
    console.error('Error - setSegmentParagraph', e)
  }
}

export const setSegmentListItem = (divDisplayId, segment, edits, isAuthor, parent, personId, editorName, tabsData) => {
  try {
    let editListItem // = edits && edits.length > 0 && edits.filter(e => e.personId === personId && Number(e.elementId) === Number(segment.elementId) && e.type === 'DELETEPARAGRAPH')[0]

    if (editListItem) {

    } else {
      let listElement = document.createElement(segment.type.toLowerCase())
      listElement.id = divDisplayId === 'tabView' ? segment.elementId + '~tabView' : segment.elementId
      if (segment.styleClass) listElement.setAttribute('class', segment.styleClass)
      if (segment.styleInline) listElement.setAttribute('style', segment.styleInline)

      let parent
      if (segment.parentElementId) parent = document.getElementById(divDisplayId === 'tabView' ? segment.parentElementId + '~tabView' : segment.parentElementId)
      if (parent) {
        parent.append(listElement)
      } else {
        getMainElement(divDisplayId).append(listElement)
      }
      return listElement
    }

  } catch (e) {
    console.error('Error - setSegmentListItem', e)
  }
}

export const setListHtml = (currentElement, listType, saveRevision, getNextId, listLevels, handleSetChosenSegment, savedCursorPosition) => {
  //1. Save a revision
  //2. Get the List level records according to the listType
  //3. Get the selection location in editorDiv
  //4. Create the UL or OL by listType
  //5. Append a new LI
  //6. Append a new Span tag with a initiating textNode
  //7. Place the cursor in the span tag
  saveRevision()
  if (!currentElement || currentElement.id === '1' || currentElement === 'editorDiv') currentElement = savedCursorPosition.newElement
  let parent = currentElement && currentElement.parentElement
  if (parent) {
    let grandParent = parent.parentElement
    //let greatGrandParent = grandParent.parentElement
    let parentNextSibling
    let parentInsert
    if (parent.nodeName === 'P') {
      parentNextSibling = parent.nextSibling
      parentInsert = parent.parentElement
      parent.remove()
    } else if (grandParent.nodeName === 'P') {
      parentNextSibling = grandParent.nextSibling
      parentInsert = grandParent.parentElement
      grandParent.remove()
    }
    let levels = listLevels && listLevels.length > 0 && listLevels.filter(m => m.listGroup === listType)
    let list = document.createElement(listType)
    list.id = getNextId()
    let listStyle = levels.filter(m => m.listType === listType && m.level === 1)[0]
    list.setAttribute('style', listStyle && listStyle.styleInline)

    let listItem = document.createElement('LI')
    listItem.id = getNextId()
    listStyle = levels.filter(m => m.listType === 'LI' && m.level === 1)[0]
    listItem.setAttribute('style', listStyle && listStyle.styleInline)

    let textNode = document.createTextNode('\u00A0') //This is important in order to set the cursor inside the new tag.

    let newSpan = document.createElement('span')
    newSpan.id = getNextId()
    newSpan.setAttribute('data-type', 'TEXT')
    newSpan.setAttribute('style', 'font-family: Calibri; border-radius: 3px;')
    newSpan.append(textNode)
    listItem.append(newSpan)
    list.append(listItem)
    if (parentInsert) parentInsert.insertBefore(list, parentNextSibling)
    handleSetChosenSegment(newSpan)

    setTimeout(() => {
      newSpan.focus()
      setCursorPosition(newSpan, newSpan, 1, 1)
    }, 100)
  }
}

export const setFormatStyleHtml = (type, getNextId, fontValue = '') => {
  try {
    let html = ""
    let beginTag = ""
    let endTag = ""
    let tagNodeName = ""
    let container = document.createElement("div")
    let selection = window.getSelection()
    if (selection.rangeCount) {
      for (let i = 0, len = selection.rangeCount; i < len; ++i) {
        container.appendChild(selection.getRangeAt(i).cloneContents());
      }
      html = container.innerHTML
    }

    if (selection) {
      if (type === 'bold') {
        beginTag = "<b>"
        endTag = "</b>"
        tagNodeName = "B"
      }
      if (type === 'italic') {
        beginTag = "<i>"
        endTag = "</i>"
        tagNodeName = "I"
      }
      if (type === 'underline') {
        beginTag = "<u>"
        endTag = "</u>"
        tagNodeName = "U"
      }
      if (type === 'strikeout') {
        beginTag = "<s>"
        endTag = "</s>"
        tagNodeName = "S"
      }

      if (type === 'fontName') {
        beginTag = `<code style="font-family: ` + fontValue + `; display:inline">`
        endTag = `</code>`
        tagNodeName = "CODE"
      }
      if (type === 'fontSize') {
        beginTag = `<kbd style="font-size: ` + fontValue + `pt; display:inline; font-family:inherit;">`
        endTag = `</kbd>`
        tagNodeName = "KBD"
      }
      if (type === 'fontColor') {
        beginTag = `<code style="color: ` + fontValue + `; display:inline; font-family:inherit;">`
        endTag = `</code>`
        tagNodeName = "PRE"
      }

      //If the selection is made within a paragraph, the children will be spans.
      //If the selection is made over a paragraph border, then children will be paragraphs. We need to collect the paragraphs and send them as children.

      let children = container.children;
      if (children.length > 0 && children[0].nodeName === 'P') {
        let spans = []
        //First, get any spans that might be on the same level of paragraphs. We hope to control the HTML enough that this would not be the case.
        for (let i = 0; i < children.length; i++) {
          if (children[i].nodeName === 'SPAN') spans.push(children[i])
        }

        for (let i = 0; i < children.length; i++) {
          for (let iP = 0; iP < children[i].children.length; iP++) {
            spans.push(children[i].children[iP]);
          }
        }
        children = spans;
      }

      if (children.length > 0 && html.indexOf('<span') > -1) {
        for (let i = 0; i < children.length; i++) {
          let child = document.querySelector(`span[id="${children[i].id}"][data-type="TEXT"]`) //renew the child span reference since that seems to be something related to having the editorDiv rebuilt and losing some integral information to the latest version of that span.
          if (child && (html.indexOf(beginTag) > -1 || html.indexOf(endTag) > -1 || hasParentNodeName(child, tagNodeName))) {
            let selectedHtml = "";
            for (let i = 0, len = selection.rangeCount; i < len; ++i) {
              if (selection.getRangeAt(i).cloneContents().getElementById(child.id))
                selectedHtml = selection.getRangeAt(i).cloneContents().getElementById(child.id).innerHTML;
            }
            removeFormatStyle(child, selectedHtml, selection, beginTag, endTag, tagNodeName)
          } else {
            if ((child.nodeName === 'SPAN' || child.nodeName === 'P') && child.id) {
              replaceSelectedHtml(selection, html, child.id, beginTag, endTag);
            } else if (child.children.length > 0) {
              for (let i2 = 0; i2 < child.children.length; i2++) {
                let lowerChild = child.children[i2];
                if ((lowerChild.nodeName === 'SPAN' || lowerChild.nodeName === 'P') && lowerChild.id) {
                  replaceSelectedHtml(selection, html, lowerChild.id, beginTag, endTag);
                }
              }
            }
          }
        }
      } else if (html === '') { //Start a new format tag (b, i, u or strikeout)
        let parentElement = selection.anchorNode;
        let loop = 0
        while (parentElement && !(parentElement.nodeName === 'SPAN' && parentElement.id) && loop < 10) {
          parentElement = parentElement.parentElement;
          loop++
        }
        if (parentElement) {
          let span = document.getElementById(parentElement.id)
          let position = getCursorPosition(span)
          let styleTag = document.createElement(tagNodeName)
          insertNodeAtCaret(styleTag)
          setCursorPosition(span, span, position, position)
          //styleTag.innerHTML = ""
        }
      } else if (html.indexOf('<span') === -1) {
        let parentElement = selection.anchorNode;
        let loop = 0
        while (parentElement && !((parentElement.nodeName === 'SPAN' || parentElement.nodeName === 'P') && parentElement.id) && loop < 10) {
          parentElement = parentElement.parentElement;
          loop++
        }
        if (!parentElement) { //Then it is most likely a new segment without a span with an id. it is a text node.
          let replacementNode = document.createElement('span');
          replacementNode.id = getNextId();
          replacementNode.innerHTML = selection.anchorNode.textContent; //This innerHTML is being set just three lines down.
          selection.anchorNode.parentNode.insertBefore(replacementNode, selection.anchorNode);
          selection.anchorNode.parentNode.removeChild(selection.anchorNode);
          replacementNode.innerHTML = replacementNode.innerHTML.replace(html, beginTag + html + endTag);
        } else {
          //If the html has a beginTag and endTag in it OR if this text belongs inside of the format style node (which means that the style is already applied so we need to undo it)
          //  Is the endTag at the end?
          //    Then create variables for 1) the full html as it is (searchHtml1), 2) the html without the end tag (searchHtml2)
          //    And create a new variable with the begin tag moved to the end which will replace the end tag with the begin tag (newHtml)
          //  Or, is the endTag at the beginning?
          //    Then create variables for 1) the full html as it is (searchHtml1), 2) the html without the begin tag (searchHtml2)
          //    And create a new variable with the end tag moved to the beginning which will replace the begin tag with the end tag (newHtml)
          // Search on both of those variables, searchHtml1 and searchHtml2, and replace the found text with newHtml
          //Or, just do the replace which must be new to that selection of text without any pre-existing style of this format type
          if (html.indexOf(beginTag) > -1 || html.indexOf(endTag) > -1 || hasParentNodeName(selection.anchorNode, tagNodeName)) {
            removeFormatStyle(selection.anchorNode, html, selection, beginTag, endTag, tagNodeName)
          } else if (parentElement.innerHTML.toString().indexOf(html) > -1 && html.length > 0) {
            parentElement.innerHTML = parentElement.innerHTML.replace(html, beginTag + html + endTag)
          } else {
            //should we use parentFullElement here?
            let testSelectedHtml = stripHtmlTagsBeginEnd(parentElement, html, html)
            parentElement.innerHTML = parentElement.innerHTML.replace(testSelectedHtml, beginTag + html + endTag)
            // while (parentElement.innerHTML.toString().indexOf(html) === -1 && html.length > 0) {
            // 	html = html.substring(1); //Cut off the first character in case a tag was put on that completed the text to be HTML when a selection was made between tags such as <b> and </b>
            // 	loop++;
            // 	parentElement.innerHTML = parentElement.innerHTML.replace(html, beginTag + html + endTag)
            // }
          }
        }
      } else {
        let loop = 0;
        let span = selection.anchorNode.parentElement;
        while (span.innerHTML.indexOf(html) === -1 && html.length > 0 && loop < 10) {
          html = html.substring(1); //Cut off the first character in case a tag was put on that completed the text to be HTML when a selection was made between tags such as <b> and </b>
          loop++;
        }
        span.innerHTML = span.innerHTML.replace(html, beginTag + html + endTag)
      }
    }
    selection.empty()
  } catch (e) {
    console.error('Error - setFormatStyleHtml', e)
  }
}

export const getCursorPosition = (node) => {
  try {
    let hasFirstNBSP = node.innerHTML.indexOf('&nbsp;') === 0
    const selection = window.getSelection()
    if (selection.rangeCount !== 0) {
      const range = window.getSelection().getRangeAt(0)
      const preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(node)
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      let position = preCaretRange.toString().length
      return hasFirstNBSP && position === 1 ? 0 : position
    }
  } catch (e) {
    console.error('Error - getCursorPosition', e)
  }
}

const insertNodeAtCaret = (node) => {
  try {
    let textNode = document.createTextNode('\u00A0') //This is important in order to set the cursor inside the new tag.
    node.append(textNode)

    if (typeof window.getSelection != "undefined") {
      let sel = window.getSelection()
      if (sel.rangeCount) {
        let range = sel.getRangeAt(0)
        range.collapse(false)
        range.insertNode(node)
        range = range.cloneRange()
        range.selectNodeContents(node)
        //range.collapse(false)
        sel.removeAllRanges()
        sel.addRange(range)
      }
    } else if (typeof document.selection != "undefined" && document.selection.type !== "Control") {
      let html = (Number(node.nodeType) === 1) ? node.outerHTML : node.data
      let id = "marker_" + ("" + Math.random()).slice(2)
      html += '<span id="' + id + '"></span>'
      let textRange = document.selection.createRange()
      textRange.collapse(false)
      textRange.pasteHTML(html)
      let markerSpan = document.getElementById(id)
      textRange.moveToElementText(markerSpan)
      textRange.select()
      markerSpan.parentNode.removeChild(markerSpan)
    }
  } catch (e) {
    console.error('Error - insertNodeAtCaret', e)
  }
}

const hasParentNodeName = (element, tagNodeName) => {
  try {
    let loop = 0
    if (tagNodeName === 'SPAN' && element.nodeName === tagNodeName && element.dataset.font === 'yes') {
      return true
    } else if (tagNodeName !== 'SPAN' && element.nodeName === tagNodeName) {
      return true
    } else {
      let parentElement = element.parentElement
      while (parentElement && loop < 10) {
        if (parentElement.nodeName === tagNodeName) {
          return (tagNodeName === 'SPAN' && parentElement.id)  //If this is a span tag parent but it has an Id, then no: This is not a pre-existing span tag that needs to be cleared.
        }
        parentElement = parentElement.parentElement
      }
    }
    return false;
  } catch (e) {
    console.error('Error - hasParentNodeName', e)
  }
}

const replaceSelectedHtml = (selection, html, childId, beginTag, endTag) => {
  try {
    let selectedHtml = '';
    for (let i = 0, len = selection.rangeCount; i < len; ++i) {
      if (selection.getRangeAt(i).cloneContents().getElementById(childId)) selectedHtml = selection.getRangeAt(i).cloneContents().getElementById(childId).innerHTML;
    }
    let span = document.querySelectorAll(`[id="${childId}"][data-type="TEXT"]`)[0]
    let testSelectedHtml = stripHtmlTagsBeginEnd(span, html, selectedHtml)
    span.innerHTML = span.innerHTML.replace(testSelectedHtml, beginTag + testSelectedHtml + endTag)
  } catch (e) {
    console.error('Error - replaceSelectedHtml', e)
  }
}

const stripHtmlTagsBeginEnd = (span, html, selectedHtml) => {
  try {
    let testSelectedHtml = selectedHtml
    let loop = 0
    while (span.innerHTML.indexOf(testSelectedHtml) === -1 && html.length > 0 && loop < 10) {
      if (testSelectedHtml.indexOf('<') === 0) { //If this text starts with a symbol of the beginning of an HTML tag, let's delete the entire tag to start with - but not any text.
        testSelectedHtml = testSelectedHtml.substring(testSelectedHtml.indexOf('>') + 1); //Cut off the first HTML tag in case a tag was put on which completed the text to be HTML when a selection was made between tags such as <b> and </b>
      }
      loop++
    }

    if (span.innerHTML.indexOf(testSelectedHtml) === -1) {
      //Back: Take text off the back to get delete html tags that won't match.
      testSelectedHtml = selectedHtml
      loop = 0
      while (span.innerHTML.indexOf(testSelectedHtml) === -1 && html.length > 0 && loop < 10) {
        if (testSelectedHtml.lastIndexOf('>') === testSelectedHtml.length - 1) { //If this text starts with a symbol of the beginning of an HTML tag, let's delete the entire tag to start with - but not any text.
          testSelectedHtml = testSelectedHtml.substring(0, testSelectedHtml.lastIndexOf('<')); //Cut off the first HTML tag in case a tag was put on which completed the text to be HTML when a selection was made between tags such as <b> and </b>
        }
        loop++
      }
    }
    return testSelectedHtml
  } catch (e) {
    console.error('Error - stripHtmlTagsBeginEnd', e)
  }
}

const removeFormatStyle = (element, html, selection, beginTag, endTag, tagNodeName) => {
  try {
    let searchHtml1 = html
    let searchHtml2 = html
    let searchHtml3 = html
    let newHtml = html
    let parentElement = element;

    while (parentElement && !((parentElement.nodeName === 'SPAN' || parentElement.nodeName === 'P') && parentElement.id)) {
      parentElement = parentElement.parentElement;
    }
    let parentFullElement = document.getElementById(parentElement.id)

    if (hasParentNodeName(element, tagNodeName) && html.indexOf(beginTag) === -1 && html.indexOf(endTag) === -1) {
      newHtml = endTag + html + beginTag  //We are cutting this text out of the format style.
      parentFullElement.innerHTML = parentFullElement.innerHTML.replace(html, newHtml)
    } else {
      if (html.indexOf(beginTag) > -1 && html.indexOf(endTag) > -1) {
        searchHtml1 = html
        searchHtml2 = html.split(endTag).join('')
        searchHtml3 = html.split(endTag).join('').split(beginTag).join('')
        let htmlCopy = html
        newHtml = html.split(endTag).join('').split(beginTag).join('')
        if (hasBeginTag(htmlCopy, beginTag)) {
          newHtml = endTag + newHtml  //Cut off the style out of this text and let it the preceding text come to and end with this style.
        }
        if (hasEndTag(htmlCopy, endTag)) {
          newHtml = newHtml + beginTag //Cut off the style out of this text and let it continue with the tag after this text.
        }
      } else if (html.indexOf(endTag) === html.length - endTag.length) {
        searchHtml1 = html
        searchHtml2 = html.split(endTag).join('')
        searchHtml3 = html.split(endTag).join('').split(beginTag).join('')
        newHtml = html.split(endTag).join('').split(beginTag).join('')
        newHtml = endTag + newHtml + beginTag
      } else if (html.indexOf(beginTag) === 0) {
        searchHtml1 = html
        searchHtml2 = html.split(beginTag).join('')
        searchHtml3 = html.split(endTag).join('').split(beginTag).join('')
        newHtml = html.split(endTag).join('').split(beginTag).join('')
        newHtml = endTag + newHtml
      } else if (hasParentNodeName(element, tagNodeName)) {
        searchHtml1 = html
        newHtml = endTag + html + beginTag //We are cutting this text out of the format style.
      }

      if (parentFullElement.innerHTML.toString().indexOf(searchHtml1) > -1) {
        parentFullElement.innerHTML = parentFullElement.innerHTML.replace(searchHtml1, newHtml)
      } else if (parentFullElement.innerHTML.toString().indexOf(searchHtml2) > -1) {
        parentFullElement.innerHTML = parentFullElement.innerHTML.replace(searchHtml2, newHtml)
      } else if (parentFullElement.innerHTML.toString().indexOf(searchHtml3) > -1) {
        parentFullElement.innerHTML = parentFullElement.innerHTML.replace(searchHtml3, newHtml)
      } else {
        let testSelectedHtml = stripHtmlTagsBeginEnd(parentFullElement, html, html)
        parentFullElement.innerHTML = parentFullElement.innerHTML.replace(testSelectedHtml, newHtml)
      }
    }
  } catch (e) {
    console.error('Error - removeFormatStyle', e)
  }
}

const hasBeginTag = (htmlCopy, beginTag) => {
  try {
    while (htmlCopy.indexOf('<') === 0) {
      let tag = htmlCopy.substring(0, htmlCopy.indexOf('>') + 1)
      htmlCopy = htmlCopy.substring(htmlCopy.indexOf('>') + 1) //Cut off the tag and look for the next one.
      if (tag === beginTag) return true
    }
    return false
  } catch (e) {
    console.error('Error - hasBeginTag', e)
  }
}

const hasEndTag = (htmlCopy, endTag) => {
  try {
    while (htmlCopy.lastIndexOf('>') === htmlCopy.length - 1) {
      let tag = htmlCopy.substring(htmlCopy.lastIndexOf('<'))
      htmlCopy = htmlCopy.substring(0, htmlCopy.lastIndexOf('<')) //Cut off the tag and look for the next one.
      if (tag === endTag) return true
    }
    return false
  } catch (e) {
    console.error('Error - hasEndTag', e)
  }
}

export const setParagraphTextAlign = (alignType, savedParagraphsForTextAlign, currentElement) => {
  //Get the paragraphs that are involved in a selection, even partially
  //If no paragraph selected, just affect the current paragraph
  let paragraph
  if (savedParagraphsForTextAlign?.length > 0) {
    savedParagraphsForTextAlign.forEach(m => {
      const paragraph = document.querySelector(`p[id="${m.id}"]`)
      if (paragraph) paragraph.style.textAlign = alignType
    })  
  } else {
    paragraph = currentElement && currentElement.nodeName === 'P' ? currentElement : currentElement.parentElement
    let loop = 0
    while (paragraph && !(paragraph && paragraph.nodeName === 'P' && paragraph.id) && loop < 10) {
      paragraph = paragraph?.parentElement
      loop++
    }
    if (paragraph && paragraph.nodeName === 'P' && paragraph.id) {
      paragraph.style.textAlign = alignType
    }
  }
  return paragraph //This is to just show that there was success or not.
}

export const setParagraphIndent = (indentType, value, savedParagraphsForTextAlign, currentElement) => {
  //Get the paragraphs that are involved in a selection, even partially
  //If no paragraph selected, just affect the current paragraph
  let paragraph
  if (savedParagraphsForTextAlign?.length > 0) {
    savedParagraphsForTextAlign.forEach(m => {
      const paragraph = document.querySelector(`p[id="${m.id}"]`)
      if (paragraph) paragraph.style[indentType] = value / 2.2 + 'em' //divide by 2.2 just because of cutting back the indentation so far.
    })
  } else {
    paragraph = currentElement && currentElement.nodeName === 'P' ? currentElement : currentElement.parentElement
    let loop = 0
    while (paragraph && !(paragraph && paragraph.nodeName === 'P' && paragraph.id) && loop < 10) {
      paragraph = paragraph.parentElement
      loop++
    }
    if (paragraph && paragraph.nodeName === 'P' && paragraph.id) {
      paragraph.style[indentType] = value / 2.2 + 'em' //divide by 2.2 just because of cutting back the indentation so far.
    }
  }
  return paragraph //This is to just show that there was success or not.
}

export const spanArrowKey = (chosenSegment, chosenHTMLSegment, saveRevision, isEndKey, isHomeKey) => {
  try {
    let selection;
    if (window.getSelection)
      selection = window.getSelection();
    else if (document.selection && document.selection.type !== "Control")
      selection = document.selection;

    let element = selection.anchorNode;

    //If this is a node without an id (such as a text node inside a penspring segment), then we need to get the parent to see if that is a span with
    let loop = 0
    while (!(element && element.id && element.nodeName === 'SPAN') && loop < 5) {
      element = element.parentElement
      loop++
    }

    if (isHomeKey) {
      if (element.id && element.nodeName === 'editorDiv') {
        //Get the next element by the cursor
        let test = selection
      } else if (element.id && (element.nodeName === 'P' || element.nodeName === 'UL' || element.nodeName === 'OL' || element.nodeName === 'LI' || element.nodeName === 'IMG')) {
        //I don't think that we evr get to this code due to the check up above which is looking for a span already
        if (element.nodeName === 'UL' || element.nodeName === 'OL') {
          element = element.firstChild
          let loop = 0
          while (element && element.nodeName !== 'LI' && loop < 5) {
            element = element.firstChild
            loop++
          }
        }
        for (let i = 0; i < element.children.length; i++) {
          if (element.children[i].id && element.children[i].nodeName === 'SPAN') {
            element = element.children[i]
            break
          }
        }
      }
    }
    if (isEndKey) {
      while (element && element.previousElementSibling && !(element.id && element.nodeName === 'SPAN') && loop < 10) {
        element = element.previousElementSibling;
        loop++
      }
    }

    loop = 0
    while (element && !(element.id && element.nodeName === 'SPAN' && element.dataset.type === 'TEXT') && loop < 10) { //TEXT is important here since there are edit spans for tab and list structure changes with the same elementId as the companion element itself
      element = element.parentNode;
      loop++
    }
    if (element) {
      let previousSpanId = chosenSegment && chosenSegment.length > 0 ? chosenSegment[chosenSegment.length - 1] && chosenSegment[chosenSegment.length - 1].id : null;
      let previousSpanHTML = chosenHTMLSegment && chosenHTMLSegment.length > 0 ? chosenHTMLSegment[chosenHTMLSegment.length - 1] : null;
      if (previousSpanId && previousSpanId !== Number(element.id)) {
        const spanPrevious = document.querySelectorAll(`[id="${previousSpanId}"][data-type="TEXT"]`)[0]
        if (spanPrevious) {
          if (spanPrevious.innerHTML !== previousSpanHTML) saveRevision()
        }
      } else {
        return {spanId: null, spanHTML: null}  //We are doing some policing here: If this is the same element, we don't change the HTML while we are still in the segment.
      }
      return {
        spanId: element && element.id,
        spanHTML: element && element.innerHTML
      }
    }
    return {
      spanId: '',
      spanHTML: ''
    }
  } catch (e) {
    console.error('Error - spanArrowKey', e)
  }
}

export const spanMouseUp = (event, chosenSegment, chosenHTMLSegment, saveRevision) => {
  // try {
    let element = event.target
    let loop = 0
    //Sometimes a click at the beginning of a paragraph's first sentence finds the cursor in the editorDiv directly and then the cursor up to the last position of the previous paragraph.
    //  This editorDiv condition will cause the cursor to be placed inside that first sentence and at the beginning of that sentence.
    if (element.id === 'editorDiv') {
      const sel = document.getSelection();
      sel.modify("extend", "forward", "word");
      if (sel && sel.anchorNode !== undefined) sel && sel.collapseToEnd();
      element = sel.anchorNode
      while (element && !(element.nodeName === 'SPAN' && !isNaN(element.id))) {
        element = element.parentElement
      }
      if (element) restoreCursorLocation(element, {start: 0, end: 0, newElement: element})
    } else {
      if (element.nodeName === 'P') { //Get the last span child with an Id
        let lastSpan
        for (let i = 0; i < element.childNodes.length; i++) {
          if (element.childNodes[i].nodeName === 'SPAN' && !isNaN(element.childNodes[i].id)) {
            lastSpan = element.childNodes[i]
          }
        }
        element = lastSpan
      } else {
        while (!element.id && loop < 10) { //element.nodeName !== 'SPAN' &&  - We now have spans in the middle of the sentence from the Word conversion for font-related sub-segments
          element = element.parentElement
          loop++
        }
      }
      if (element && element.nodeName === 'SPAN' && !isNaN(element.id)) {
        let previousSpanId = chosenSegment && chosenSegment.length > 0 ? chosenSegment[chosenSegment.length - 1] && chosenSegment[chosenSegment.length - 1].id : null;
        if (previousSpanId !== 'editorDiv') {
          let previousSpanHTML = chosenHTMLSegment && chosenHTMLSegment.length > 0 ? chosenHTMLSegment[`chosenHTMLSegment`.length - 1] : null;
          if (previousSpanId && previousSpanId !== Number(element.id)) {
            const spanPrevious = document.querySelectorAll(`[id="${previousSpanId}"][data-type="TEXT"]`)[0]
            if (spanPrevious) {
              if (spanPrevious.innerHTML !== previousSpanHTML) saveRevision()
            }
          }
        }
      }
    }
    return {
      span: element,
      spanId: element && element.id,
      spanHTML: element && element.innerHTML
    }
  // } catch (e) {
  //   console.error('Error - spanMouseUp', e)
  //   return {
  //     span: '',
  //     spanId: '',
  //     spanHTML: '',
  //   }
  // }
}

export const getLeftSideWithTags = (firstSegmentInvolved, leftSideText) => {
  //This handles an element or just text. If there i an element, it will get leftSide text. Otherwise, you can send the text in as leftSideText only.
  //Left Side:
  // 1. Get the leftSide up to where it matches the segmentInvolvedHtml (leftSide) [Strip off the front tags from the segmentInvolvedHtml until it matches as a substring of leftSide]
  // 2. In leftSide, gather the front type tags (not the end tags) anywhere in the LeftSide (create a frontTags array to hold them and a leftSideCopy to cut off one tag at a time.)
  // 3. If any of the gathered tags have end tags found in leftSide (from another leftSideCopy which will be destroyed again), then take them out of the array (one at a time and also delete that end tag from leftSideCopy.)
  // 4. If there are front tags left over, put their equivalent end tags on the end of the LeftSide. (accumulate those in endTagsToAdd)

  try {
    // 1. Get the leftSide up to where it matches the segmentInvolvedHtml (leftSide) [Strip off the front tags from the segmentInvolvedHtml until it matches as a substring of leftSide]
    let leftSide = ''
    let loop = 0
    if (firstSegmentInvolved) {
      leftSide = document.querySelectorAll(`[id="${firstSegmentInvolved.id}"][data-type="TEXT"]`)[0]
      leftSide = leftSide.innerHTML
      let compareString = firstSegmentInvolved.innerHTML
      while (leftSide.indexOf(compareString) === -1 && loop < 10) {
        if (compareString.indexOf('<') > -1) {
          compareString = compareString.substring(compareString.indexOf('>') + 1)
        }
        loop++
      }
      leftSide = leftSide.substring(0, leftSide.indexOf(compareString))  //Now, take the leftSide of the segment without the segmentInvolvedHtml. We aren't done with leftSide. It will be used below.
    }

    // 2. In leftSide, gather the front type tags (not the end tags) anywhere in the LeftSide (create a frontTags array to hold them and a leftSideCopy to cut of one tag at a time.)
    let leftSideCopy = leftSideText || leftSide
    leftSideCopy = leftSideCopy.replace('</', '')  //The text might be ugly, but just get rid of the beginning end tags because we don't want to pick them up in our '<' earch and mistake them for a front tag.
    let frontTags = []
    loop = 0
    let tag = ''
    while (leftSideCopy.indexOf('<') > -1 && leftSideCopy.length > 0 && loop < 10) {
      leftSideCopy = leftSideCopy.substring(leftSideCopy.indexOf('<'))  //Cut off any text in front of the tag
      tag = leftSideCopy.substring(0, leftSideCopy.indexOf('>') + 1)
      if (tag) {
        frontTags = frontTags && frontTags.length > 0 ? frontTags.concat(tag) : [tag]
      }
      leftSideCopy = leftSideCopy.substring(leftSideCopy.indexOf('>') + 1); //Cut off the first HTML tag in case a tag was put on which completed the text to be HTML when a selection was made between tags such as <b> and </b>
      loop++
    }

    // 3. If any of the gathered tags have end tags found in leftSide (from another leftSideCopy which will be destroyed again), then take them out of the array (one at a time and also delete that end tag from leftSideCopy.)
    leftSideCopy = leftSideText || leftSide
    let endTagsToAdd = [];
    for (let i = 0; i < frontTags.length; i++) {
      let tagSearch = frontTags[i].indexOf(' ') > -1 ? frontTags[i].substring(0, frontTags[i].indexOf(' ')) + '>' : frontTags[i]
      tagSearch = tagSearch.replace('<', '</')
      if (leftSideCopy.indexOf(tagSearch) === -1) {
        endTagsToAdd = endTagsToAdd && endTagsToAdd.length > 0 ? endTagsToAdd.concat(tagSearch) : [tagSearch]
      } else {
        leftSideCopy = leftSideCopy.replace(frontTags[0], '') //Take out the end tage from the leftSideCopy in case there are more than just the one type.
      }
    }

    // 4. If there are front tags left over, put their equivalent end tags on the end of the LeftSide. (accumulate those in endTagsToAdd)
    let addEndTags = ''
    for (let i = 0; i < endTagsToAdd.length; i++) {
      addEndTags += endTagsToAdd[i]
    }
    return (leftSideText || leftSide) + addEndTags
  } catch (e) {
    console.error('Error - getLeftSideWithTags', e)
  }
}

export const getRightSideWithTags = (lastSegmentInvolved) => {
  //Right Side:
  // 1. Get the rightSide from the end of where it matches the last segmentInvolvedHtml (rightSide) [Strip off the front tags from the segmentInvolvedHtml]
  // 2. In segmentInvolvedHtml, gather the front type tags (not the end tags) anywhere in the segmentInvolvedHtml (create a frontTags array to hold them and a segmentInvolvedHtmlCopy to cut off one tag at a time.)
  // 3. If any of the gathered tags have end tags found in segmentInvolvedHtml (from another segmentInvolvedHtmlCopy which will be destroyed again), then take them out of the array (one at a time and also delete that end tag from segmentInvolvedHtmlCopy.)
  // 4. If there are front tags left over, put front tags on the beginning of the segmentInvolvedHtmlCopy. (accumulate those in endTagsToAdd)

  try {
    // 1. Get the rightSide from the end of where it matches the last segmentInvolvedHtml (rightSide) [Strip off the front tags from the segmentInvolvedHtml]
    let rightSide = document.querySelectorAll(`[id="${lastSegmentInvolved.id}"][data-type="TEXT"]`)[0]
    let compareString = lastSegmentInvolved.innerHTML
    let loop = 0
    while (rightSide.indexOf(compareString) === -1 && loop < 10) {
      if (compareString.lastIndexOf('>') === compareString.length - 1) {
        compareString = compareString.substring(0, compareString.lastIndexOf('<'))
      }
      loop++
    }
    rightSide = rightSide.substring(rightSide.indexOf(compareString) + compareString.length)

    // 2. In segmentInvolvedHtml, gather the front type tags (not the end tags) anywhere in the segmentInvolvedHtml (create a frontTags array to hold them and a segmentInvolvedHtmlCopy to cut off one tag at a time.)
    let segmentInvolvedHtmlCopy = lastSegmentInvolved.innerHTML
    segmentInvolvedHtmlCopy = segmentInvolvedHtmlCopy.replace('</', '')  //The text might be ugly, but just get rid of the beginning end tags because we don't want to pick them up in our '<' earch and mistake them for a front tag.
    let frontTags = []
    let tag = ''
    while (segmentInvolvedHtmlCopy.indexOf('<') > -1 && segmentInvolvedHtmlCopy.length > 0 && loop < 10) {
      segmentInvolvedHtmlCopy = segmentInvolvedHtmlCopy.substring(segmentInvolvedHtmlCopy.indexOf('<'))  //Cut off any text in front of the tag
      tag = segmentInvolvedHtmlCopy.substring(0, segmentInvolvedHtmlCopy.indexOf('>') + 1)
      if (tag) {
        frontTags = frontTags && frontTags.length > 0 ? frontTags.concat(tag) : [tag]
      }
      segmentInvolvedHtmlCopy = segmentInvolvedHtmlCopy.substring(segmentInvolvedHtmlCopy.indexOf('>') + 1); //Cut off the first HTML tag in case a tag was put on which completed the text to be HTML when a selection was made between tags such as <b> and </b>
      loop++
    }

    // 3. If any of the gathered tags have end tags found in rightSide (from another rightSideCopy which will be destroyed again), then take them out of the array (one at a time and also delete that end tag from rightSideCopy.)
    let rightSideCopy = rightSide
    let frontTagsToAdd = [];
    for (let i = 0; i < frontTags.length; i++) {
      let tagSearch = frontTags[i].indexOf(' ') > -1 ? frontTags[i].substring(0, frontTags[i].indexOf(' ')) + '>' : frontTags[i]
      tagSearch = tagSearch.replace('<', '</')
      if (rightSideCopy.indexOf(tagSearch) > -1) { //If it is found, we want to add that to the beginning since the tag would have started in the selected part and then cut out from the substring.
        frontTagsToAdd = frontTagsToAdd && frontTagsToAdd.length > 0 ? frontTagsToAdd.concat(frontTags[0]) : [frontTags[0]]
      } else {
        rightSideCopy = rightSideCopy.replace(frontTags[0], '') //Take out the end tag from the leftSideCopy in case there are more than just the one type.
      }
    }

    // 4. If there are front tags left over, put front tags on the beginning of the segmentInvolvedHtmlCopy. (accumulate those in endTagsToAdd)
    let addFrontTags = ''
    for (let i = 0; i < frontTagsToAdd.length; i++) {
      addFrontTags += frontTagsToAdd[i]
    }
    return addFrontTags + rightSide
  } catch (e) {
    console.error('Error - getRightSideWithTags', e)
  }
}

export const getRightSideWithTagsfromLeftSide = (leftSideHtml, rightSideHtml) => {
  //Right Side:
  // 1. From leftSideHtml, gather the front type tags (not the end tags) anywhere in the leftSideHtml (create a frontTags array to hold them and a leftSideHtmlCopy to cut off one tag at a time.)
  // 2. If any of the gathered tags have end tags found in leftSideHtml (from another leftSideHtmlCopy which will be destroyed again), then take them out of the array (one at a time and also delete that end tag from leftSideHtmlCopy.)
  // 3. If there are front tags left over, put front tags on the beginning of the rightSideHtml. (accumulate those in endTagsToAdd)

  try {
    // 1. From leftSideHtml, gather the front type tags (not the end tags) anywhere in the leftSideHtml (create a frontTags array to hold them and a leftSideHtmlCopy to cut off one tag at a time.)
    let leftSideHtmlCopy = leftSideHtml.replace('</', '')  //The text might be ugly, but just get rid of the beginning end tags because we don't want to pick them up in our '<' earch and mistake them for a front tag.
    let frontTags = []
    let tag = ''
    let loop = 0
    while (leftSideHtmlCopy.indexOf('<') > -1 && leftSideHtmlCopy.length > 0 && loop < 10) {
      leftSideHtmlCopy = leftSideHtmlCopy.substring(leftSideHtmlCopy.indexOf('<'))  //Cut off any text in front of the tag
      tag = leftSideHtmlCopy.substring(0, leftSideHtmlCopy.indexOf('>') + 1)
      if (tag) {
        frontTags = frontTags && frontTags.length > 0 ? frontTags.concat(tag) : [tag]
      }
      leftSideHtmlCopy = leftSideHtmlCopy.substring(leftSideHtmlCopy.indexOf('>') + 1); //Cut off the first HTML tag in case a tag was put on which completed the text to be HTML when a selection was made between tags such as <b> and </b>
      loop++
    }

    // 2. If any of the gathered tags have end tags found in rightSideHtml (from another rightSideHtmlCopy which will be destroyed again), then take them out of the array (one at a time and also delete that end tag from rightSideHtmlCopy.)
    let rightSideHtmlCopy = rightSideHtml
    let frontTagsToAdd = [];
    for (let i = 0; i < frontTags.length; i++) {
      let tagSearch = frontTags[i].indexOf(' ') > -1 ? frontTags[i].substring(0, frontTags[i].indexOf(' ')) + '>' : frontTags[i]
      tagSearch = tagSearch.replace('<', '</')
      if (rightSideHtmlCopy.indexOf(tagSearch) > -1) { //If it is found, we want to add that to the beginning since the tag would have started in the selected part and then cut out from the substring.
        frontTagsToAdd = frontTagsToAdd && frontTagsToAdd.length > 0 ? frontTagsToAdd.concat(frontTags[0]) : [frontTags[0]]
      } else {
        rightSideHtmlCopy = rightSideHtmlCopy.replace(frontTags[0], '') //Take out the end tag from the leftSideCopy in case there are more than just the one type.
      }
    }

    // 4. If there are front tags left over, put front tags on the beginning of the segmentInvolvedHtmlCopy. (accumulate those in endTagsToAdd)
    let addFrontTags = ''
    for (let i = 0; i < frontTagsToAdd.length; i++) {
      addFrontTags += frontTagsToAdd[i]
    }
    return addFrontTags + rightSideHtml
  } catch (e) {
    console.error('Error - getRightSideWithTagsFromLeftSide', e)
  }
}

export const spliceRemainingEndsFromSelectDelete = (event, selectionChildren) => {
  try {
    //We have to consider that we have passed through a paragraph boundary. Gather all P-s and SPAN-s
    let paragraphCount = 0
    let segmentsInvolved = []
    selectionChildren.forEach((m) => {
      if (m.nodeName === 'P' && m.id) {
        segmentsInvolved.push(m) //We need to record the paragraphs, too, so that we can delete them.
        paragraphCount++
        m.childNodes.forEach(pspan => { //But only get those spans which are involved in the selection
          if (pspan.nodeName === 'SPAN' && pspan.id) {
            segmentsInvolved.push(pspan)
          }
        })
      } else if (m.nodeName === 'SPAN' && m.id) {
        segmentsInvolved.push(m)
      }
    })

    //Loop through the segments involved, but if there is only one segment involved, then jump down further and let the delete go without doing anything more here.
    if (segmentsInvolved.length > 1) {
      event.stopPropagation()
      event.preventDefault()
      let firstSpanIndex, lastSpanIndex
      segmentsInvolved.forEach((m, i) => {
        if (!firstSpanIndex && firstSpanIndex !== 0 && m.nodeName === 'SPAN') firstSpanIndex = i
        if (m.nodeName === 'SPAN') lastSpanIndex = i
      })

      let leftSide = getLeftSideWithTags(segmentsInvolved[firstSpanIndex])
      let rightSide = getRightSideWithTags(segmentsInvolved[lastSpanIndex])

      //If there are paragraphs involved, we need to append the last paragraph's span to the first paragraph. I refer to those spans that are after the last involved Span of the last paragraph
      //If there aren't paragraphs, then the check for the node lastParagraph will be invalid and just skip the rest.
      let firstParagraphId, lastParagraphId
      segmentsInvolved.forEach((m) => {
        if (!firstParagraphId && firstParagraphId !== 0 && m.nodeName === 'P') firstParagraphId = m.id
        if (m.nodeName === 'P') lastParagraphId = m.id
      })
      let firstParagraph = document.querySelectorAll(`[id="${firstParagraphId}"][data-type="TEXT"]`)[0]
      let lastParagraph = document.querySelectorAll(`[id="${lastParagraphId}"][data-type="TEXT"]`)[0]
      if (lastParagraph) {
        lastParagraph.childNodes.forEach(span => {
          if (span.nodeName === 'SPAN') {
            let isInvolved = false
            segmentsInvolved.forEach(seg => {
              if ((!seg.id) || (seg.nodeName === 'SPAN' && seg.id === span.id)) {  //Include anything that isn't a span as well, such as textNode.
                isInvolved = true
              }
            })
            if (!isInvolved) {
              firstParagraph.append(span)
            }
          }
        })
      }

      //Remove the second span all the way to the last span (I tried to use the original selectionChildren in order to get any extra textNodes, but I couldn't get it located in the DOM with another call in order to delete it. So I'm leaving the extra spaces between sentences.)
      let okayToStartDeleting = false
      let foundTheFirstSpan = false
      segmentsInvolved.forEach((m, i) => {
        if (m.nodeName === 'P' && !okayToStartDeleting) { //We are counting down so that we can determine when we have come to the last paragraph which we don't want to delete
          paragraphCount--
        }
        if (!foundTheFirstSpan && m.nodeName === 'SPAN') {
          foundTheFirstSpan = true
        } else {
          if (foundTheFirstSpan) { //Don't delete the first one in which we just concatenated the beginning and ending text
            if (m.nodeName === 'SPAN' || (m.nodeName === 'P' && paragraphCount > 1)) {  //We are making sure that we don't delete the last paragraph
              if (m.nodeName === 'P') {
                paragraphCount--
              }
              let removeChild = document.querySelectorAll(`[id="${segmentsInvolved[i].id}"][data-type="TEXT"]`)[0]
              removeChild.parentNode.removeChild(removeChild)
            }
          }
        }
      })
      //Set the first span's innerHTML with the left-over of the first span and add on the left-over of the last span text
      document.getElementById(segmentsInvolved[firstSpanIndex].id).innerHTML = leftSide + rightSide
    }
  } catch (e) {
    console.error('Error - spliceRemainingEndsFromSelectDelete', e)
  }
}

export const setPastedHtmlEditor = (event, currentElement) => {
  let pasteText = event.clipboardData || window.clipboardData
  let pasteHtml = pasteText.getData('text/plain')
  pasteHtml = pasteHtml.replace(/\r\n/g, '<br/>')
  pasteHtml = pasteHtml.replace(/\n/g, '<br/>')

  let {leftSideOfClickedIn, rightSideOfClickedIn} = getLeftRightSidesOfClickedIn(event.target)
  currentElement.innerHTML = leftSideOfClickedIn + pasteHtml + rightSideOfClickedIn
  currentElement.style.backgroundColor = backgroundColors.editPending
}

export const setPastedPlainAuthorNotList = (currentElement, pastePlain, chosenSegment, getNextId, savedCursorPosition) => {
  // 1. isPenspringText: true | false
  // 2. isPenspringCopy: true | false (because the copied text was not cut out)
  // 3. Crawl through the pasted text
  //      a. If the first element is a paragraph, skip it (since this is a set of spans that crossed a paragraph, so the first span has to have a paragraph for the structure, but we don't start with a paragraph in this case.
  //      b. If there is left-side text
  //           i.  If the existing first segment is an incomplete sentence,
  //                  concatenate it to the landing left-span-segment
  //                  finish any front tags with end tags
  //               else, be sure the existing first segment is ended and set the next span
  //      c. On span, if isPenspringText and isPenspringCopy OR !isPenspringText, set span segment id-s to the nextAvailableId
  //            i. If it is isPenspringText and isPenspringCopy, leave the id alone.
  //            ii. Look for multiple sentences and split them into additional span-segments
  //            iii. If this is the last segment
  //                  if rightside of clickedIn is an incomplete sentence, then merge in the text to the last new span.
  //      d. On paragraph, set nextAvailableId no matter if this is Penspring text and is not a copy or not
  //            If there was a previous paragraph. concatenate a closing tag before starting the next paragraph
  //            Concatenate only the paragraph tag before the spans to follow
  //      e. If the last text is an incomplete sentence, then concatenate it to the landing right-span-segment
  //      f. Replace the editorDiv innerHTML:  Replace the clickedIn outerHTML with the new clicked in HTML plus the other elements and the ending span, however that turns out.
  try {
    if (!pastePlain) return
    pastePlain = pastePlain.replace(/\r\n/g, '<br/>')
    pastePlain = pastePlain.replace(/\n/g, '<br/>')

    let previousElementId
    let newElementsHtml = ''

    // 1. isPenspringText: true | false
    // let isPenspringText = pastePlain.indexOf('data-type') > -1
    // if (!isPenspringText) {
    // 	if (!currentElement) {
    // 		let lastElementId = chosenSegment[chosenSegment.length - 1].id
    // 		let currentElement = document.querySelector(`span[id="${lastElementId}"][data-type='TEXT']`)
    // 	}
    //if (currentElement.id === 'editorDiv') currentElement = getMainElementChildren()[0]
    const {newOuterHtml} = sentenceService.delineateSentences(previousElementId, pastePlain, getNextId)
    //pastePlain = newOuterHtml
    //}
    //pastePlain = "<html lang='en'><body>" + pastePlain + "</body></html>"

    // // 2. isPenspringCopy: true | false (because the copied text was not cut out)
    // //let hasPenspringSegment
    // let isPenspringCopy //This is just to determine if we need to reassign the span.id to the nextAvailableId if there is already a span with that id. Otherwise, we want to preserve it as a moved segment
    // let fragment = new DOMParser().parseFromString(pastePlain, "text/html");
    // let body = fragment.getElementsByTagName('body')[0]
    // let elementArray = []
    // for (let i = 0; i < body.children.length; i++) {  //We are strongly assuming a structure of spans as single-level children. But we also assume if there are paragraphs involved, then paragraphs are single-level with spans of single-level
    // 	let element = body.children[i]
    // 	elementArray = elementArray && elementArray.length > 0 ? elementArray.concat(element) : [element]
    // 	if (element.nodeName.toUpperCase() === 'P' || (element.nodeName.toUpperCase() === 'SPAN' && element.outerHTML.indexOf('mso-ascii') > -1)) {
    // 		for (let p = 0; p < element.children.length; p++) {
    // 			elementArray = elementArray && elementArray.length > 0 ? elementArray.concat(element.children[p]) : [element.children[p]]
    // 		}
    // 	}
    // }

    // 3. Crawl through the pasted text
    //      a. If the first element is a paragraph, skip it (since this is a set of spans that crossed a paragraph, so the first span has to have a paragraph for the structure, but we don't start with a paragraph in this case.
    //      b. If there is left-side text
    //           i.  If the existing first segment is an incomplete sentence,
    //                  concatenate it to the landing left-span-segment
    //                  finish any front tags with end tags
    //               else, be sure the existing first segment is ended and set the next span
    //      c. On span, if isPenspringText and isPenspringCopy OR !isPenspringText, set span segment id-s to the nextAvailableId
    //            i. Look for multiple sentences and split them into additional span-segments
    //            ii. If this is the last segment
    //                 if rightside of clickedIn is an incomplete sentence, then merge in the text to the last new span.
    //      d. On paragraph, set nextAvailableId no matter if this is Penspring text and is not a copy or not
    //            If there was a previous paragraph. concatenate a closing tag before starting the next paragraph
    //            Concatenate only the paragraph tag before the spans to follow
    //      e. If the last text is an incomplete sentence, then concatenate it to the landing right-span-segment
    if (currentElement.nodeName === 'P') {
      let children = currentElement.children
      for (let i = 0; i < children.length; i++) {
        if (children[i].nodeName === 'SPAN' && children[i].id) currentElement = children[i]
      }
    }
    let {leftSideOfClickedIn, rightSideOfClickedIn} = getLeftRightSidesOfClickedIn(currentElement)

    // for (let i = 0; i < elementArray.length; i++) {
    // 	if ((!newElementsHtml && elementArray[i].nodeName.toUpperCase() === 'P') || (isPenspringCopy && elementArray[i].nodeName.toUpperCase() === 'SPAN' && !elementArray[i].id)) {
    // 		continue
    // 	} else {
    // 		if (!isPenspringCopy) isPenspringCopy = document.querySelectorAll(`[id="${elementArray[i].id}"][data-type="TEXT"]`)[0] //We protect this here to set it if it exists in order to avoid a ghost span in the middle of a span which might throw off our logic
    //
    // 		if (!newElementsHtml) { //We haven't started yet so this must be the first span segment
    // 			previousElementId = currentElement.id
    // 			leftSideOfClickedIn = getLeftSideWithTags(null, leftSideOfClickedIn)
    // 			currentElement.innerHTML = leftSideOfClickedIn + elementArray[i].innerHTML
    // 			if (i === elementArray.length - 1) {
    // 				rightSideOfClickedIn = getRightSideWithTagsfromLeftSide(leftSideOfClickedIn, rightSideOfClickedIn)
    // 				currentElement.innerHTML = currentElement.innerHTML + rightSideOfClickedIn
    // 			}
    // 			newElementsHtml = currentElement.outerHTML + '&nbsp;'
    // 		} else {
    // 			if ((isPenspringText && isPenspringCopy) || !isPenspringText) { //elementArray[i].nodeName.toUpperCase() === 'SPAN' && (
    // 				elementArray[i].id = getNextId()
    // 			}
    // 			previousElementId = elementArray[i].id
    //
    // 			if (i === elementArray.length - 1) {
    // 				rightSideOfClickedIn = getRightSideWithTagsfromLeftSide(leftSideOfClickedIn, rightSideOfClickedIn)
    // 				elementArray[i].innerHTML = elementArray[i].innerHTML + rightSideOfClickedIn
    // 			}
    // 			if (elementArray[i].nodeName.toUpperCase() === 'P') {
    // 				elementArray[i].id = getNextId()
    // 				previousElementId = elementArray[i].id
    // 				newElementsHtml += '</p>' + elementArray[i].outerHTML.substring(0, elementArray[i].outerHTML.indexOf('>') + 1)
    // 			} else {
    // 				newElementsHtml += elementArray[i].outerHTML + '&nbsp;' //Each segment needs to have a space between it since the segments would run against each other in the view.
    // 			}
    // 		}
    // 	}
    // }
    //      f. Replace the editorDiv innerHTML:  Replace the currentElement outerHTML with the new clicked in HTML plus the other elements and the ending span, however that turns out.
    let editorDiv = document.getElementById('editorDiv')
    editorDiv.innerHTML = editorDiv.innerHTML.replace(currentElement.outerHTML, leftSideOfClickedIn + newOuterHtml + rightSideOfClickedIn);
  } catch (e) {
    console.error('Error: setPasteHtml', e)
  }
}

export const setPastedPlainAuthorIntoList = (currentElement, pastePlain, chosenSegment, getNextId, savedCursorPosition) => {
  if (currentElement.nodeName === 'LI') currentElement = currentElement.firstChild
  let listItem = currentElement.parentElement
  let list = listItem.parentElement
  let listItemNextSibling = listItem.nextSibling
  let endLineIndex = pastePlain.indexOf('\r\n') > -1 ? pastePlain.indexOf('\r\n') : pastePlain.indexOf('\n')
  let adjustIndex = pastePlain.indexOf('\r\n') > -1 ? 1 : 0
  let currentText = pastePlain.substring(0, endLineIndex - adjustIndex)
  let remainPlain = pastePlain.substring(endLineIndex + adjustIndex + 1)
  let loop = 0
  while (currentText && loop < 100) {
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId()
    newListItem.setAttribute('style', listItem.style.cssText)
    let newSpan = document.createElement('SPAN')
    newSpan.id = getNextId()
    newSpan.setAttribute('style', currentElement.style.cssText)
    newSpan.innerHTML = currentText || '&nbsp;'
    newListItem.append(newSpan)
    if (listItemNextSibling) {
      list.insertBefore(newListItem, listItemNextSibling)
    } else {
      list.append(newListItem)
    }
    endLineIndex = remainPlain.indexOf('\r\n') > -1 ? remainPlain.indexOf('\r\n') : remainPlain.indexOf('\n')
    adjustIndex = remainPlain.indexOf('\r\n') > -1 ? 1 : 0
    currentText = remainPlain.substring(0, endLineIndex - adjustIndex)
    remainPlain = remainPlain.substring(endLineIndex + adjustIndex + 1)
    loop++
  }
}

export const setPastedHtmlAuthorIntoList = (currentElement, pasteHtml, chosenSegment, getNextId, savedCursorPosition) => {
  if (currentElement.nodeName === 'LI') currentElement = currentElement.firstChild
  let listItem = currentElement.parentElement
  let list = listItem.parentElement
  let listItemNextSibling = listItem.nextSibling

  pasteHtml = pasteHtml.substring(pasteHtml.indexOf('<!--StartFragment-->') + 20, pasteHtml.indexOf('<!--EndFragment-->'))
  pasteHtml = pasteHtml.replace(/\n/g, '<br/>')
  let fragment = new DOMParser().parseFromString(pasteHtml, "text/html");
  const body = fragment.getElementsByTagName('body')[0]
  let paragraphs
  if (body) {
    paragraphs = body.children
  } else {
    paragraphs = fragment.getElementsByTagName('p')
  }

  for (let i = 0; i < paragraphs.length; i++) {
    for (let p = 0; p < paragraphs[i].children.length; p++) {
      let newSpan
      //if (paragraphs[i].children[p].innerHTML !== "") {
      if (paragraphs[i].children[p] && paragraphs[i].children[p].nodeName !== 'SPAN') {
        newSpan = document.createElement('SPAN')
        newSpan.innerHTML = paragraphs[i].innerHTML || '&nbsp;'
      } else {
        newSpan = paragraphs[i]
      }
      newSpan.id = getNextId()
      newSpan.setAttribute('style', currentElement.style.cssText)

      let newListItem = document.createElement('LI')
      newListItem.id = getNextId()
      newListItem.setAttribute('style', listItem.style.cssText)
      newListItem.append(newSpan)
      if (listItemNextSibling) {
        list.insertBefore(newListItem, listItemNextSibling)
      } else {
        list.append(newListItem)
      }
      //}
    }
  }
}

export const setPastedHtmlAuthorIntoSentences = (currentElement, pasteHtml, chosenSegment, getNextId, savedCursorPosition) => {
  if (currentElement.nodeName === 'P') currentElement = currentElement.firstChild
  let paragraph = currentElement.parentElement
  let paragraphNextSibling = paragraph.nextSibling
  let mainBody = paragraph.parentElement

  pasteHtml = pasteHtml.substring(pasteHtml.indexOf('<!--StartFragment-->') + 20, pasteHtml.indexOf('<!--EndFragment-->'))
  pasteHtml = pasteHtml.replace(/\n/g, '<br/>')
  let fragment = new DOMParser().parseFromString(pasteHtml, "text/html");
  const body = fragment.getElementsByTagName('body')[0]
  let paragraphs
  if (body) {
    paragraphs = body.children
  } else {
    paragraphs = fragment.getElementsByTagName('p')
  }
  const paragraphsLength = (paragraphs && paragraphs.length) || 0
  for (let i = 0; i < paragraphsLength; i++) {
    let childrenLength = (paragraphs[i].children && paragraphs[i].children.length) || 0
    for (let p = 0; p < childrenLength; p++) {
      let newSpan
      if (paragraphs[i] && paragraphs[i].children[p] && paragraphs[i].children[p].nodeName !== 'SPAN') {
        newSpan = document.createElement('SPAN')
        newSpan.innerHTML = paragraphs[i].innerHTML || '&nbsp;'
      } else {
        newSpan = paragraphs[i]
      }
      if (newSpan) {
        newSpan.id = getNextId()
        newSpan.setAttribute('style', currentElement.style.cssText)

        let newParagraph = document.createElement('P')
        newParagraph.id = getNextId()
        newParagraph.setAttribute('style', paragraph.style.cssText)
        newParagraph.append(newSpan)
        if (paragraphNextSibling) {
          mainBody.insertBefore(newParagraph, paragraphNextSibling)
        } else {
          mainBody.append(newParagraph)
        }
        const {newOuterHtml} = sentenceService.delineateSentences(newSpan.id, newSpan.outerHTML, getNextId)
        newParagraph.innerHTML = newOuterHtml
        //if (newSpan.outerHTML !== newOuterHtml) editorDiv.innerHTML = editorDiv.innerHTML.replace(newSpan.outerHTML, newOuterHtml);
      }
    }
  }
}

export const processNewSentences = (elementId, outerHtml, nextElementId, getNextId, spanStyleInline) => {
  // 1. get the span element
  // 2. process the sentences and get the result back
  // 3. If new sentences were found, replace the original span element with all of the new HTML.
  try {
    const {newOuterHtml} = sentenceService.delineateSentences(elementId, outerHtml, getNextId, spanStyleInline)
    let editorDiv = document.getElementById('editorDiv')
    if (outerHtml !== newOuterHtml) editorDiv.innerHTML = editorDiv.innerHTML.replace(outerHtml, newOuterHtml);
  } catch (e) {
    console.error('Error: processNewSentences', e)
  }
}

export const processAuthorTextChanges = (segments, newChosen, getNextId, spanStyleInline) => {
  // If the span element innerHTML has changed from the segment.text version
  //    process that new text looking for new sentences
  //  else
  //    look for a textNode of a paragraph
  let previousElementId
  if (newChosen) {
    previousElementId = newChosen.id
    let previousSpan = document.querySelectorAll(`[id="${previousElementId}"][data-type="TEXT"]`)[0]
    if (previousSpan) {
      let segmentText = segments.filter(m => Number(m.elementId) === Number(previousElementId))[0]
      segmentText = segmentText && segmentText.text
      //This is necessary because a &nbsp; is put at the end of each sentence for spacing and then taken off before it is saved to the database.
      let previousSpanHTMLWithoutNBSP = previousSpan.innerHTML.indexOf('&nbsp;') === previousSpan.innerHTML.length - 6 ? previousSpan.innerHTML.substring(0, previousSpan.innerHTML.length - 6) : previousSpan.innerHTML
      if (segmentText && previousSpan.innerHTML !== segmentText) {
        processNewSentences(previousElementId, previousSpan.outerHTML, getNextElementId(previousElementId), getNextId, spanStyleInline)
      }
    }
  }

  //Look for text nodes
  const mainDiv = getMainElementChildren()
  for (let p = 0; p < mainDiv.length; p++) {
    let paragraphChildren = mainDiv[p].childNodes

    for (let s = 0; s < paragraphChildren.length; s++) {
      if (paragraphChildren[s].id)
        previousElementId = paragraphChildren[s].id

      if (paragraphChildren[s].nodeName === '#text') {
        let nextElementId
        if (paragraphChildren[s].nextElementSibling) {
          nextElementId = paragraphChildren[s].nextElementSibling && paragraphChildren[s].nextElementSibling.id
        } else {
          nextElementId = mainDiv[p].nextElementSibling && mainDiv[p].nextElementSibling.id
        }
        let contents = paragraphChildren[s].textContent
        contents = contents.replace(/\u00a0/g, "").replace(/\xA0/g, '').replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ').replace(/ /g, '')
        if (contents.length > 0) {
          //Strip off space from the front and back.
          let textContent = paragraphChildren[s].textContent
          if (textContent.indexOf('&nbsp;') === 0) textContent = textContent.substring(textContent.indexOf('&nbsp;') + 6)
          if (textContent.indexOf('&nbsp;') === 0) textContent = textContent.substring(textContent.indexOf('&nbsp;') + 6)
          if (textContent.indexOf('\u00a0') === 0) textContent = textContent.substring(textContent.indexOf('\u00a0') + 1)  //Notice just the 1 here. Should it be 6?
          if (textContent.indexOf('\xA0') === 0) textContent = textContent.substring(textContent.indexOf('\xA0') + 1) //Notice just the 1 here. Should it be 6?
          if (textContent.indexOf(' ') === 0) textContent = textContent.substring(textContent.indexOf(' ') + 1)
          if (textContent.indexOf(' ') === 0) textContent = textContent.substring(textContent.indexOf(' ') + 1)
          if (textContent.lastIndexOf('&nbsp;') === textContent.length - 6) textContent = textContent.substring(0, textContent.lastIndexOf('&nbsp;'))
          if (textContent.lastIndexOf('\u00a0') > -1) textContent = textContent.substring(0, textContent.lastIndexOf('\u00a0'))  //Notice just the 1 here. Should it be 6?
          if (textContent.lastIndexOf('\xA0') > -1) textContent = textContent.replace(/\xA0/g, '') ///substring(0, textContent.lastIndexOf('\xA0')-1) //Notice just the 1 here. Should it be 6?
          if (textContent.lastIndexOf(' ') === textContent.length - 1) textContent = textContent.substring(0, textContent.lastIndexOf(' '))
          if (textContent.lastIndexOf(' ') === textContent.length - 1) textContent = textContent.substring(0, textContent.lastIndexOf(' '))

          paragraphChildren[s].textContent = textContent
          processNewSentences(previousElementId, textContent, nextElementId, getNextId)
        }
      }
      if (paragraphChildren[s].id)
        previousElementId = paragraphChildren[s].id
    }
  }
}

export const getNextElementId = (previousElementId) => {
  let previousElement = document.querySelectorAll(`[id="${previousElementId}"][data-type="TEXT"]`)[0]
  if (previousElement && previousElement.nextElementSibling) {
    return previousElement.nextElementSibling.id
  } else {
    let parentElement = previousElement.parentElement
    if (parentElement && parentElement.nextElementSibling)
      return parentElement.nextElementSibling.id
  }
}

export const combineSpansIfBackspacing = (backspaceNodes, savedCursorPosition, clearBackspaceNodes) => {
  const selection = window.getSelection();
  let focusNode = selection.baseNode
  if (focusNode && !(focusNode.dataset && focusNode.dataset.sequence)) focusNode = selection.baseNode.parentNode
  if (focusNode)
    backspaceNodes.push({
      id: focusNode.id,
      nodeName: focusNode.nodeName,
      textLength: focusNode.textContent.length,
      cursorPosition: savedCursorPosition.start
    })

  let threeDeletes = (backspaceNodes && backspaceNodes.length > 2 && backspaceNodes.filter(m => Number(m.textLength) + 1 === m.cursorPosition && m.nodeName === 'SPAN')) || []

  if (threeDeletes.length >= 3 && focusNode.dataset && focusNode.dataset.sequence) {
    clearBackspaceNodes()
    let leftNode = document.querySelectorAll(`[id="${focusNode.id}"][data-type="TEXT"]`)[0]
    let nextElementSibling = leftNode.nextElementSibling || null
    let rightNode
    let loop = 0
    while (!(nextElementSibling && nextElementSibling.dataset && nextElementSibling.dataset.sequence) && loop < 10) {
      nextElementSibling = nextElementSibling.nextElementSibling
      loop++
    }
    if (nextElementSibling) {
      rightNode = nextElementSibling
      //setSavedCursorPosition(editorService.saveCursorLocation(document.getElementById('editorDiv')))
      leftNode.innerHTML = leftNode.innerHTML + rightNode.innerHTML
      rightNode.remove()
      let changeCursorPosition = Object.assign({}, savedCursorPosition)
      changeCursorPosition.start = changeCursorPosition.start - 1
      changeCursorPosition.end = changeCursorPosition.end - 1
      restoreCursorLocation(document.getElementById('editorDiv'), changeCursorPosition)
      return true
    }
  }
}

export const combineSpansIfDeleteKeying = (deleteKeyNodes, savedCursorPosition, clearDeleteKeyNodes) => {
  const selection = window.getSelection();
  let focusNode = selection.baseNode
  if (focusNode && !(focusNode.dataset && focusNode.dataset.sequence)) focusNode = selection.baseNode.parentNode

  if (focusNode)
    deleteKeyNodes.push({
      id: focusNode.id,
      nodeName: focusNode.nodeName,
      textLength: focusNode.textContent.length,
      cursorPosition: savedCursorPosition.start
    })

  //When the deleteKeyNodes records have the same length as the cursor location three times, then merge the adjoining spans
  let threeDeletes = (deleteKeyNodes && deleteKeyNodes.length > 2 && deleteKeyNodes.filter(m => m.textLength === m.cursorPosition && m.nodeName === 'SPAN')) || []

  if (threeDeletes.length >= 3 && focusNode.dataset && focusNode.dataset.sequence) {
    clearDeleteKeyNodes()
    let leftNode = document.querySelectorAll(`[id="${focusNode.id}"][data-type="TEXT"]`)[0]
    let nextElementSibling = leftNode.nextElementSibling || null
    let rightNode
    let loop = 0

    while (!(nextElementSibling && nextElementSibling.dataset && nextElementSibling.dataset.sequence) && loop < 10) {
      nextElementSibling = nextElementSibling.nextElementSibling
      loop++
    }
    if (nextElementSibling) {
      rightNode = nextElementSibling
      //setSavedCursorPosition(saveCursorLocation(document.getElementById('editorDiv')))
      leftNode.innerHTML = leftNode.innerHTML + rightNode.innerHTML
      rightNode.remove()
      let changeCursorPosition = Object.assign({}, savedCursorPosition)
      changeCursorPosition.start = changeCursorPosition.start - 1
      changeCursorPosition.end = changeCursorPosition.end - 1
      restoreCursorLocation(document.getElementById('editorDiv'), changeCursorPosition)
      return true
    }
  }
}

export const isMobile = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export const gatherSegmentsToSave = (segments, chapterId) => {
  let saveSegments = []
  const mainBodyTag = document.querySelectorAll(`[data-main-body-tag="yes"]`) //Word conversion files have a div tag with this data attribute. The parent/child loop below will not work without this div
  const editorDiv = mainBodyTag ? document.getElementById('1') : document.getElementById('editorDiv')  //Notice that we refer to the mainBodyTag as Id "1". The reason for this is that we are using getElementById which returns children more directly. The getSelectorAll returns an HTMLCollection which, for whatever reason, is not a direct children list.
  if (editorDiv) {
    saveElementWorkSegment(segments, editorDiv, chapterId, null, 'BODY', saveSegments)
  }
  //We have to consider the hierarchical structure of parent-child relationships of an OL or UL list.
  const mainDiv = getMainElementChildren()
  for (let p = 0; p < mainDiv.length; p++) {
    saveElementChildren(segments, mainDiv[p], chapterId, saveSegments);
  }
  return saveSegments
}

export const saveElementChildren = (segments, child, chapterId, saveSegments) => {
  if (child && child.dataset
    && child.dataset.type !== 'COMMENT'
    && child.dataset.type !== 'MOVE'
    && child.dataset.type !== 'CHANGESTYLE'
    && child.dataset.type !== 'ADDPARAGRAPH'
    && child.dataset.type !== 'DELETEPARAGRAPH'
    && child.dataset.type !== 'ADDTAB'
    && child.dataset.type !== 'DELETETAB'
    && child.dataset.type !== 'ADDPARAGRAPHSENTENCE'
    && child.dataset.type !== 'ADDSENTENCE'
    && child.dataset.type !== 'DELETESENTENCE'
    && child.dataset.type !== 'ADDLIST'
    && child.dataset.type !== 'ADDLISTITEM'
    && child.dataset.type !== 'ADDLISTITEM_TEXT'
    && child.dataset.type !== 'DELETELISTITEM'
    && child.dataset.type !== 'REORDERLISTITEMS'
    && child.dataset.type !== 'LISTLEVELMINUS'
    && child.dataset.type !== 'LISTLEVELPLUS') {

    let segmentType = "";

    if (child.nodeName === "P") {
      segmentType = "PARAGRAPH";
    } else if (child.nodeName === "SPAN") {
      segmentType = "TEXT";
    } else if (child.nodeName === "OL") {
      segmentType = "OL";
    } else if (child.nodeName === "UL") {
      segmentType = "UL";
    } else if (child.nodeName === "LI") {
      segmentType = "LI";
    } else if (child.nodeName === "IMG") {
      segmentType = "IMAGE";
    }

    let newParentElementId = child.parentElement && child.parentElement.id;

    if (segmentType !== "") {
      saveElementWorkSegment(segments, child, chapterId, newParentElementId, segmentType, saveSegments);
    }

    if (segmentType !== "TEXT") {
      let children = child.children;
      for (let i = 0; i < children.length; i++) {
        saveElementChildren(segments, children[i], chapterId, saveSegments);
      }
    }
  }
}

export const saveElementWorkSegment = (segments, element, chapterId, parentElementId, segmentType, saveSegments) => {
  let segment = segments.filter(m => m.elementId === Number(element.id))[0]
  let comment = segment && segment.comment ? segment.comment : '';
  let text = element.innerHTML.replace('<s></s>', '').replace('<b></b>', '').replace('<i></i>', '').replace('<u></u>', '') //Also get rid of any closed up format tags in succession that happen when undoing format styles over text.
  text = stripOutEditImages(text)
  //Careful here: The Word conversion returns with spans that have just a &nbsp; in them in order to provide the style for a TAB. Don't take out the &nbsp; if it isn't an add-on for a sentence-type span.
  if (text && text.length > 6 && text.indexOf('&nbsp;') === text.length - 6) text = text.substring(0, text.length - 6)
  let styleClass = element.getAttribute('class')
  let styleInline = element.getAttribute('style')
  let imageSource = element.getAttribute("src") //These four are image related attributes
  let imageHeight = element.getAttribute("height")
  let imageWidth = element.getAttribute("width")
  let imageAlt = element.getAttribute("alt")

  saveSegments.push({
    workSegmentId: 0,
    chapterId,
    type: segmentType,
    text,
    elementId: Number(element.id),
    parentElementId,
    styleClass,
    styleInline,
    imageSource,
    imageHeight,
    imageWidth,
    imageAlt,
    comment,
  })
}

const stripOutEditImages = (text) => {
  let fragment = new DOMParser().parseFromString(text, "text/html");
  const body = fragment.getElementsByTagName('body')[0]
  let remainingHtml = ''
  for (let i = 0; i < body.childNodes.length; i++) {
    if (!(body.childNodes[i].nodeName === 'IMG' && body.childNodes[i].dataset.type)) {
      remainingHtml += body.childNodes[i].outerHTML || body.childNodes[i].data
    }
  }
  if (remainingHtml && remainingHtml.length > 6 && remainingHtml.indexOf(' ') === 0) remainingHtml = remainingHtml.substring(1)
  return remainingHtml
}

export const useTabToNextSentence = (span) => {
  //If in a list structure, look for the next element in the structure (use the structure of edit-list-structure service)
  //otherwise if we are not in a list structure, go through a paragraph and then skip to the next paragraph - which could also lead us to enter a list structure, by the way.
  let nextSpan
  if (span) {
    if (isCursorInsideList(span)) {
      const structure = editListStructure.getListStructure(span, span)
      let elementSpanIndex = editListStructure.getStructureIndex(structure, span)
      let loop = 0
      ++elementSpanIndex
      nextSpan = structure[elementSpanIndex] && structure[elementSpanIndex].element
      while (!(nextSpan && nextSpan.nodeName === 'SPAN' && nextSpan.id) && loop < 10) {
        ++elementSpanIndex
        nextSpan = structure[elementSpanIndex] && structure[elementSpanIndex].element
        loop++
      }
      if (nextSpan && nextSpan.nodeName === 'SPAN' && nextSpan.id) {
        nextSpan.contentEditable = 'true'
        nextSpan.setAttribute('tabIndex', '0')
        setCursorPosition(nextSpan, nextSpan, 0, 0)
        return nextSpan
      }
    } else {
      span.setAttribute('tabIndex', '-1')
      nextSpan = span.nextElementSibling
      let paragraph = span.parentElement
      let paragraphNext = paragraph.nextSibling //We are also looking to see if this is a list sibling (OL or UL) below.
      let loop = 0
      while (nextSpan && !(nextSpan && nextSpan.nodeName === 'SPAN' && nextSpan.id && (nextSpan.dataset.type === 'TEXT' || nextSpan.dataset.type === 'ADDSENTENCE' || nextSpan.dataset.type === 'ADDPARAGRAPHSENTENCE')) && loop < 10) {
        nextSpan = nextSpan.nextElementSibling
        loop++
      }
      if (nextSpan && nextSpan.nodeName === 'SPAN' && nextSpan.id && (nextSpan.dataset.type === 'TEXT' || nextSpan.dataset.type === 'ADDSENTENCE' || nextSpan.dataset.type === 'ADDPARAGRAPHSENTENCE')) {
        nextSpan.contentEditable = 'true'
        nextSpan.setAttribute('tabIndex', '0')
        setCursorPosition(nextSpan, nextSpan, 0, 0)
        return nextSpan
      } else {
        if (paragraphNext && (paragraphNext.nodeName === 'OL' || paragraphNext.nodeName === 'UL')) {
          const structure = editListStructure.getListStructure(paragraphNext, paragraphNext)
          let elementSpanIndex = editListStructure.getStructureIndex(structure, paragraphNext)
          let loop = 0
          ++elementSpanIndex
          nextSpan = structure[elementSpanIndex] && structure[elementSpanIndex].element
          while (!(nextSpan && nextSpan.nodeName === 'SPAN' && nextSpan.id) && loop < 10) {
            ++elementSpanIndex
            nextSpan = structure[elementSpanIndex] && structure[elementSpanIndex].element
            loop++
          }
          if (nextSpan && nextSpan.nodeName === 'SPAN' && nextSpan.id) {
            nextSpan.contentEditable = 'true'
            nextSpan.setAttribute('tabIndex', '0')
            setCursorPosition(nextSpan, nextSpan, 0, 0)
            return nextSpan
          }
        } else {
          nextSpan = paragraphNext.firstChild
          let loop = 0
          while (nextSpan && !(nextSpan && nextSpan.nodeName === 'SPAN' && nextSpan.id && (nextSpan.dataset.type === 'TEXT' || nextSpan.dataset.type === 'ADDSENTENCE' || nextSpan.dataset.type === 'ADDPARAGRAPHSENTENCE')) && loop < 10) {
            nextSpan = nextSpan.nextElementSibling
            loop++
          }
          if (nextSpan && nextSpan.nodeName === 'SPAN' && nextSpan.id && (nextSpan.dataset.type === 'TEXT' || nextSpan.dataset.type === 'ADDSENTENCE' || nextSpan.dataset.type === 'ADDPARAGRAPHSENTENCE')) {
            nextSpan.contentEditable = 'true'
            nextSpan.setAttribute('tabIndex', '0')
            setCursorPosition(nextSpan, nextSpan, 0, 0)
            return nextSpan
          }
        }
      }
    }
  }
}

export const useTabToPreviousSentence = (span) => {
  //If in a list structure, look for the previous element in the structure (use the structure of edit-list-structure service)
  //otherwise if we are not in a list structure, go through a paragraph and when reaching the first child,  skip to the previous paragraph - which could also lead us to enter a list structure, by the way.
  let previousSpan
  if (span) {
    if (isCursorInsideList(span)) {
      const structure = editListStructure.getListStructure(span, span)
      let elementSpanIndex = editListStructure.getStructureIndex(structure, span)
      let loop = 0
      --elementSpanIndex
      previousSpan = structure[elementSpanIndex] && structure[elementSpanIndex].element
      while (!(previousSpan && previousSpan.nodeName === 'SPAN' && previousSpan.id) && loop < 10) {
        --elementSpanIndex
        previousSpan = structure[elementSpanIndex] && structure[elementSpanIndex].element
        loop++
      }
      if (previousSpan && previousSpan.nodeName === 'SPAN' && previousSpan.id) {
        previousSpan.contentEditable = 'true'
        previousSpan.setAttribute('tabIndex', '0')
        setCursorPosition(previousSpan, previousSpan, 0, 0)
        return previousSpan
      }
    } else {
      span.setAttribute('tabIndex', '-1')
      previousSpan = span.previousElementSibling
      let paragraph = span.parentElement
      let paragraphPrevious = paragraph.previousSibling //We are also looking to see if this is a list sibling (OL or UL) below.
      let loop = 0
      while (previousSpan && !(previousSpan && previousSpan.nodeName === 'SPAN' && previousSpan.id && (previousSpan.dataset.type === 'TEXT' || previousSpan.dataset.type === 'ADDSENTENCE' || previousSpan.dataset.type === 'ADDPARAGRAPHSENTENCE')) && loop < 10) {
        previousSpan = previousSpan.previousElementSibling
        loop++
      }
      if (previousSpan && previousSpan.nodeName === 'SPAN' && previousSpan.id && (previousSpan.dataset.type === 'TEXT' || previousSpan.dataset.type === 'ADDSENTENCE' || previousSpan.dataset.type === 'ADDPARAGRAPHSENTENCE')) {
        previousSpan.contentEditable = 'true'
        previousSpan.focus()
        previousSpan.setAttribute('tabIndex', '0')
        return previousSpan
      } else {
        if (paragraphPrevious && (paragraphPrevious.nodeName === 'OL' || paragraphPrevious.nodeName === 'UL')) {
          const structure = editListStructure.getListStructure(paragraphPrevious, paragraphPrevious)
          let elementSpanIndex = structure.length
          let loop = 0
          --elementSpanIndex
          previousSpan = structure[elementSpanIndex] && structure[elementSpanIndex].element
          while (!(previousSpan && previousSpan.nodeName === 'SPAN' && previousSpan.id) && loop < 10) {
            --elementSpanIndex
            previousSpan = structure[elementSpanIndex] && structure[elementSpanIndex].element
            loop++
          }
          if (previousSpan && previousSpan.nodeName === 'SPAN' && previousSpan.id) {
            previousSpan.contentEditable = 'true'
            previousSpan.setAttribute('tabIndex', '0')
            setCursorPosition(previousSpan, previousSpan, 0, 0)
            return previousSpan
          }
        } else {
          previousSpan = paragraphPrevious && paragraphPrevious.lastChild
          if (previousSpan) {
            let loop = 0
            while (previousSpan && !(previousSpan && previousSpan.nodeName === 'SPAN' && previousSpan.id && (previousSpan.dataset.type === 'TEXT' || previousSpan.dataset.type === 'ADDSENTENCE' || previousSpan.dataset.type === 'ADDPARAGRAPHSENTENCE')) && loop < 10) {
              previousSpan = previousSpan.previousElementSibling
              loop++
            }
            if (previousSpan && previousSpan.nodeName === 'SPAN' && previousSpan.id && (previousSpan.dataset.type === 'TEXT' || previousSpan.dataset.type === 'ADDSENTENCE' || previousSpan.dataset.type === 'ADDPARAGRAPHSENTENCE')) {
              previousSpan.contentEditable = 'true'
              previousSpan.setAttribute('tabIndex', '0')
              setCursorPosition(previousSpan, previousSpan, 0, 0)
              return previousSpan
            }
          }
        }
      }
    }
  }
}


// function sleep(milliseconds) {
// 	const date = Date.now();
// 	let currentDate = null;
// 	do {
// 		currentDate = Date.now();
// 	} while (currentDate - date < milliseconds);
// }

export const setCurrentElementSpan = (span, handleSetCurrentElement) => {
  if (span) {
    let cleanSpanId = span.id.indexOf('~tabView') > -1 ? span.id.substring(0, span.id.indexOf('~tabView')) : span.id
    let element = document.querySelector(`[id="${cleanSpanId}"][data-type="${span.dataset.type}"]`)
    if (element) {
      if (element.nodeName === 'SPAN' && element.id) {
        handleSetCurrentElement(element)
      } else if (element.nodeName === 'P') {
        if (element.hasChildNodes()) {
          for (let i = 0; i < element.children.length; i++) {
            if (!span && element.children[i].nodeName === 'SPAN' && element.children[i].id) {
              span = element.children[i]
            }
          }
        }
        handleSetCurrentElement(span)
      } else {
        span = element.parentElement
        let loop = 0
        while (span && !(span.nodeName === 'SPAN' && span.id) && loop < 10) {
          span = span.parentElement
          loop++
        }
        handleSetCurrentElement(span)
      }
    }
  }
}

export const processEditorTextChanges = (copySegments, span, addOrUpdateEdit, edits, personId, editorName, workSummary, isTranslation) => {
  let existEdit = span && span.id && edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(span.id) && m.personId === personId && m.type === 'TEXT')[0]
  let segment = span && copySegments && copySegments.length > 0 && copySegments.filter(m => m.elementId === Number(span.id))[0]
  let spanInnerHTML = !span ? '' : span.innerHTML.indexOf('&nbsp;') === span.innerHTML.length - 6 ? span.innerHTML.substring(0, span.innerHTML.length - 6) : span.innerHTML  //This is necessary because a &nbsp; is put at the end of each sentence for spacing and then taken off before it is saved to the database.
  let spanInnerHTMLMinusAllNBSP = !span ? '' : span.innerHTML.replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ')
  let segmentTextMinusAllNBSP = !(segment && segment.text) ? '' : spanInnerHTML.replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ')
  if (span && !(segment && (segment.text === spanInnerHTML || spanInnerHTMLMinusAllNBSP === segmentTextMinusAllNBSP))) {
    if (!(existEdit && existEdit.editSegmentId && existEdit.text === spanInnerHTML)) {
      span.style.backgroundColor = backgroundColors.editPending

      addOrUpdateEdit({
        editSegmentId: (existEdit && existEdit.editSegmentId) || 0,
        firstName: editorName && editorName.firstName,
        lastName: editorName && editorName.lastName,
        personId: personId,
        chapterId: workSummary ? workSummary.chapterId_current && workSummary.chapterId_current !== guidEmpty ? workSummary.chapterId_current : workSummary.chapterOptions && workSummary.chapterOptions[0].chapterId : guidEmpty,
        languageId: isTranslation ? isTranslation.languageId : workSummary.languageId_current,
        elementId: span.id,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        type: 'TEXT',
        text: spanInnerHTML,
        authorTextSnapshot: segment.text,
        comment: (existEdit && existEdit.comment) || '',
      })
    }
  }

}

export const removeDeleteParagraphBreakIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('ParagraphQuestionMinus') && loop < 5) {
    let images = document.getElementsByClassName('ParagraphQuestionMinus')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

export const removeAddParagraphBreakIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('ParagraphQuestionPlus') && loop < 5) {
    let images = document.getElementsByClassName('ParagraphQuestionPlus')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

export const combineParagraphs = (imgParagraphQuestionMinus, editorName) => {
  // 1. Ignore the currentParagraphElement and create it from the imgParagraphQuestionMinus.dataset.paragraphElementId
  // 2. Delete the ParagraphQuestionMinus image
  // 3. Add the ParagraphMinusEditorColor to the end of the previousSiblingElement
  // 4. Move the current paragraph's sentences to the previousSiblingElement paragraph
  //    a. Don't move the blank span tags (with the spaces or &nbsp;)
  // 5. Delete the current paragraph

  // 1. Ignore the currentParagraphElement and create it from the imgParagraphQuestionMinus.dataset.paragraphElementId
  const currentParagraphElement = document.getElementById(imgParagraphQuestionMinus.dataset.paragraphElementId)

  // 2. Delete the ParagraphQuestionMinus image
  imgParagraphQuestionMinus.remove()

  // 3. Add the ParagraphMinusEditorColor to the end of the previousSiblingElement
  const previousSiblingElement = currentParagraphElement.previousElementSibling
  const img = createParagraphMinusEditor(`/inline/paragraph-minus-${backgroundColors.currentEditorColor}.svg`, editorName.firstName + ' ' + editorName.lastName, currentParagraphElement.id)
  previousSiblingElement.append(img)

  // 4. Move the current paragraph's sentences to the previousSiblingElement paragraph
  //    a. Don't move the blank span tags (with the spaces or &nbsp;)
  for (let i = 0; i < currentParagraphElement.children.length; i++) {
    let notBlank = currentParagraphElement.children[i].innerHTML.replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ').replace(/ /g, '')
    if (notBlank && currentParagraphElement.children[i].nodeName === 'SPAN' && currentParagraphElement.children[i].id) {
      previousSiblingElement.append(currentParagraphElement.children[i])
      let space = document.createTextNode("\u00A0")
      previousSiblingElement.append(space)
    }
  }

  // 5. Delete the current paragraph
  currentParagraphElement.remove()
}

export const createParagraphMinusEditor = (urlImage, editorFullName, currentParagraphElementId) => {
  let img = document.createElement('img')
  img.id = currentParagraphElementId
  img.src = urlImage
  img.alt = 'Delete P'
  img.height = 22
  img.className = 'ParagraphMinus'
  img.style.cursor = 'pointer'
  img.title = editorFullName
  img.setAttribute('data-type', 'DELETEPARAGRAPH')
  return img
}

export const createParagraphBreak = (imgParagraphQuestionPlus, editorName, getNextId) => {
  // 1. Get the spanOnRight from the imgParagraphQuestionPlus.dataset.spanId
  // 2. Delete the imgParagraphQuestionPlus image
  // 3. Add the ParagraphPlusEditorColor to the end of the previousSiblingElement
  // 4. Create the new paragraph and append it to the left paragraph
  // 5. Move any children from the left paragraph beginning with the spanOnRight and children that follow, if any.

  // 1. Ignore the currentParagraphElement and create it from the imgParagraphQuestionMinus.dataset.paragraphElementId
  const spanOnRight = document.querySelectorAll(`[id="${imgParagraphQuestionPlus.dataset.spanId}"][data-type="TEXT"]`)[0]

  // 2. Delete the imgParagraphQuestionPlus image
  if (imgParagraphQuestionPlus) imgParagraphQuestionPlus.remove()

  // 3. Add the ParagraphPlusEditorColor to the end of the previousSiblingElement
  let parentParagraph = spanOnRight.parentElement
  const img = createParagraphPlusEditor(`/inline/paragraph-plus-${backgroundColors.currentEditorColor}.svg`, editorName, spanOnRight)
  parentParagraph.insertBefore(img, spanOnRight)

  // 4. Create the new paragraph and append it to the left paragraph
  let newParagraph = document.createElement('p')
  newParagraph.id = spanOnRight.id
  //newParagraph.setAttribute('data-subsequence', spanOnRight.subSequence) //This is for ADDPARAGRAPHSENTENCE only. We don't accumulate ADDPARAGRAPH-s
  newParagraph.setAttribute('style', parentParagraph.style.cssText)
  let grandParent = parentParagraph.parentElement
  let nextParagraph = parentParagraph.nextElementSibling
  grandParent.insertBefore(newParagraph, nextParagraph)

  // 5. Move any children from the left paragraph beginning with the spanOnRight and children that follow, if any.
  let foundSpanOnRight = false
  let children = parentParagraph.children
  for (let i = 0; i < children.length;) {
    if (!foundSpanOnRight) {
      if (children[i].nodeName === 'SPAN' && children[i].id === spanOnRight.id) {
        foundSpanOnRight = true
        newParagraph.append(children[i])
        let space = document.createTextNode("\u00A0")
        newParagraph.append(space)
      } else {
        i++
      }
    } else {
      newParagraph.append(children[i])
    }
  }
}

export const createParagraphPlusEditor = (urlImage, editorName, edit, divDisplay) => {
  let validId = edit.elementId ? edit.elementId : edit.id //most of the time edit really is an edit record with elementId. But there is at least one situation out there which is an actual span element which is going to be id instead of elementId
  let className = !divDisplay || divDisplay.id === 'editorDiv' ? 'ParagraphPlus' : 'ParagraphPlus~tabView'
  let elementId = !divDisplay || divDisplay.id === 'editorDiv' ? validId : validId + '~tabView'
  //const existImage = document.querySelector(`img[data-span-id="${elementId}"][data-type="ADDPARAGRAPH"]`)
  // if (!existImage) { //This was not letting the add paragraph be created. It was not in the view. But the logic here was saying that it found it.  So we will ignore this condition and let it be written. If we get duplicates, we wlil soon find out.
  let img = document.createElement('img')
  img.id = elementId  
  img.src = urlImage
  img.alt = 'Add P'
  img.height = 22
  img.className = className
  img.style.cursor = 'pointer'
  img.title = editorName.firstName + ' ' + editorName.lastName
  img.setAttribute('data-span-id', elementId)
  img.setAttribute('data-type', 'ADDPARAGRAPH')
  //if (edit.subSequence) img.setAttribute('data-subsequence', edit.subSequence)   //This is for ADDPARAGRAPHSENTENCE only. We don't accumulate ADDPARAGRAPH-s
  return img
  // }
}


export const removeMoveSentencesStartIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('MoveStart') && loop < 5) {
    let images = document.getElementsByClassName('MoveStart')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

export const removeMoveSentencesEndIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('MoveEnd') && loop < 5) {
    let images = document.getElementsByClassName('MoveEnd')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

export const removeMoveSentencesTargetIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('MoveTarget') && loop < 5) {
    let images = document.getElementsByClassName('MoveTarget')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

export const setMoveSentencesEndIcons = (personId, editorName, chapterId, updateMoveEdit, editLanguageId, startElementId) => {
  let assignNewImages = []
  let foundStartElement = false

  const mainDiv = getMainElementChildren()
  for (let p = 0; p < mainDiv.length; p++) {
    let spans = mainDiv[p].children
    let isFirstParagraphSegment = true  //Don't put a move-end tag at the beginning of the first sentence of a paragraph.
    for (let s = 0; s < spans.length; s++) {
      if (!foundStartElement) {
        if (spans[s].nodeName === 'SPAN' && Number(spans[s].id) === Number(startElementId)) {
          foundStartElement = true
          if (s === spans.length - 1) {
            let img = document.createElement('img')
            img.src = MoveEnd
            img.height = 10
            img.width = 22
            img.className = 'MoveEnd'
            img.style.cursor = 'pointer'
            img.setAttribute('data-paragraph-id', mainDiv[p].id) //Notice that this is the paragraph and not the span id
            img.setAttribute('data-type', 'MOVE')
            img.setAttribute('data-is-move-end-paragraph', 'YES')
            img.addEventListener("click", function (event) {
              //inside 1
              event.stopPropagation()
              event.preventDefault()
              updateMoveEdit('ChoseEndElement', mainDiv[p].id, editLanguageId, mainDiv[p].id, mainDiv[p].id)
              removeMoveSentencesEndIcons()
              let element = document.getElementById(event.target.dataset.spanId)
              if (!element) element = document.getElementById(event.target.dataset.paragraphId)
              let isEnd
              if (element.nodeName === 'SPAN') {
                isEnd = element.nextElementSibling && element.nextElementSibling.nodeName === 'SPAN' && element.nextElementSibling.id ? '' : element.parentElement
              } else if (element.nodeName === 'P') {
                isEnd = element.id
              }
              setMoveSentencesTargetIcons(personId, editorName, chapterId, updateMoveEdit, editLanguageId, startElementId, spans[s].id, isEnd)
              setMoveSentencesEditorIcon('end', personId, editorName, chapterId, mainDiv[p], mainDiv[p], 'insertAfter', 'moveEndParagraph')
            })
            assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
              moveEndParagraph: mainDiv[p].id,
              paragraph: mainDiv[p],
              image: img,
              span: null
            })
          }
        }
      } else if (foundStartElement) {
        if (!isFirstParagraphSegment) {
          let isNotEmptySpan = spans[s].innerHTML.replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ').replace(/ /g, '')
          if (isNotEmptySpan) {
            let spanId = spans[s].id ? spans[s].id : spans[s].dataset.spanId
            let img = document.createElement('img')
            img.src = MoveEnd
            img.height = 10
            img.width = 22
            img.className = 'MoveEnd'
            img.style.cursor = 'pointer'
            img.setAttribute('data-span-id', spans[s].id)
            img.setAttribute('data-type', 'MOVE')
            img.addEventListener("click", function (event) {
              //inside 2
              event.stopPropagation()
              event.preventDefault()
              updateMoveEdit('ChoseEndElement', spanId, editLanguageId)
              removeMoveSentencesEndIcons()
              setMoveSentencesTargetIcons(personId, editorName, chapterId, updateMoveEdit, editLanguageId, startElementId, spanId)
              let spanImageSelected = document.querySelector(`span[id="${spanId}"][data-type='TEXT']`)
              setMoveSentencesEditorIcon('end', personId, editorName, chapterId, mainDiv[p], spanImageSelected, 'insertAfter')
            })
            assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
              paragraph: mainDiv[p],
              image: img,
              span: spans[s]
            })
          }
        }
        if (s === spans.length - 1) {
          let img = document.createElement('img')
          img.src = MoveEnd
          img.height = 10
          img.width = 22
          img.className = 'MoveEnd'
          img.style.cursor = 'pointer'
          img.setAttribute('data-paragraph-id', mainDiv[p].id) //Notice that this is the paragraph and not the span id
          img.setAttribute('data-type', 'MOVE')
          img.setAttribute('data-is-move-end-paragraph', 'YES')
          img.addEventListener("click", function (event) {
            event.stopPropagation()
            event.preventDefault()
            //inside 3
            updateMoveEdit('ChoseEndElement', mainDiv[p].id, editLanguageId, mainDiv[p].id, mainDiv[p].id)
            removeMoveSentencesEndIcons()
            setMoveSentencesTargetIcons(personId, editorName, chapterId, updateMoveEdit, editLanguageId, startElementId, mainDiv[p].id)  //This had a last parameter repeating: mainDiv[p].id
            setMoveSentencesEditorIcon('end', personId, editorName, chapterId, mainDiv[p], mainDiv[p], 'insertAfter', mainDiv[p].id)
          })
          assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
            moveEndParagraph: mainDiv[p].id,
            paragraph: mainDiv[p],
            image: img,
            span: null
          })
        }
      }
      if (spans[s].nodeName === 'SPAN' && spans[s].id) {
        isFirstParagraphSegment = false
      }
    }
  }
  assignNewImages.forEach(m => {
    if (m.moveEndParagraph) {
      m.paragraph.append(m.image)
    } else {
      m.paragraph.insertBefore(m.image, m.span)
    }
  })
}

export const getMoveSegments = (newMoveEditArray, endElementId, moveEndParagraph) => {
  // 1. get the moveEdit startElementId from editorDiv
  // 2. record in the moveEdit's moveSegmentsArray all of the elements from the startElementId (which have real text) to the endElementId
  //      but not including the endElementId (unless we are ending with the last sentence of a paragraph)
  let foundStartElement = false
  let foundEndElement = false
  let startingParagraphId
  const mainDiv = getMainElementChildren()
  for (let p = 0; p < mainDiv.length && !foundEndElement; p++) {
    let spans = mainDiv[p].children
    if (startingParagraphId && startingParagraphId !== mainDiv[p].id) {
      newMoveEditArray.push(mainDiv[p].id) //We include any paragraph borders that we cross over to get more sentences in the move.
    }
    for (let s = 0; s < spans.length && !foundEndElement; s++) {
      if (!foundStartElement) {
        if (spans[s].nodeName === 'SPAN' && Number(spans[s].id) === Number(newMoveEditArray[0])) {
          foundStartElement = true
          startingParagraphId = mainDiv[p].id
        } //Notice that it skips the found element so it is not recorded in the array since it is already the first element of the array when coming to this function.
        if (moveEndParagraph && Number(endElementId) === Number(mainDiv[p].id) && s === spans.length - 1) {
          foundEndElement = true
          break
        }
      } else {
        if (spans[s].nodeName === 'SPAN' && Number(spans[s].id) === Number(endElementId)) {
          foundEndElement = true
          if (moveEndParagraph) newMoveEditArray.push(spans[s].id) //We include the span that is past the actual selection because we need to put a move-target-editor icon for the editor's view once the sentences are moved to the actual target.
          break
        } else if (spans[s].nodeName === 'SPAN' && !isNaN(spans[s].id)) {
          newMoveEditArray.push(spans[s].id)
        }
        if (moveEndParagraph && Number(endElementId) === Number(mainDiv[p].id) && s === spans.length - 1) {
          foundEndElement = true
          break
        }
      }
    }
  }
  return newMoveEditArray
}

export const setMoveSentencesTargetIcons = (personId, editorName, chapterId, updateMoveEdit, editLanguageId, startElementId, endElementId, moveEndParagraph) => {
  //Create a target element under these conditions:
  let assignNewImages = []
  let moveElementArray = getMoveSegments([startElementId], endElementId, moveEndParagraph)

  const mainDiv = getMainElementChildren()
  for (let p = 0; p < mainDiv.length; p++) {
    if (mainDiv[p].nodeName !== 'UL' && mainDiv[p].nodeName !== 'OL' && mainDiv[p].nodeName !== 'LI') {
      let spans = mainDiv[p].children
      for (let s = 0; s < spans.length; s++) {
        if (s < spans.length) {
          let isSelected = moveElementArray.indexOf(spans[s].id) > -1
          let lastSelectedId = moveElementArray[moveElementArray.length - 1]
          let lastSelectedElement = lastSelectedId && document.querySelector(`span[id="${lastSelectedId}"][data-type='TEXT']`)
          let isLastNextSibling = lastSelectedElement && lastSelectedElement.nextSibling && lastSelectedElement.nextElementSibling.id === spans[s].id
          if (!(isSelected || isLastNextSibling) && spans[s].id !== moveEndParagraph) {
            let spanId = spans[s].id
            let isNotEmptySpan = spans[s].innerHTML.replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ').replace(/ /g, '')
            if (isNotEmptySpan) {
              let img = document.createElement('img')
              img.src = MoveTarget
              img.height = 14
              img.className = 'MoveTarget'
              img.style.cursor = 'pointer'
              img.setAttribute('data-span-id', spans[s].id)
              img.setAttribute('data-type', 'MOVE')
              img.addEventListener("click", function (event) {
                event.stopPropagation()
                event.preventDefault()
                removeMoveSentencesTargetIcons()
                updateMoveEdit('ChoseTarget', spanId, editLanguageId)
              })
              assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
                paragraph: mainDiv[p],
                image: img,
                span: spans[s]
              })
            }
          }
        }
      }
      //The difference here is data-paragraph-id instead of data-span-id. Also the paragraphId is sent into updateMoveEdit.
      let img = document.createElement('img')
      img.src = MoveTarget
      img.height = 14
      img.className = 'MoveTarget'
      img.style.cursor = 'pointer'
      img.setAttribute('data-paragraph-id', mainDiv[p].id)
      img.setAttribute('data-type', 'MOVE')
      img.addEventListener("click", function (event) {
        event.stopPropagation()
        event.preventDefault()
        removeMoveSentencesTargetIcons()
        updateMoveEdit('ChoseTarget', '', editLanguageId, mainDiv[p].id, true)
      })
      assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
        paragraph: mainDiv[p],
        image: img,
        span: '',
        endOfParagraph: mainDiv[p]
      })
    }
  }
  assignNewImages.forEach(m => {
    if (m.span) {
      m.paragraph.insertBefore(m.image, m.span)
    } else {
      m.paragraph.append(m.image)
    }
  })
}

export const setMoveSentencesEditorIcon = (iconType, personId, editorName, chapterId, paragraph, span, insertAfter, moveEndParagraph) => {
  let img = document.createElement('img')
  img.src = `/inline/move-${iconType}-${backgroundColors.currentEditorColor}.svg`
  //img.id = span.id
  img.height = 15
  img.width = 25
  img.className = 'MoveTargetChosen'
  img.style.cursor = 'pointer'
  img.title = editorName.firstName + ' ' + editorName.lastName
  img.setAttribute('data-span-id', span && span.id)
  img.setAttribute('data-paragraph-id', paragraph.id) //Notice that this is the paragraph and not the span id
  img.setAttribute('data-is-move-end-paragraph', 'YES')
  img.setAttribute('data-type', 'MOVE')

  if (moveEndParagraph) {
    paragraph.append(img)
  } else {
    paragraph.insertBefore(img, span)
  }
}

export const moveSentencesInSegments = (paramSegments, editMove) => {
  // 1. Get the beginning elementId
  // 2. Collect all of the elements (including paragraphs) but do not include the end elementId since that was only saved so we know where to put a move-target-editor icon for the edit-owner's view
  // 3. Move the collection and insert them at the moveToElementId
  // All is well with moving segments before a given segment, but when the target is the end of a paragraph then we need to look for the last segment of that paragraph and put them at the end. 
  // Remember we are not working with the DOM here but the list of workSegments in sequential order
  let moveSegmentsArray = editMove && editMove.moveSegmentsArray && editMove.moveSegmentsArray.split(',') //The editMoves.moveSegmentsArray is only a string of a list of elementIds. We need to make it an array.
  let cutSegmentsIndices = []
  let targetSegmentIndex = null
  let cutSegments = []
  let newSegments = []

  if (editMove.isMoveEndParagraphTarget) {
    // find the paragraph
    // then go until another paragraph or list (UL/OL) starts.
    // get that previous span's index
    // move the segments after that last segment (the insert statement below needs to keep the targetSegmentIndex as it is without decrementing it by one.
    let foundParagraph = false
    let foundNextParagraph = false
    paramSegments && paramSegments.length > 0 && paramSegments.forEach((m, i) => {
      if (!foundParagraph && Number(m.elementId) === Number(editMove.moveEndParagraph)) {
        foundParagraph = true
      } else if (foundParagraph && !foundNextParagraph) {
        if (m.type === 'PARAGRAPH' || m.type === 'UL' || m.type === 'OL') {
          foundNextParagraph = true
        } else {
          //the last child is the last one to get an index before this loop is through because the foundNextParagraph is true.
          targetSegmentIndex = i
        }
      }
    })
  } else {
    paramSegments && paramSegments.length > 0 && paramSegments.forEach((m, i) => {
      if (Number(m.elementId) === Number(editMove.moveToElementId)) {
        targetSegmentIndex = i  //ToDo this might be a problem here. The code I removed was "i+1" inferring an proactive adjusted placemenet., But moveTo should be that given targetIndex. so if this was accurate for "i+1" that you find in testing later, then we need to reconsider the logic for both scenarios.
      }
    })
  }

  moveSegmentsArray && moveSegmentsArray.length > 0 && moveSegmentsArray.forEach((moveElementId, mIndex) => {
    paramSegments && paramSegments.length > 0 && paramSegments.forEach((m, mIndex) => {
      if (Number(moveElementId) === Number(m.elementId)) {
        cutSegmentsIndices.push(mIndex)
      }
    })
  })

  cutSegments = paramSegments.splice(cutSegmentsIndices[0], cutSegmentsIndices.length)
  //We actually need to push AFTER this last element. We add 1 to the targetSegmentIndex found.  No. I took out "targetSegmentIndex + 1". We need it before that element.
  newSegments = insert(paramSegments, targetSegmentIndex, ...cutSegments)
  return newSegments
}

export const setMoveIcons = (displayPersonId, divDisplayId, edits = [], chosenTab, tabsData = [], isAuthor) => {
  //Set the move-start-editor and move-end-editor icons around the moved sentences (whether they have been moved to the new location for the edit-owner or remain in place for others)
  //If this is the edit-owner,
  //  the segments have already been adjusted in setSegmentSpanWithEdits.
  //  set the move-target-editor icon to the place where the sentences have been moved from
  //    If this is a "moveEndParagraph" that needs to be treated differently because the target icon needs to be placed at the end of the paragraph "paragraph.append(img)" instead of inserted before a span "paragraph.insertBefore(img, span)"
  //      then place the target where the sentences were moved from as "paragraph.append(img)"
  //else (this is not the edit-owner)
  //  set the move-target-editor icon at the editMove.moveToElementId
  //    If this is a "moveEndParagraph" that needs to be treated differently because the Move-End icon needs to be placed at the end the paragraph "paragraph.append(img)" instead of inserted before a span "paragraph.insertBefore(img, span)"
  let divContentIdAdd = divDisplayId === 'editorDiv' ? '' : '~' + divDisplayId
  let editMoves = (edits && edits.length > 0 && edits.filter(e => e.type === 'MOVE')) || []
  
  editMoves.forEach(m => {
    let isEditOwner = divDisplayId === 'tabView' ? m.personId === chosenTab : m.personId === displayPersonId
    let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === m.personId)[0]) || {id: '', label: '', editorColor: '', editorName: ''}
    let editorColor = (editor && editor.editorColor && editor.editorColor.replace('#', '')) || backgroundColors.currentEditorColor
    let moveSegmentsArray = m.moveSegmentsArray.split(',') //The editMoves.moveSegmentsArray is only a string of a list of elementIds. We need to make it an array.
    let moveStartElementId = moveSegmentsArray && moveSegmentsArray.length > 0 && moveSegmentsArray[0]
    let moveEndElementId = moveSegmentsArray && moveSegmentsArray.length > 0 && moveSegmentsArray[moveSegmentsArray.length - 1]

    //Start element
    let startElement = document.querySelector(`span[id="${moveStartElementId + divContentIdAdd}"][data-type='TEXT']`)
    if (startElement) {
      let {
        isImageDuplicate,
        img
      } = createMoveIconEditor('start', editorColor, m.firstName + ' ' + m.lastName, startElement, m.editSegmentId, divDisplayId)
      if (!isImageDuplicate) startElement.parentElement.insertBefore(img, startElement)
    }

    //End element
    let moveEndElement = document.querySelector(`span[id="${moveEndElementId + divContentIdAdd}"][data-type='TEXT']`)
    if (moveEndElement) {
      let {
        isImageDuplicate,
        img
      } = createMoveIconEditor('end', editorColor, m.firstName + ' ' + m.lastName, startElement, m.editSegmentId, divDisplayId)
      if (!isImageDuplicate) {
        let nextSibling = moveEndElement.nextSibling
        let loop = 0
        while (nextSibling && !(nextSibling.nodeName === 'SPAN' && nextSibling.id) && loop < 5) {
          nextSibling = nextSibling.nextSibling
          loop++
        }
        if (nextSibling && nextSibling.nodeName === 'SPAN' && nextSibling.id) {
          moveEndElement.parentElement.insertBefore(img, nextSibling)
        } else {
          moveEndElement.parentElement.append(img)
        }
      }
    }

    //Target element
    if (isEditOwner) {
      //if (m.moveEndParagraph) {
      //If this is the editor, we are already swapping the sentences so we need to actually put the target where the sentences WERE located.
      //So we are using the startElementId rather than the moveEndParagraph that we are checking here.
      //This gets tricky, because it is moved already. So let's pick up the element that comes in the workSegments right before this startElementId
      let elementPreTarget = document.getElementById(m.precedingStartElementId + divContentIdAdd)
      if (elementPreTarget) {
        let { isImageDuplicate, img } = createMoveIconEditor('to', editorColor, m.firstName + ' ' + m.lastName, startElement, m.editSegmentId, divDisplayId)
        if (!isImageDuplicate) {
          if (elementPreTarget.nodeName === 'P') {
            if (elementPreTarget) {
              if (elementPreTarget.firstChild) {
                elementPreTarget.insertBefore(img, elementPreTarget.firstChild)
              } else {
                elementPreTarget.append(img)
              }
            }
          } else if (elementPreTarget.nodeName === 'SPAN') {
            if (elementPreTarget.nextElementSibling) {
              elementPreTarget.parentElement.insertBefore(img, elementPreTarget.nextElementSibling)
            } else {
              elementPreTarget.parentElement.append(img)
            }
          }
        }
      }
      // } else {
      // 	//We need to place this target at the end of the paragraph (append) rather than doing an insertBefore that last segment
      // 	let precedingStartElementId = document.querySelector(`span[id="${m.precedingStartElementId + divContentIdAdd}"][data-type='TEXT']`)
      // 	if (lastMoveSegment) {
      // 		let {isImageDuplicate, img} = createMoveIconEditor('to', editorColor, editor.editorName, lastMoveSegment, m.editSegmentId, divDisplayId)
      // 		if (!isImageDuplicate) lastMoveSegment.parentElement.append(img)
      // 	}
      // }
    } else {
      //This is NOT the edit-owner-editor. Put this target at the TO location.
      let targetElement
      if (m.moveEndParagraph) {
        targetElement = document.querySelector(`p[id="${m.moveEndParagraph + divContentIdAdd}"]`)
      } else {
        targetElement = document.querySelector(`span[id="${m.moveToElementId + divContentIdAdd}"][data-type='TEXT']`)
      }
      if (targetElement) {
        let {isImageDuplicate, img} = createMoveIconEditor('to', editorColor, editor.editorName, startElement, m.editSegmentId, divDisplayId)
        if (!isImageDuplicate && img) {
          if (m.moveEndParagraph) {
            targetElement.append(img)
          } else {
            targetElement.parentElement.insertBefore(img, targetElement)
          }
        }
      }
    }
  })
}

export const createMoveIconEditor = (iconType, editorColor, editorFullName, span, editSegmentId, divDisplayId) => {
  let divContentIdAdd = divDisplayId === 'editorDiv' ? '' : '~' + divDisplayId
  let img = document.createElement('img')
  img.id = span.id
  img.src = img.src = `/inline/move-${iconType}-${editorColor}.svg`
  img.alt = 'Move'
  img.height = 15
  img.title = editorFullName
  if (iconType === 'end') img.width = 25
  img.className = 'MoveSentences' + divContentIdAdd
  img.style.cursor = 'pointer'
  img.contentEditable = 'false'  //ToDo do we need to set this so the cursor doesn't going into it while editing?
  img.setAttribute('data-span-id', span && span.id)
  img.setAttribute('data-icon-type', iconType)
  img.setAttribute('data-edit-segment-id', editSegmentId) // + divContentIdAdd
  img.setAttribute('data-type', 'MOVE')

  const isImageDuplicate = false //document.querySelectorAll(`[data-edit-segment-id="${editSegmentId + divContentIdAdd}"][data-icon-type="to"]`) //.MoveSentences${divContentIdAdd} ???
  return {
    isImageDuplicate: isImageDuplicate && isImageDuplicate.length > 0,
    img,
  }
}

export const removeChangeStyleStartIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('ChangeStartStart') && loop < 5) {
    let images = document.getElementsByClassName('ChangeStartStart')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

export const removeChangeStyleEndIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('ChangeStyleEnd') && loop < 5) {
    let images = document.getElementsByClassName('ChangeStyleEnd')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

export const setChangeStyleEndIcons = (personId, editorName, chapterId, updateChangeStyleEdit, editLanguageId, startElementId) => {
  let assignNewImages = []
  let foundStartElement = false
  const spans = Array.from(document.querySelectorAll('span[id][data-type="TEXT"]')).filter(span => !isNaN(Number(span.id.trim())));

  for (let s = 0; s < spans.length; s++) {
    let spanParent = spans[s].parentElement
    //Don't put a change-style-end tag at the beginning of the first sentence of a paragraph.
    let isFirstParagraphSegment = spanParent.firstChild === spans[s] 
    let isLastChild = getIsLastChild(spans[s]) 
    if (!foundStartElement) {
      if (spans[s].nodeName === 'SPAN' && Number(spans[s].id) === Number(startElementId)) {
        foundStartElement = true
        if (isLastChild) {
          let img = document.createElement('img')
          img.src = ChangeStyleEnd
          img.height = 15
          //img.width = 22
          img.className = 'ChangeStyleEnd'
          img.style.cursor = 'pointer'
          img.setAttribute('data-paragraph-id', spanParent.id) //Notice that this is the paragraph and not the span id
          img.setAttribute('data-type', 'CHANGESTYLE')
          img.setAttribute('data-is-change-style-end-paragraph', 'YES')
          img.addEventListener("click", function (event) {
            event.stopPropagation()
            event.preventDefault()
            updateChangeStyleEdit('ChoseEndElement', spanParent.id, editLanguageId, [], spanParent.id)
            removeChangeStyleEndIcons()
            let element = document.getElementById(event.target.dataset.spanId)
            if (!element) element = document.getElementById(event.target.dataset.paragraphId)
            let isEnd
            if (element.nodeName === 'SPAN') {
              isEnd = element.nextElementSibling && element.nextElementSibling.nodeName === 'SPAN' && element.nextElementSibling.id ? '' : element.parentElement
            } else if (element.nodeName === 'P' || element.nodeName === 'LI') {
              isEnd = element.id
            }
            setChangeStyleEditorIcon('end', personId, editorName, chapterId, spanParent, spanParent, 'insertAfter', 'moveEndParagraph')
          })
          assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
            moveEndParagraph: spanParent.id,
            paragraph: spanParent,
            image: img,
            span: null
          })
        }
      }
    } else if (foundStartElement) {
      if (!isFirstParagraphSegment) {
        let isNotEmptySpan = spans[s].innerHTML.replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ').replace(/ /g, '')
        if (isNotEmptySpan) {
          let spanId = spans[s].id ? spans[s].id : spans[s].dataset.spanId
          let img = document.createElement('img')
          img.src = ChangeStyleEnd
          img.height = 15
          //img.width = 22
          img.className = 'ChangeStyleEnd'
          img.style.cursor = 'pointer'
          img.setAttribute('data-span-id', spans[s].id)
          img.setAttribute('data-type', 'CHANGESTYLE')
          img.addEventListener("click", function (event) {
            //inside 2
            event.stopPropagation()
            event.preventDefault()
            updateChangeStyleEdit('ChoseEndElement', spanId, editLanguageId)
            removeChangeStyleEndIcons()
            let spanImageSelected = document.querySelector(`span[id="${spanId}"][data-type='TEXT']`)
            setChangeStyleEditorIcon('end', personId, editorName, chapterId, spanParent, spanImageSelected, 'insertAfter')
          })
          assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
            paragraph: spanParent,
            image: img,
            span: spans[s]
          })
        }
      }
      if (isLastChild) {
        let img = document.createElement('img')
        img.src = ChangeStyleEnd
        img.height = 15
        //img.width = 22
        img.className = 'ChangeStyleEnd'
        img.style.cursor = 'pointer'
        img.setAttribute('data-paragraph-id', spanParent.id) //Notice that this is the paragraph and not the span id
        img.setAttribute('data-type', 'CHANGESTYLE')
        img.setAttribute('data-is-change-style-end-paragraph', 'YES')
        img.addEventListener("click", function (event) {
          event.stopPropagation()
          event.preventDefault()
          updateChangeStyleEdit('ChoseEndElement', spanParent.id, editLanguageId, [], spanParent.id)
          removeChangeStyleEndIcons()
          setChangeStyleEditorIcon('end', personId, editorName, chapterId, spanParent, spanParent, 'insertAfter', spanParent.id)
        })
        assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
          moveEndParagraph: spanParent.id,
          paragraph: spanParent,
          image: img,
          span: null
        })
      }
    }
    if (spans[s].nodeName === 'SPAN' && spans[s].id) {
      isFirstParagraphSegment = false
    }
  }

  assignNewImages.forEach(m => {
    if (m.moveEndParagraph) {
      m.paragraph.append(m.image)
    } else {
      m.paragraph.insertBefore(m.image, m.span)
    }
  })
}

export const getChangeStyleSegments = (newChangeStyleEditArray, endElementId, moveEndParagraph) => {
  // 1. get the changeStyleEdit startElementId from editorDiv
  // 2. record in the changeStyleEdit's changeStyleSegmentsArray all of the elements from the startElementId (which have real text) to the endElementId
  //      but not including the endElementId (unless we are ending with the last sentence of a paragraph)
  let foundStartElement = false
  let foundEndElement = false
  const spans = Array.from(document.querySelectorAll('span[id][data-type="TEXT"]')).filter(span => !isNaN(Number(span.id.trim())));

  for (let s = 0; s < spans.length && !foundEndElement; s++) {
    let spanParent = spans[s].parentElement
    var test = spans[s]
    let isLastChild = getIsLastChild(spans[s])
    if (!foundStartElement) {
      if (spans[s].nodeName === 'SPAN' && Number(spans[s].id) === Number(newChangeStyleEditArray[0])) {
        foundStartElement = true
      } //Notice that it skips the found element so it is not recorded in the array since it is already the first element of the array when coming to this function.
      if (moveEndParagraph && Number(endElementId) === Number(spanParent.id) && isLastChild) {
        foundEndElement = true
        break
      }
    } else {
      if (spans[s].nodeName === 'SPAN' && Number(spans[s].id) === Number(endElementId)) {
        foundEndElement = true
        if (moveEndParagraph) newChangeStyleEditArray.push(spans[s].id) //We include the span that is past the actual selection because we need to put a move-target-editor icon for the editor's view once the sentences are moved to the actual target.
        break
      } else if (spans[s].nodeName === 'SPAN' && !isNaN(spans[s].id)) {
        newChangeStyleEditArray.push(spans[s].id)
      }
      if (moveEndParagraph && Number(endElementId) === Number(spanParent.id) && isLastChild) {
        foundEndElement = true
        break
      }
    }
  }
  return newChangeStyleEditArray
}

export const setChangeStyleEditorIcon = (iconType, personId, editorName, chapterId, paragraph, span, insertAfter, moveEndParagraph) => {
  let img = document.createElement('img')
  img.src = `/inline/change-style-${iconType}-${backgroundColors.currentEditorColor}.png`
  //img.id = span.id
  img.height = 15
  //img.width = 25
  img.className = 'ChangeStyleTargetChosen' //This might be a misnomer since the ChangeStyle doesn't have a target like the moveSentence edit does (which is what this was copied from)
  img.style.cursor = 'pointer'
  img.title = editorName.firstName + ' ' + editorName.lastName
  img.setAttribute('data-span-id', span && span.id)
  img.setAttribute('data-paragraph-id', paragraph.id) //Notice that this is the paragraph and not the span id
  img.setAttribute('data-is-change-style-end-paragraph', 'YES')
  img.setAttribute('data-type', 'CHANGESTYLE')

  if (moveEndParagraph) {
    paragraph.append(img)
  } else {
    paragraph.insertBefore(img, span)
  }
}

export const changeStyleInSegments = (paramSegments, editChangeStyle) => {
  // 1. Get the beginning elementId
  // 2. Collect all of the elements (including paragraphs) but do not include the end elementId since that was only saved so we know where to put a change-style-target-editor icon for the edit-ownerr's view
  // 3. Move the collection and insert them at the moveToElementId
  // All is well with moving segments before a given segment, but when the target is the end of a paragraph then we need to look for the last segment of that paragraph and put them at the end.
  let changeStyleSegmentsArray = editChangeStyle && editChangeStyle.changeStyleSegmentsArray && editChangeStyle.changeStyleSegmentsArray.split(',') //The editChangeStyles.changeStyleSegmentsArray is only a string of a list of elementIds. We need to make it an array.
  let cutSegmentsIndices = []
  let targetSegmentIndex = null
  let cutSegments = []
  let newSegments = []

  if (editChangeStyle.isChangeStyleEndParagraphTarget) {
    // find the paragraph
    // then go until another paragraph or list (UL/OL) starts.
    // get that previous span's index
    // move the segments after that last segment (the insert statement below needs to keep the targetSegmentIndex as it is without decrementing it by one.
    let foundParagraph = false
    let foundNextParagraph = false
    paramSegments && paramSegments.length > 0 && paramSegments.forEach((m, i) => {
      if (!foundParagraph && Number(m.elementId) === Number(editChangeStyle.moveEndParagraph)) {
        foundParagraph = true
      } else if (foundParagraph && !foundNextParagraph) {
        if (m.type === 'PARAGRAPH' || m.type === 'UL' || m.type === 'OL') {
          foundNextParagraph = true
        } else {
          //the last child is the last one to get an index before this loop is through because the foundNextParagraph is true.
          targetSegmentIndex = i
        }
      }
    })
  } else {
    paramSegments && paramSegments.length > 0 && paramSegments.forEach((m, i) => {
      if (Number(m.elementId) === Number(editChangeStyle.moveToElementId)) {
        targetSegmentIndex = i  //ToDo this might be a problem here. The code I removed was "i+1" inferring an proactive adjusted placemenet., But moveTo should be that given targetIndex. so if this was accurate for "i+1" that you find in testing later, then we need to reconsider the logic for both scenarios.
      }
    })
  }

  changeStyleSegmentsArray && changeStyleSegmentsArray.length > 0 && changeStyleSegmentsArray.forEach((moveElementId, mIndex) => {
    paramSegments && paramSegments.length > 0 && paramSegments.forEach((m, mIndex) => {
      if (Number(moveElementId) === Number(m.elementId)) {
        cutSegmentsIndices.push(mIndex)
      }
    })
  })

  cutSegments = paramSegments.splice(cutSegmentsIndices[0], cutSegmentsIndices.length)
  //We actually need to push just before this last element - not after. Otherwise, we are putting the moved text at the end instead of the beginning: targetSegmentIndex-1
  newSegments = insert(paramSegments, targetSegmentIndex, ...cutSegments)
  return newSegments
}

export const setChangeStyleIcons = (displayPersonId, divDisplayId, edits = [], chosenTab, tabsData = [], isAuthor) => {
  let divContentIdAdd = divDisplayId === 'editorDiv' ? '' : '~' + divDisplayId
  let editChangeStyles = (edits && edits.length > 0 && edits.filter(e => e.type === 'CHANGESTYLE')) || []

  editChangeStyles.forEach(m => {
    let isEditOwner = divDisplayId === 'tabView' ? m.personId === chosenTab : m.personId === displayPersonId
    let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === m.personId)[0]) || { id: '', label: '', editorColor: '', editorName: '' }
    let editorColor = (editor && editor.editorColor && editor.editorColor.replace('#', '')) || backgroundColors.currentEditorColor
    let changeStyleSegmentsArray = m.changeStyleSegmentsArray && m.changeStyleSegmentsArray.split(',') //The editChangeStyles.changeStyleSegmentsArray is only a string of a list of elementIds. We need to make it an array.
    let changeStyleStartElementId = changeStyleSegmentsArray && changeStyleSegmentsArray.length > 0 && changeStyleSegmentsArray[0]
    let changeStyleEndElementId = changeStyleSegmentsArray && changeStyleSegmentsArray.length > 0 && changeStyleSegmentsArray[changeStyleSegmentsArray.length - 1]

    //Start element
    let startElement = document.querySelector(`span[id="${changeStyleStartElementId + divContentIdAdd}"][data-type='TEXT']`)
    if (startElement) {
      let { isImageDuplicate, img } = createChangeStyleIconEditor('start', editorColor, m.firstName + ' ' + m.lastName, startElement, m.editSegmentId, divDisplayId)
      if (!isImageDuplicate) startElement.parentElement.insertBefore(img, startElement)
    }

    //End element
    let changeStyleEndElement = document.querySelector(`span[id="${changeStyleEndElementId + divContentIdAdd}"][data-type='TEXT']`)
    if (changeStyleEndElement) {
      let { isImageDuplicate, img } = createChangeStyleIconEditor('end', editorColor, m.firstName + ' ' + m.lastName, startElement, m.editSegmentId, divDisplayId)
      if (!isImageDuplicate) {
        let nextSibling = changeStyleEndElement.nextSibling
        let loop = 0
        while (nextSibling && !(nextSibling.nodeName === 'SPAN' && nextSibling.id) && loop < 5) {
          nextSibling = nextSibling.nextSibling
          loop++
        }
        if (nextSibling && nextSibling.nodeName === 'SPAN' && nextSibling.id) {
          changeStyleEndElement.parentElement.insertBefore(img, nextSibling)
        } else {
          changeStyleEndElement.parentElement.append(img)
        }
      }
    }
  })
}

export const createChangeStyleIconEditor = (iconType, editorColor, editorFullName, span, editSegmentId, divDisplayId) => {
  let divContentIdAdd = divDisplayId === 'editorDiv' ? '' : '~' + divDisplayId
  let img = document.createElement('img')
  img.id = span?.id
  img.src = img.src = `/inline/change-style-${iconType}-${editorColor}.png`
  img.alt = 'Change style'
  img.height = 15
  img.title = editorFullName
  //if (iconType === 'end') img.width = 25
  img.className = 'ChangeStyle' + divContentIdAdd
  img.style.cursor = 'pointer'
  img.contentEditable = 'false'  //ToDo do we need to set this so the cursor doesn't going into it while editing?
  img.setAttribute('data-span-id', span && span.id)
  img.setAttribute('data-icon-type', iconType)
  img.setAttribute('data-edit-segment-id', editSegmentId) // + divContentIdAdd
  img.setAttribute('data-type', 'CHANGESTYLE')

  const isImageDuplicate = false //document.querySelectorAll(`[data-edit-segment-id="${editSegmentId + divContentIdAdd}"][data-icon-type="to"]`) //.ChangeStyle${divContentIdAdd} ???
  return {
    isImageDuplicate: isImageDuplicate && isImageDuplicate.length > 0,
    img,
  }
}

export const authorAcceptDeleteParagraph = (currentImgElement) => {
  // 1. Delete the delete paragraph icons.
  let imgTabView = document.querySelector(`img[id="${currentImgElement.id}~tabView"]`)
  if (imgTabView) imgTabView.remove()
  //currentElement.remove()  This deletes the entire paragraph! don't do this.
  //Get the paragraph that is to be deleted
  let currentParagraph = document.querySelector(`p[id="${currentImgElement.id}"]`)

  // 2. Move the current paragraph's sentences to the previousSiblingElement paragraph
  //    a. Don't move the blank span tags (with the spaces or &nbsp;)
  //let currentParagraph = document.querySelector(`p[id="${currentElementId}"]`)
  if (currentParagraph) {
    let previousParagraph = currentParagraph && currentParagraph.previousElementSibling
    if (previousParagraph) {  //We will change the code so that a delete paragraph icon will not be placed before a paragraph that doesn't have a previousSibling paragraph
      for (let i = 0; i < currentParagraph.children.length; i++) {
        let notBlank = currentParagraph.children[i].innerHTML.replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ').replace(/ /g, '')
        if (notBlank && currentParagraph.children[i].nodeName === 'SPAN' && currentParagraph.children[i].id) {
          previousParagraph.append(currentParagraph.children[i])
          let space = document.createTextNode("\u00A0")
          previousParagraph.append(space)
        }
      }
    }

    // 3. Delete the current paragraph
    currentParagraph.remove()
  }
}

export const authorAcceptAddParagraph = (currentElement, getNextId) => {
  // 1. Delete the delete paragraph icons.
  let img = document.getElementsByClassName('ParagraphPlus')[0]
  let loop = 0
  while (img && img.id === currentElement.id && loop < 10) {
    img.remove()
    img = document.getElementsByClassName('ParagraphPlus')[0]
    loop++
  }

  // 2. Get the span on the right of the add paragraph icon
  const spanOnRight = document.querySelectorAll(`[id="${currentElement.id}"][data-type="TEXT"]`)[0]

  // 3. Get the parentParagraph of the span on the right
  let parentParagraph = spanOnRight.parentElement

  // 4. Create the new paragraph and insert it after the left paragraph
  //    a. Copy the style of the parent paragraph to get the indent and margin spacing to match.
  let newParagraph = document.createElement('p')
  newParagraph.id = getNextId()
  newParagraph.setAttribute('style', parentParagraph.style.cssText)
  let bodyDiv = document.getElementById(1)
  if (bodyDiv && bodyDiv.nodeName === 'DIV') {
    let nextParagraph = parentParagraph.nextElementSibling
    bodyDiv.insertBefore(newParagraph, nextParagraph)
  } else {
    let editorDiv = document.getElementById('editorDiv')
    let nextParagraph = parentParagraph.nextElementSibling
    editorDiv.insertBefore(newParagraph, nextParagraph)
  }

  // 5. Move any children from the left paragraph beginning with the spanOnRight and children that follow, if any.
  let foundSpanOnRight = false
  let children = parentParagraph.children
  for (let i = 0; i < children.length;) {
    if (!foundSpanOnRight) {
      if (children[i].nodeName === 'SPAN' && children[i].id === spanOnRight.id) {
        foundSpanOnRight = true
        newParagraph.append(children[i])
        let space = document.createTextNode("\u00A0")
        newParagraph.append(space)
      } else {
        i++
      }
    } else {
      newParagraph.append(children[i])
    }
  }
}

export const unshowMoveIcons = (edit) => {
  //Since the target can be in two different places, we need to consider setting it in those two places separately:
  //The target uses the elementId of the sentence AFTER the selected sentences so we know where to place the moved-to target icon for the edit-owner's view.
  let elements = document.getElementsByClassName('MoveSentences')
  for (let i = 0; i < elements.length; i++) {
    if (Number(elements[i].dataset.editSegmentId) === Number(edit.editSegmentId) && elements[i].dataset.iconType === 'to') {
      //if (Number(elements[i].dataset.spanId) === Number(edit.elementId) && elements[i].dataset.iconType === 'to') {
      elements[i].height = 15
      elements[i].style.backgroundColor = backgroundColors.normal
    }
    if (Number(elements[i].dataset.spanId) === Number(edit.moveToElementId) && elements[i].dataset.iconType === 'to') {
      elements[i].height = 15
      elements[i].style.backgroundColor = backgroundColors.normal
    }
  }
  elements = document.getElementsByClassName('MoveSentences~tabView')
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].dataset.spanId === String(edit.elementId + '~tabView') && elements[i].dataset.iconType === 'to') {
      elements[i].height = 15
      elements[i].style.backgroundColor = backgroundColors.normal
    }
  }
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].dataset.spanId === String(edit.moveToElementId + '~tabView') && elements[i].dataset.iconType === 'to') {
      elements[i].height = 15
      elements[i].style.backgroundColor = backgroundColors.normal
    }
  }

  let moveSegmentsArray = (edit && edit.moveSegmentsArray && edit.moveSegmentsArray.split(',')) || []
  moveSegmentsArray && moveSegmentsArray.length > 0 && moveSegmentsArray.forEach((elementId, index) => {
    if (index < moveSegmentsArray.length) {
      let element = document.querySelectorAll(`[id="${elementId}"][data-type="TEXT"]`)[0]
      if (element) {
        element.style.backgroundColor = backgroundColors.normal
      }
      element = document.querySelectorAll(`[id="${elementId + '~tabView'}"][data-type="TEXT"]`)[0]
      if (element) {
        element.style.backgroundColor = backgroundColors.normal
      }
    }
  })
}

export const removeMoveIcons = (edit) => {
  //Since the target can be in two different places, we need to consider setting it in those two places separately:
  //The target uses the elementId of the sentence AFTER the selected sentences so we know where to place the moved-to target icon for the edit-owner's view.
  let elements = document.getElementsByClassName('MoveSentences')
  for (let i = 0; i < elements.length; i++) {
    if (Number(elements[i].dataset.editSegmentId) === Number(edit.editSegmentId) && elements[i].dataset.iconType === 'to') {
      elements[i].remove()
    }
    if (Number(elements[i].dataset.spanId) === Number(edit.moveToElementId) && elements[i].dataset.iconType === 'to') {
      elements[i].remove()
    }
    if (Number(elements[i].dataset.spanId) === Number(edit.elementId) && elements[i].dataset.iconType === 'to') {
      elements[i].remove()
    }
    if (Number(elements[i].dataset.editSegmentId) === Number(edit.editSegmentId) && elements[i].dataset.iconType === 'start') {
      elements[i].remove()
    }
    if (Number(elements[i].dataset.editSegmentId) === Number(edit.editSegmentId) && elements[i].dataset.iconType === 'end') {
      elements[i].remove()
    }
  }
  elements = document.getElementsByClassName('MoveSentences~tabView')
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].dataset.spanId === String(edit.elementId + '~tabView') && elements[i].dataset.iconType === 'to') {
      elements[i].remove()
    }
    if (elements[i].dataset.spanId === String(edit.moveToElementId + '~tabView') && elements[i].dataset.iconType === 'to') {
      elements[i].remove()
    }
    if (elements[i].dataset.spanId === String(edit.elementId + '~tabView') && elements[i].dataset.iconType === 'to') {
      elements[i].remove()
    }
    if (elements[i].dataset.spanId === String(edit.elementId + '~tabView') && elements[i].dataset.iconType === 'start') {
      elements[i].remove()
    }
    if (elements[i].dataset.spanId === String(edit.elementId + '~tabView') && elements[i].dataset.iconType === 'end') {
      elements[i].remove()
    }
  }

}

export const unshowChangeStyleIcons = (edit) => {
  let changeStyleSegmentsArray = (edit && edit.changeStyleSegmentsArray && edit.changeStyleSegmentsArray.split(',')) || []
  changeStyleSegmentsArray && changeStyleSegmentsArray.length > 0 && changeStyleSegmentsArray.forEach((elementId, index) => {
    if (index < changeStyleSegmentsArray.length) {
      let element = document.querySelectorAll(`[id="${elementId}"][data-type="TEXT"]`)[0]
      if (element) {
        element.style.backgroundColor = backgroundColors.normal
      }
      element = document.querySelectorAll(`[id="${elementId + '~tabView'}"][data-type="TEXT"]`)[0]
      if (element) {
        element.style.backgroundColor = backgroundColors.normal
      }
    }
  })
}

export const removeChangeStyleIcons = (edit) => {
  let elements = document.getElementsByClassName('ChangeStyle')
  for (let i = 0; i < elements.length; i++) {
    if (Number(elements[i].dataset.editSegmentId) === Number(edit.editSegmentId) && elements[i].dataset.iconType === 'start') {
      elements[i].remove()
    }
    if (Number(elements[i].dataset.editSegmentId) === Number(edit.editSegmentId) && elements[i].dataset.iconType === 'end') {
      elements[i].remove()
    }
  }
  elements = document.getElementsByClassName('MoveSentences~tabView')
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].dataset.spanId === String(edit.elementId + '~tabView') && elements[i].dataset.iconType === 'start') {
      elements[i].remove()
    }
    if (elements[i].dataset.spanId === String(edit.elementId + '~tabView') && elements[i].dataset.iconType === 'end') {
      elements[i].remove()
    }
  }

}

export const unshowAddParagraphIcons = (edit) => {
  //Since the target can be in two different places, we need to consider setting it in those two places separately:
  //The target uses the elementId of the sentence AFTER the selected sentences so we know where to place the moved-to target icon for the edit-owner's view.
  let elements = document.getElementsByClassName('ParagraphPlus')
  for (let i = 0; i < elements.length; i++) {
    if (Number(elements[i].dataset.spanId) === Number(edit.elementId)) {
      elements[i].height = 22
      elements[i].style.backgroundColor = backgroundColors.normal
    }
  }
  elements = document.getElementsByClassName('ParagraphPlus~tabView')
  for (let i = 0; i < elements.length; i++) {
    if (Number(elements[i].dataset.spanId) === Number(edit.elementId)) {
      elements[i].height = 22
      elements[i].style.backgroundColor = backgroundColors.normal
    }
  }
}

export const unshowDeleteParagraphIcons = (elementId) => {
  //Since the target can be in two different places, we need to consider setting it in those two places separately:
  //The target uses the elementId of the sentence AFTER the selected sentences so we know where to place the moved-to target icon for the edit-owner's view.
  let elements = document.querySelectorAll(`[data-type='DELETEPARAGRAPH']`)
  for (let i = 0; i < elements.length; i++) {
    elements[i].height = 22
    elements[i].style.backgroundColor = backgroundColors.normal
  }
  let element = document.querySelectorAll(`[id="${elementId}"][data-type='DELETEPARAGRAPH']`)[0]
  if (element) {
    element.height = 22
    element.style.backgroundColor = backgroundColors.currentFocus
  }
  element = document.querySelectorAll(`[id="${elementId}~tabView"][data-type='DELETEPARAGRAPH']`)[0]
  if (element) {
    element.height = 22
    element.style.backgroundColor = backgroundColors.currentFocus
  }
}


export const insertAddParagraphIcons = (personId, divDisplay, paragraph, span, segment, paramEdits, tabsData, editorName, getNextId, editIsEditorAddParagraph) => {
  let removeSpace = false
  if (editIsEditorAddParagraph && editIsEditorAddParagraph.personId === personId) {
    //isEditorAddParagraph
    //This function is solely for the isEditorAddParagraph in order to pick up the AddSentence for the given single edit.
    // This ADDSENTENCE will not go BEFORE the anchor element referenced as the elementId but after that anchor sentence and inside the new paragraph for the editor's view.
    const addSentenceEdit = paramEdits && paramEdits.length > 0 && paramEdits.filter(m => m.personId === personId && Number(m.elementId) === Number(segment.elementId) && m.type === 'ADDSENTENCE' && m.isEditorAddParagraph)[0]
    let spanElementId = divDisplay.id === 'editorDiv' ? segment.elementId : segment.elementId + '~tabView'
    if (paragraph) {
      let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === editIsEditorAddParagraph.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      let currentEditorName = editor && editor.editorName && editor.editorName.firstName ? editor.editorName : editorName
      let editorColor = getEditorColor(editIsEditorAddParagraph.personId, tabsData, 'withoutSymbol')
      const imgParagraph = createParagraphPlusEditor(`/inline/paragraph-plus-${editorColor}.svg`, currentEditorName, segment, divDisplay)
      if (imgParagraph) paragraph.append(imgParagraph)

      let newParagraph = document.createElement('p')
      newParagraph.id = spanElementId //The editor may have more than one ADDPARAGRAPH and ADDSENTENCE pair so we wlil use the same spanElementId for them all (p and span pairs - all of them!) but keep them separate with the subSequence in order to keep them straight but allow more than one to be added to the span tag they belong to.
      //newParagraph.setAttribute('data-subsequence', span.subSequence)   //This is for ADDPARAGRAPHSENTENCE only. We don't accumulate ADDPARAGRAPH-s
      newParagraph.setAttribute('style', paragraph.style.cssText)
      getMainElement().append(newParagraph)

      if (addSentenceEdit) {
        let imgAddSentence = createParagraphSentencePlusEditor(`/inline/sentence-plus-${editorColor}.svg`, currentEditorName, addSentenceEdit, divDisplay.id)
        if (imgAddSentence) newParagraph.append(imgAddSentence)

        // if (newParagraph.children.length === 0) {
        //   let space = document.createTextNode("\u00A0")
        //   newParagraph.append(space)
        //   removeSpace = true
        // }
        let newSpan = document.createElement('span')
        newSpan.id = spanElementId 
        newSpan.type = 'ADDSENTENCE'
        //newSpan.setAttribute('data-subsequence', span.subSequence)  //This is for ADDPARAGRAPHSENTENCE only. We don't accumulate ADDSENTENCES-s
        newSpan.setAttribute('data-type', 'ADDSENTENCE')
        newSpan.innerHTML = addSentenceEdit.text || '&nbsp;____&nbsp;'
        newSpan.setAttribute('style', addSentenceEdit.styleSnapshot)
        newSpan.style.backgroundColor = backgroundColors.editPending
        newParagraph.append(newSpan)
      }
      return {returnParagraph: newParagraph, removeSpace: false}  //Notice that this new paragraph replaces the paragraph that is being sent around to the next span.
    }
  } else {
    //ADDPARAGRAPH
    //If this is the editor who has made an ADDPARAGRAPH edit, then create the paragraph and put in the icon
    //For other editors (and the author) put in the icon without creating the new paragraph
    //Yet, we have some additional logic that is an exception to the previous standard: An editor's new paragraph with a new sentence which is marked as isEditorAddParagraph flag in the EditSegment record.
    //  In this case, a split sentence will need the leftSide to stay in the old paragraph, the addParagraph icon to follow, and then the new Paragraph will have a new sentence which is the rightSide of that original sentence.
    //  When we say the leftSide stays, this is different logic because the leftSide is, essentially, the anchor sentence which the AddParagraph is attached to BEFORE the anchor. So now the AddParagraph icon is going AFTER the anchor.
    //  So, one of the significant logic changes here will be that any ADDSENTENCE marked as isEditorAddParagraph will also be added here and then ignored in the ADDSENTENCE insert logic elsewhere.
    const elementId = divDisplay.id === 'tabView' ? segment.elementId + '~tabView' : segment.elementId
    // const existImage = document.querySelector(`img[data-span-id="${elementId}"][data-type="ADDPARAGRAPH"]`)
    // if (!existImage) {  //This was causing the icon not to be written because it thought it was there on tabView. But it was not there in view. So we'll just skip this safety net and print it out anyway.
      let editAddParagraphThisEditor = paramEdits && paramEdits.length > 0 && paramEdits.filter(e => e.personId === personId && Number(e.elementId) === Number(segment.elementId) && e.type === 'ADDPARAGRAPH')[0]
      let editAddParagraphAll = (paramEdits && paramEdits.length > 0 && paramEdits.filter(e => Number(e.elementId) === Number(segment.elementId) && e.type === 'ADDPARAGRAPH')) || []

      editAddParagraphAll && editAddParagraphAll.length > 0 && editAddParagraphAll.forEach(m => {
        const editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === m.personId)[0]) || {
          id: '',
          label: '',
          editorColor: '',
          editorName: {}
        }
        let currentEditorName = editor && editor.editorName && editor.editorName.firstName ? editor.editorName : editorName
        const editorColor = getEditorColor(m.personId, tabsData, 'withoutSymbol')
        const img = createParagraphPlusEditor(`/inline/paragraph-plus-${editorColor}.svg`, currentEditorName, segment, divDisplay)
        if (img) paragraph.append(img)

        //if (m.isEditorAddParagraph) {
          let isEditorAddSentence = paramEdits && paramEdits.length > 0 && paramEdits.filter(e => e.personId === personId && Number(e.elementId) === Number(segment.elementId) && e.type === 'ADDSENTENCE' && e.isEditorAddParagraph)[0]
          if (isEditorAddSentence) {
            let imgAddSentence = createSentencePlusEditor(`/inline/sentence-plus-${editorColor}.svg`, currentEditorName, m, divDisplay.id)
            if (imgAddSentence) paragraph.append(imgAddSentence)
          }
        //}
      })

      if (editAddParagraphThisEditor && getMainElement()) {
        let newParagraph = document.createElement('p')
        newParagraph.id = span.id
        //newParagraph.setAttribute('data-subsequence', span.subSequence) //This is for ADDPARAGRAPHSENTENCE only. We don't accumulate ADDPARAGRAPH-s
        newParagraph.setAttribute('style', paragraph.style.cssText)
        getMainElement().append(newParagraph)
        newParagraph.append(span)
        if (newParagraph.children.length === 0) {
          let space = document.createTextNode("\u00A0")
          newParagraph.append(space)
          removeSpace = true
        }
        return {returnParagraph: newParagraph, removeSpace}  //Notice that this new paragraph replaces the paragraph that is being sent around to the next span, if there is one.
      }
    // }
  }
  return {returnParagraph: paragraph, removeSpace} //This is the default paragraph if the newParagraph was not returned above.
}

export const getEditorColor = (personId, tabsData, withoutSymbol) => {
  let editorColor = (tabsData && tabsData.length > 0 && tabsData.filter(m => m.id === personId)[0] && tabsData.filter(m => m.id === personId)[0].editorColor) || backgroundColors.currentEditorColor
  if (editorColor && withoutSymbol) editorColor = editorColor.replace('#', '')
  return editorColor
}

export const getEditorGradient = (personId, tabsData, reverse) => {
  let editorColor = (tabsData && tabsData.length > 0 && tabsData.filter(m => m.id === personId)[0] && tabsData.filter(m => m.id === personId)[0].editorColor) || backgroundColors.currentEditorColor
  let background = backgroundGradients.filter(m => m.rgb === editorColor || m.rgb === '#' + editorColor)[0]
  if (background) {
    return reverse ? background.reverse : background.gradient
  }
}

export const removeMoveEditIconsAll = () => {
  removeMoveSentencesStartIcons()
  removeMoveSentencesEndIcons()
  removeMoveSentencesTargetIcons()
  let moveImages = document.querySelectorAll(`[data-type="MOVE"]`)
  for (let i = 0; i < moveImages.length; i++) {
    if (!(moveImages[i].dataset && moveImages[i].dataset.editSegmentId)) {
      moveImages[i].remove()
    }
  }
}

export const removeChangeStyleEditIconsAll = () => {
  removeChangeStyleStartIcons()
  removeChangeStyleEndIcons()
  let changeStyleImages = document.querySelectorAll(`[data-type="CHANGESTYLE"]`)
  for (let i = 0; i < changeStyleImages.length; i++) {
    if (!(changeStyleImages[i].dataset && changeStyleImages[i].dataset.editSegmentId)) {
      changeStyleImages[i].remove()
    }
  }
}

export const mouseUpAction = ({
  event,
  isEditorDivView,
  chosenHTMLSegment,
  setChosenAddParagraphEdit,
  handleSetCurrentElement,
  setContextMenuIfMobile,
  saveRevision,
  isAuthor,
  savedCursorPosition,
  chosenSegment,
  handleSetChosenSegment,
  isTextChanged,
  segments,
  addOrUpdateEdit,
  edits,
  personId,
  editorName,
  workSummary,
  getNextId,
  setChosenHTMLSegment,
  setChosenAddParagraphSentenceEdit,
  setChosenAddSentenceEdit,
  setChosenDeleteListItemEdit,
  setChosenDeleteParagraphEdit,
  scrollDocumentToMatch,
  isTranslation,
  editLanguageId,
  updateChangeCounts,
  getEditSegments,
//  previousSpan,
}) => {
  let newHTML = [...chosenHTMLSegment]
  let previousSpan = getPreviousSegment(chosenSegment)

  if (previousSpan && previousSpan.nodeName === 'SPAN' && previousSpan && previousSpan.dataset && (previousSpan.dataset.type === 'ADDSENTENCE' || previousSpan.dataset.type === 'ADDPARAGRAPHSENTENCE') && !previousSpan.title) { //There is a problem with adding a new sentence when clicking on an existing ADDSENTENCE edit since it has the same dataset.type of 'ADDSENTENCE'. So we are just making sure that there isn't an editor's name in the title.
    if (previousSpan.innerHTML === '&nbsp;&nbsp;' || previousSpan.innerHTML === '&nbsp;____&nbsp;') {
      previousSpan.innerHTML = '&nbsp;____&nbsp;'
    } else {
      let edit = edits && edits.length > 0 && edits.filter(m => m.personId === personId && Number(m.elementId) === Number(previousSpan.id) 
        && ((m.type === 'ADDSENTENCE' && previousSpan.dataset.type === 'ADDSENTENCE') 
          || (m.type === 'ADDPARAGRAPHSENTENCE' && previousSpan.dataset.type === 'ADDPARAGRAPHSENTENCE' && Number(m.subSequence) === Number(previousSpan.dataset.subsequence))))[0]
      if (edit) {
        let innerHtml = previousSpan.innerHTML
        innerHtml = innerHtml && innerHtml.lastIndexOf('&nbsp;') === innerHtml.length - 6 ? innerHtml.substring(0, innerHtml.length - 6) : innerHtml
        if (innerHtml !== edit.text) {
          let text = previousSpan.innerHTML
          if (text.indexOf('&nbsp;') === 0) text = text.substring(6) //Strip off that extra space on the front which is used to give the user a space from the left to start type ing in the ____ blank

          addOrUpdateEdit({
            editSegmentId: edit.editSegmentId,
            editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
            personId: personId,
            firstName: editorName && editorName.firstName,
            lastName: editorName && editorName.lastName,
            chapterId: edit.chapterId,
            elementId: edit.elementId,
            languageId: editLanguageId,
            text,
            type: edit.type, //could be ADDSENTENCE or ADDPARAGRAPHSENTENCE,
            authorTextSnapshot: '',
            subSequence: edit.subSequence,
            updateTextAddParagraphSentence: edit.type === 'ADDPARAGRAPHSENTENCE'
          }, () => setTimeout(() => getEditSegments(personId, workSummary.workId, edit.chapterId, workSummary.languageId_current), 500))
          updateChangeCounts()
        }
      }
    }
  }

  if (event.target.dataset.type === 'ADDPARAGRAPHSENTENCE') {
    if (event.target.nodeName === 'IMG') {
      // handleSetChosenSegment(event.target)
      // handleSetCurrentElement(event.target)

      // setAddParagraphSentenceEdit({
      //   isAuthor,
      //   currentElement: event.target,
      //   edits,
      //   addOrUpdateEdit,
      //   editorName,
      //   personId,
      //   workSummary,
      //   editLanguageId,
      // })
      updateChangeCounts()

    } else if (event.target.nodeName === 'SPAN') {
      if (event.target.innerHTML === '&nbsp;____&nbsp;') {
        savedCursorPosition = saveCursorLocation(document.getElementById('editorDiv'))
        let elementCursorOffset = getElementCursorOffset(document.getElementById('editorDiv'))
        event.target.innerHTML = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
        let newCursorPosition = { ...savedCursorPosition }
        newCursorPosition.start = newCursorPosition.start - elementCursorOffset + 1
        //restoreCursorLocation(document.getElementById('editorDiv'), newCursorPosition) //Help ToDo or delete ? Is this useful here? We just clicked into the position we want, I believe
        event.target.focus()
      }
      if (event.target.dataset.type !== 'LISTLEVELMINUS' && event.target.dataset.type !== 'LISTLEVELPLUS' && event.target.dataset.type !== 'ADDTAB' && event.target.dataset.type !== 'DELETETAB') {
        if (!isAuthor && event.target.nodeName === 'SPAN') event.target.contentEditable = 'true'
      }
      handleSetChosenSegment(event.target)
      handleSetCurrentElement(event.target)  //Help ToDo or delete ? One of these already calls the other
      setChosenAddParagraphSentenceEdit(event.target.id)  //Help ToDo or delete ? And what is the purpose of this one here?

      let img = document.querySelector(`img[data-span-id="${event.target.id}"][class="ParagraphSentencePlus"]`)  //I don't think this is doing anything helpful, plus it doesn't pick up the image in this case.
      if (img) {
        //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
        img.style.backgroundColor = backgroundColors.currentFocus
      }
      img = document.querySelector(`img[data-span-id="${event.target.id}"][class="ParagraphSentencePlus~tabView"]`)
      if (img) {
        //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
        img.style.backgroundColor = backgroundColors.currentFocus
      }
    }
    return { currentSpan: event.target, returnIsTextChanged: false }

  } else if (event.target.dataset.type === 'ADDSENTENCE') {
    if (event.target.nodeName === 'IMG') {
      //let element = document.querySelectorAll(`[id="${event.target.dataset.spanId}"][data-type="TEXT"]`)[0]
      handleSetChosenSegment(event.target)
      handleSetCurrentElement(event.target)

      //We are getting a duplicate AddSentence edit in rapid fire succession when choosing to add a sentence before anothre sentence. But we don't want to be able to let another sentence be added in front since the editor can add as many sentences as he wants right in this ADDSENTENCE edit.
      // setAddSentenceEdit({
      //   isAuthor,
      //   currentElement: event.target,
      //   edits,
      //   addOrUpdateEdit,
      //   editorName,
      //   personId,
      //   workSummary,
      //   editLanguageId,
      // })
      // updateChangeCounts()

    } else if (event.target.nodeName === 'SPAN') {
      if (event.target.innerHTML === '&nbsp;____&nbsp;') {
        savedCursorPosition = saveCursorLocation(document.getElementById('editorDiv'))
        let elementCursorOffset = getElementCursorOffset(document.getElementById('editorDiv'))
        event.target.innerHTML = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
        let newCursorPosition = {...savedCursorPosition}
        newCursorPosition.start = newCursorPosition.start - elementCursorOffset + 1
        restoreCursorLocation(document.getElementById('editorDiv'), newCursorPosition)
        event.target.focus()
      }
      if (event.target.dataset.type !== 'LISTLEVELMINUS' && event.target.dataset.type !== 'LISTLEVELPLUS' && event.target.dataset.type !== 'ADDTAB' && event.target.dataset.type !== 'DELETETAB') {
        if (!isAuthor && event.target.nodeName === 'SPAN') event.target.contentEditable = 'true'
      }
      handleSetChosenSegment(event.target)
      handleSetCurrentElement(event.target)
      setChosenAddSentenceEdit(event.target.id)

      let img = document.querySelector(`img[data-span-id="${event.target.id}"][class="SentencePlus"]`)
      if (img) {
        //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
        img.style.backgroundColor = backgroundColors.currentFocus
      }
      img = document.querySelector(`img[data-span-id="${event.target.id}"][class="SentencePlus~tabView"]`)
      if (img) {
        //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
        img.style.backgroundColor = backgroundColors.currentFocus
      }
    }
    return {currentSpan: event.target, returnIsTextChanged: false}

  // } else if (event.target.dataset.type === 'DELETESENTENCE') {
  //   if (event.target.nodeName === 'IMG') {
  //     let elementExist = document.querySelector(`img[id="${event.target.id}"][data-type="DELETESENTENCE"]`)
  //     handleSetChosenSegment(elementExist)
  //     handleSetCurrentElement(elementExist)
  //
  //     if (elementExist && !elementExist.dataset.personId) {
  //       setDeleteSentenceEdit({
  //         isAuthor,
  //         currentElement: event.target,
  //         saveEditorDivSegmentsPersistent,
  //         handleSetCurrentElement,
  //         edits,
  //         segments,
  //         addOrUpdateEdit,
  //         editorName,
  //         personId,
  //         workSummary,
  //         responseEdit,
  //         editLanguageId,
  //       })
  //     }
  //   }
  //   setContextMenuIfMobile()
  //   return {currentSpan: '', returnIsTextChanged: false}


  } else if (event.target.dataset.type === 'ADDTAB' || event.target.dataset.type === 'DELETETAB') {
    event.target.style.backgroundColor = backgroundColors.currentFocus
    handleSetCurrentElement(event.target)
    handleSetChosenSegment(event.target)
    setContextMenuIfMobile()
    //handleSetAddTabEdit(event.target.id)
    return {currentSpan: '', returnIsTextChanged: false}

  } else if (event.target.dataset.type === 'LISTLEVELMINUS' || event.target.dataset.type === 'LISTLEVELPLUS') {
    event.target.style.backgroundColor = backgroundColors.currentFocus
    handleSetCurrentElement(event.target)
    handleSetChosenSegment(event.target)
    setContextMenuIfMobile()
    //handleSetAddTabEdit(event.target.id)
    return {currentSpan: '', returnIsTextChanged: false}

  } else if (event.target.dataset.type === 'DELETELISTITEM') {
    if (isAuthor && (!event.target.dataset.personId || event.target.dataset.personId === personId)) {
      editListStructure.authorDeleteListItem(event.target, getNextId)
      updateChangeCounts()
    } else {
      event.target.style.backgroundColor = backgroundColors.currentFocus
    }
    handleSetCurrentElement(event.target)
    handleSetChosenSegment(event.target)
    setContextMenuIfMobile()
    setChosenDeleteListItemEdit(event.target.id)
    return {currentSpan: '', returnIsTextChanged: false}

  } else if (event.target.dataset.type === 'REORDERLISTITEMS') {
    event.target.style.backgroundColor = backgroundColors.currentFocus
    handleSetCurrentElement(event.target)
    handleSetChosenSegment(event.target)
    setContextMenuIfMobile()
    scrollDocumentToMatch(event.target)
    //setChosenReorderListItemsEdit(event.target.id)
    updateChangeCounts()
    return {currentSpan: '', returnIsTextChanged: false}

  } else if (event.target.dataset.type === 'DELETEPARAGRAPH') {
    handleSetCurrentElement(event.target)
    handleSetChosenSegment(event.target)
    setContextMenuIfMobile()
    setChosenDeleteParagraphEdit(event.target.id)
    updateChangeCounts()
    return {currentSpan: '', returnIsTextChanged: false}

  } else if (event.target.dataset.type === 'ADDPARAGRAPH') {
    let element = event.target
    element.style.backgroundColor = backgroundColors.currentFocus
    //element.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
    element.id = element.dataset.spanId
    handleSetChosenSegment(element)
    handleSetCurrentElement(element)
    setChosenAddParagraphEdit(element.id)
    updateChangeCounts()

    let elements = document.getElementsByClassName(isEditorDivView ? 'ParagraphPlus~tabView' : 'ParagraphPlus')
    for (let i = 0; i < elements.length; i++) {
      if (Number(elements[i].dataset.spanId) === Number(element.id)) {
        //elements[i].height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
        elements[i].style.backgroundColor = backgroundColors.currentFocus
      }
    }
    setContextMenuIfMobile()
    return {currentSpan: '', returnIsTextChanged: false}

  } else if (event.target.dataset.type === 'MOVE') {
    event.target.style.backgroundColor = backgroundColors.currentFocus
    handleSetChosenSegment(event.target)
    handleSetCurrentElement(event.target)
    setContextMenuIfMobile()
    return {currentSpan: '', returnIsTextChanged: false}

  } else if (event.target.dataset.type === 'CHANGESTYLE') {
    event.target.style.backgroundColor = backgroundColors.currentFocus
    handleSetChosenSegment(event.target)
    handleSetCurrentElement(event.target)
    setContextMenuIfMobile()
    return { currentSpan: '', returnIsTextChanged: false }

  } else {
    if (event.target.dataset.type === 'ADDLISTITEM' || event.target.dataset.type === 'ADDLISTITEM_TEXT') {
      setClickIntoAddListItem({ //It seems that one of the main purposes of this function is to change the &nbsp;____&nbsp; to four &nbsp;-s so that the user can type. It then squashes those extra spaces down as soon as they type any letter.
        element: event.target,
        isAuthor,
        handleSetChosenSegment,
        handleSetCurrentElement,
        setChosenAddSentenceEdit,
        savedCursorPosition,
        isEditorDivView,
        setContextMenuIfMobile
      }) //This was taken out of the if-else-then statements and set here since it is possible that the user just clicked out of an ADDLISTITEM that has text that needs to be updated as well as starting the text of another ADDLISTITEM where it replaced the ____ prompt text with just spaces.
    }

    if (!previousSpan) previousSpan = getPreviousSegment(chosenSegment)  
    compareChangeToAddOrUpdateEdit({
      currentSpan: event.target, 
      previousSpan, 
      addOrUpdateEdit, 
      segments, 
      edits, 
      personId, 
      editorName, 
      workSummary,
      isAuthor,
      handleSetChosenSegment
    })
    handleSetChosenSegment(event.target)
    //handleSetCurrentElement(event.target)  Don't do this here.

    //If there isn't a high-lighted selection made (and this is the editorDiv view and this is the author)
    if (isEditorDivView && isAuthor && savedCursorPosition && savedCursorPosition.start - savedCursorPosition.end === 0) {
      let paragraph = event.target
      let loop = 0
      while (paragraph && !((paragraph.nodeName === 'P' || paragraph.nodeName === 'LI' || paragraph.nodeName === 'UL' || paragraph.nodeName === 'OL') && paragraph.id) && loop < 10) {
        paragraph = paragraph.parentElement
        loop++
      }

      if (isTextChanged) {
        let previousSpan = document.querySelector(`span[id="${chosenSegment[chosenSegment.length - 1] && chosenSegment[chosenSegment.length - 1].id}"][data-type="TEXT"]`)
        if (previousSpan) {
          if (isAuthor) {
            processAuthorTextChanges(segments, previousSpan, getNextId, previousSpan.style.cssText)
          } else if (previousSpan && previousSpan.dataset && previousSpan.dataset.type !== 'ADDSENTENCE') {
            processEditorTextChanges(segments, previousSpan, addOrUpdateEdit, edits, personId, editorName, workSummary, isTranslation)
          }
        }
        isTextChanged = false
      }
    }

    if (!isAuthor && isEditorDivView && event.target && event.target.nodeName === 'SPAN' && (event.target.dataset.type === 'TEXT' || event.target.dataset.type === 'ADDSENTENCE')) {
      event.stopPropagation()
      event.preventDefault()
      if (event.target.dataset.type !== 'ADDTAB' && event.target.nodeName === 'SPAN') event.target.contentEditable = 'true'
    }
    handleSetChosenSegment(event.target)
    return { currentSpan: event.target, returnIsTextChanged: isTextChanged, previousSpan: getPenspringSpan(event.target) } //getPenspringSpan is to be sure that we are on a valid penspring span and not some internal span to define text style inside.
  }
}

export const setSegmentViews = (chosenTab, workSummary, segments, edits = [], isAuthor, personId, editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, isTranslation) => {
  //Notice that when setSegmentsWithEdits is called for the tabView that the personId is chosenTab instead of personId. It is personId for editorDiv.
  if (workSummary.authorPersonId && personId) {  //Don't bother building the views until we have our people's Id-s available.
    if (workSummary.authorPersonId === personId) {
      //Since this is the author, personId is sent into setSegments
      setSegments('editorDiv', segments, workSummary, edits, isAuthor, personId, editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab)
      //The tabView will be set by chosenTab which could be the author.
      if (chosenTab === workSummary.authorPersonId) {
        setTimeout(() => setSegments('tabView', segments, workSummary, edits, isAuthor, chosenTab, editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab), 500)
      } else {
        setTimeout(() => setSegmentsWithEdits('tabView', segments, edits, chosenTab, workSummary, editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab), 500)
      }
    } else {
      //The editorDiv calls setSegmentsWithEdits for the person logged in (personId)
      setSegmentsWithEdits('editorDiv', segments, edits, personId, workSummary, editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab)
      //The tabView will be set by chosenTab which could be the author.
      if (!chosenTab || chosenTab === workSummary.authorPersonId) {
        setTimeout(() => setSegments('tabView', segments, workSummary, edits, isAuthor, chosenTab, editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab), 500)
      } else {
        setTimeout(() => setSegmentsWithEdits('tabView', segments, edits, chosenTab, workSummary, editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab), 500)
      }
    }
  }
}

export const cleanText = (text) => {
  let regex = "/<(.|\n)*?>/"
  text = text && text.replace(regex, "")
    .replace(/<br>/g, "")
    //.replace(/<[^>]*>/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .replace(/&nbsp;/g, ' ')
    .replace(/&#xa0;/g, ' ')
    .trim()
  return text
}

export const getMoveAuthorTextSnapshot = (moveEditArray, targetElementId, moveEndParagraph, isMoveEndParagraphTarget) => {
  let authorTextSnapshot = 'Moved from: '
  if (moveEditArray && moveEditArray.length > 0) {
    //The sentences to move
    let firstMoveSegment = document.querySelectorAll(`[id="${moveEditArray[0]}"][data-type="TEXT"]`)[0]
    let lastMoveSegment = document.querySelectorAll(`[id="${moveEditArray[moveEditArray.length - 1]}"][data-type="TEXT"]`)[0]
    if (!moveEndParagraph) {
      authorTextSnapshot += firstMoveSegment.innerHTML + `<img src='/inline/move-start.svg' height='17'/><img src='/inline/move-end.svg' height='17'/>` + lastMoveSegment.innerHTML + '<br/>'
    } else {
      authorTextSnapshot += firstMoveSegment.innerHTML + `<img src='/inline/move-start.svg' height='17'/><img src='/inline/move-end.svg' height='17'/> {end of paragraph}<br/>`
    }

    //The TARGET location
    let targetSegment
    let beforeTargetSegment
    if (!targetElementId) {
      targetSegment = document.querySelectorAll(`[id="${targetElementId}"][data-type="TEXT"]`)[0]
      beforeTargetSegment = targetSegment && targetSegment.previousElementSibling
      let loop = 0;
      while (beforeTargetSegment && !(beforeTargetSegment && beforeTargetSegment.nodeName === 'SPAN' && beforeTargetSegment.id) && loop < 5) {
        beforeTargetSegment = beforeTargetSegment.previousElementSibling
        loop++
      }
    } else if (isMoveEndParagraphTarget) {
      targetSegment = document.querySelectorAll(`[id="${isMoveEndParagraphTarget}"][data-type="TEXT"]`)[0]
      beforeTargetSegment = targetSegment && targetSegment.lastElementChild
      let loop = 0;
      while (beforeTargetSegment && !(beforeTargetSegment && beforeTargetSegment.nodeName === 'SPAN' && beforeTargetSegment.id) && loop < 5) {
        beforeTargetSegment = beforeTargetSegment.previousElementSibling
        loop++
      }
    }
    authorTextSnapshot += targetElementId ? 'Moved before: ' : 'Moved after: '
    if (beforeTargetSegment && beforeTargetSegment.nodeName === 'SPAN' && beforeTargetSegment.id) {
      authorTextSnapshot += beforeTargetSegment.innerHTML
    } else {
      authorTextSnapshot += targetElementId ? '{beginning of paragraph}' : '{end of paragraph}'
    }
    authorTextSnapshot += `<img src='/inline/move-target.svg' height='17'/>` + targetElementId && targetSegment ? targetSegment.innerHTML : ''
  }

  return authorTextSnapshot
}

export const getParagraphDeleteBeforeAfter = (paragraphElement) => {
  let authorTextSnapshot = 'Delete paragraph break between: '
  let previousParagraphLastSegment
  let paragraphElementFirstSegment

  if (paragraphElement.previousElementSibling) {
    let previousParagraph = paragraphElement.previousElementSibling
    for (let i = 0; i < previousParagraph.children.length; i++) {
      if (previousParagraph.children[0].nodeName === 'SPAN' && previousParagraph.children[0].id) {
        previousParagraphLastSegment = previousParagraph.children[0]
      }
    }
    authorTextSnapshot += previousParagraphLastSegment && previousParagraphLastSegment.innerHTML
  } else {
    authorTextSnapshot += '{beginning of document}'
  }
  authorTextSnapshot += `<img src='/inline/paragraph-minus.svg' height='17'/>`

  for (let i = 0; i < paragraphElement.children.length; i++) {
    if (paragraphElement.children[0].nodeName === 'SPAN' && paragraphElement.children[0].id) {
      paragraphElementFirstSegment = paragraphElement.children[0]
      break
    }
  }
  authorTextSnapshot += paragraphElementFirstSegment && paragraphElementFirstSegment.innerHTML
  return authorTextSnapshot
}

export const getNewParagraphSegmentsBeforeAfter = (spanElement) => {
  let authorTextSnapshot = 'Add paragraph break between: '
  let previousSegment = spanElement.previousElementSibling
  let nextSegment = spanElement.nextElementSibling
  let loop = 0

  if (previousSegment) {
    while (previousSegment && !(previousSegment.nodeName === 'SPAN' + previousSegment.id) && loop < 5) {
      previousSegment = previousSegment.previousElementSibling
      loop++
    }
    if (previousSegment && previousSegment.nodeName === 'SPAN' + previousSegment.id) {
      authorTextSnapshot += previousSegment.innerHTML
    }
  } else {
    authorTextSnapshot += '<error finding previousSegment>'
  }
  authorTextSnapshot += `<img src='/inline/paragraph-plus.svg' height='17'/>`

  if (nextSegment) {
    while (nextSegment && !(nextSegment.nodeName === 'SPAN' + nextSegment.id) && loop < 5) {
      nextSegment = nextSegment.previousElementSibling
      loop++
    }
    if (nextSegment && nextSegment.nodeName === 'SPAN' + nextSegment.id) {
      authorTextSnapshot += nextSegment.innerHTML
    }
  } else {
    authorTextSnapshot += '<error finding nextSegment>'
  }
  return authorTextSnapshot
}

export const showEditChosen = (editSegmentId, edits = [], setChosenMoveEdit, setChosenChangeStyleEdit) => {
  let edit = edits.filter(m => m.editSegmentId === Number(editSegmentId))[0]
  if (edit) {
    if (edit.type === 'TEXT') {
      let segment = document.querySelectorAll(`[id="${edit.elementId}"]`)[0] //[data-type="TEXT"]
      if (segment) {
        segment.style.backgroundColor = backgroundColors.currentFocus
        segment.scrollIntoView({block: "center", behavior: 'smooth', inline: 'nearest'})
      }
      //It can't scroll both contenteditable-s at the same time with scrollIntoView - or at least in close sequence
      let segmentTabView = document.getElementById(edit.elementId + '~tabView')
      if (segmentTabView) {
        segmentTabView.style.backgroundColor = backgroundColors.currentFocus
        setTimeout(() => segmentTabView.scrollIntoView({block: "center", behavior: 'smooth', inline: 'nearest'}), 500)
      }
    } else if (edit.type === 'MOVE') {
      setChosenMoveEdit(edit.editSegmentId)
      let moveSegmentsArray = edit.moveSegmentsArray.split(',')
      showTarget(moveSegmentsArray[moveSegmentsArray.length - 1], edit.moveToElementId, edit.editSegmentId)
      showMoveSentences(moveSegmentsArray, edit.editSegmentId)
      let firstSentence = document.querySelectorAll(`[id="${moveSegmentsArray[moveSegmentsArray.length - 1]}"][data-type="TEXT"]`)[0]
      if (firstSentence) firstSentence.scrollIntoView({block: "center", behavior: 'smooth', inline: 'nearest'})

    } else if (edit.type === 'CHANGESTYLE') {
      setChosenChangeStyleEdit(edit.editSegmentId)
      let changeStyleSegmentsArray = edit.changeStyleSegmentsArray.split(',')
      showChangeStyle(changeStyleSegmentsArray, edit.editSegmentId)
      let firstSentence = document.querySelectorAll(`[id="${changeStyleSegmentsArray[changeStyleSegmentsArray.length - 1]}"][data-type="TEXT"]`)[0]
      if (firstSentence) firstSentence.scrollIntoView({ block: "center", behavior: 'smooth', inline: 'nearest' })

    } else if (edit.type === 'DELETELISTITEM') {
      let imgDelete = document.querySelectorAll(`[id="${edit.elementId}"][data-type="DELETELISTITEM"]`)[0]
      if (imgDelete) {
        imgDelete.style.backgroundColor = backgroundColors.currentFocus
        //imgDelete.style.height = '27px'
        imgDelete.scrollIntoView({block: "center", behavior: 'smooth', inline: 'nearest'})
      }

    } else if (edit.type === 'ADDLISTITEM') {
      let imgDelete = document.querySelectorAll(`[id="${edit.elementId}"][data-type="ADDLISTITEM"]`)[0]
      if (imgDelete) {
        imgDelete.style.backgroundColor = backgroundColors.currentFocus
        //imgDelete.style.height = '27px'
        imgDelete.scrollIntoView({block: "center", behavior: 'smooth', inline: 'nearest'})
      }

    } else if (edit.type === 'REORDERLISTITEMS') {
      let imgDelete = document.querySelectorAll(`[id="${edit.elementId}"][data-type="REORDERLISTITEMS"]`)[0]
      if (imgDelete) {
        imgDelete.style.backgroundColor = backgroundColors.currentFocus
        //imgDelete.style.height = '27px'
        imgDelete.scrollIntoView({block: "center", behavior: 'smooth', inline: 'nearest'})
      }

    } else if (edit.type === 'DELETEPARAGRAPH') {
      let imgDelete = document.querySelector(`img[data-paragraph-element-id="${edit.elementId}"][data-type="DELETEPARAGRAPH"]`)
      if (imgDelete) {
        imgDelete.style.backgroundColor = backgroundColors.currentFocus
        //imgDelete.style.height = '27px'
        imgDelete.scrollIntoView({block: "center", behavior: 'smooth', inline: 'nearest'})
      }

    } else if (edit.type === 'ADDPARAGRAPH') {
      let imgAdd = document.querySelectorAll(`[data-span-id="${edit.elementId}"][data-type="ADDPARAGRAPH"]`)[0]
      if (imgAdd) {
        imgAdd.style.backgroundColor = backgroundColors.currentFocus
        //imgAdd.style.height = '27px'
        imgAdd.scrollIntoView({block: "center", behavior: 'smooth', inline: 'nearest'})
      }
    }
  }
}

export const showTarget = (elementId, moveToElementId, editSegmentId) => {
  //Since the target can be in two different places, we need to consider setting it in those two places separately:
  let editorDivImg = document.querySelectorAll(`img[data-type='MOVE'][data-edit-segment-id="${editSegmentId}"]`)
  for (let i = 0; i < editorDivImg.length; i++) {
    //editorDivImg[i].height = 22
    //editorDivImg[i].width = 38
    editorDivImg[i].style.backgroundColor = backgroundColors.currentFocus
  }
  //The target uses the elementId of the sentence AFTER the selected sentences so we know where to place the moved-to target icon for the edit-owner's view.
  editorDivImg = document.querySelectorAll(`img[data-type='MOVE'][data-edit-segment-id="${editSegmentId}~tabView"]`)
  for (let i = 0; i < editorDivImg.length; i++) {
    //editorDivImg[i].height = 22
    //editorDivImg[i].width = 38
    editorDivImg[i].style.backgroundColor = backgroundColors.currentFocus
  }
  let editorDivImgTarget = document.querySelector(`img[data-type='MOVE'][data-edit-segment-id="${editSegmentId}"][data-icon-type='to']`)
  let tavDivImgTarget = document.querySelector(`img[data-type='MOVE'][data-edit-segment-id="${editSegmentId}~tabView"][data-icon-type='to']`)
  if (editorDivImgTarget) editorDivImgTarget.scrollIntoView({behavior: "smooth", block: "center"});
  if (tavDivImgTarget) setTimeout(() => tavDivImgTarget.scrollIntoView({behavior: "smooth", block: "center"}), 500);
}

export const showMoveSentences = (moveSegmentsArray, editSegmentId) => {
  let editorDivImg = document.querySelectorAll(`img[data-type='MOVE'][data-edit-segment-id="${editSegmentId}"]`)
  for (let i = 0; i < editorDivImg.length; i++) {
    editorDivImg[i].style.backgroundColor = backgroundColors.currentFocus
  }
  editorDivImg = document.querySelectorAll(`img[data-type='MOVE'][data-edit-segment-id="${editSegmentId}~tabView"]`)
  for (let i = 0; i < editorDivImg.length; i++) {
    editorDivImg[i].style.backgroundColor = backgroundColors.currentFocus
  }
  for (let i = 0; i < moveSegmentsArray.length; i++) {
    const span = document.querySelector(`span[id="${moveSegmentsArray[i]}"][data-type="TEXT"]`)
    if (span) span.style.backgroundColor = backgroundColors.currentFocus
    const spanTabView = document.querySelector(`span[id="${moveSegmentsArray[i]}~tabView"][data-type="TEXT"]`)
    if (spanTabView) spanTabView.style.backgroundColor = backgroundColors.currentFocus
  }
  let editorDivImgTarget = document.querySelector(`img[data-type='MOVE'][data-edit-segment-id="${editSegmentId}"][data-icon-type='start']`)
  let tavDivImgTarget = document.querySelector(`img[data-type='MOVE'][data-edit-segment-id="${editSegmentId}~tabView"][data-icon-type='start']`)
  if (editorDivImgTarget) editorDivImgTarget.scrollIntoView({behavior: "smooth", block: "center"});
  if (tavDivImgTarget) setTimeout(() => tavDivImgTarget.scrollIntoView({behavior: "smooth", block: "center"}), 500);
}

export const showChangeStyle = (changeStyleSegmentsArray, editSegmentId) => {
  let editorDivImg = document.querySelectorAll(`img[data-type='CHANGESTYLE'][data-edit-segment-id="${editSegmentId}"]`)
  for (let i = 0; i < editorDivImg.length; i++) {
    editorDivImg[i].style.backgroundColor = backgroundColors.currentFocus
  }
  editorDivImg = document.querySelectorAll(`img[data-type='CHANGESTYLE'][data-edit-segment-id="${editSegmentId}~tabView"]`)
  for (let i = 0; i < editorDivImg.length; i++) {
    editorDivImg[i].style.backgroundColor = backgroundColors.currentFocus
  }
  for (let i = 0; i < changeStyleSegmentsArray.length; i++) {
    const span = document.querySelector(`span[id="${changeStyleSegmentsArray[i]}"][data-type="TEXT"]`)
    if (span) span.style.backgroundColor = backgroundColors.currentFocus
    const spanTabView = document.querySelector(`span[id="${changeStyleSegmentsArray[i]}~tabView"][data-type="TEXT"]`)
    if (spanTabView) spanTabView.style.backgroundColor = backgroundColors.currentFocus
  }
  let editorDivImgTarget = document.querySelector(`img[data-type='CHANGESTYLE'][data-edit-segment-id="${editSegmentId}"][data-icon-type='start']`)
  let tavDivImgTarget = document.querySelector(`img[data-type='CHANGESTYLE'][data-edit-segment-id="${editSegmentId}~tabView"][data-icon-type='start']`)
  if (editorDivImgTarget) editorDivImgTarget.scrollIntoView({ behavior: "smooth", block: "center" });
  if (tavDivImgTarget) setTimeout(() => tavDivImgTarget.scrollIntoView({ behavior: "smooth", block: "center" }), 500);
}

export const setAddParagraphSentenceIcon = (currentElement, personId, editorName, chapterId, addOrUpdateEdit, setIsInitEdits, setAddParagraphSentence, editLanguageId, getEditSegments) => {
  if (currentElement && currentElement.nodeName === 'SPAN') {
    setAddParagraphSentence(false)
    let mimicStyleSpan = document.querySelector(`span[id="${currentElement.id}"][data-type="TEXT"]`)
    let previousSpan = getPreviousSpan(currentElement)
    addOrUpdateEdit({
      editSegmentId: 0,
      editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
      personId: personId,
      firstName: editorName && editorName.firstName,
      lastName: editorName && editorName.lastName,
      chapterId: chapterId,
      elementId: previousSpan.id,
      languageId: editLanguageId,
      text: '&nbsp;____&nbsp;',
      type: 'ADDPARAGRAPHSENTENCE',
      styleSnapshot: mimicStyleSpan && mimicStyleSpan.style.cssText,
      authorTextSnapshot: '',
      subSequence: 1, //Help ToDo: This might need to be calculated better considering any other ADDPARAGRAPHSENTENCE edits which may already exist for the same elementId ... and where this edit is going to fit considering others that exist for that same elementId
    }, () => {
      setTimeout(() => getEditSegments(personId, guidEmpty, chapterId, editLanguageId), 500)
      setTimeout(() => setCursorPositionByRecallAddParagraphSentence(previousSpan.id, 1), 1500)
    })
    removeAddParagraphSentenceIcons()
  }
}

export const setAddSentenceIcon = (currentElement, personId, editorName, chapterId, addOrUpdateEdit, setIsInitEdits, setAddSentence, editLanguageId, getEditSegments) => {
  if (currentElement && currentElement.nodeName === 'SPAN') {
    setAddSentence(false)
    let mimicStyleSpan = document.querySelector(`span[id="${currentElement.id}"][data-type="TEXT"]`)
    addOrUpdateEdit({
      editSegmentId: 0,
      editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
      personId: personId,
      firstName: editorName && editorName.firstName,
      lastName: editorName && editorName.lastName,
      chapterId: chapterId,
      elementId: currentElement.id,
      languageId: editLanguageId,
      text: '&nbsp;____&nbsp;',
      type: 'ADDSENTENCE',
      styleSnapshot: mimicStyleSpan && mimicStyleSpan.style.cssText,
      authorTextSnapshot: '',
    }, () => {
      setTimeout(() => getEditSegments(personId, guidEmpty, chapterId, editLanguageId), 500)
      setTimeout(() => setCursorPositionByRecall(currentElement.id, 'ADDSENTENCE'), 1000)
    })
    removeAddSentenceIcons()
  }
}

export const removeAddParagraphSentenceIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('ParagraphSentenceQuestionPlus') && loop < 5) {
    let images = document.getElementsByClassName('ParagraphSentenceQuestionPlus')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

export const removeAddSentenceIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('SentenceQuestionPlus') && loop < 5) {
    let images = document.getElementsByClassName('SentenceQuestionPlus')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

export const createParagraphSentencePlusEditor = (urlImage, editorName, edit, divDisplayId) => {
  let className = divDisplayId === 'editorDiv' ? 'ParagraphSentencePlus' : 'ParagraphSentencePlus~tabView'
  let elementId = divDisplayId === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
  let img = document.createElement('img')
  img.id = elementId  //Don't confuse the getElementById searches with an id that matches a span segment.
  img.src = urlImage
  img.alt = 'Add S'
  img.height = 22
  img.className = className
  img.style.cursor = 'pointer'
  img.title = editorName.firstName + ' ' + editorName.lastName
  img.style.marginRight = '3px'
  img.style.position = 'relative'
  img.style.top = '3px'
  img.setAttribute('data-type', 'ADDPARAGRAPHSENTENCE')
  img.setAttribute('data-subsequence', edit.subSequence)
  return img
}

export const createSentencePlusEditor = (urlImage, editorName, edit, divDisplayId) => {
  let className = divDisplayId === 'editorDiv' ? 'SentencePlus' : 'SentencePlus~tabView'
  let elementId = divDisplayId === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
  let img = document.createElement('img')
  img.id = elementId  //Don't confuse the getElementById searches with an id that matches a span segment.
  img.src = urlImage
  img.alt = 'Add S'
  img.height = 22
  img.className = className
  img.style.cursor = 'pointer'
  img.title = editorName.firstName + ' ' + editorName.lastName
  img.style.marginRight = '3px'
  img.style.position = 'relative'
  img.style.top = '3px'
  img.setAttribute('data-type', 'ADDSENTENCE')
  //img.setAttribute('data-subsequence', edit.subSequence)   //This is for ADDPARAGRAPHSENTENCE only. We don't accumulate ADDSENTENCE-s
  return img
}

export const createCommentImage = (urlImage, editorName, spanOnRightId, divDisplayId) => {
  let className = divDisplayId === 'editorDiv' ? 'SentencePlus' : 'SentencePlus~tabView'
  let img = document.createElement('img')
  img.id = spanOnRightId  //Don't confuse the getElementById searches with an id that matches a span segment.
  img.src = urlImage
  img.alt = ''
  img.height = 15
  img.contentEditable = 'false'
  img.className = className
  img.style.cursor = 'pointer'
  img.style.opacity = '.6'
  img.title = editorName && editorName.firstName + ' ' + editorName.lastName
  img.style.marginRight = '3px'
  img.style.position = 'relative'
  img.style.top = '3px'
  img.setAttribute('data-type', 'COMMENT')
  return img
}

export const insertAddSentenceIcons = (personId, divDisplayId, edits, tabsData, editorName, chosenTab) => {
  //ADDSENTENCE
  //If this is the editor who has made an ADDSENTENCE edit, then create the sentence and put in the icon
  //For other editors (and the author) put in the icon without creating the new sentence
  //The edit.elementId for AddSentence has the before-span elementId in it. Take off the first five 9's: '9999'
  let addSentenceEdits = edits && edits.length > 0 && edits.filter(e => e.type === 'ADDSENTENCE' && !e.isEditorAddParagraph) //The isEditorAddParagraph ADDSENTENCE icons are added in the insertAddParagraphIcons function that is called from setSegmentsWithEdits function
  addSentenceEdits && addSentenceEdits.length > 0 && addSentenceEdits.forEach(edit => {
    let editPerson = divDisplayId === 'tabView' ? edit.personId === chosenTab : edit.personId === personId
    let spanElementId = divDisplayId === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
    let span = document.querySelector(`span[id="${spanElementId}"][data-type="TEXT"]`)
    if (span) {
      let paragraph = span.parentElement
      if (paragraph) {
        let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
          id: '',
          label: '',
          editorColor: '',
          editorName: {}
        }
        let currentEditorName = editor && editor.editorName && editor.editorName.firstName ? editor.editorName : editorName
        let editorColor = getEditorColor(edit.personId, tabsData, 'withoutSymbol')
        let img = createSentencePlusEditor(`/inline/sentence-plus-${editorColor}.svg`, currentEditorName, edit, divDisplayId)
        paragraph.insertBefore(img, span)

        const addSpace = edit.text && (edit.text.lastIndexOf('&nbsp;') === edit.text.length - 6 || edit.text.lastIndexOf(' ') === edit.text.length - 1) ? '' : '&nbsp;'
        const resultText = edit.text + addSpace

        if (editPerson) {
          let newSpan = document.createElement('span')
          newSpan.id = edit.elementId
          newSpan.type = 'ADDSENTENCE'
          newSpan.setAttribute('data-add-sentence-element-id', span.id)
          newSpan.setAttribute('data-type', 'ADDSENTENCE')
          newSpan.innerHTML = resultText || '&nbsp;____&nbsp;'
          newSpan.setAttribute('style', edit.styleSnapshot)
          newSpan.style.backgroundColor = backgroundColors.editPending
          paragraph.insertBefore(newSpan, span)
        }
      }
    }
  })
}


export const insertAddSentenceAndParagraphIcons = (personId, divDisplay, edits, tabsData, editorName, chosenTab) => {
  //ADDSENTENCE
  //If this is the editor who has made an ADDSENTENCE edit, then create the sentence and put in the icon
  //For other editors (and the author) put in the icon without creating the new sentence
  //The edit.elementId for AddSentence has the before-span elementId in it. Take off the first five 9's: '9999'
  //UPDATE: since we will need to be able to add many paragraphs and sentences in succession, we are using the subSequence field in the editSegment table to keep track of these sentence and paragraph pairs.
  //So ... we receive the edits in order of elementId followed by subSequence. If it just a single sentence added to the end of a sentence, we'll just add it,
  //    But if there are one or more pairs of paragraphs and sentences, we will list them one after another. The change to the code in general is 
  //    that the ADDSENTENCE now comes after the sentence in question and not before so we can continue to tack on paragraphs and sentences one after another with the same starting elementId.
  //HOWEVER, we will go backwards! We will continue to stack the sentence/paragraph pairs on top of each other but BEHIND the existing element target.
  //And, we will be surgically implementing paragraphs and span text inserts when it is the editor who owns the edit.
  //Be aware that even if there is more than one sentence in a paragraph, it is going to be one complete span tag since they are not split up from the editor. That will happen after the author accepts the sentence(ss.
  //  So if there is going to be a paragrpah followed by a span, there will only be one of each. If there is not a paragraph, then it is just a sentence added after an existing span segment.
  //1. Make a copy of the edits so we can delete them as we process them until we are done since we will be taking a collection by elementId and personId
  //2. While-loop through the related edits in clumps. (while there are edits left in the list)
  //3.   Get the first collection of related elementId and personId edits in REVERSE order
  //4.   For-loop of the number of edits in this clump.
  //5.       Create the imgParagraphSpan
  //6.       If this is the editor-owner
  //7.          Create the span
  //8.          Write the segment text to the span
  //9.      end if
  //10.      If this is the editor-owner
  //11.        Create the paragraph
  //12.        Attach the imgParagraphSpan before the span (append)
  //13.        Attach the span in the paragraph (append)
  //14.        Attach the paragraph AFTER the existing element target
  //15.        Move any elements found after the existing element target and move them to the new paragraph (append)
  //16.      else //this is NOT the editor-owner
  //17.        Attach the imgParagraphSpan after the existing element target
  //18.      end if
  //19.    end for-loop
  //20.   Delete the collection of edits
  //21. end while-loop

  let collection = []
  //1. Make a copy of the edits so we can delete them as we process them until we are done since we will be taking a collection by elementId and personId
  let addEdits = edits && edits.length > 0 && edits.filter(e => e.type === 'ADDPARAGRAPHSENTENCE') 
  addEdits = doSort(addEdits, { sortField: 'subSequence', isAsc: true, isNumber: true })

  if (addEdits && addEdits.length > 0) {
    //2. While-loop through the related edits in clumps. (while there are edits left in the list)
    while (addEdits && addEdits.length > 0) {
      //3.  Get the collection of related elementId and personId edits.
      collection = addEdits.filter(m => m.elementId === addEdits[0].elementId && m.personId === addEdits[0].personId)
      let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === collection && collection[0].personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      let currentEditorName = editor && editor.editorName && editor.editorName.firstName ? editor.editorName : editorName
      let editorColor = getEditorColor(collection && collection[0] && collection[0].personId, tabsData, 'withoutSymbol')

      //4.   For-loop of the number of edits in this clump.
      for (let i = collection.length-1; i >= 0; i--) { 
        const existingElementId = divDisplay.id === 'editorDiv' ? collection[i].elementId : collection[i].elementId + '~tabView'
        const existingElement = document.querySelector(`span[id="${existingElementId}"][data-type="TEXT"]`)
        const editPerson = divDisplay.id === 'tabView' ? collection[i].personId === chosenTab : collection[i].personId === personId
        if (existingElement) {
          const existingParent = existingElement.parentElement
          if (existingParent) {
            const existingParentSibling = existingParent.nextSibling
            let newSpan
            //5.       Create the imgParagraphSpan
            let imgParagraphSpan = createParagraphSentencePlusEditor(`/inline/paragraph-sentence-plus-${editorColor}.png`, currentEditorName, collection[i], divDisplay.id)
            //6.       If this is the editor-owner
            if (editPerson) {
              //7.          Create the span
              newSpan = document.createElement('span')
              newSpan.id = existingElementId
              newSpan.setAttribute('data-type', 'ADDPARAGRAPHSENTENCE')
              newSpan.setAttribute('data-subsequence', collection[i].subSequence || 1)
              newSpan.setAttribute('style', existingElement.style.cssText)
              newSpan.style.backgroundColor = backgroundColors.editPending
              let textNode = document.createTextNode('\u00A0') //This is important in order to set the cursor inside the span.
              newSpan.append(textNode)
              const addSpace = collection[i].text && (collection[i].text.lastIndexOf('&nbsp;') === collection[i].text.length - 6 || collection[i].text.lastIndexOf(' ') === collection[i].text.length - 1) ? '' : '&nbsp;'
              //8.          Write the segment text to the span
              newSpan.innerHTML = collection[i].text + addSpace
            //9.      end if
            }
            //10.        If this is the editor-owner
            if (editPerson) {
              //11.        Create the paragraph
              let newParagraph = document.createElement('p')
              newParagraph.id = existingElementId
              newParagraph.setAttribute('data-subsequence', collection[i].subSequence || 1)
              newParagraph.setAttribute('data-type', "ADDPARAGRAPHSENTENCE")
              if (existingParent && existingParent.style && existingParent.style.cssText) newParagraph.setAttribute('style', existingParent.style.cssText)
              //12.          Attach the imgSpan before the span (append)
              newParagraph.append(imgParagraphSpan)
              //13.          Attach the span in the paragraph (append)
              newParagraph.append(newSpan)
              //14.          Attach the paragraph AFTER the existing element target
              const existingParentSibling = existingParent.nextSibling
              const grandParent = existingParent.parentElement
              if (existingParentSibling) {
                grandParent.insertBefore(newParagraph, existingParentSibling)
                //15.          Move any elements found after the existing element target and move them to the new paragraph (append)
                let foundTargetElement = false
                for (let index = 0; index < existingParent.children.length;) {
                  if (!foundTargetElement) {
                    if (existingParent.children[index].nodeName === 'SPAN' && existingParent.children[index] === existingElement) { //We should avoid looking for the existingElement by it's Id since these edits of both paragraph and span share the same Id but are differentiated by the data-type and data-subsequence
                      foundTargetElement = true
                    }
                    index++
                  } else {
                    newParagraph.append(existingParent.children[index]) //Be aware that because we are moving a child from this list that it is automatically decrementing the length by 1
                  }
                }
              }
              //16.      else //this is NOT the editor-owner
            } else if (!editPerson) {
              //17.        Attach the imgParagraphSpan after the existing element target
              insertAfterElementOrParagraphEnd(imgParagraphSpan, existingElement, existingParent)
            //18.      end if
            }
          }
        }
      //19.    end for-loop
      }
      //20.   Delete the last clump of the collection of edits
      addEdits = addEdits.filter(m => !(m.elementId === addEdits[0].elementId && m.personId === addEdits[0].personId))
    //21. end while-loop
    }
  }
}

export const showCommentBubbles = (personId, divDisplayId, edits, segments, chosenTab, tabsData, editorName) => {
  //Author comments come from workSegment. Editor comments come from editSegment
  let editComments = edits && edits.length > 0 && edits.filter(e => e.comment)
  editComments && editComments.length > 0 && editComments.forEach(edit => {
    let spanElementId = divDisplayId === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
    let span = document.querySelector(`span[id="${spanElementId}"][data-type="TEXT"]`)
    if (span) {
      let paragraph = span.parentElement
      if (paragraph) {
        let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
          id: '',
          label: '',
          editorColor: '',
          editorName: {}
        }
        let currentEditorName = editor && editor.editorName && editor.editorName.firstName ? editor.editorName : editorName
        let editorColor = getEditorColor(edit.personId, tabsData, 'withoutSymbol')
        let existImage = document.querySelector(`img[id="${edit.elementId}"][data-type='COMMENT'][editorName="${editorName && editorName.firstName + ' ' + editorName.lastName}"]`)
        if (!existImage) {
          let img = createCommentImage(`https://penspring.com/comment/comment-${editorColor}.png`, currentEditorName, edit.elementId, divDisplayId)
          paragraph.insertBefore(img, span)
        }
        span.style.backgroundColor = backgroundColors.editPending
      }
    }
  })
  //The author in workSegments
  let segmentComments = segments && segments.length > 0 && segments.filter(e => e.comment)
  segmentComments && segmentComments.length > 0 && segmentComments.forEach(segment => {
    let spanElementId = divDisplayId === 'editorDiv' ? segment.elementId : segment.elementId + '~tabView'
    let span = document.querySelector(`span[id="${spanElementId}"][data-type="TEXT"]`)
    if (span) {
      let paragraph = span.parentElement
      if (paragraph) {
        let existImage = document.querySelector(`img[id="${segment.elementId}"][data-type='COMMENT'][editorName="${editorName && editorName.firstName + ' ' + editorName.lastName}"]`)
        if (!existImage) {
          let img = createCommentImage(`https://penspring.com/comment/comment-white.png`, editorName, segment.elementId, divDisplayId)
          paragraph.insertBefore(img, span)
        }
      }
    }
  })
}

export const insertAuthorsAcceptedTab = (paragraphElement) => {
  //Adding a ADDTAB, sets the text-indent property to 36pt. Additional tabs are ADDTAB edits which increment the margin-left which leads to a block indent. This is Microsoft Word convention.
  if (paragraphElement) {
    let textIndent = paragraphElement.style['text-indent']
    textIndent = textIndent.match(/\d+/)
    if (textIndent > 0) {
      let marginLeft = paragraphElement.style['margin-left']
      marginLeft = marginLeft.match(/\d+/)
      marginLeft = String(Number(marginLeft) + Number(addMarginLeft))
      paragraphElement.style['margin-left'] = marginLeft + 'pt'
    } else {
      paragraphElement.style['text-indent'] = '36pt' //ToDo this might need to be a custom setting according to a user's preference of tabs.
    }
  }
}

const createImageAddOrDeleteTab = ({
                                     personId,
                                     spanId,
                                     paragraphId,
                                     direction,
                                     size,
                                     tabsData,
                                     editorName,
                                     editType,
                                     subSequence
                                   }) => {
  let editorColor = getEditorColor(personId, tabsData, 'withoutSymbol')
  let img = document.createElement('img')
  img.id = editType === 'ADDTAB' || editType === 'DELETETAB' ? paragraphId : spanId
  img.src = `/inline/tab-${direction}-${size}-${editorColor}.png`
  img.style.cursor = 'pointer'
  img.style.borderRadius = direction === 'right' ? '4px 0px 0px 4px' : '0px 4px 4px 0px'
  img.style.position = 'relative'
  img.style.top = '3px'
  img.title = editorName.firstName + ' ' + editorName.lastName
  img.setAttribute('data-type', editType)
  img.setAttribute('data-span-id', spanId)
  img.setAttribute('data-paragraph-id', paragraphId)
  img.setAttribute('data-sub-sequence', subSequence)
  return img
}

export const clearTextHighlights = (divDisplayId, chosenSegment, edits, currentSpanElement) => {
  //Go back fifteen elements just to ensure that they are cleared.
  const divName = divDisplayId === 'tabView' ? '~tabView' : ''
  let length = chosenSegment.length >= 15 ? 15 : chosenSegment.length
  for (let i = chosenSegment.length; i > chosenSegment.length - length; i--) {
    if (Number(currentSpanElement && currentSpanElement.id) !== Number(chosenSegment[i] && chosenSegment[i].id)) {
      let element = document.querySelector(`[id="${chosenSegment[i] && chosenSegment[i].id}${divName}"][data-type="TEXT"]`)
      if (element) {
        const edit = edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(element.id) && m.type === 'TEXT')[0]
        if (edit) {
          element.style.backgroundColor = backgroundColors.editPending
        } else {
          element.style.backgroundColor = backgroundColors.normal
        }
      }
    }
  }
}

export const unshowAddParagraphSentenceIcons = (edit) => {
  //Since the target can be in two different places, we need to consider setting it in those two places separately:
  //The target uses the elementId of the sentence AFTER the selected sentences so we know where to place the moved-to target icon for the edit-owner's view.
  let elements = document.getElementsByClassName('ParagraphSentencePlus')
  for (let i = 0; i < elements.length; i++) {
    if (Number(elements[i].dataset.spanId) === Number(edit.elementId)) {
      elements[i].height = 22
      elements[i].style.backgroundColor = backgroundColors.normal
    }
  }
  elements = document.getElementsByClassName('ParagraphSentencePlus~tabView')
  for (let i = 0; i < elements.length; i++) {
    if (Number(elements[i].dataset.spanId) === Number(edit.elementId)) {
      elements[i].height = 22
      elements[i].style.backgroundColor = backgroundColors.normal
    }
  }
}

export const unshowAddSentenceIcons = (edit) => {
  //Since the target can be in two different places, we need to consider setting it in those two places separately:
  //The target uses the elementId of the sentence AFTER the selected sentences so we know where to place the moved-to target icon for the edit-owner's view.
  let elements = document.getElementsByClassName('SentencePlus')
  for (let i = 0; i < elements.length; i++) {
    if (Number(elements[i].dataset.spanId) === Number(edit.elementId)) {
      elements[i].height = 22
      elements[i].style.backgroundColor = backgroundColors.normal
    }
  }
  elements = document.getElementsByClassName('SentencePlus~tabView')
  for (let i = 0; i < elements.length; i++) {
    if (Number(elements[i].dataset.spanId) === Number(edit.elementId)) {
      elements[i].height = 22
      elements[i].style.backgroundColor = backgroundColors.normal
    }
  }
}

export const authorAcceptAddParagraphSentence = (currentElement, getNextId, edit) => {
  // 1. Delete the add sentence icon.
  let currentElementId = currentElement.id
  currentElement.remove()
  let imgTabView = document.querySelector(`img[id="${currentElementId}~tabView"][data-type="ADDPARAGRAPHSENTENCE"]`)
  if (imgTabView) imgTabView.remove()

  // 2. Get the span on the right of the add sentence
  const spanOnRight = document.querySelector(`span[id="${currentElementId}"][data-type="TEXT"]`)

  if (spanOnRight) {
    // 3. Get the parentParagraph of the span on the right
    let parentParagraph = spanOnRight.parentElement

    if (parentParagraph) {
      // 4. Create the new span
      let newSpan = document.createElement('span')
      newSpan.id = getNextId()
      newSpan.innerHTML = edit.text
      newSpan.setAttribute('data-type', 'TEXT')

      // 5. Add the addSentence text before the spanOnRight
      parentParagraph.insertBefore(newSpan, spanOnRight)
      return newSpan
    }
    //ToDo probably need to add to the tabView as well, if it is open. Maybe not: The segments should rebuild with the newly accepted edits since the edits will change accordin
    //  to any stacked ADDPARAGRAPHSENTENCE-s which may have been reassigned to a new, upper element that was just accepted.
  }
}

export const authorAcceptAddSentence = (currentElement, getNextId, edit) => {
  // 1. Delete the add sentence icon.
  let currentElementId = currentElement.id
  currentElement.remove()
  let imgTabView = document.querySelector(`img[id="${currentElementId}~tabView"][data-type="ADDSENTENCE"]`)
  if (imgTabView) imgTabView.remove()

  // 2. Get the span on the right of the add sentence
  const spanOnRight = document.querySelector(`span[id="${currentElementId}"][data-type="TEXT"]`)

  if (spanOnRight) {
    // 3. Get the parentParagraph of the span on the right
    let parentParagraph = spanOnRight.parentElement

    if (parentParagraph) {
      // 4. Create the new span
      let newSpan = document.createElement('span')
      newSpan.id = getNextId()
      newSpan.innerHTML = edit.text
      newSpan.setAttribute('data-type', 'TEXT')

      // 5. Add the addSentence text before the spanOnRight
      parentParagraph.insertBefore(newSpan, spanOnRight)
      return newSpan
    }
    //ToDo probably need to add to the tabView as well, if it is open
  }
}

export const isFirstPositionOfParagraphOrList = (currentElement, eventShiftKey, chosenSegment) => {
  //Only return paragraph or list element if there is not a span tab
  //return the tabParagraphOrList element

  //If currentElement is an LI, then move it into the first child which should be a span
  if (!currentElement) {
    let lastChosen = chosenSegment[chosenSegment.length - 1]
    if (lastChosen) currentElement = document.querySelector(`span[id="${lastChosen.id}"][data-type='TEXT']`)
  }
  if (currentElement && (currentElement.nodeName === 'LI' || currentElement.nodeName === 'P')) return currentElement
  let cursorPosition = getCursorPosition(currentElement, true)
  if (cursorPosition === 0) {
    let parent = currentElement.parentElement
    if (parent) {
      for (let i = 0; i < parent.children.length; i++) {
        if (parent.children[i].nodeName === 'SPAN' && parent.children[i].dataset.type === 'TEXT') {
          if (parent.children[i] === currentElement) {
            return parent
          } else {
            return null
          }
          // }
        }
      }
    }
  }
}

export const adjustTab = ({
                            chapterId,
                            event,
                            tabParagraphOrList,
                            currentElement,
                            addOrUpdateEdit,
                            isAuthor,
                            getNextId,
                            edits,
                            personId,
                            editorName,
                            getEditSegments,
                            workId,
                            responseEdit,
                            setIsInitEdits,
                            tabsData,
                            chapterListLevels,
                            listLevelGeneral,
                            addChapterListLevels,
                            chosenSegment,
                            forceShiftKey,
                            editLanguageId,
                          }) => {
  //If there is a tabParagraphOrList
  //   If the TAB+SHIFT or Backspace key is pressed
  //     If this is not the author
  //       if there is an ADDTAB edit existing
  //         delete it in order to reverse it and not create a new opposing edit
  //     else
  //	   Add the DELETETAB edit
  //   else if this is just a TAB
  //     If this is not the author
  //        If there is a DELETETAB edit existing
  //         delete it in order to reverse it and not create a new opposing edit
  //        end if
  //    else
  //       Add the ADDTAB edit
  //	  end if
  //  End if
  //Else if the parent is an LI
  //   If the TAB+SHIFT or Backspace key is pressed
  //	CONSIDER DELETING OPPOSING EDITS BEFORE GOING ON
  //     Set the style of the LI to the previous level (or no level if this is the first level already)
  //   Else
  //     Set the style of the LI to the next level
  //   End if
  // end if
  if (tabParagraphOrList && tabParagraphOrList.nodeName === 'P') {
    if (forceShiftKey || event.shiftKey || event.which === 8) { //Backspace is 8
      let existAddTab = edits && edits.length > 0 && edits.filter(m => m.personId === personId && m.elementId === Number(tabParagraphOrList.id) && m.type === 'ADDTAB')[0]
      if (isAuthor) {
        authorAcceptDeleteParagraph(tabParagraphOrList)
      } else { //This is the editor - not the author
        if (existAddTab) {
          let addElement = document.querySelector(`img[id="${tabParagraphOrList.id}"][data-type="ADDTAB"]`)
          if (addElement) addElement.remove()
          responseEdit(existAddTab, 'DeleteEdit', "", "", () => setIsInitEdits('FORCE'))
          setTimeout(() => setCursorPositionByRecall(getFirstValidSpan(tabParagraphOrList).id, 'TEXT'), 500)

        } else if (!isAuthor) {
          if (hasTabToTakeAway(tabParagraphOrList, edits, personId)) {
            addOrUpdateEdit({
              editSegmentId: 0,
              elementId: tabParagraphOrList.id,
              editSegmentTypeId: 0,
              personId,
              chapterId,
              languageId: editLanguageId,
              firstName: editorName && editorName.firstName,
              lastName: editorName && editorName.lastName,
              type: 'DELETETAB',
              text: '',
              authorTextSnapshot: '',
              comment: '',
            }, () => {
              getEditSegments(personId, workId, chapterId, editLanguageId)
              setTimeout(() => setCursorPositionByRecall(getFirstValidSpan(tabParagraphOrList).id, 'TEXT'), 500)
            })
          } else {
            //1. Delete any tab edits for this given paragraph
            //2. Create a DELETEPARAGRAPH edit.
            const existDeleteTabs = edits && edits.length > 0 && edits.filter(m => m.personId === personId && m.elementId === Number(tabParagraphOrList.id) && m.type === 'DELETETAB')
            existDeleteTabs && existDeleteTabs.length > 0 && existDeleteTabs.forEach(m => {
              responseEdit(m, 'DeleteEdit')

            })
            addOrUpdateEdit({
              editSegmentId: 0,
              editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
              personId: personId,
              chapterId: chapterId,
              elementId: tabParagraphOrList.id,
              languageId: editLanguageId,
              firstName: editorName && editorName.firstName,
              lastName: editorName && editorName.lastName,
              type: 'DELETEPARAGRAPH',
              authorTextSnapshot: getParagraphDeleteBeforeAfter(tabParagraphOrList),
            }, () => {
              setTimeout(() => getEditSegments(personId, guidEmpty, chapterId, editLanguageId), 500)
              setTimeout(() => setCursorPositionByRecall(getFirstValidSpan(tabParagraphOrList).id, 'TEXT'), 1000)
            })

          }
        }
      }
    } else {
      if (!isAuthor) {
        let existDeleteTab = edits && edits.length > 0 && edits.filter(m => m.personId === personId && m.elementId === Number(tabParagraphOrList.id) && m.type === 'DELETETAB')[0]
        if (!isAuthor) {
          if (existDeleteTab) {
            let deleteElement = document.querySelector(`img[id="${tabParagraphOrList.id}"][data-type="DELETETAB"]`)
            if (deleteElement) deleteElement.remove()
            //Be sure that the backend is just deleting one of these since they can accumulate to be more than one and we don't want to delete them all.
            responseEdit(existDeleteTab, 'DeleteEdit', "", "", () => setIsInitEdits('FORCE'))
            setTimeout(() => setCursorPositionByRecall(getFirstValidSpan(tabParagraphOrList).id, 'TEXT'), 500)

          } else {
            //The Word convention is that the first tab goes to text-indent so that it is a TAB,
            //  then additional TABs are margin-left which is a block-indent. So when text-indent has a single tab (36pt) then increment margin-left
            addOrUpdateEdit({
              editSegmentId: 0,
              elementId: tabParagraphOrList.id,
              editSegmentTypeId: 0, //This will be filled in by the type name below when it goes to the backend.
              personId,
              chapterId,
              languageId: editLanguageId,
              firstName: editorName && editorName.firstName,
              lastName: editorName && editorName.lastName,
              type: 'ADDTAB',
              text: '',
              authorTextSnapshot: '',
            }, () => {
              getEditSegments(personId, workId, chapterId, editLanguageId)
              setTimeout(() => setCursorPositionByRecall(getFirstValidSpan(tabParagraphOrList).id, 'TEXT'), 1000)
            })
          }
        }
      }
    }
  } else if (tabParagraphOrList && tabParagraphOrList.nodeName === 'LI') {
    if (forceShiftKey || event.shiftKey || event.which === 8) { //Backspace is 8
      if (!isAuthor) {
        let existMinusLevel = edits && edits.length > 0 && edits.filter(m => m.personId === personId && (m.startElementId === Number(currentElement.id) || m.elementId === Number(tabParagraphOrList.id)) && m.type === 'LISTLEVELPLUS')[0]
        if (!isAuthor && existMinusLevel) {
          let deleteElement = document.querySelectorAll(`[id="${existMinusLevel.elementId}"][data-type="LISTLEVELPLUS"]`)[0]
          if (deleteElement) deleteElement.remove()
          responseEdit(existMinusLevel, 'DeleteEdit', "", "", () => setIsInitEdits('FORCE'))
          setTimeout(() => setCursorPositionByRecall(currentElement.id, 'TEXT'), 500)
          
        } else {
          let firstSpan = getFirstSpanFromParent(tabParagraphOrList)
          addOrUpdateEdit({
            editSegmentId: 0,
            elementId: firstSpan.id, //We need to anchor this to the first span because the listItem structure can change and delete a listItem so that looses the anchor for DELETELISTITEM, ADDLISTITEM, LISTITEMMINUS and LISTITEMPLUS
            editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
            personId,
            chapterId,
            languageId: editLanguageId,
            firstName: editorName && editorName.firstName,
            lastName: editorName && editorName.lastName,
            type: 'LISTLEVELMINUS',
          }, () => {
            getEditSegments(personId, workId, chapterId, editLanguageId)
            setTimeout(() => setCursorPositionByRecall(firstSpan.id, 'TEXT'), 500)
          })
        }
      }

      editListStructure.setListLevel({
        currentElement,
        elementListItem: tabParagraphOrList,
        direction: 'MINUS',
        chapterId,
        getNextId,
        edits,
        chapterListLevels,
        listLevelGeneral,
        addChapterListLevels,
        chosenSegment,
      })
    } else {
      if (!isAuthor) {
        let existMinusLevel = edits && edits.length > 0 && edits.filter(m => m.personId === personId && (m.elementId === Number(currentElement.id) || m.elementId === Number(tabParagraphOrList.id)) && m.type === 'LISTLEVELMINUS')[0]
        if (existMinusLevel) {
          let deleteElement = document.querySelectorAll(`[id="${existMinusLevel.elementId}"][data-type="LISTLEVELMINUS"]`)[0]
          if (deleteElement) deleteElement.remove()
          responseEdit(existMinusLevel, 'DeleteEdit', "", "", () => setIsInitEdits('FORCE'))
          setTimeout(() => setCursorPositionByRecall(currentElement.id, 'TEXT'), 500)
          
        } else {
          let firstSpan = getFirstSpanFromParent(tabParagraphOrList)
          addOrUpdateEdit({
            editSegmentId: 0,
            elementId: firstSpan.id, //We need to anchor this to the first span because the listItem structure can change and delete a listItem so that looses the anchor for DELETELISTITEM, ADDLISTITEM, LISTLEVELMINUS and LISTLEVELPLUS
            editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
            personId,
            chapterId,
            languageId: editLanguageId,
            firstName: editorName && editorName.firstName,
            lastName: editorName && editorName.lastName,
            type: 'LISTLEVELPLUS',
            subSequence: '' //This will be filled in as the next subSequence if there is more than one or start at 1 on the database side.
          }, () => {
            getEditSegments(personId, workId, chapterId, editLanguageId)
            setTimeout(() => setCursorPositionByRecall(firstSpan.id, 'TEXT'), 500)
          })
        }
      }
      editListStructure.setListLevel({
        currentElement,
        elementListItem: tabParagraphOrList,
        direction: 'PLUS',
        chapterId,
        getNextId,
        edits,
        chapterListLevels,
        listLevelGeneral,
        addChapterListLevels,
        chosenSegment,
      })
    }
  }
}

const getThisUserEditsForText = (elementId, personId, edits) => {
  return edits && edits.length > 0 && edits.filter(m => m.personId === personId && Number(m.elementId) === Number(elementId) && m.type === 'TEXT')[0]
}

const insertTabsIconsAndAdjustParagraphStyles = (divDisplayId, edits = [], tabsData = [], personId, editorName) => {
  //Get the distinct paragraphs of the edits for ADDTAB and DELETETAB
  //There should not be a mix of these edit types since we are depending on logic when entering an ADDTAB that it would take away any DELETETABs until they are gone (and the other way around)
  //We will then get the entire count of the given edit type for each paragraph Id in turn
  //If this is the edit owner
  //  The first count will affect the text-indent of the paragraph. Any other edits above that first count will affect the margin-left.
  //    In the case of the DELETETABs, when margin-left reaches zero, we will ignore any other DELETETABs
  //Otherwise we leave the paragraph unchanged with its style for other views and just display the images to indicate the edit-owner's intentions.
  //By the way, we will only display as many images as there is style in the given paragraph (1 for text-indent and any others for what is left in margin-left values)
  const editTabs = edits && edits.length > 0 && edits.filter(m => m.type === 'ADDTAB' || m.type === 'DELETETAB')
  const uniqueParagraphIds = editTabs && editTabs.length > 0 ? [...new Set(editTabs.map(m => m.elementId))] : [];
  uniqueParagraphIds && uniqueParagraphIds.length > 0 && uniqueParagraphIds.forEach(paragraphId => {
    const paragraphIdByView = divDisplayId === 'editorDiv' ? paragraphId : paragraphId + '~tabView'
    const spanIdByView = getFirstSpanFromParent(paragraphIdByView)
    const tabEdits = edits && edits.length > 0 && edits.filter(m => m.elementId === paragraphId && (m.type === 'ADDTAB' || m.type === 'DELETETAB'))
    if (tabEdits && tabEdits.length > 0) {
      //const existImage = document.querySelector(`img[id="${paragraphIdByView}"][data-type="${tabEdits[0].type}"]`)
      //if (!existImage) {
        let tabCount = 0  //This will be determined by the text-indent value and how many margin-left values there are to delete from or add to
        const paragraph = document.querySelector(`p[id="${paragraphIdByView}"]`)
        if (paragraph) {
          if (tabEdits[0].type === 'DELETETAB') {
            let textIndent = paragraph.style['text-indent']
            textIndent = textIndent.match(/\d+/)
            if (textIndent > 0) {
              tabCount++
              if (tabEdits[0].personId === personId) paragraph.style['text-indent'] = '0pt'
            }
            if (tabEdits.length > 1) {
              let marginLeft = paragraph.style['margin-left']
              marginLeft = marginLeft.match(/\d+/)
              const tabsAvailable = Math.ceil(marginLeft / addMarginLeft)
              if (tabEdits[0].personId === personId) {
                marginLeft = marginLeft - ((tabEdits.length - tabCount) * addMarginLeft)
                if (marginLeft < 0) marginLeft = 0
                paragraph.style['margin-left'] = marginLeft + 'pt'
              }
              tabCount += tabsAvailable < tabEdits.length - tabCount ? tabsAvailable : tabEdits.length - tabCount //If there aren't enough tabsAvailable to take, then just add tabsAvailable, otherwise count up th erest of the tabEdits less the first one that might have taken up one of the tabs with text-indent
            }
            for (let i = 0; i < tabCount; i++) {
              const img = createImageAddOrDeleteTab({
                personId: tabEdits[0].personId,
                paragraphId: paragraphIdByView,
                spanId: spanIdByView,
                direction: 'left',
                size: 'small',
                tabsData,
                editorName,
                editType: tabEdits[0].type,
                subSequence: tabEdits[0].subSequence,
              })
              paragraph.insertBefore(img, paragraph.firstChild)
            }
          } else if (tabEdits[0].type === 'ADDTAB') {
            let tabCount = tabEdits.length
            let textIndent = paragraph.style['text-indent']
            textIndent = textIndent.match(/\d+/)
            //If text-indent is filled in, then add a marginLeft in the else condition
            if (!textIndent || textIndent.length === 0) {
              if (tabEdits[0].personId === personId) paragraph.style['text-indent'] = '36pt'
            } else {
              let marginLeft = paragraph.style['margin-left']
              marginLeft = marginLeft.match(/\d+/)
              if (tabEdits[0].personId === personId) {
                marginLeft = String(Number(marginLeft) + Number((tabCount * addMarginLeft)))
                paragraph.style['margin-left'] = marginLeft + 'pt'
              }
            }
            for (let i = 0; i < tabCount; i++) {
              const img = createImageAddOrDeleteTab({
                personId: tabEdits[0].personId,
                paragraphId: paragraphIdByView,
                spanId: spanIdByView,
                direction: 'right',
                size: i === 0 && textIndent === 0 ? 'large' : 'small',
                tabsData,
                editorName,
                editType: tabEdits[0].type,
                subSequence: tabEdits[0].subSequence,
              })
              paragraph.insertBefore(img, paragraph.firstChild)
            }
          }
        }
      // }
    }
  })
}

const highlightAndAdjustListItemEdits = ({
                                           divDisplay,
                                           edits = [],
                                           tabsData = [],
                                           personId,
                                           chapterId,
                                           editorName,
                                           getNextId,
                                           chapterListLevels,
                                           listLevelGeneral,
                                           addChapterListLevels,
                                           chosenTab,
                                         }) => {
  let tabEdits = edits && edits.length > 0 && edits.filter(m => (m.type === 'LISTLEVELMINUS' || m.type === 'LISTLEVELPLUS'))
  //These two edit types save the LI (listItem) as the elementId
  tabEdits && tabEdits.length > 0 && tabEdits.forEach(edit => {
    let elementId = divDisplay.id === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
    let span = document.querySelector(`span[id="${elementId}"][data-type='TEXT']`)
    let listItem = span && span.parentElement
    if (span && listItem) {
      //The listItem for the image here could be different after the editor has the listLevels moved. So we'll get the listItem again.
      const img = createImageAddOrDeleteTab({
        personId: edit.personId,
        spanId: span.id,
        paragraphId: listItem.id,
        direction: edit.type === 'LISTLEVELMINUS' ? 'left' : 'right',
        size: 'small',
        tabsData,
        editorName,
        editType: edit.type,
        subSequence: edit.subSequence
      })
      listItem.insertBefore(img, listItem.firstChild)
      if ((divDisplay.id === 'editorDiv' && edit.personId === personId) || (divDisplay.id === 'tabView' && edit.personId === chosenTab)) {
        //The listItem for the image below could be different after the editor has the listLevels moved. So we'll get the listItem again.
        listItem = span.parentElement
        editListStructure.setListLevel({
          currentElement: span,
          elementListItem: listItem,
          direction: edit.type === 'LISTLEVELMINUS' ? 'MINUS' : 'PLUS',
          chapterId,
          getNextId,
          edits,
          chapterListLevels,
          listLevelGeneral,
          addChapterListLevels,
        })
      }
    }
  })
}

const getPreviousSegment = (chosenSegment) => {
  let segment = chosenSegment && chosenSegment.length > 0 && chosenSegment[chosenSegment.length - 1]
  let previousSpan
  let previousSpanFinal
  if (segment) {
    if (segment.addListItemSequence) {
      previousSpan = document.querySelectorAll(`[id="${segment.id}"][data-type="${segment.type}"][data-add-list-item-sequence="${segment.addListItemSequence}"]`)
    } else if (segment.subSequence > 0) { //This it is an ADDPARAGRAPHSENTENCE
      previousSpan = document.querySelectorAll(`[id="${segment.id}"][data-type="${segment.type}"][data-subsequence="${segment.subSequence}"]`)
    } else {
      previousSpan = document.querySelectorAll(`[id="${segment.id}"][data-type="${segment.type}"]`)
    }
    previousSpan && previousSpan.length > 0 && previousSpan.forEach(m => {
      if (!previousSpanFinal && m.nodeName === 'SPAN') previousSpanFinal = m
    })
  }
  return previousSpanFinal
}

const removeEditHighlights = (elementIdByDisplay, edits) => {
  edits && edits.length > 0 && edits.filter(m => m.elementId === Number(elementIdByDisplay)).forEach(m => {
    let elementMinus = document.querySelectorAll(`[id="${elementIdByDisplay}"][data-type="LISTLEVELMINUS"]`)
    let elementPlus = document.querySelectorAll(`[id="${elementIdByDisplay}"][data-type="LISTLEVELPLUS"]`)
    for (let i = 0; i < elementMinus.length; i++) {
      elementMinus[i].remove()
    }
    for (let i = 0; i < elementPlus.length; i++) {
      elementPlus[i].remove()
    }
  })
}

export const getMainElementChildren = () => {
  //The intention here is to look for the intended first div which is a thing because of the Word document that comes with a main div before it starts the paragraph.
  //But it is possible that we may have to fall back on the editorDiv as the mainDiv if there is ever a circumstance of a document that doesn't have the intended mainDiv in our processing.
  const bodyNode = document.querySelectorAll(`[id="1"][data-main-body-tag="yes"]`)[0]
  const editorDiv = document.getElementById('editorDiv')
  if (bodyNode && editorDiv) return bodyNode && bodyNode.children ? bodyNode.children : editorDiv.children
}

const getMainElement = (divDisplayId) => {
  const bodyNode = document.querySelector(`[id="${divDisplayId === 'tabView' ? '1~tabView' : '1'}"][data-main-body-tag="yes"]`)
  const editorDiv = document.getElementById(divDisplayId ? divDisplayId : 'editorDiv')
  return bodyNode ? bodyNode : editorDiv
}

export const removeAddListItemIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('ListItemQuestionPlus') && loop < 5) {
    let images = document.getElementsByClassName('ListItemQuestionPlus')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

const setImageAddListItem = (child, personId, editorName, workId, chapterId, addOrUpdateEdit, setIsInitEdits, setAddListItem, assignNewImages, isAuthor, getNextId, editLanguageId, handleSetChosenSegment) => {
  if (child && child.nodeName === 'LI' && child.firstChild && (child.firstChild.nodeName === 'SPAN' || (child.firstChild.nodeName === 'IMG' && child.firstChild.dataset.type === 'ADDLISTITEM'))) {
    let img = document.createElement('img')
    img.id = child.id
    img.src = ListItemQuestionPlus
    img.height = 22
    img.className = 'ListItemQuestionPlus'
    img.style.cursor = 'pointer'
    img.style.position = 'relative'
    img.style.top = '3px'
    img.title = 'Add a new list item'
    img.setAttribute('data-span-id', child.id)
    img.setAttribute('data-type', 'ADDLISTITEM')
    img.setAttribute('data-add-list-item-sequence', getNextAddListItemSequence(child))
    img.addEventListener("click", function (event) {
      event.stopPropagation()
      event.preventDefault()
      setAddListItem(false)
      if (!isAuthor) {
        addOrUpdateEdit({
          editSegmentId: 0,
          editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
          personId: personId,
          firstName: editorName && editorName.firstName,
          lastName: editorName && editorName.lastName,
          chapterId: chapterId,
          languageId: editLanguageId,
          elementId: child.id,
          addListItemSequence: child.dataset.addListItemSequence ? Number(child.dataset.addListItemSequence) + 1 : '',
          //isNewAddListItemSequence: true, //This is what will trigger an insert particularly when a list item is being placed between other pending list items for this editor but we want to keep it in the target order.\\
          text: '&nbsp;____&nbsp;',
          type: 'ADDLISTITEM',
          authorTextSnapshot: '',
        }, () => setTimeout(() => setCursorPositionByRecallAddListItem(child.id, 1, handleSetChosenSegment), 1000))
      }
      removeAddListItemIcons()
      if (isAuthor) addNewListItemByAuthor(child.id, getNextId)
    })
    assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
      listItem: child,
      image: img,
    })
  }
  for (let p = 0; p < child.children.length; p++) {
    assignNewImages = setImageAddListItem(child.children[p], personId, editorName, workId, chapterId, addOrUpdateEdit, setIsInitEdits, setAddListItem, assignNewImages, isAuthor, getNextId, editLanguageId, handleSetChosenSegment)
  }
  return assignNewImages
}

export const authorAcceptAddListItem = (currentElement, text, getNextId) => {
  // 1. Delete the add list item icon.
  let currentElementId = currentElement.id
  currentElement.remove()
  let imgTabView = document.querySelector(`img[id="${currentElementId}~tabView"][data-type="ADDLISTITEM"][data-add-list-item-sequence="${currentElement.addListItemSequence}"]`)
  if (imgTabView) imgTabView.remove()

  let firstSpan = document.querySelector(`span[id="${currentElementId}"]`)
  let listItem = firstSpan.parentElement
  let parent = listItem.parentElement
  let newListItem = document.createElement('LI')
  newListItem.setAttribute('style', listItem.style.cssText)
  if (listItem.nextSibling) {
    parent.insertBefore(newListItem, listItem.nextSibling)
  } else {
    parent.append(newListItem)
  }
  let findSpanStyle
  let loop = 0
  while (!findSpanStyle && loop < 5) {
    if (listItem.children[loop] && listItem.children[loop].nodeName === 'SPAN') findSpanStyle = listItem.children[loop].style.cssText
    loop++
  }
  let newSpan = document.createElement('span')
  newSpan.id = getNextId()
  newSpan.type = 'TEXT'
  //newSpan.style.backgroundColor = backgroundColors.editPending
  newSpan.setAttribute('data-type', 'TEXT')
  newSpan.setAttribute('style', findSpanStyle)
  newSpan.innerHTML = text
  newListItem.append(newSpan)
  return newSpan
}

export const unshowAddListItemIcons = (edit) => {
  //Since the target can be in two different places, we need to consider setting it in those two places separately:
  //The target uses the elementId of the sentence AFTER the selected sentences so we know where to place the moved-to target icon for the edit-owner's view.
  let elements = document.querySelectorAll(`[data-type="ADDLISTITEM"]`)
  for (let i = 0; i < elements.length; i++) {
    if (Number(elements[i].dataset.spanId) === Number(edit.elementId)) {
      elements[i].height = 22
      elements[i].style.backgroundColor = backgroundColors.normal
    }
  }
  // elements = document.getElementsByClassName('ListItemPlus~tabView')
  // for(let i = 0; i < elements.length; i++) {
  // 	if (Number(elements[i].dataset.spanId) === Number(edit.elementId)) {
  // 		elements[i].height = 22
  // 		elements[i].style.backgroundColor = backgroundColors.normal
  // 	}
  // }
}

export const insertAddListItemIcons = (personId, divDisplayId, edits, tabsData, editorName, chosenTab) => {
  //ADDLISTITEM
  //If this is the editor who has made an ADDLISTITEM edit, then create the new listItem after the current list item and put in the list item edit icon
  //For other editors (and the author) put in the icon at the END of the listitem that precedes the new added list item without creating the list item nor the sentence(s)
  //The edit.elementId for AddListItem is the list item elementId that precedes the new add list item(s).
  let addListItemEdits = edits && edits.length > 0 && edits.filter(e => e.type === 'ADDLISTITEM')
  addListItemEdits = doSort(addListItemEdits, { sortField: 'addListItemSequence', isAsc: false, isNumber: true })  //We want to add this in reverse since we are taking to the beginning.
  addListItemEdits && addListItemEdits.length > 0 && addListItemEdits.forEach(edit => {
    const elementId = divDisplayId === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
    // const imageExists = document.querySelector(`img[id="${elementId}"][data-type='ADDLISTITEM'][data-add-list-item-sequence="${edit.addListItemSequence}"]`)
    // if (!imageExists) {
      const editPerson = divDisplayId === 'editorDiv' ? edit.personId === personId : edit.personId === chosenTab
      //This edit elementId is actually the first span - because it is possible that the adjustments made in a OL or UL can annihilate a listItem for the purpose of adjusting the structure properly.
      // So, in order to find the listItem for our purposes here, we will take the span and get the parent which should (highly likely) be the listItem.
      const firstSpanElementId = divDisplayId === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
      const firstSpan = document.querySelector(`span[id="${firstSpanElementId}"]`)
      if (firstSpan) {
        const listItem = firstSpan.parentElement
        if (listItem) {
          let parent = listItem.parentElement
          let indexInsert
          for (let i = 0; i < parent.children.length; i++) {
            if (parent.children[i] === listItem) indexInsert = i + 1 * 1
          }
          let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
            id: '',
            label: '',
            editorColor: '',
            editorName: {}
          }
          let currentEditorName = editor && editor.editorName && editor.editorName.firstName ? editor.editorName : editorName
          let editorColor = getEditorColor(edit.personId, tabsData, 'withoutSymbol')
          let img = createListItemPlusEditor(`/inline/list-item-plus-${editorColor}.svg`, currentEditorName, edit, divDisplayId)

          if (editPerson) {
            let newListItem = document.createElement('LI')
            newListItem.id = firstSpanElementId    //Help ToDo: Be sure that if this span with this elementId is ever deleted that the editSegment table will be updated to assign these editSegments to the remaining elementId. (I suppose if it is deleted altogether then this edit wouldn't matter anyway.)
            newListItem.setAttribute('style', listItem.style.cssText)
            newListItem.setAttribute('data-type', 'ADDLISTITEM')
            newListItem.setAttribute('data-edit-segment-id', edit.editSegmentId)
            newListItem.setAttribute('data-add-list-item-sequence', edit.addListItemSequence)
            if (parent.children.length === indexInsert) {
              parent.append(newListItem)
            } else {
              let nextSibling = listItem.nextSibling
              if (nextSibling) {
                parent.insertBefore(newListItem, listItem.nextSibling)
              } else {
                parent.insertBefore(newListItem, listItem)
              }
            }
            let newSpan = document.createElement('span')
            newSpan.id = firstSpanElementId
            newSpan.type = 'ADDLISTITEM_TEXT'
            newSpan.style.backgroundColor = backgroundColors.editPending
            newSpan.setAttribute('data-add-list-item-sequence', edit.addListItemSequence)
            newSpan.setAttribute('data-edit-segment-id', edit.editSegmentId)
            newSpan.setAttribute('data-type', 'ADDLISTITEM')
            newSpan.innerHTML = edit.text || '&nbsp;____&nbsp;'
            newSpan.contentEditable = 'true'
            newListItem.append(newSpan)
            if (img) newListItem.insertBefore(img, newSpan)
          } else if (img) {
            listItem.append(img)
            // if (listItem.firstChild) {
            //   listItem.insertBefore(img, listItem.firstChild)
            // } else if (listItem.nextSibling) {
            //   parent.insertBefore(img, listItem.nextSibling)
            // } else {
            //   parent.append(img)
            // }
          }
        }
      }
    // }
  })
}

export const createListItemPlusEditor = (urlImage, editorName, edit, divDisplayId) => {
  const elementId = divDisplayId === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
  // const exists = document.querySelector(`img[id="${elementId}"][data-type='ADDLISTITEM']`)
  // if (!exists) {
    let img = document.createElement('img')
    img.id = elementId
    img.src = urlImage
    img.alt = ' ' //Don't put anything in here because the numbered list will have a font that will just cause the text to be garbled, such as 'Symbols' font
    img.height = 22
    img.style.cursor = 'pointer'
    img.title = editorName.firstName + ' ' + editorName.lastName
    img.style.marginRight = '3px'
    img.style.position = 'relative'
    img.style.top = '3px'
    img.setAttribute('data-add-list-item-sequence', edit.addListItemSequence)
    img.setAttribute('data-edit-segment-id', edit.editSegmentId)
    img.setAttribute('data-type', 'ADDLISTITEM')
    return img
  // }
}

export const addNewListItemByAuthor = (spanElement, getNextId) => {
  const listItemElement = spanElement.parentElement
  const listElement = listItemElement.parentElement
  if (listElement) {
    let indexInsert
    for (let i = 0; i < listElement.children.length; i++) {
      if (listElement.children[i] === listItemElement) indexInsert = i + 1 * 1
    }
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId()
    newListItem.setAttribute('style', listElement.style.cssText)
    newListItem.setAttribute('data-type', 'LI')
    if (listElement.children.length === indexInsert) {
      listElement.append(newListItem)
    } else {
      let nextSibling = listItemElement.nextSibling
      if (nextSibling) {
        listElement.insertBefore(newListItem, listItemElement.nextSibling)
      } else {
        listElement.insertBefore(newListItem, listItemElement)
      }
    }
    let newSpan = document.createElement('span')
    newSpan.id = getNextId()
    newSpan.type = 'TEXT'
    newListItem.setAttribute('style', listItemElement.style.cssText)
    newSpan.setAttribute('data-type', 'TEXT')
    newSpan.innerHTML = '&nbsp;'
    newSpan.contentEditable = 'true'
    newListItem.append(newSpan)
    setCursorPosition(newSpan, newSpan, 0, 0)
    return newSpan
  }
}

export const removeDeleteListItemIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('ListItemQuestionMinus') && loop < 5) {
    let images = document.getElementsByClassName('ListItemQuestionMinus')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

const setImageDeleteListItem = (child, personId, editorName, workId, chapterId, addOrUpdateEdit, setIsInitEdits, setDeleteListItem, assignNewImages, isAuthor, getNextId, editLanguageId) => {
  if (child && child.nodeName === 'LI' && child.dataset.type !== 'ADDLISTITEM') {
    let img = document.createElement('img')
    img.id = child.id
    img.src = ListItemQuestionMinus
    img.height = 22
    img.className = 'ListItemQuestionMinus'
    img.style.cursor = 'pointer'
    img.style.position = 'relative'
    img.style.top = '3px'
    img.title = 'Delete this list item'
    img.setAttribute('data-span-id', child.id)
    img.setAttribute('data-type', 'DELETELISTITEM')
    img.addEventListener("click", function (event) {
      event.stopPropagation()
      event.preventDefault()
      if (!isAuthor) {
        addOrUpdateEdit({
          editSegmentId: 0,
          editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
          personId: personId,
          firstName: editorName && editorName.firstName,
          lastName: editorName && editorName.lastName,
          chapterId: chapterId,
          languageId: editLanguageId,
          elementId: child.id,
          text: '',
          type: 'DELETELISTITEM',
          authorTextSnapshot: '',
        }) 
      }
      removeDeleteListItemIcons()
    })
    assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
      listItem: child,
      image: img,
    })
  }
  for (let p = 0; p < child.children.length; p++) {
    assignNewImages = setImageDeleteListItem(child.children[p], personId, editorName, workId, chapterId, addOrUpdateEdit, setIsInitEdits, setDeleteListItem, assignNewImages, isAuthor, getNextId, editLanguageId)
  }
  return assignNewImages
}

export const unshowDeleteListItemIcons = (elementId) => {
  //Since the target can be in two different places, we need to consider setting it in those two places separately:
  //The target uses the elementId of the sentence AFTER the selected sentences so we know where to place the moved-to target icon for the edit-owner's view.
  let elements = document.querySelectorAll(`[data-type='DELETELISTITEM']`)
  for (let i = 0; i < elements.length; i++) {
    elements[i].height = 22
    elements[i].style.backgroundColor = backgroundColors.normal
  }
  let element = document.querySelectorAll(`[id="${elementId}"][data-type='DELETELISTITEM']`)[0]
  if (element) {
    element.height = 22
    element.style.backgroundColor = backgroundColors.currentFocus
  }
  element = document.querySelectorAll(`[id="${elementId}~tabView"][data-type='DELETELISTITEM']`)[0]
  if (element) {
    element.height = 22
    element.style.backgroundColor = backgroundColors.currentFocus
  }
}

export const insertDeleteListItemIcons = (personId, editorDiv, edits, tabsData, editorName) => {
  //DELETELISTITEM
  //If this is the editor who has made a DELETELISTITEM edit,
  //   If the list item has children
  //      Keep the LI element
  //      Let the children stay with their own LI's below
  //   else
  //      Delete the LI contents, but the LI needs to stay in order to hold the delete image.
  //   end if
  //end if
  //For other editors (and the author) put in the icon without removing the sentence(s) or LI contents (which could be an image)
  //The edit.elementId for DeleteListItem has the list item elementId in it.
  let addListItemEdits = edits && edits.length > 0 && edits.filter(e => e.type === 'DELETELISTITEM')
  addListItemEdits && addListItemEdits.length > 0 && addListItemEdits.forEach(edit => {
    let editPerson = edit.personId === personId
    let spanElementId = editorDiv.id === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
    let span = document.getElementById(spanElementId)
    if (span) {
      let listItem = span.parentElement
      let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      let currentEditorName = editor && editor.editorName && editor.editorName.firstName ? editor.editorName : editorName
      let editorColor = getEditorColor(edit.personId, tabsData, 'withoutSymbol')
      let img = createListItemMinusEditor(`/inline/list-item-minus-${editorColor}.svg`, currentEditorName, edit, editorDiv)

      if (editPerson) {
        for (let i = 0; i < listItem.children.length; i++) {
          if (listItem.children[i].nodeName === 'SPAN') {
            listItem.children[i].innerHTML = '&nbsp;'
          }
        }
      }
      //Add the image after deleting the direct contents of listItem
      listItem.insertBefore(img, span)
    }
  })
}

export const setParagraphSingleStyles = (personId, editorDiv, edits, tabsData, editorName) => {
  //DELETELISTITEM
  //If this is the editor who has made a paragraph single-style edit (TEXTALIGN, TEXTINDENT, BLOCKLEFT, OR BLOCKRIGHT),
  //   set the paragraph style directly in the DOM reference
  //end if
  //For other editors (and the author) put in the icon without removing the sentence(s) or LI contents (which could be an image)
  let paragraphSingleEdits = edits && edits.length > 0 && edits.filter(e => e.type === 'TEXTALIGN' || e.type === 'TEXTINDENT' || e.type === 'BLOCKLEFT' || e.type === 'BLOCKRIGHT')
  paragraphSingleEdits?.length > 0 && paragraphSingleEdits.forEach(edit => {
    let editPerson = edit.personId === personId
    let paragraphElementId = editorDiv.id === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
    let paragraph = document.getElementById(paragraphElementId)
    if (paragraph && paragraph.nodeName === 'P') {
      let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
        id: '',
        label: '',
        editorColor: '',
        editorName: {}
      }
      let currentEditorName = editor && editor.editorName && editor.editorName.firstName ? editor.editorName : editorName
      let editorColor = getEditorColor(edit.personId, tabsData, 'withoutSymbol')
      let img
      if (edit.type === 'TEXTALIGN') img = createParagraphSingleStyleEditor(`/inline/text-align-${editorColor}.png`, currentEditorName, edit, editorDiv)
      if (edit.type === 'TEXTINDENT') img = createParagraphSingleStyleEditor(`/inline/text-index-${editorColor}.png`, currentEditorName, edit, editorDiv)
      if (edit.type === 'BLOCKLEFT') img = createParagraphSingleStyleEditor(`/inline/block-left-${editorColor}.png`, currentEditorName, edit, editorDiv)
      if (edit.type === 'BLOCKRIGHT') img = createParagraphSingleStyleEditor(`/inline/block-right-${editorColor}.png`, currentEditorName, edit, editorDiv)

      const firstChild = paragraph.firstChild
      if (firstChild) paragraph.insertBefore(img, firstChild)

      if (editPerson) {
        if (edit.type === 'TEXTALIGN') paragraph.style.textAlign = edit.styleEdit
        if (edit.type === 'TEXTINDENT') paragraph.style.textIndent = edit.styleEdit
        if (edit.type === 'BLOCKLEFT') paragraph.style.marginLeft = edit.styleEdit
        if (edit.type === 'BLOCKRIGHT') paragraph.style.marginRight = edit.styleEdit
      }
    }
  })
}

export const createParagraphSingleStyleEditor = (urlImage, editorName, edit, editorDiv) => {
  let elementId = editorDiv.id === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
  let img = document.createElement('img')
  img.id = elementId
  img.src = urlImage
  img.alt = ' ' //Don't put anything in here because the numbered list will have a font that will just cause the text to be garbled, such as 'Symbols' font
  img.height = 22
  img.style.cursor = 'pointer'
  img.title = editorName.firstName + ' ' + editorName.lastName
  img.style.marginRight = '3px'
  img.style.position = 'relative'
  img.style.top = '3px'
  img.setAttribute('data-type', 'DELETELISTITEM')
  img.setAttribute('data-person-id', edit.personId)
  return img
}

export const createListItemMinusEditor = (urlImage, editorName, edit, editorDiv) => {
  let elementId = editorDiv.id === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
  let img = document.createElement('img')
  img.id = elementId
  img.src = urlImage
  img.alt = ' ' //Don't put anything in here because the numbered list will have a font that will just cause the text to be garbled, such as 'Symbols' font
  img.height = 22
  img.style.cursor = 'pointer'
  img.title = editorName.firstName + ' ' + editorName.lastName
  img.style.marginRight = '3px'
  img.style.position = 'relative'
  img.style.top = '3px'
  img.setAttribute('data-type', 'DELETELISTITEM')
  img.setAttribute('data-person-id', edit.personId)
  return img
}

export const authorAcceptDeleteListItem = (elementData, getNextId) => {
  //If this is DELETELISTITEM
  let listItemElementIds = []
  // 1. Delete the delete list item icon.
  let img = document.querySelector(`[id="${elementData.id}"][data-type="DELETELISTITEM"]`)
  if (img) img.remove()
  img = document.querySelector(`[id="${elementData.id}~tabView"][data-type="DELETELISTITEM"]`)
  if (img) img.remove()

  let firstSpan = document.querySelector(`[id="${elementData.id}"][data-type="TEXT"]`)
  let listItem = firstSpan.parentElement
  let deletedListItemLevel = editListStructure.getElementLevel(listItem)
  let listItemPreviousSibling = listItem.previousSibling
  let listItemParent = listItem.parentElement
  let list = listItem.parentElement

  let children = listItem.children
  for (let i = 0; i < children.length; i++) {
    if (children[i].nodeName !== 'LI' && children[i].nodeName !== 'OL' && children[i].nodeName !== 'UL') {
      listItemElementIds.push(children[i].id)
      children[i].remove()
    }
  }
  let orphanList, orphanListItem, orphanSpan
  let foundFirstId = false
  for (let i = 0; i < listItem.children.length; i++) {
    if (!foundFirstId) {
      if (listItem.children[i].id && (listItem.children[i].nodeName === 'OL' || listItem.children[i].nodeName === 'UL')) {
        orphanList = listItem.children[i]
        foundFirstId = true
      } else if (listItem.children[i].id && listItem.children[i].nodeName === 'IL') {
        orphanListItem = listItem.children[i]
        foundFirstId = true
      }
    }
  }
  if (orphanList) {
    orphanListItem = orphanList.firstChild
  }
  if (orphanListItem) {
    let foundSpan = false
    for (let i = 0; i < orphanListItem.children.length; i++) {
      if (!foundSpan && orphanListItem.children[i].nodeName === 'SPAN' && orphanListItem.children[i].id) {
        orphanSpan = orphanListItem.children[i]
        foundSpan = true
      }
    }
  }

  //If there are any children left over, set the li list-style to 'none'
  //Else delete the LI record itself (otherwise it needs to be preserved to keep children in their place)
  if (listItem.children.length === 0) {
    listItemElementIds.push(listItem.id)
    listItem.remove()
  } else {
    listItem.style['list-style-type'] = 'none'
  }
  //And the list.
  if (list.children.length === 0) {
    listItemElementIds.push(list.id)
    list.remove()
  }
  //Also delete the tabView listItem and list, if they are empty.
  let firstSpanTabView = document.querySelector(`[id="${elementData.id}~tabView"][data-type="TEXT"]`)
  let listItemTabView = firstSpanTabView.parentElement
  let listTabView = listItemTabView.parentElement
  let childrenTabView = listItemTabView.children
  for (let i = 0; i < childrenTabView.length; i++) {
    if (childrenTabView[i].nodeName !== 'LI' && childrenTabView[i].nodeName !== 'OL' && childrenTabView[i].nodeName !== 'UL') {
      childrenTabView[i].remove()
    }
  }

  if (listItemTabView.children.length === 0) {
    listItemTabView.remove()
  } else {
    listItemTabView.style['list-style-type'] = 'none'
  }
  //And the list.
  if (listTabView.children.length === 0) {
    listTabView.remove()
  }

  editListStructure.mergeSameLevelAfterDelete(deletedListItemLevel, orphanList, orphanListItem, orphanSpan, listItemPreviousSibling, listItemParent, listItem, getNextId)
  return listItemElementIds
}


//***********************     REORDER LIST ITEMS     *****************************************//

export const setReorderListItemsMovesAndIcons_DOM = (displayPersonId, divDisplayId, edits = [], tabsData = [], editorName, chosenTab) => {
  //If this is the edit-owner,
  //  move the segment listItems into their order locations
  //    Get the reorderParent list
  //    Get all of the children (listItems LI)
  //    Append them in REVERSE order before the firstChild
  //    The additional children that showed up since the editor made this reorder will be at the last
  //Set the reorder-list icon at the beginning of the orderParent (the list OL or UL) (Do this last especially for the icon to show up at the top of that list when all is done.
  let divContentIdAdd = divDisplayId === 'editorDiv' ? '' : '~' + divDisplayId
  let editReorders = (edits && edits.length > 0 && edits.filter(e => e.type === 'REORDERLISTITEMS')) || []
  editReorders.forEach(edit => {
    let isEditOwner = divDisplayId === 'tabView' ? edit.personId === chosenTab : edit.personId === displayPersonId
    let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {id: '', label: '', editorColor: '', editorName: ''}
    let editorColor = (editor && editor.editorColor && editor.editorColor.replace('#', '')) || backgroundColors.currentEditorColor
    let reorderEditIds = edit.reorderEdit.split(',') //The reorderEdit is only a string of a list of elementIds. We need to make it an array.

    let listItem = document.getElementById(edit.reorderParent + divContentIdAdd)
    if (listItem) {
      if (isEditOwner) {
        for (let i = reorderEditIds.length - 1; i >= 0; i--) {  //We need to do this backwards since we ae adding on to each other by insertBefore the firstChild which keeps getting a first child.
          let moveItem
          let moveItems = document.querySelectorAll(`[id="${reorderEditIds[i] + divContentIdAdd}"]`) //There could be other edit-related controls on this listItem, so we're going to be sure that we have the LI
          for (let move = 0; move < moveItems.length; move++) {
            if (moveItems[move].nodeName === 'LI') moveItem = moveItems[move]
          }
          if (moveItem) {
            listItem.insertBefore(moveItem, listItem.firstChild)
          }
        }
      }
      let img = createReorderListItemsEditor(`/inline/reorder-list-${editorColor}.svg`, edit, divDisplayId, editorName)
      if (listItem.firstChild) {
        listItem.insertBefore(img, listItem.firstChild)
      } else {
        listItem.append(img)
      }
    }
  })
}

export const setReorderListItemsMovesAndIcons_Segments = (segments, movingListItemElementId, targetListItemElementId, listElementNextNeighborId) => {
  //This is only used for the author. This will use the local segments non-state version in order to reorder her list and then be able to UNDO. It is up to the user to save their workSegments with the press of the button.
  //The trick here is that we are working with a listElement. We will be taking out the listItem to be moved (and all of its children) which will then collapse the listElement
  //  We do have the targetIndex where we want to set the listElement. So now we have to look at the listElement to determine if a current listItem is in that position.
  //  If there is a listItem in that ELEMENTindex, we will insert the children (backwards) into that position. We do that by finding the SEGMENTindex of that listElement
  //     and insert the segment records into the segment array.
  //  If there is NOT a listItem in that ELEMENTindex because we're at the end of the listItem sequence, then we find the SEGMENTindex of listElementNextNeighbor.
  //    Then will use that SEGMENTindex to insert the listElement and its children
  //0.  Move the segment listItems into their order locations by array index
  //1.  Find the listItemSegment that is going to be reassigned in its order
  //2.  Find the childrenSegments of that listItemSegment that are going to go with it (make an array recursively for the tree depth)
  //3.  Take the listItemSegment and childrenSegments out of segments 
  //4.  Find the targetSEGMENTindex where these moved records need to be placed: If the targetListItemElementId is null, then we'll us the listElementNextNeighborId
  //5.  Place the LI and children in that index in reverse order so that it just keeps filling in on that same index
  
  //0.  Move the segment listItems into their order locations by array index
  var tempSegments = [...segments]
  //1.  Find the listItemSegment that is going to be reassigned in its order
  //const listItemSegmentIndex = tempSegments.findIndex(m => m.elementId === movingListItemElementId && m.type === 'LI')
  const listItemSegment = tempSegments.filter(m => m.elementId === Number(movingListItemElementId) && m.type === 'LI')[0]
  //2.  Find the childrenSegments of that listItemSegment that are going to go with it (make an array)
  //    We need to get the children of the children dynamically here.
  
  const childrenSegments = findAllDescendants(tempSegments, listItemSegment.elementId)
  //3.  Take the listItemSegment and childrenSegments out of tempSegments
  tempSegments = tempSegments.filter(m => !(m.elementId === movingListItemElementId && m.type === 'LI'))
  tempSegments = tempSegments.filter(m => m.parentElementId !== listItemSegment.elementId)
  //4.  Find the index of the targetListItemSegment from the targetListItemElement where these moved records need to be placed
  const targetSegmentElementId = targetListItemElementId ? targetListItemElementId : listElementNextNeighborId
  const targetSegmentIndex = tempSegments.findIndex(m => m.elementId === targetSegmentElementId) 

  //5.  Place the LI and children in that index in reverse order so that it just keeps filling in on that same index
  for(let i = childrenSegments.length-1; i >= 0; i--) {
    tempSegments.splice(targetSegmentIndex, 0, childrenSegments[i])
  }
  tempSegments.splice(targetSegmentIndex, 0, listItemSegment)
  return tempSegments
}

const findAllDescendants = (tempSegments, parentId) => {
  let descendants = tempSegments.filter(segment => segment.parentElementId === Number(parentId));
  descendants.forEach(child => {
    descendants.push(findAllDescendants(tempSegments, child.elementId))
  });
  return descendants;
}

export const createReorderListItemsEditor = (urlImage, edit, divDisplay, editorName) => {
  let elementId = divDisplay === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
  let img = document.createElement('img')
  img.id = elementId
  img.src = urlImage
  img.alt = ' ' //Don't put anything in here because the numbered list will have a font that will just cause the text to be garbled, such as 'Symbols' font
  img.height = 22
  img.style.cursor = 'pointer'
  img.title = editorName.firstName + ' ' + editorName.lastName
  img.style.marginRight = '3px'
  img.style.position = 'relative'
  img.style.top = '3px'
  img.setAttribute('data-type', 'REORDERLISTITEMS')
  return img
}

export const removeReorderListItemsDropDowns = () => {
  let loop = 0
  while (!!document.getElementsByClassName('reorderListItemsDropDown') && loop < 5) {
    let dropDowns = document.getElementsByClassName('reorderListItemsDropDown')
    if (dropDowns && dropDowns.length > 0) {
      for (let i = 0; i < dropDowns.length; i++) {
        dropDowns[i].remove()
      }
    }
    loop++
  }
}

export const setReorderDropDownLists = (currentElement, edits = [], onChooseReorder, isAuthor) => {
  let newArray = []
  if (currentElement && currentElement.id) {
    //1. Get the list element from the currentElement.
    let listItemElement = currentElement.parentElement
    let listElement = listItemElement.parentElement
    //If the drop-down lists are already displayed, don't display them again.
    let exists = document.querySelectorAll(`[data-list-id="${listElement.id}"][data-type='REORDERLISTITEMS']`)
    if (!(exists && exists.length > 0)) {
      //2. If it already exists, then these drop down lists should not be added.
      if (listElement) {
        //3. Create the dropdown lists for each list item.
        let listItemIndex = 0
        for (let index = 0; index < listElement.children.length; index++) {
          if (listElement.children[index].nodeName === 'LI') {
            let dropDown = createReorderListItemDropDown(listElement, listElement.children[index], listItemIndex++, onChooseReorder, isAuthor)
            listElement.children[index].insertBefore(dropDown, listElement.children[index].firstChild)
            newArray.push(listElement.children[index])
          }
        }
      }
    }
  }
  return newArray
}

export const unshowReorderListItemsIcons = (elementId) => {
  //Since the target can be in two different places, we need to consider setting it in those two places separately:
  //The target uses the elementId of the sentence AFTER the selected sentences so we know where to place the moved-to target icon for the edit-owner's view.
  let elements = document.querySelectorAll(`[data-type="REORDERLISTITEMS"]`)
  for (let i = 0; i < elements.length; i++) {
    elements[i].height = 22
    elements[i].style.backgroundColor = backgroundColors.normal
  }
}

export const authorAcceptReorderListItems = (editSegment, segments, chapterId, getNextId, personId, addOrUpdateSegments) => {
  // 1. Delete the delete list item icon.
  let img = document.querySelector(`[id="${editSegment.elementId}"][data-type="REORDERLISTITEMS"]`)
  if (img) img.remove()
  img = document.querySelector(`[id="${editSegment.elementId}~tabView"][data-type="REORDERLISTITEMS"]`)
  if (img) img.remove()
  let listItem = document.getElementById(editSegment.elementId)
  if (listItem) {
    setAuthorReorderListItems(listItem, editSegment)
    let saveSegments = gatherSegmentsToSave(segments, chapterId)
    addOrUpdateSegments(personId, saveSegments)
    //setSaveWorkSpaceTime(new Date()) //This is on the AuthoringEditor page, but this function above is being called by SentenceEdit which is on EditReviewView. sentenceEdit is a sibling to PenspringHTML. We just won't bother setting the time here.
  }
}

const createReorderListItemDropDown = (listElement, listItemElement, currentIndex, onChooseReorder, isAuthor) => {
  let sendEdit = function (event) {
    onChooseReorder(listElement, listItemElement, currentIndex, event.target.value, isAuthor)
  }
  let dropDown = document.createElement('select')
  dropDown.classList.add('reorderListItemsDropDown')
  dropDown.setAttribute('data-list-id', listElement.id)
  dropDown.setAttribute('data-type', 'REORDERLISTITEMS')
  dropDown.setAttribute('style', `margin-right: 2px; border-color: #0f6078; border-size: 1px; outline-color: #c28422; outline-width: 1px; border-radius: 0px 10px 10px 0px; width: 22px; font-size: 12px; font-weight: normal; cursor: pointer;-webkit-appearance: none; -moz-appearance: none; text-indent: 3px; text-overflow: '';`)
  dropDown.addEventListener('change', sendEdit); //, false

  let listItemIndex = 0
  for (let i = 0; i <= listElement.children.length; i++) {
    if (listElement.children[i] && listElement.children[i].nodeName === 'LI') {
      let option = document.createElement('option');
      option.value = listItemIndex;
      option.innerHTML = listItemIndex + 1;
      option.selected = currentIndex === listItemIndex ? 'selected' : ''
      dropDown.append(option);
      listItemIndex++
    }
  }
  return dropDown
}

export const isCursorInsideList = (element, currentElement) => {
  if (currentElement && (element.id === 'editorDiv' || (element.id === '1' || element.dataset.mainBodyTag === 'yes'))) {
    //Get the closest element of the cursor
    element = currentElement
  }
  if (element) {
    if (element.nodeName === 'LI' || element.nodeName === 'UL' || element.nodeName === 'OL') {
      return true
    }
    element = element.parentElement
    if (element) {
      if (element.nodeName === 'LI' || element.nodeName === 'UL' || element.nodeName === 'OL') {
        return true
      }
      element = element.parentElement
      if (element) {
        if (element.nodeName === 'LI' || element.nodeName === 'UL' || element.nodeName === 'OL') {
          return true
        }
      }
    }
  }
}

export const isCursorInsideSpan = (element) => {
  return element && element.nodeName === 'SPAN'
}

export const arrangeSequence = (editorEdit, currentIndex, targetIndex) => {
  //1. Save off the array value of the current index
  //2. Make space for the array value to be placed in its targetIndex
  let currentValue = editorEdit.splice(currentIndex, 1)
  return [...editorEdit.slice(0, targetIndex), currentValue[0], ...editorEdit.slice(targetIndex)]
}

export const setAuthorReorderListItems = (listItem, edit) => {
  //  move the segment listItems into their order locations
  //    Get the reorderParent list
  //    Get all of the children (listItems LI)
  //    Append them in REVERSE order before the firstChild
  //old code? let reorderEditIds = edit.reorderEdit.split(',')
  if (listItem && edit) {
    let reorderEditIds = edit.reorderEdit.split(',') 
    for (let i = reorderEditIds.length; i >= 0; i--) {  //We need to do this backwards since we ae adding on to each other by insertBefore the firstChild which keeps getting a first child.
      let moveItem
      let test = reorderEditIds[i-1]
      let moveItems = document.querySelectorAll(`[id="${reorderEditIds[i-1]}"]`) //There could be other edit-related controls on this listItem, so we're going to be sure that we have the LI
      for (let move = 0; move < moveItems.length; move++) {
        if (moveItems[move].nodeName === 'LI') moveItem = moveItems[move]
      }
      if (moveItem) listItem.insertBefore(moveItem, listItem.firstChild)
    }
  }
}

export const updateListItemOrder = ({
                                      listElement,
                                      listItemId,
                                      currentIndex,
                                      targetIndex,
                                      isAuthor,
                                      addOrUpdateEdit,
                                      addOrUpdateSegments,
                                      setSaveWorkSpaceTime,
                                      segments,
                                      chapterId,
                                      getNextId,
                                      personId,
                                      editorName,
                                      editLanguageId,
                                    }) => {
  //1. Record the children Ids of the listElement in order
  //2. Move the children according to the currentIndex change to targetIndex
  //3. Record the workSegment order of the listElement
  //4. Send the edit record
  //5. If the new edit record doesn't change the order, then we need to manually change the LI order
  let reorderEdit = []
  // let reorderOriginal = []
  // let foundListElement
  // let foundEndListElement
  for (let i = 0; i < listElement.children.length; i++) {
    if (listElement.children[i].nodeName === 'LI') reorderEdit.push(listElement.children[i].id)
  }
  reorderEdit = arrangeSequence(reorderEdit, currentIndex, targetIndex)
  //This reorderOriginal code doesn't work since the segment can run into a child of a child OL or UL which would then stop the gathering of the target list children. We weren't doing anything with that reorderOriginal list anyway...for now.
  // segments.forEach((m, i) => {
  // 	if (!foundListElement && m.elementId === Number(listElement.id) && (m.type === 'OL' || m.type === 'UL')) {
  // 		foundListElement = true
  // 	} else if (foundListElement && !foundEndListElement) {
  // 		if (m.type === 'OL' || m.type === 'UL' || m.type === 'PARAGRAPH') foundEndListElement = true
  // 		if (!foundEndListElement) {
  // 			if (m.type === 'LI') reorderOriginal.push(m.elementId)
  // 		}
  // 	}
  // })
  if (!isAuthor) {
    addOrUpdateEdit({
      editSegmentId: 0,
      editSegmentTypeId: 0, //This will be filled in on the server side when it sees the type below.
      personId: personId,
      firstName: editorName && editorName.firstName,
      lastName: editorName && editorName.lastName,
      chapterId,
      languageId: editLanguageId,
      elementId: listElement.id,
      type: 'REORDERLISTITEMS',
      reorderParent: listElement.id,
      reorderEdit: reorderEdit.toString(),
      //reorderOriginal: reorderOriginal.toString(),
    })
  } else {
    setAuthorReorderListItems(listElement, reorderEdit)
    let saveSegments = gatherSegmentsToSave(segments, chapterId)
    addOrUpdateSegments(personId, saveSegments)
    setSaveWorkSpaceTime(new Date())
  }
}

const highlightSpanTextEdits = (personId, chosenTab, edits = []) => {
  //If personId equals the edit personId, then we will set the text for the EditorDiv
  //If personId equals the chosenTab value, then the tabView will get the text updated.
  edits && edits.length > 0 && edits.filter(m => m.type === 'TEXT').forEach(edit => {
    //editorDiv
    let span = document.getElementById(edit.elementId)
    if (span) {
      span.style.backgroundColor = backgroundColors.editPending
      // if (personId === edit.personId) {
      //   span.innerHTML = edit.text
      // }
    }
    //tabView
    span = document.getElementById(edit.elementId + '~tabView')
    if (span) {
      span.style.backgroundColor = backgroundColors.editPending
      // if (chosenTab === edit.personId) {
      //   span.innerHTML = edit.text
      // }
    }
  })
}


//************************     DELETE SENTENCE        ********************************//

export const setDeleteSentenceIcons = (currentElement, personId, editorName, chapterId, updateDeleteSentenceEdit, editLanguageId) => {
  if (currentElement && currentElement.nodeName === 'SPAN') {
    let exists = document.querySelector(`img[id="${currentElement.id}"][data-type="DELETESENTENCE"]`)
    if (!exists) {
      let img = document.createElement('img')
      img.id = currentElement.id
      img.src = SentenceQuestionMinusStart
      img.height = 22
      img.className = 'SentenceQuestionMinusStart'
      img.style.cursor = 'pointer'
      img.style.position = 'relative'
      img.style.top = '3px'
      //img.title = 'Delete a sentence'
      img.setAttribute('data-type', 'DELETESENTENCE')
      img.addEventListener("click", function (event) {
        event.stopPropagation()
        //event.preventDefault()
        //setDeleteSentence(false)
        updateDeleteSentenceEdit('ChoseStartElement', currentElement.id, editLanguageId)
        removeDeleteSentenceStartIcons()
        setDeleteSentencesEndIcons(updateDeleteSentenceEdit, editLanguageId, currentElement.id)
        setDeleteSentencesEditorIcon(personId, editorName, chapterId, currentElement)
      })
      currentElement.parentElement.insertBefore(img, currentElement)
    }
  }
}

export const removeDeleteSentenceStartIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('SentenceQuestionMinusStart') && loop < 5) {
    let images = document.getElementsByClassName('SentenceQuestionMinusStart')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}


export const removeDeleteSentenceEndIcons = () => {
  let loop = 0
  while (!!document.getElementsByClassName('SentenceQuestionMinusEnd') && loop < 5) {
    let images = document.getElementsByClassName('SentenceQuestionMinusEnd')
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
    loop++
  }
}

export const removeIconsFromDeleteSentenceArray = (deleteSentenceArray, personId) => {
  for(let i = 0; i < deleteSentenceArray.length; i++) {
    let images = document.querySelectorAll(`[id="${deleteSentenceArray[i]}"][data-type="DELETESENTENCE"]`)
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        images[i].remove()
      }
    }
  }
}

export const setDeleteSentencesEditorIcon = (personId, editorName, chapterId, currentElement) => {
  if (currentElement) {
    let paragraph = currentElement.parentElement
    if (paragraph) {
      let img = createSentenceMinusEditor(`/inline/sentence-minus-${backgroundColors.currentEditorColor}.svg`, editorName, currentElement.id, currentElement.dataset.deleteinseries, 'editorDiv', personId) //Help ToDo: I don't think that we are feeding currentElement.dataset.deleteinseries to the element itself. We are just doing the DELETESENTENCE icons with deleteinseries.
      if (img) paragraph.insertBefore(img, currentElement)
    }
  }
}

export const createSentenceMinusEditor = (urlImage, editorName, editElementId, deleteInSeries, editorDiv, personId) => {
  const className = editorDiv === 'editorDiv' ? 'SentenceMinus' : 'SentenceMinus~tabView'
  const elementId = editorDiv === 'editorDiv' ? editElementId : editElementId + '~tabView'
  const existIcon = document.querySelector(`[id="${elementId}"][data-type="DELETESENTENCE"]`)
  // if (!existIcon) { //this was cutting out the image even when I didn't see it in the editor on ~tabView
  let img = document.createElement('img')
  img.id = elementId
  img.src = urlImage
  img.alt = ' '
  img.height = 19
  img.className = className
  img.style.cursor = 'pointer'
  img.title = editorName.firstName + ' ' + editorName.lastName
  img.style.marginRight = '3px'
  img.style.position = 'relative'
  img.style.top = '3px'
  img.setAttribute('data-deleteinseries', deleteInSeries)
  img.setAttribute('data-type', 'DELETESENTENCE')
  img.setAttribute('data-person-id', personId)
  return img
  // }
}

export const insertDeleteSentenceIcons = (personId, editorDiv, edits, tabsData, editorName) => {
  //DELETESENTENCE
  //If this is the editor who has made an DELETESENTENCE edit, then remove the innerHTML of the sentence (not the entire span since some other edit icons depend on that anchor) and put in the icon
  //For other editors (and the author) put in the icon and leave the sentence
  let deleteSentenceEdits = edits && edits.length > 0 && edits.filter(e => e.type === 'DELETESENTENCE')
  deleteSentenceEdits && deleteSentenceEdits.length > 0 && deleteSentenceEdits.forEach(edit => {
    let editPerson = edit.personId === personId
    let spanElementId = editorDiv.id === 'editorDiv' ? edit.elementId : edit.elementId + '~tabView'
    let span = document.querySelector(`span[id="${spanElementId}"][data-type="TEXT"]`)
    if (span) {
      let paragraph = span.parentElement
      if (paragraph) {
        let editor = (tabsData && tabsData.length > 0 && tabsData.filter(t => t.id === edit.personId)[0]) || {
          id: '',
          label: '',
          editorColor: '',
          editorName: {}
        }
        let currentEditorName = editor && editor.editorName && editor.editorName.firstName ? editor.editorName : editorName
        let editorColor = getEditorColor(edit.personId, tabsData, 'withoutSymbol')
        let img = createSentenceMinusEditor(`/inline/sentence-minus-${editorColor}.svg`, currentEditorName, edit.elementId, edit.deleteInSeries, editorDiv.id, personId)
        if (img) paragraph.insertBefore(img, span)

        if (editPerson) {
          span.innerHTML = ''
        }
      }
    }
  })
}

export const setDeleteSentencesEndIcons = (updateDeleteSentenceEdit, editLanguageId, startElementId) => {
  let assignNewImages = []
  let foundStartElement = false

  const mainDiv = getMainElementChildren()
  for (let p = 0; p < mainDiv.length; p++) {
    let spans = mainDiv[p].children
    let isFirstParagraphSegment = true  //Don't put a sentence-end tag at the beginning of the first sentence of a paragraph.
    for (let s = 0; s < spans.length; s++) {
      if (!foundStartElement) {
        if (spans[s].nodeName === 'SPAN' && Number(spans[s].id) === Number(startElementId)) {
          foundStartElement = true
          if (s === spans.length - 1) {
            //Notice that we are bothering to save the data-paragraph-id here. But I don'tknow what that is going to buy us.
            //I believe that the focus here is in updateDeleteSentenceEdit which should be the next spanId so we know when to stop deleting spans.
            //  Otherwise, all of the spans to the end of this document will be deleted (not including list items, by the way)
            //So we will bother to get the next span in the next paragraph from this current paragraph and set the element id in updateDeleteSentenceEdit of that next span.
            //  By the way, if this really is the end of the document then we don't care to let it try to delete the rest of the spans.
            let nextParagraphFirstSpanId
            let nextParagraph = mainDiv[p].nextElementSibling
            //First we need to find a paragraph that has children in case there are empty paragraphs below the span element in question.
            let loop = 0
            while (!(nextParagraph && nextParagraph.nodeName === 'P' && nextParagraph.children.length > 0) && loop < 20) {
              nextParagraph = nextParagraph && nextParagraph.nextElementSibling
              loop++
            }
            if (nextParagraph) {
              for (let p = 0; p < nextParagraph.children.length; p++) {
                if (nextParagraph.children[p].nodeName === 'SPAN' && nextParagraph.children[p].id) {
                  nextParagraphFirstSpanId = nextParagraph.children[p].id
                  break
                }
              }
            } else {
              nextParagraphFirstSpanId = mainDiv[p].id //This really isn't a span but it appears that we are at the end of the document so we really don't care.
            }
            let img = document.createElement('img')
            img.src = SentenceQuestionMinusEnd
            img.height = 17
            img.width = 30
            img.className = 'SentenceQuestionMinusEnd'
            img.style.cursor = 'pointer'
            img.setAttribute('data-paragraph-id', mainDiv[p].id) //Notice that this is the paragraph and not the span id
            img.setAttribute('data-type', 'DELETESENTENCE')
            img.addEventListener("click", function (event) {
              //inside 1
              event.stopPropagation()
              event.preventDefault()
              updateDeleteSentenceEdit('ChoseEndElement', nextParagraphFirstSpanId, editLanguageId)
              removeDeleteSentenceEndIcons()
            })
            assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
              moveEndParagraph: mainDiv[p].lastChild === spans[s] ? mainDiv[p].id : '',
              paragraph: mainDiv[p],
              image: img,
              span: null
            })
          }
        }
      } else if (foundStartElement) {
        if (!isFirstParagraphSegment) {
          let isNotEmptySpan = spans[s].innerHTML.replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ').replace(/ /g, '')
          if (isNotEmptySpan) {
            let spanId = spans[s].id ? spans[s].id : spans[s].dataset.spanId
            let img = document.createElement('img')
            img.src = SentenceQuestionMinusEnd
            img.height = 15
            img.width = 30
            img.className = 'SentenceQuestionMinusEnd'
            img.style.cursor = 'pointer'
            img.setAttribute('data-span-id', spans[s].id)
            img.setAttribute('data-type', 'DELETESENTENCE')
            img.addEventListener("click", function (event) {
              event.stopPropagation()
              event.preventDefault()
              updateDeleteSentenceEdit('ChoseEndElement', spanId, editLanguageId)
              removeDeleteSentenceEndIcons()
            })
            assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
              moveEndParagraph: mainDiv[p].lastChild === spans[s] ? mainDiv[p].id : '',
              paragraph: mainDiv[p],
              image: img,
              span: spans[s]
            })
          }
        }
        if (s === spans.length - 1) {
          //Notice that we are bothering to save the data-paragraph-id here. But I don'tknow what that is going to buy us.
          //I believe that the focus here is in updateDeleteSentenceEdit which should be the next spanId so we know when to stop deleting spans.
          //  Otherwise, all of the spans to the end of this document will be deleted (not including list items, by the way)
          //So we will bother to get the next span in the next paragraph from this current paragraph and set the element id in updateDeleteSentenceEdit of that next span.
          //  By the way, if this really is the end of the document then we don't care to let it try to delete the rest of the spans.
          let nextParagraphFirstSpanId
          let nextParagraph = mainDiv[p].nextElementSibling
          //First we need to find a paragraph that has children in case there are empty paragraphs below the span element in question.
          let loop = 0
          while (!(nextParagraph && nextParagraph.nodeName === 'P' && nextParagraph.children.length > 0) && loop < 20) {
            nextParagraph = nextParagraph && nextParagraph.nextElementSibling
            loop++
          }
          if (nextParagraph) {
            for (let p = 0; p < nextParagraph.children.length; p++) {
              if (nextParagraph.children[p].nodeName === 'SPAN' && nextParagraph.children[p].id) {
                nextParagraphFirstSpanId = nextParagraph.children[p].id
                break
              }
            }
          } else {
            nextParagraphFirstSpanId = mainDiv[p].id //This really isn't a span but it appears that we are at the end of the document so we really don't care.
          }
          let img = document.createElement('img')
          img.src = SentenceQuestionMinusEnd
          img.height = 15
          img.width = 30
          img.className = 'SentenceQuestionMinusEnd'
          img.style.cursor = 'pointer'
          img.setAttribute('data-paragraph-id', mainDiv[p].id) //Notice that this is the paragraph and not the span id
          img.setAttribute('data-type', 'DELETESENTENCE')
          img.addEventListener("click", function (event) {
            event.stopPropagation()
            event.preventDefault()
            updateDeleteSentenceEdit('ChoseEndElement', nextParagraphFirstSpanId, editLanguageId)
            removeDeleteSentenceEndIcons()
          })
          assignNewImages.push({  //Don't assign the element to the DOM here. It will result in an infinite loop as you add elements.
            moveEndParagraph: mainDiv[p].lastChild === spans[s] ? mainDiv[p].id : '',
            paragraph: mainDiv[p],
            image: img,
            span: null
          })
        }
      }
      if (spans[s].nodeName === 'SPAN' && spans[s].id) {
        isFirstParagraphSegment = false
      }
    }
  }

  assignNewImages.forEach(m => {
    // if (m.moveEndParagraph) {  I don't know why this works in MoveSentences but it doubles up images at the end of the paragraph for the DeleteSentences
    //   m.paragraph.append(m.image)
    // } else {
      m.paragraph.insertBefore(m.image, m.span)
    //}
  })
}

export const deleteSentencesInArray = ({
    startElementId,
    endElementId,
    isAuthor,
    saveEditorDivSegmentsPersistent,
    handleSetCurrentElement,
    edits,
    segments,
    addOrUpdateEdit,
    editorName,
    personId,
    workSummary,
    responseEdit,
    editLanguageId,
    getEditSegments
  }) => {

  let endElement
  const deleteInSeries = Math.floor(100000 + Math.random() * 900000)
  let foundStartElement = false
  let foundEndElement = false
  const mainDiv = getMainElementChildren()
  for (let p = 0; p < mainDiv.length; p++) {
    let spans = mainDiv[p].children
    for (let s = 0; s < spans.length; s++) {
      if (!foundStartElement) {
        if (spans[s].nodeName === 'SPAN' && Number(spans[s].id) === Number(startElementId)) {
          foundStartElement = true
        }
      }
      if (foundStartElement && !foundEndElement) {
        if ((spans[s].nodeName === 'SPAN' && Number(spans[s].id) === Number(endElementId))) {
          foundEndElement = true
          endElement = spans[s]
          break
        }
        if (!foundEndElement) {
          if (spans[s].nodeName === 'SPAN' && spans[s].id) {
            setDeleteSentenceEdit({
              isAuthor,
              currentElement: spans[s],
              saveEditorDivSegmentsPersistent,
              handleSetCurrentElement,
              edits,
              segments,
              addOrUpdateEdit,
              editorName,
              personId,
              workSummary,
              responseEdit,
              editLanguageId,
              deleteInSeries,
              getEditSegments,
            })
          }
        }
      }
    }
  }
  setTimeout(() => getEditSegments(personId, guidEmpty, workSummary.chapterId_current, editLanguageId), 500)
  if (endElement) setTimeout(() => setCursorPositionByRecall(endElement.id, 'TEXT'), 1000)
}

export const unshowDeleteSentenceIcons = (edit) => {
  //Since the target can be in two different places, we need to consider setting it in those two places separately:
  //The target uses the elementId of the sentence AFTER the selected sentences so we know where to place the moved-to target icon for the edit-owner's view.
  let elements = document.getElementsByClassName('SentenceMinus')
  for (let i = 0; i < elements.length; i++) {
    elements[i].height = 22
    elements[i].style.backgroundColor = backgroundColors.normal
  }
  elements = document.getElementsByClassName('SentenceMinus~tabView')
  for (let i = 0; i < elements.length; i++) {
    elements[i].height = 22
    elements[i].style.backgroundColor = backgroundColors.normal
  }
}

export const authorAcceptDeleteSentence = (currentElement, getNextId) => {
  // 1. Delete the add sentence icon.
  let img = document.querySelectorAll(`[data-span-id="${currentElement.id}"][data-type="DELETESENTENCE"][class="SentenceMinus"]`)[0]
  if (img) img.remove()
  img = document.querySelectorAll(`[data-span-id="${currentElement.id}"][data-type="DELETESENTENCE"][class="SentenceMinus~tabView"]`)[0]
  if (img) img.remove()

  const span = document.querySelector(`[id="${currentElement.id.replace('~tabView', '')}"][data-type="TEXT"]`)

  if (span) {
    span.parentNode.removeChild(span)
  }
}

const setDeleteSentenceEdit = ({
    isAuthor,
    currentElement,
    saveEditorDivSegmentsPersistent,
    handleSetCurrentElement,
    edits,
    segments,
    addOrUpdateEdit,
    editorName,
    personId,
    workSummary,
    responseEdit,
    editLanguageId,
    deleteInSeries,
    getEditSegments,
  }) => {

  let img = document.querySelector(`img[id="${currentElement.id}"][data-type="DELETESENTENCE"]`)
  if (img) {
    //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
    img.style.backgroundColor = backgroundColors.currentFocus
  }
  img = document.querySelector(`img[id="${currentElement.id}~tabView"][data-type="DELETESENTENCE"]`)
  if (img) {
    //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
    img.style.backgroundColor = backgroundColors.currentFocus
  }

  if (isAuthor) {
    let editorElement = document.querySelector(`span[id="${currentElement.id}"][data-type="TEXT"]`)
    if (editorElement) editorElement.parentNode.removeChild(editorElement)
    currentElement.remove()
    handleSetCurrentElement({id: ''})
    edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(currentElement.id)).forEach(m => {
      responseEdit(m, 'RejectEdit', 'DELETESENTENCE')
    });
    saveEditorDivSegmentsPersistent()

  } else {
    let existEdit = edits && edits.length > 0 && edits.filter(m => m.elementId === Number(currentElement.id) && m.personId === personId && m.type === 'DELETESENTENCE')[0]
    if (!existEdit) {
      let authorTextSnapshot = segments && segments.length > 0 && segments.filter(m => m.elementId === Number(currentElement.id))[0]
      if (authorTextSnapshot) authorTextSnapshot = authorTextSnapshot.text
      const chapterId = workSummary ? workSummary.chapterId_current && workSummary.chapterId_current !== guidEmpty ? workSummary.chapterId_current : workSummary.chapterOptions && workSummary.chapterOptions[0].chapterId : guidEmpty

      addOrUpdateEdit({
        personId: personId,
        firstName: editorName && editorName.firstName,
        lastName: editorName && editorName.lastName,
        chapterId,
        elementId: Number(currentElement.id),
        languageId: editLanguageId,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        type: 'DELETESENTENCE',
        text: '',
        authorTextSnapshot,
        comment: '',
        deleteInSeries,
      }) //The recall is done in the function where the array of deleted sentences is done so that the recall is done only once at the end.
    }
  }
}

const setAddParagraphSentenceEdit = ({
                              isAuthor,
                              currentElement,
                              edits,
                              addOrUpdateEdit,
                              editorName,
                              personId,
                              workSummary,
                              editLanguageId,
                            }) => {

  let img = document.querySelector(`[data-span-id="${currentElement.dataset.spanId}"][class="SentencePlus"]`)
  if (img) {
    //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
    img.style.backgroundColor = backgroundColors.currentFocus
  }
  img = document.querySelector(`[data-span-id="${currentElement.dataset.spanId}"][class="SentencePlus~tabView"]`)
  if (img) {
    //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
    img.style.backgroundColor = backgroundColors.currentFocus
  }

  if (!isAuthor) {
    let existEdit = edits && edits.length > 0 && edits.filter(m => m.elementId === Number(currentElement.id) && m.personId === personId && m.type === 'ADDPARAGRAPHSENTENCE')[0]
    if (!existEdit) {
      let mimicStyleSpan = document.querySelector(`span[id="${currentElement.id}"][data-type="TEXT"]`)
      addOrUpdateEdit({
        elementId: Number(currentElement.id),
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        personId: personId,
        firstName: editorName && editorName.firstName,
        lastName: editorName && editorName.lastName,
        chapterId: workSummary ? workSummary.chapterId_current && workSummary.chapterId_current !== guidEmpty ? workSummary.chapterId_current : workSummary.chapterOptions && workSummary.chapterOptions[0].chapterId : guidEmpty,
        languageId: editLanguageId,
        type: 'ADDPARAGRAPHSENTENCE',
        text: '&nbsp;____&nbsp;',
        styleSnapshot: mimicStyleSpan && mimicStyleSpan.style.cssText,
        authorTextSnapshot: '',
        comment: existEdit && existEdit.comment,
      })
    }
  }

}

const setAddSentenceEdit = ({
  isAuthor,
  currentElement,
  edits,
  addOrUpdateEdit,
  editorName,
  personId,
  workSummary,
  editLanguageId,
}) => {

  let img = document.querySelector(`[data-span-id="${currentElement.dataset.spanId}"][class="SentencePlus"]`)
  if (img) {
    //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
    img.style.backgroundColor = backgroundColors.currentFocus
  }
  img = document.querySelector(`[data-span-id="${currentElement.dataset.spanId}"][class="SentencePlus~tabView"]`)
  if (img) {
    //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
    img.style.backgroundColor = backgroundColors.currentFocus
  }

  if (!isAuthor) {
    let existEdit = edits && edits.length > 0 && edits.filter(m => m.elementId === Number(currentElement.id) && m.personId === personId && m.type === 'ADDSENTENCE')[0]
    if (!existEdit) {
      let mimicStyleSpan = document.querySelector(`span[id="${currentElement.id}"][data-type="TEXT"]`)
      addOrUpdateEdit({
        elementId: Number(currentElement.id),
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        personId: personId,
        firstName: editorName && editorName.firstName,
        lastName: editorName && editorName.lastName,
        chapterId: workSummary ? workSummary.chapterId_current && workSummary.chapterId_current !== guidEmpty ? workSummary.chapterId_current : workSummary.chapterOptions && workSummary.chapterOptions[0].chapterId : guidEmpty,
        languageId: editLanguageId,
        type: 'ADDSENTENCE',
        text: '&nbsp;____&nbsp;',
        styleSnapshot: mimicStyleSpan && mimicStyleSpan.style.cssText,
        authorTextSnapshot: '',
        comment: existEdit && existEdit.comment,
      })
    }
  }

}

export const setDeleteParagraphBreakIcon = (currentElement, personId, editorName, chapterId, addOrUpdateEdit, setIsInitEdits, setDeleteParagraphBreak, editLanguageId, getEditSegments) => {
  if (currentElement && (currentElement.nodeName === 'SPAN' || currentElement.nodeName === 'P')) {
    let paragraph = currentElement.nodeName === 'SPAN' ? currentElement.parentElement : currentElement
    let loop = 0
    while (!(paragraph && paragraph.nodeName === 'P') && loop < 10) {
      paragraph = paragraph.parentElement
      loop++
    }
    if (paragraph) {
      addOrUpdateEdit({
        editSegmentId: 0,
        editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
        personId: personId,
        chapterId: chapterId,
        elementId: paragraph.id,
        languageId: editLanguageId,
        firstName: editorName && editorName.firstName,
        lastName: editorName && editorName.lastName,
        type: 'DELETEPARAGRAPH',
        authorTextSnapshot: getParagraphDeleteBeforeAfter(paragraph),
      }, () => {
        setTimeout(() => getEditSegments(personId, guidEmpty, chapterId, editLanguageId), 500)
        setTimeout(() => setCursorPositionByRecall(currentElement.id, 'TEXT'), 1000)
      })
      setDeleteParagraphBreak(false)
    }
  }
}

export const setAddParagraphBreakIcon = (currentElement, personId, editorName, chapterId, addOrUpdateEdit, setIsInitEdits, setAddParagraphBreak, getNextId, editLanguageId, getEditSegments) => {
  if (currentElement && currentElement.nodeName === 'SPAN') {
    let paragraph = currentElement.parentElement
    let loop = 0
    while (!(paragraph && paragraph.nodeName === 'P') && loop < 10) {
      paragraph = paragraph.parentElement
      loop++
    }
    if (paragraph) {
      let prevSpan = getPrevSpan(currentElement)
      if (prevSpan) {
          setAddParagraphBreak(false)
          addOrUpdateEdit({
            editSegmentId: 0,
            personId: personId,
            firstName: editorName && editorName.firstName,
            lastName: editorName && editorName.lastName,
            chapterId: chapterId,
            elementId: Number(prevSpan.id),
            languageId: editLanguageId,
            editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
            type: 'ADDPARAGRAPH',
            authorTextSnapshot: getNewParagraphSegmentsBeforeAfter(currentElement),
          }, () => {
            setTimeout(() => getEditSegments(personId, guidEmpty, chapterId, editLanguageId), 500)
            setTimeout(() => setCursorPositionByRecall(currentElement.id, 'TEXT'), 1000)
          })

          if (isLastChildOfParagraph(prevSpan)) {
            addOrUpdateEdit({
              editSegmentId: 0,
              personId: personId,
              firstName: editorName && editorName.firstName,
              lastName: editorName && editorName.lastName,
              chapterId: chapterId,
              elementId: Number(prevSpan.id), //Notice that this is the nextSpan and not the current element since added sentences are set before any given element as they are written back in the editor's or authors views.
              languageId: editLanguageId,
              editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
              type: 'ADDSENTENCE',
              text: '&nbsp;____&nbsp;',
              styleSnapshot: prevSpan.style.cssText,
              authorTextSnapshot: '',
              isEditorAddParagraph: true,
            })
          }
          removeAddParagraphBreakIcons()
      }
    }
  }
}

export const setAddListItemIcon = (currentElement, personId, editorName, chapterId, addOrUpdateEdit, setIsInitEdits, setAddListItem, isAuthor, getNextId, editLanguageId, handleSetChosenSegment) => {
  if (currentElement) {
    let listItem = currentElement.nodeName === 'LI' ? currentElement : currentElement.parentElement
    let loop = 0
    while (!(listItem && listItem.nodeName === 'LI') && loop < 5) {
      listItem = listItem && listItem.parentElement
      loop++
    }
    let firstSpan = getFirstSpanFromParent(listItem)
    if (firstSpan) {
      let existImage = document.querySelector(`img[id="${firstSpan.id}"][data-type='ADDLISTITEM']`)
      if (!existImage && listItem && firstSpan) {
        let img = document.createElement('img')
        img.id = firstSpan.id
        img.src = ListItemQuestionPlus
        img.height = 22
        img.className = 'ListItemQuestionPlus'
        img.style.cursor = 'pointer'
        img.style.position = 'relative'
        img.style.top = '3px'
        img.title = 'Add a new list item'
        img.setAttribute('data-type', 'ADDLISTITEM')
        img.setAttribute('data-add-list-item-sequence', listItem.dataset.addListItemSequence)
        img.contentEditable = 'false'  //ToDo does this cause the icon not to be able to be clicked on to see it in the side panel?
        img.addEventListener("click", function (event) {
          event.stopPropagation()
          event.preventDefault()
          setAddListItem(false)
          const firstSpan = getFirstSpanFromParent(listItem)
          if (!isAuthor) addOrUpdateEdit({
            editSegmentId: 0,
            personId: personId,
            firstName: editorName && editorName.firstName,
            lastName: editorName && editorName.lastName,
            chapterId: chapterId,
            elementId: firstSpan.id,
            languageId: editLanguageId,
            editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
            addListItemSequence: getNextAddListItemSequence(listItem),
            //isNewAddListItemSequence: true, //This is what will trigger an insert particularly when a list item is being placed between other pending list items for this editor but we want to keep it in the target order.\\
            text: '&nbsp;____&nbsp;',
            type: 'ADDLISTITEM',
            authorTextSnapshot: '',
          }, () => setTimeout(() => setCursorPositionByRecallAddListItem(firstSpan.id, 1, handleSetChosenSegment), 1000))

          removeAddListItemIcons()
          if (isAuthor) addNewListItemByAuthor(listItem.id, getNextId)
        })
        listItem.insertBefore(img, listItem.firstChild)
      }
    }
  }
}

export const setDeleteListItemIcon = ({
  currentElement, 
  personId, 
  editorName, 
  chapterId, 
  addOrUpdateEdit, 
  segments, 
  isAuthor, 
  editLanguageId,
  workSummary, 
  edits, 
  tabsData, 
  getNextId, 
  chapterListLevels, 
  addChapterListLevels, 
  listLevelGeneral, 
  chosenTab,
  updateSegmentsLocal
}) => {
  if (currentElement) {
    let listItem = currentElement.nodeName === 'LI' ? currentElement : currentElement.parentElement
    let loop = 0
    while (!(listItem && listItem.nodeName === 'LI') && loop < 5) {
      listItem = listItem && listItem.parentElement
      loop++
    }
    let firstSpan = getFirstSpanFromParent(listItem)
    let existImage = document.querySelector(`img[id="${firstSpan.id}"][data-type='DELETELISTITEM']`)
    if (!existImage && listItem && listItem.dataset.type !== 'ADDLISTITEM' && firstSpan) {
      let img = document.createElement('img')
      img.id = firstSpan.id
      img.src = ListItemQuestionMinus
      img.height = 22
      img.className = 'ListItemQuestionMinus'
      img.style.cursor = 'pointer'
      img.style.position = 'relative'
      img.style.top = '3px'
      img.title = 'Delete this list item'
      img.setAttribute('data-span-id', listItem.id)
      img.setAttribute('data-type', 'DELETELISTITEM')
      img.contentEditable = 'false'    //ToDo does this cause the icon not to be able to be clicked on to see it in the side panel?
      img.addEventListener("click", function (event) {
        event.stopPropagation()
        event.preventDefault()
        //setDeleteListItem(false)
        const firstSpan = getFirstSpanFromParent(listItem)
        if (isAuthor) {
          let tempSegments = deleteNewListItemByAuthor(segments, firstSpan)
          tempSegments = gatherSegmentsToSave(tempSegments, chapterId)
          setSegments('editorDiv', tempSegments, workSummary, edits, isAuthor, personId, editorName, tabsData, getNextId, chapterListLevels, addChapterListLevels, listLevelGeneral, chosenTab)
          updateSegmentsLocal(tempSegments)
        } else {
          addOrUpdateEdit({
            editSegmentId: 0,
            personId: personId,
            firstName: editorName && editorName.firstName,
            lastName: editorName && editorName.lastName,
            chapterId: chapterId,
            elementId: firstSpan.id,
            languageId: editLanguageId,
            editSegmentTypeId: 0, //This will be filled in on the server side by the type entered below
            text: '',
            type: 'DELETELISTITEM',
            authorTextSnapshot: '',
          })
        }
        removeDeleteListItemIcons()
      })
      listItem.insertBefore(img, listItem.firstChild)
    }
  }
}

export const setMoveSentencesStartIcon = (currentElement, personId, editorName, chapterId, updateMoveEdit, editLanguageId) => {
  let hasMoveEndPending = document.getElementsByClassName('MoveEnd')
  let hasMoveTargetPending = document.getElementsByClassName('MoveTarget')
  if (currentElement && currentElement.nodeName === 'SPAN' && hasMoveEndPending.length === 0 && hasMoveTargetPending.length === 0) {
    let paragraph = currentElement.parentElement
    let loop = 0
    while (!(paragraph && paragraph.nodeName === 'P') && loop < 10) {
      paragraph = paragraph.parentElement
      loop++
    }
    updateMoveEdit('ChoseStartElement', currentElement.id, editLanguageId)
    removeMoveSentencesStartIcons()
    setMoveSentencesEndIcons(personId, editorName, chapterId, updateMoveEdit, editLanguageId, currentElement.id)
    setMoveSentencesEditorIcon('start', personId, editorName, chapterId, paragraph, currentElement)
  }
}

export const setChangeStyleStartIcon = (currentElement, personId, editorName, chapterId, updateChangeStyleEdit, editLanguageId) => {
  let hasChangeStyleEndPending = document.getElementsByClassName('ChangeStyleEnd')
  if (currentElement && currentElement.nodeName === 'SPAN' && hasChangeStyleEndPending.length === 0) {
    let paragraph = currentElement.parentElement
    let loop = 0
    while (!(paragraph && (paragraph.nodeName === 'P' || paragraph.nodeName === 'LI')) && loop < 10) {
      paragraph = paragraph.parentElement
      loop++
    }
    updateChangeStyleEdit('ChoseStartElement', currentElement.id, editLanguageId)
    setChangeStyleEndIcons(personId, editorName, chapterId, updateChangeStyleEdit, editLanguageId, currentElement.id)
    setChangeStyleEditorIcon('start', personId, editorName, chapterId, paragraph, currentElement)
  }
}

export const getPrecedingElementId = (startElementId) => {
  let result = ""
  let startElement = document.querySelector(`span[id="${startElementId}"][data-type='TEXT']`)
  if (startElement) {
    let previousElementSibling = startElement.previousElementSibling
    let loop = 0
    while (previousElementSibling && !(previousElementSibling && previousElementSibling.nodeName === 'SPAN' && previousElementSibling.id) && loop < 5) {
      previousElementSibling = previousElementSibling.previousElementSibling
      loop++
    }
    if (previousElementSibling && previousElementSibling.id) return previousElementSibling.id
    if (!result && startElement.parentElement && startElement.parentElement.id) return startElement.parentElement.id
    if (!result || !startElement.parentElement.id) return 0
  }
}

export const adjustParagraphForAuthor = (paragraph, hasShiftKey) => {
  //This is strongly assumed to be the author. The text-indent is the first setting for a TAB. Then margin-left will be incremented by 36pt for every tab thereafter.
  //If the shiftKey is pressed with the TAB
  //  if there is margin-left, take 36 away
  //  else if there is text-indent set to 36pt, take it away
  //  else do nothing
  //  end if
  //else
  //  if text-indent is empty, add 36pt
  //  else add to margin-left (empty or not)
  //  end if
  //end if
  if (!paragraph) return
  if (hasShiftKey) {
    if (paragraph.style['margin-left'] !== "" && paragraph.style['margin-left'] !== "0pt") {
      let marginLeft = paragraph.style['margin-left']
      marginLeft = marginLeft.match(/\d+/)
      marginLeft = String(Number(marginLeft) + Number(addMarginLeft * -1))
      paragraph.style['margin-left'] = marginLeft + 'pt'
    } else if (paragraph.style['text-indent'] === "36pt") {
      paragraph.style['text-indent'] = "0pt"
    }
  } else {
    if (paragraph.style['text-indent'] === "" || paragraph.style['text-indent'] === "0pt") {
      paragraph.style['text-indent'] = "36pt"
    } else {
      let marginLeft = paragraph.style['margin-left']
      marginLeft = marginLeft.match(/\d+/)
      marginLeft = String(Number(marginLeft) + Number(hasShiftKey ? addMarginLeft * -1 : addMarginLeft))
      paragraph.style['margin-left'] = marginLeft + 'pt'
    }
  }
  let firstPenspringChild = paragraph.firstChild
  let loop = 1
  while (!(firstPenspringChild && firstPenspringChild.id && firstPenspringChild.nodeName === 'SPAN') && loop < 5) {
    firstPenspringChild = paragraph.children[loop]
    loop++
  }
  return firstPenspringChild && firstPenspringChild.id && firstPenspringChild.nodeName === 'SPAN' ? firstPenspringChild : ''
}

export const ensureChosenElementNotTop = (element, chosenSegment) => {
  if ((element.id === "1" && element.dataset.mainBodyTag) || element.id === "editorDiv") {
    let elementData = chosenSegment[chosenSegment.length - 1]
    if (elementData) {
      const resetElement = document.querySelector(`span[id="${elementData.id}"][data-type='TEXT']`)
      return resetElement
    }
  }
  return element
}

export const setAsParagraphElement = (element) => {
  if (element) {
    let paragraph = element.nodeName === 'SPAN' ? element.parentElement : element
    let loop = 0
    while (!(paragraph && paragraph.nodeName === 'P') && loop < 5) {
      paragraph = paragraph.parentElement
      loop++
    }
    return paragraph && paragraph.nodeName === 'P' ? paragraph : ''
  }
}

export const isListItemBlankContent = (listItem) => {
  let isNotEmptySpan = false
  for (let i = 0; i < listItem.children.length; i++) {
    if (listItem.children[i] && listItem.children[i].innerHTML) {
      const contents = listItem.children[i].innerHTML.replace(/\u00a0/g, "").replace(/\xA0/g, '').replace(/&nbsp;/g, '').replace(/&#xa0;/g, ' ').replace(/ /g, '')
      if (contents.length > 0) isNotEmptySpan = true
    }
  }
  return !isNotEmptySpan
}

export const getListItemText = (currentElement, segments) => {
  //For the ADDLISTITEM, the elementId is now the firstChild span elementId since any structure change around this edit could annihilate a listItem. But the span is going to be consistent. So we need to get the parentElement of the span which should be (most likely) the listItem.
  if (currentElement && currentElement.id) {
    const elementId = currentElement.id.indexOf('tabView') ? currentElement.id.replace('~tabView', '') : currentElement.id
    let text = ''
    let foundSegment = false
    let foundEnd = false
    segments && segments.length > 0 && segments.forEach(m => {
      if (!foundSegment) {
        if (m.elementId === Number(elementId)) {
          foundSegment = true
          text = m.text
        }
      } else if (!foundEnd) {
        if (m.type === 'TEXT') {
          text += '&nbsp;' + m.text
        } else {
          foundEnd = true
        }
      }
    })
    return cleanText(text)
  }
}

export const getNextAddListItemSequence = (previousSpan, prevEdit) => {
  if (prevEdit && prevEdit.addListItemSequence > 0) return prevEdit.addListItemSequence
  if (previousSpan && previousSpan.dataset.addListItemSequence > 0) return previousSpan.dataset.addListItemSequence
  const addListItems = document.querySelectorAll(`li[id="${previousSpan.id}"][data-type='ADDLISTITEM']`)
  return addListItems.length === 0 ? 1 : addListItems.length
}

export const getListItemFirstChildWithId = (listItem) => {
  let firstChildWithSpanId
  for (let i = 0; i < listItem.children.length; i++) {
    if (listItem.children[i] && listItem.children[i].id) {
      firstChildWithSpanId = listItem.children[i]
      break
    }
  }
  return firstChildWithSpanId
}

const hasTabToTakeAway = (tabParagraphOrList, personId) => {
  //This is for the edit-owner who would have the element styles changed already for any existing edits so we don't have to count these values against existing edits.
  const textIndent = tabParagraphOrList.style['text-indent'].match(/\d+/)
  const marginLeft = tabParagraphOrList.style['margin-left'].match(/\d+/)
  const tabsAvailable = Math.ceil(marginLeft / addMarginLeft)
  let tabElementCount = textIndent > 0 ? 1 : 0
  tabElementCount += tabsAvailable
  return tabElementCount
}

export const getFirstSpanFromParent = (parent) => {
  let firstSpan
  if (parent) {
    if (parent.nodeName === 'UL' || parent.nodeName === 'OL') {
      parent = parent.firstChild
    }
    if (parent && parent.children) {
      for (let i = 0; i < parent.children.length; i++) {
        if (!firstSpan && parent.children[i].nodeName === 'SPAN' && parent.children[i].id) firstSpan = parent.children[i]
      }
      //If we didn't find a span with an ID, then look for one without an Id (although this will cause us trouble when trying to anchor an edit to the first span)
      if (!firstSpan) {
        for (let i = 0; i < parent.children.length; i++) {
          if (!firstSpan && parent.children[i].nodeName === 'SPAN') firstSpan = parent.children[i]
        }
      }
      //If still nothing, then let's take the first text node (although this will cause us trouble when trying to anchor an edit to the first span)
      if (!firstSpan) {
        for (let i = 0; i < parent.children.length; i++) {
          if (!firstSpan && parent.children[i].nodeName === '#text') firstSpan = parent.children[i]
        }
      }
    }
  }
  return firstSpan
}

export const isListStart = (currentElement) => {
  if (currentElement) {
    let innerHTML = currentElement.innerHTML.replace('&nbsp;', '').replace(' ', '')
    return innerHTML === '1.' ? 'OL' : innerHTML === '*' ? 'UL' : ''
  }
}

export const isTextEqual = (textA, textB) => {
  let a = textA.trim()
  a = a.lastIndexOf('&nbsp') === a.length - 6 ? a.substring(0, a.length - 6) : a

  let b = textB.trim()
  b = b.lastIndexOf('&nbsp') === b.length - 6 ? b.substring(0, b.length - 6) : b

  return a === b

}

const setBackgroundColor = (span, edit, segment) => {
  let backgroundColor = backgroundColors.normal
  if (segment.textSource === 'TRANSLATEDSEGMENT') {
    backgroundColor = backgroundColors.translatedFinal
  } else if (edit && edit.type === 'TEXT') {
    backgroundColor = backgroundColors.editPending
  } else if (span.style.backgroundColor === backgroundColors.editPending) {
    backgroundColor = backgroundColors.editPending
  }
  return backgroundColor
}

export const isCursorAtStartOrEnd = (element) => {
  let el = element.cloneNode(true)
  el.innerHTML = String(el.innerHTML).trim() //This is necessary because we put in an extra space for display of all span and then take it out when saving to the database or when recording an edit. Otherwise, we will not find that we have come to the end of a sentence of the last sentence in a paragraph when the editor is using the right arrow to move to the next sentence (which would be the first sentence of the next paragraph).
  let atStart = false, atEnd = false;
  let selRange, testRange;
  if (window.getSelection) {
    let sel = window.getSelection();
    if (sel.rangeCount) {
      selRange = sel.getRangeAt(0);
      testRange = selRange.cloneRange();

      testRange.selectNodeContents(el);
      testRange.setEnd(selRange.startContainer, selRange.startOffset);
      atStart = (testRange.toString() === "");

      testRange.selectNodeContents(el);
      testRange.setStart(selRange.endContainer, selRange.endOffset);
      if (el.innerText.length <= selRange.endOffset) atEnd = true
      //atEnd = (testRange.toString() === "");
    }
  } else if (document.selection && document.selection.type !== "Control") {
    selRange = document.selection.createRange();
    testRange = selRange.duplicate();

    testRange.moveToElementText(el);
    testRange.setEndPoint("EndToStart", selRange);
    atStart = (testRange.text === "");

    testRange.moveToElementText(el);
    testRange.setEndPoint("StartToEnd", selRange);
    atEnd = (testRange.text === "");
  }

  return { atStart, atEnd };
}

const fillInEmptyParagraphsWithSpan = (divDisplayId) => {
  const mainElement = getMainElement()
  let paragraphs = mainElement ? mainElement.children : []
  for(let i = 0; i < paragraphs.length; i++) {
    if (paragraphs[i].innerHTML === "") {
      let spanSpace = document.createTextNode("\u00A0")
      paragraphs[i].append(spanSpace)
    }
  }
}

export const checkSentenceForChange = ({ spanElement, segments, edits, editorName, isAuthor, addOrUpdateEdit, personId, workSummary, editLanguageId, updateChangeCounts }) => {
  let prevEdit = edits && edits.length > 0 && edits.filter(m => Number(m.editSegmentId) === Number(spanElement.editSegmentId) 
    && ((m.type === 'TEXT' && spanElement.dataset.type === 'TEXT') 
      || (m.type === 'ADDSENTENCE' && spanElement.dataset.type === 'ADDSENTENCE') 
      || (m.type === 'ADDLISTITEM' && spanElement.dataset.type === 'ADDLISTITEM' && m.addListItemSequence === Number(spanElement.dataset.addListItemSequence))
      || (m.type === 'ADDPARAGRAPHSENTENCE' && spanElement.dataset.type === 'ADDPARAGRAPHSENTENCE' && Number(m.subSequence) === Number(spanElement.dataset.subsequence))))[0]

    //If this is a new sentence from the editor by ADDSENTENCE or ADDPARAGRAPHSENTENCE, then prevSegment is not going to find anything.
  let prevSegment = segments && segments.length > 0 && segments.filter(m => m.elementId === Number(spanElement.id) && ((m.type === 'TEXT' && spanElement.dataset.type === 'TEXT') || (m.type === 'ADDLISTITEM' && spanElement.dataset.type.indexOf('ADDLISTITEM') > -1)))[0]
  if (!prevSegment) {
    prevSegment = { text: prevEdit && prevEdit.text }
  }

    //Take off the ending space that is added on when the page is built
  let prevSpanHtml = spanElement.innerHTML
  if (prevSpanHtml !== '&nbsp;____&nbsp;' && prevSpanHtml.length > 7 && (prevSpanHtml.indexOf('&nbsp; ') === 0 || prevSpanHtml.indexOf('&nbsp;') === 0) && spanElement.dataset.type !== 'ADDTAB') {
    prevSpanHtml = prevSpanHtml.substring(prevSpanHtml.indexOf('&nbsp; ') === 0 ? 7 : 6) //Cut off the font 7 characters
    spanElement.innerHTML = prevSpanHtml
  }
  if ((spanElement.dataset.type === 'TEXT' || spanElement.dataset.type === 'ADDSENTENCE' || spanElement.dataset.type === 'ADDPARAGRAPHSENTENCE') 
      && (prevSpanHtml.lastIndexOf('&nbsp;') === prevSpanHtml.length - 6 || prevSpanHtml.lastIndexOf("\u00A0") === prevSpanHtml.length - 6)) { //We don't want to do this to the ADDLISTITEM_TEXT since the originating text does contain underlines and bookend &nbsp;-s
    prevSpanHtml = prevSpanHtml.substring(0, prevSpanHtml.length - 6)
  }
  let prevHtmlWithoutNBSP = prevSpanHtml && prevSpanHtml.length > 0 && prevSpanHtml.replace(/&nbsp;/g, '').replace(/&#xa0;/g, '').trim()
  let prevSegmentTextWithoutNBSP = prevSegment && prevSegment.text && prevSegment.text.length > 0 && prevSegment.text.replace(/&nbsp;/g, '').replace(/&#xa0;/g, '').trim()

  if (spanElement.dataset.type && spanElement.dataset.type.indexOf('ADDLISTITEM') && spanElement.dataset.editSegmentId) { //Is there really an editSegmentId in the dataset? I don't know if we are focusing much on that record type.
    prevEdit = edits && edits.length > 0 && edits.filter(m => m.editSegmentId === spanElement.dataset.editSegmentId)[0]
  }
  if (!isAuthor && (spanElement.dataset.type.indexOf('TEXT') > -1 || spanElement.dataset.type.indexOf('ADDLISTITEM') > -1 || spanElement.dataset.type.indexOf('ADDSENTENCE') > -1 || spanElement.dataset.type === 'ADDPARAGRAPHSENTENCE')
    && prevSegment && prevHtmlWithoutNBSP !== prevSegmentTextWithoutNBSP) { //If it is ADDLISTITEM_TEXT, then just update the edit. Otherwise, check the other comparisons before saving for a TEXT edit.
    // && prevSpanHtml !== prevSegment.text  This was a bad comparison because after all of the cleaning above, prevSegment.text had a &nbsp; at the end but prevSpanHtml had been cleaned out. That was the only difference.

    addOrUpdateEdit({
      editSegmentId: (prevEdit && prevEdit.editSegmentId) || 0,
      editSegmentTypeId: 0, //This is going to be filled in by the backend with the type given below.
      firstName: editorName && editorName.firstName,
      lastName: editorName && editorName.lastName,
      personId,
      chapterId: workSummary ? workSummary.chapterId_current && workSummary.chapterId_current !== guidEmpty ? workSummary.chapterId_current : workSummary.chapterOptions && workSummary.chapterOptions[0].chapterId : guidEmpty,
      workId: workSummary && workSummary.workId,
      elementId: spanElement.id,
      languageId: editLanguageId,
      type: spanElement.dataset.type.indexOf('ADDLISTITEM') > -1 ? 'ADDLISTITEM' : spanElement.dataset.type,  //This used to be 'TEXT' but now that it is used for the ADDLISTITEM text, we'll make this dynamic and hope that it is clean logic.
      text: spanElement.innerHTML,
      authorTextSnapshot: (prevEdit && prevEdit.authorTextSnapshot) || (prevSegment && prevSegment.text) || '',
      addListItemSequence: getNextAddListItemSequence(spanElement, prevEdit),
      subSequence: spanElement.dataset.subsequence,
      comment: (prevEdit && prevEdit.comment) || '',
      updateTextAddParagraphSentence: spanElement.dataset.type === 'ADDPARAGRAPHSENTENCE' 
    })
    spanElement.innerHTML = spanElement.innerHTML + '&nbsp;' //This is to add the floating end-space which is taken off when the segments are saved to the database. This is for normal reading of sentences spaces by a single space.
    return true
    //Do not let the segments be refreshed on the page or the focus into the previous span with the move of a left arrove from the beginning of an editor's sentence will not happen.
    //We will force the new edit into the edits values (which means that edits can't be a constant) which is also saved off to the database but it just isn't being called back in which reset the page too much.
    // const currentSegment = segments.filter(m => String(m.elementId) === String(spanElement.id) && m.type === 'TEXT')[0]
    // const chapterId = workSummary && workSummary.chapterId_current && workSummary.chapterId_current !== guidEmpty
    //   ? workSummary.chapterId_current
    //   : workSummary.chapterOptions[0].chapterId

    // const editNew = {
    //   addListItemSequence: 0,
    //   authorTextSnapshot: currentSegment && currentSegment.text,
    //   chapterId,
    //   workId: workSummary && workSummary.workId,
    //   comment: "",
    //   editLanguageId: 0,
    //   editSegmentId: currentSegment && currentSegment.workSegmentId,
    //   editSegmentTypeId: 210,
    //   editVotes: [],
    //   elementId: spanElement.id,
    //   //entryDate:"", 
    //   firstName: editorName && editorName.firstName,
    //   lastName: editorName && editorName.lastName,
    //   isAuthor: false,
    //   languageId: 1,
    //   personId: personId,
    //   text: spanElement.innerHTML,
    //   type: "TEXT",
    //   workSegmentTypeId: 4, //Text
    // }
    // let newEdits = edits.filter(m => String(m.elementId) !== String(spanElement.id))
    // newEdits = newEdits.length > 0 ? newEdits.concat(editNew) : [editNew]
    // return newEdits
  }
}

export const setIntoFirstSpan = (element) => {
  //If we are in the editorDiv directly or the main div or the element parameter is null, then find the mainDiv followed by the first paragraph followed by the first span.
  //Or if we are in a paragraph element, then move the cursor into the first span (if there is one)
  if (element && element.nodeName === 'SPAN' && element.id) {
    return
  } else if (!element || element.id === 'editorDiv' || element.id === '1') {
    const mainDiv = document.querySelector(`[id="1"][data-main-body-tag="yes"]`)
    if (mainDiv) {
      const firstParagraph = mainDiv.firstElementChild
      if (firstParagraph) {
        const firstSpan = firstParagraph.firstElementChild
        if (firstSpan) setCursorPosition(firstSpan, firstSpan, 0, 0)
      } else {
        setCursorPosition(firstParagraph, firstParagraph, 0, 0)
      }
    } else {
      setCursorPosition(mainDiv, mainDiv, 0, 0) //I don't know if this is going to allow the sentences to be split up to become valid penspring segments.
    }
  } else if (element && element.nodeName === 'P') {
    const firstSpan = element.firstElementChild //This is strongly implying that the next element is a valid penspring segments. We'll need to get more aggressive if we flounder to find the next penspring span.
    if (firstSpan) setCursorPosition(firstSpan, firstSpan, 0, 0)
  }
}

const cleanExtraEndSpans = (innerHTML) => {
  const beginSpanCount = innerHTML.toLowerCase().split("<span>").length - 1
  const endSpanCount = innerHTML.toLowerCase().split("</span>").length - 1
  const difference = endSpanCount - beginSpanCount
  innerHTML = innerHTML.trimRight()
  for(let i=0; i < difference; i++) {
    innerHTML = innerHTML.substring(0, innerHTML.toLowerCase().lastIndexOf('</span>'))
  }
  return innerHTML
}

const getElementSubSequence = (element, addNumber) => {
  let subSequence = element && element.dataset && element.dataset.subsequence ? element.dataset.subsequence : 0
  if (addNumber > 0) subSequence = Number(subSequence) + Number(addNumber) * 1 //The *1 helps the addition be a number rather than a string like 2 + 1 = 21. Wrong.
  return subSequence
}

const getNextSpan = (element) => {
  let parent = element.parentElement //This is assuming a very simple structure of one-layer of spans to a paragraph parent.
  let foundElement = false
  let nextSpan
  let loop = 0
  
  while (!nextSpan && parent && parent.nodeName === 'P' && parent.id && loop < 5) {
    for (let i = 0; i < parent.children.length; i++) {
      if (!foundElement) {
        if (parent.children[i] && parent.children[i].nodeName === 'SPAN' && parent.children[i].id && parent.children[i].id === element.id) {
          foundElement = true
        }
      } else if (parent.children[i].nodeName === 'SPAN' && parent.children[i].id) {
        nextSpan = parent.children[i]
      }
    }
    loop++
    parent = parent.nextSibling
  }
  return nextSpan
}

export const getPrevSpan = (element) => {
  let parent = element.parentElement
  let prevSpan
  let foundCurrentElement = false
  let prevParentLastChild 

  for (let i = 0; i < parent.children.length; i++) {
    if (!foundCurrentElement && parent.children[i] && parent.children[i].nodeName === 'SPAN' && parent.children[i].id) {
      if (parent.children[i] === element) {
        foundCurrentElement = true
      } else {
        prevSpan = parent.children[i]
      }
    }
  }

  if (prevSpan && prevSpan.nodeName === 'SPAN' && prevSpan.id) return prevSpan

  let parentPrevSibling = parent.previousSibling
  if (parentPrevSibling) {
    for (let i = 0; i < parentPrevSibling.children.length; i++) {
      //We will just keep assigning the prevParentLastChild while there is a valid penspring span node. That will tell us the last one by the time the loop is done.
      if (parentPrevSibling.children[i] && parentPrevSibling.children[i].nodeName === 'SPAN' && parentPrevSibling.children[i].id) {
        prevParentLastChild = parentPrevSibling.children[i]
      }
    }
  }
  return prevParentLastChild
}

const isLastChildOfParagraph = (element) => {
  let nextSibling = element.nextSibling
  if (!nextSibling) return true
  else if (nextSibling && nextSibling.nodeName === 'SPAN' && nextSibling.id) return false
}

const insertAfterElementOrParagraphEnd = (element, existingElement, existingParent) => {
  if (element) {
    let foundNextSibling = false
    let nextSibling = existingElement.nextSibling
    let loop = 0
    if (nextSibling && (nextSibling.nodeName === 'SPAN' || nextSibling.nodeName === 'IMG') && nextSibling.id) foundNextSibling = true
    while (!foundNextSibling && !(nextSibling && (nextSibling.nodeName === 'SPAN' || nextSibling.nodeName === 'IMG') && nextSibling.id) && loop < 10) {
      nextSibling = nextSibling && nextSibling.nextSibling
      if (nextSibling && (nextSibling.nodeName === 'SPAN' || nextSibling.nodeName === 'IMG') && nextSibling.id) foundNextSibling = true
      loop++
    }
    if (foundNextSibling) {
      existingParent.insertBefore(element, nextSibling)
    }
    else existingParent.append(element)
  }
}

const setCursorPositionByRecallAddParagraphSentence = (elementId, subSequence) => {
  const newElement = document.querySelector(`span[id="${elementId}"][data-subsequence="${subSequence}"][data-type="ADDPARAGRAPHSENTENCE"]`)
  if (newElement) {
    newElement.contentEditable = 'true'
    setCursorPosition(newElement, newElement, 0, 0)
  }
}

export const setCursorPositionByRecallAddListItem = (elementId, addListItemSequence, handleSetChosenSegment) => {
  const newElement = document.querySelector(`span[id="${elementId}"][data-add-list-item-sequence="${addListItemSequence}"][data-type="ADDLISTITEM"]`)
  if (newElement) {
    newElement.contentEditable = 'true'
    setCursorPosition(newElement, newElement, 0, 0)
    handleSetChosenSegment(newElement)
  }
}

export const setCursorPositionByRecall = (elementId, dataType) => {
  const newElement = document.querySelector(`span[id="${elementId}"][data-type="${dataType}"]`)
  if (newElement) {
    newElement.contentEditable = 'true'
    setCursorPosition(newElement, newElement, 0, 0)
  }
}

const getPreviousSpan = (element) => {
  if (element) {
    let previousSpan = element.previousSibling
    let loop = 0
    while (previousSpan && !(previousSpan.nodeName === 'SPAN' && previousSpan.id) && loop < 7) {
      previousSpan = previousSpan && previousSpan.previousSibling
      loop++
    }
    if (!previousSpan) {
      const parentParagraph = element.parentElement
      const previousParagraph = parentParagraph.previousSibling
      //Get the last span in the paragraph. We are strongly assuming that there is at least one valid span segment with a penspring id in that paragraph.
      for (let i = previousParagraph.children.length-1; i >= 0; i--) {
        if (previousParagraph.children[i].nodeName === 'SPAN' && previousParagraph.children[i].id) {
          previousSpan = previousParagraph.children[i]
          break;
        }
      }
    }
    return previousSpan
  }
}

const getFirstValidSpan = (paragraphOrListElement) => {
  const span = paragraphOrListElement.firstChild
  if (span && span.nodeName === 'SPAN' && span.id) return span
  for (let i = 0; i < paragraphOrListElement.children.length; i++) {
    const child = paragraphOrListElement.children[i]
    if (child && child.nodeName === 'SPAN' && child.id) return child
  }
}

const setClickIntoAddListItem = ({
    element, 
    isAuthor,
    handleSetChosenSegment,
    handleSetCurrentElement,
    setChosenAddSentenceEdit,
    savedCursorPosition,
    isEditorDivView,
    setContextMenuIfMobile
  }) => {

  if (element.dataset.type === 'ADDLISTITEM' || element.dataset.type === 'ADDLISTITEM_TEXT') {
    if (element.class === 'LISTITEMQUESTIONPLUS') return false
    if (element.nodeName === 'IMG') {
      let elementAddList = document.querySelector(`[id="${element.id}"][data-type="ADDLISTITEM"][data-add-list-item-sequence="${element.dataset.addListItemSequence}"]`)
      handleSetChosenSegment(elementAddList)
      handleSetCurrentElement(elementAddList)
      setChosenAddSentenceEdit(elementAddList.dataset.spanId) //Help ToDo: This might be wrong here. Is htere an AddListItem chosen function that should be called instead. This whole addlist function may have been copied from AddSentence function and not updated properly.

      let cleanElementId = elementAddList.id.indexOf('~tabView') > -1 ? elementAddList.id.substring(0, elementAddList.id.length - 8) : elementAddList.id
      let img = document.querySelector(`img[id="${cleanElementId}"][data-add-list-item-sequence="${element.dataset.addListItemSequence}"][class='']`)
      if (img) {
        //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
        img.style.backgroundColor = backgroundColors.currentFocus
      }
      img = document.querySelector(`img[id="${cleanElementId}~tabView"][data-add-list-item-sequence="${element.dataset.addListItemSequence}"][class='']`)
      if (img) {
        //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
        img.style.backgroundColor = backgroundColors.currentFocus
      }
    } else if (element.nodeName === 'SPAN') {
      if (element.innerHTML === '&nbsp;____&nbsp;') {
        savedCursorPosition = saveCursorLocation(document.getElementById('editorDiv'))
        let elementCursorOffset = getElementCursorOffset(document.getElementById('editorDiv'))
        element.innerHTML = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
        let newCursorPosition = { ...savedCursorPosition }
        newCursorPosition.start = newCursorPosition.start - elementCursorOffset + 1
        restoreCursorLocation(document.getElementById('editorDiv'), newCursorPosition)
        element.focus()
      }
      if (element.dataset.type !== 'LISTLEVELMINUS' && element.dataset.type !== 'LISTLEVELPLUS' && element.dataset.type !== 'ADDTAB' && element.dataset.type !== 'DELETETAB') {
        if (!isAuthor && element.nodeName === 'SPAN') element.contentEditable = 'true'
      }
      handleSetChosenSegment(element)
      handleSetCurrentElement(element)
      setChosenAddSentenceEdit(element.id) //Help ToDo: This might be wrong here. Is htere an AddListItem chosen function that should be called instead. This whole addlist function may have been copied from AddSentence function and not updated properly.

      let img = document.querySelector(`img[data-span-id="${element.id}"][class="ListItemPlus"]`)
      if (img) {
        //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
        img.style.backgroundColor = backgroundColors.currentFocus
      }
      img = document.querySelector(`img[data-span-id="${element.id}"][class="ListItemPlus~tabView"]`)
      if (img) {
        //img.height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
        img.style.backgroundColor = backgroundColors.currentFocus
      }
    }

    let elements = document.getElementsByClassName(isEditorDivView ? 'ListItemPlus' : 'ListItemPlus~tabView')
    for (let i = 0; i < elements.length; i++) {
      if (Number(elements[i].dataset.spanId) === Number(element.id)) {
        //elements[i].height = 30  //We are going to keep the interface better sophisticated by not oversizing these icons. Just highlight.
        elements[i].style.backgroundColor = backgroundColors.currentFocus
      }
    }
    setContextMenuIfMobile()
    return { currentSpan: '', returnIsTextChanged: false }

  }
}

export const getAddListItemNextParentElement = (element) => {
  //This is a span element. 
  //we need to use the nextSibling of the parentElement(this ADDLISTITEM is attached to a span.We need to go to the LI(listitem) above it and then get the nextSibling.If there
  //  isn't a nextSibling, then the parent.NextSibling. If that doesn't exist, we need the grandparent.NextSibling. If that doesn't exist, we need the greatGrandparent.NextSibling.
  //  Then we will get the worksequence from that and put our new segment BEFORE that nextSibling element.
  if (element) {
    const listItem = element.parentElement
    const listItemNextSibling = listItem.nextSibling
    if (listItemNextSibling && listItemNextSibling.id) return listItemNextSibling.id
    const parentList = listItem.parentElement
    const parentListNextSibling = parentList.nextSibling
    if (parentListNextSibling && parentListNextSibling.id) return parentListNextSibling.id
    const grandparentList = parentList.parentElement
    const grandparentListNextSibling = grandparentList.nextSibling
    if (grandparentListNextSibling && grandparentListNextSibling.id) return grandparentListNextSibling.id
    const greatGrandparentList = grandparentList.parentList
    const greatGrandparentListNextSibling = greatGrandparentList.nextSibling
    if (greatGrandparentListNextSibling && greatGrandparentListNextSibling.id) return greatGrandparentListNextSibling.id
  }
}

export const setChangeStylesForEditor_DOM = (divDisplayId, segments, textStyleChangeEntries, changeStyleEntireDoc, changeStyleEditArray, chapterId) => {
  //0. Set the newStyleInline text from the textStyleChangeEntries records
  //1. If this is changeStyleEntireDoc,
  //2.    Use the same code when uploading a document and setting all aspects of the font, size, text-indent, and the rest.
  //3. Else if there are textStyleChangeEntries records
  //4.    Loop through the changeStyleEditArray (subtracting the spans as they are processed in the same paragraph
  //5.        If the first sentence is not at the beginning of the paragraph (firstChild) or if the last sentence is in the same paragraph as the first sentence but is not the last segment in the paragraph
  //6.            Update the spans styleInline until the next paragraph (which we can tell from the workSegment records that share the same parentElementId - paragraph.id)
  //                 (but watch for Ol, UL, and LI and set the outline version of the styleInine)
  //7.        else, 
  //8.            Update the paragraph styleInline (but watch for Ol, UL, and LI and set the outline version of the styleInine)
  //9.            Remove any Span styleInline in between
  //10.        end if
  //11.    End of loop
  //12. End if

  const ifTabView = divDisplayId === 'tabView' ? '~tabView' : ''

  //0. Set the newStyleInline text from the EditSegmentChangeStyleEntry records
  var newStyleInlineAll = ''
  var newStyleInlineFontOnly = ''
  var newStyleInlineParagraphOnly = ''

  textStyleChangeEntries?.forEach(entry => {
    if (entry.code === "fontFamily") {
      newStyleInlineAll += "font-family: '" + entry.value + "'; "
      newStyleInlineFontOnly += "font-family: '" + entry.value + "'; "
    } else if (entry.code === "fontSize") {
      newStyleInlineAll += "font-size: " + entry.value + "px; "
      newStyleInlineFontOnly += "font-size: " + entry.value + "px; "
    } else if (entry.code === "lineHeight") {
      newStyleInlineAll += "line-height: " + entry.value + "; "
      newStyleInlineParagraphOnly += "line-height: " + entry.value + "; "
    } else if (entry.code === "textAlign") {
      newStyleInlineAll += "text-align: " + entry.value + "; "
      newStyleInlineParagraphOnly += "text-align: " + entry.value + "; "
    } else if (entry.code === "marginBottom") {
      newStyleInlineAll += "margin-bottom: " + Number(entry.value/2) + "px; margin-top: " + Number(entry.value/2 ) + "px; "
      newStyleInlineParagraphOnly += "margin-bottom: " + Number(entry.value / 2) + "px; margin-top: " + Number(entry.value / 2) + "px; "
    } else if (entry.code === "textIndent") {
      newStyleInlineAll += "text-indent: " + Math.round(entry.value * 10 / 22) + "em; "
      newStyleInlineParagraphOnly += "text-indent: " + Math.round(entry.value * 10 / 22) + "em; "
    }
  })
  const styleKeyValues = textStyleChangeEntries.reduce((obj, pair) => {
    obj[pair.code] = pair.value;
    return obj;
  }, {})
  
  if (segments && textStyleChangeEntries && textStyleChangeEntries?.length > 0) {
    //1. If this is ChangeStyleEntireDoc,
    if (changeStyleEntireDoc) {
      //2.    Use the same code when uploading a document and setting all aspects of the font, size, text-indent, and the rest.
      updateChapterWithTextStyle_DOM(textStyleChangeEntries)
    
    //3. Else if there are EditSegmentChangeStyleEntry records
    } else {
      var elementIds = Array.isArray(changeStyleEditArray) ? changeStyleEditArray.map(Number) : changeStyleEditArray.split(",").map(Number)
      //4.    Loop through the ChangeStyleEditArray (subtracting the spans as they are processed in the same paragraph
      let loop = 0
      for (var i = 0; i < elementIds.length && loop < 50;) { //Notice that we are not encrementing the i since we will do that in the loop according to how many spans we find.
        var firstElement = document.querySelector(`span[id="${elementIds[i] + ifTabView}"]`) //segments.filter(x => x.elementId === elementIds[i])[0]
        var lastElement = document.querySelector(`span[id="${elementIds[elementIds.length - 1] + ifTabView}"]`)  //segments.filter(x => x.elementId === elementIds[elementIds.length - 1])[0]
        if (firstElement && lastElement) {
          var paragraph = firstElement.parentElement // segments.filter(x => x.elementId === firstElement.parentElementId)[0]
          if (paragraph) {
            var sentencesBetweenParagraphAndFirst = hasSentencesBetweenParagraphAndFirst_DOM(paragraph, firstElement) //segments.filter(x => x.parentElementId == paragraph.ElementId && x.sequence > paragraph.sequence && x.sequence < firstElement.sequence)
            var sentencesBetweenLastChildAndParagraph = hasSentencesBetweenLastChildAndParagraph_DOM(paragraph, lastElement) //segments.filter(x => x.parentElementId == paragraph.ElementId && x.sequence > paragraph.sequence && x.sequence < lastElement.sequence && x.parentElementId == lastElement.parentElementId)
            var updateSegments = segments.filter(m => m.chapterId == chapterId && m.parentElementId == Number(paragraph.id.replace('~tabView', '')) && elementIds.indexOf(m.elementId) > -1)

            //5.        If the first sentence is not at the beginning of the paragraph (firstChild) or if the last sentence is in the same paragraph as the first sentence but is not the last segment in the paragraph
            if (sentencesBetweenParagraphAndFirst || (lastElement.parentElement == paragraph && sentencesBetweenLastChildAndParagraph)) {
            //6.            Update the spans styleInline until the next paragraph (which we can tell from the workSegment records that share the same parentElementId - paragraph.id)
            //                 (but watch for Ol, UL, and LI and set the outline version of the styleInine)
              if (paragraph.nodeName === 'P') {
                paragraph.setAttribute('style', newStyleInlineParagraphOnly)
              } 

              updateSegments?.forEach(segment => {
                var span = document.querySelector(`span[id="${segment.elementId + ifTabView}"]`)
                if (span) {
                  span.style.fontFamily = styleKeyValues['fontFamily']
                  span.style.fontSize = styleKeyValues['fontSize'] + "px"
                }
              })
            }
            else {
              //8.            Update the paragraph styleInline (but watch for Ol, UL, and LI and set the outline version of the styleInine)
              if (paragraph.nodeName === 'P') {
                paragraph.setAttribute('style', newStyleInlineAll)

                //9.            Remove any Span styleInline in between
                updateSegments?.forEach(segment => {
                  var span = document.querySelector(`span[id="${segment.elementId + ifTabView}"]`)
                  if (span) span.setAttribute('style', '')
                })
              } else { //It is OL, UL or LI
                updateSegments?.forEach(segment => {
                  var span = document.querySelector(`span[id="${segment.elementId + ifTabView}"]`)
                  if (span) {
                    span.style.fontFamily = styleKeyValues['fontFamily']
                    span.style.fontSize = styleKeyValues['fontSize'] + "px"
                  }
                })
              }
            }
            i += updateSegments.length;
          }
          //10.        end if
        }
        loop++
        //11.    End of loop
      }
      //12. End if
    }
  }
}

export const setChangeStylesForEditor_Segments = (segments, textStyleChangeEntries, changeStyleEntireDoc, changeStyleEditArray, chapterId) => {
  //0. For the author: This is going to create a new set of segments to be rebuilt on the page. But not saved to the database until the user wants to save it, so that the UNDO will reverse this change style settings.
  //0. Set the newStyleInline text from the textStyleChangeEntries records
  //1. If this is changeStyleEntireDoc,
  //2.    Use the same code when uploading a document and setting all aspects of the font, size, text-indent, and the rest.
  //3. Else if there are textStyleChangeEntries records
  //4.    Loop through the changeStyleEditArray (subtracting the spans as they are processed in the same paragraph
  //5.        If the first sentence is not at the beginning of the paragraph (firstChild) or if the last sentence is in the same paragraph as the first sentence but is not the last segment in the paragraph
  //6.            Update the spans styleInline until the next paragraph (which we can tell from the workSegment records that share the same parentElementId - paragraph.id)
  //7.        else, 
  //8.            Update the paragraph styleInline
  //9.            Remove any Span styleInline in between
  //10.        end if
  //11.    End of loop
  //12. End if

  //In our case with segments for the author, this should never be tabView. We are temporarily adjusting segments until the author saves her changes to the database during her session.
  //const ifTabView = divDisplayId === 'tabView' ? '~tabView' : ''

  //0. Set the newStyleInline text from the EditSegmentChangeStyleEntry records
  let segmentsCopy = [...segments]
  var newStyleInlineAll = ''
  var newStyleInlineFontOnly = ''
  var newStyleInlineParagraphOnly = ''

  textStyleChangeEntries?.forEach(entry => {
    if (entry.code === "fontFamily") {
      newStyleInlineAll += "font-family: '" + entry.value + "'; "
      newStyleInlineFontOnly += "font-family: '" + entry.value + "'; "
    } else if (entry.code === "fontSize") {
      newStyleInlineAll += "font-size: " + entry.value + "px; "
      newStyleInlineFontOnly += "font-size: " + entry.value + "px; "
    } else if (entry.code === "lineHeight") {
      newStyleInlineAll += "line-height: " + entry.value + "; "
      newStyleInlineParagraphOnly += "line-height: " + entry.value + "; "
    } else if (entry.code === "textAlign") {
      newStyleInlineAll += "text-align: " + entry.value + "; "
      newStyleInlineParagraphOnly += "text-align: " + entry.value + "; "
    } else if (entry.code === "marginBottom") {
      newStyleInlineAll += "margin-bottom: " + Number(entry.value / 2) + "px; margin-top: " + Number(entry.value / 2) + "px; "
      newStyleInlineParagraphOnly += "margin-bottom: " + Number(entry.value / 2) + "px; margin-top: " + Number(entry.value / 2) + "px; "
    } else if (entry.code === "textIndent") {
      newStyleInlineAll += "text-indent: " + Math.round(entry.value * 10 / 22) + "em; "
      newStyleInlineParagraphOnly += "text-indent: " + Math.round(entry.value * 10 / 22) + "em; "
    }
  })


  //3. Else if there are EditSegmentChangeStyleEntry records
  if (segments?.length > 0 && textStyleChangeEntries && textStyleChangeEntries?.length > 0) {
    //1. If this is ChangeStyleEntireDoc,
    if (changeStyleEntireDoc) {
      //2.    Use the same code when uploading a document and setting all aspects of the font, size, text-indent, and the rest.
      segmentsCopy = updateChapterWithTextStyle_Segments(textStyleChangeEntries, segments)
    }
    else {
      var elementIds = Array.isArray(changeStyleEditArray) ? changeStyleEditArray.map(Number) : changeStyleEditArray.split(",").map(Number)
      //4.    Loop through the ChangeStyleEditArray (subtracting the spans as they are processed in the same paragraph
      let loop = 0
        //&& loop < 50  We are going to ignore trying to avoid an infinite loop for the sake of letting the user affect as many sentences as they want.
      for (var i = 0; i < elementIds.length;) { //Notice that we are not encrementing the i since we will do that in the loop according to how many spans we find.
        var firstSegment = segments.filter(m => m.elementId === elementIds[i] && m.type === 'TEXT')[0]
        const paragraphSegment = getParagraphSegmentOfSpan(segments, firstSegment)
        var lastSegment = segments.filter(m => m.elementId === elementIds[elementIds.length - 1] && m.type === 'TEXT')[0]
        if (firstSegment && lastSegment) {
          if (paragraphSegment) {
            var sentencesBetweenParagraphAndFirst = hasSentencesBetweenParagraphAndFirst_Segments(segments, paragraphSegment, firstSegment)
            var sentencesBetweenLastChildAndParagraph = hasSentencesBetweenLastChildAndParagraph_Segments(segments, paragraphSegment, lastSegment)
            var updateSegments = segments.filter(m => m.chapterId == chapterId && m.parentElementId == paragraphSegment.elementId && elementIds.indexOf(m.elementId) > -1)

            //5.        If the first sentence is not at the beginning of the paragraph (firstChild) or if the last sentence is in the same paragraph as the first sentence but is not the last segment in the paragraph
            if (sentencesBetweenParagraphAndFirst || (lastSegment.parentElementId == paragraphSegment.elementId && sentencesBetweenLastChildAndParagraph)) {
              //6.            Update the spans styleInline until the next paragraph (which we can tell from the workSegment records that share the same parentElementId - paragraph.id)
              segmentsCopy[segmentsCopy.findIndex(m => m.elementId === paragraphSegment.elementId)].styleInline = newStyleInlineParagraphOnly

              updateSegments?.forEach(segment => {
                segmentsCopy[segmentsCopy.findIndex(m => m.elementId === segment.elementId)].styleInline = newStyleInlineFontOnly
              })
            }
            else {
              //8.            Update the paragraph styleInline
              segmentsCopy[segmentsCopy.findIndex(m => m.elementId === paragraphSegment.elementId)].styleInline = newStyleInlineAll

              //9.            Remove any Span styleInline in between
              updateSegments = updateSegments?.map(segment => {
                segmentsCopy[segmentsCopy.findIndex(m => m.elementId === segment.elementId)].styleInline = ''
              })
            }
            i += updateSegments.length;
          }
          //10.        end if
        }
        //loop++ We are going to ignore trying to avoid an infinite loop for the sake of letting the user affect as many sentences as they want.
        //11.    End of loop
      }
      //12. End if
    }
  }
  return segmentsCopy
}

const hasSentencesBetweenParagraphAndFirst_DOM = (paragraph, firstElement) => {
  if (paragraph.firstChild === firstElement) return false
  let hasChildBetween = false
  for (let i = 0; i < paragraph.children.length; i++) {
    if (paragraph.children[i].nodeName === 'SPAN' && paragraph.children[i].dataset.type === 'TEXT' && paragraph.children[i] !== firstElement) {
      return true
    }
  }
  return hasChildBetween
}

const hasSentencesBetweenParagraphAndFirst_Segments = (segments, paragraphSegment, firstElement) => {
  //Look upwards in the segments until you come to a paragraph that the segment belongs to.
  let currentIndex = segments.findIndex(m => m.elementId === firstElement.elementId && firstElement.type === 'TEXT')
  let foundParagraph
  let segmentCount = 0
  let loop = 0
  while (!foundParagraph && currentIndex >= 0 && loop < 100) {
    --currentIndex
    if (segments[currentIndex] && segments[currentIndex].type === 'PARAGRAPH' && segments[currentIndex].elementId === paragraphSegment.elementId) {
      foundParagraph = true
    } else {
      segmentCount++
    }
    loop++
  }
  return segmentCount
}

const hasSentencesBetweenLastChildAndParagraph_DOM = (paragraph, lastElement) => {
  let foundLastElement = false
  let hasChildBetween = false
  for (let i = 0; i < paragraph.children.length; i++) {
    if (!foundLastElement && paragraph.children[i].nodeName === 'SPAN' && paragraph.children[i].dataset.type === 'TEXT' && paragraph.children[i] === lastElement) {
      foundLastElement = true
    } else if (foundLastElement) {
      return true
    }
  }
  return hasChildBetween
}

const hasSentencesBetweenLastChildAndParagraph_Segments = (segments, paragraphSegment, lastElement) => {
  //Look upwards in the segments until you come to a paragraph that the segment belongs to.
  let currentIndex = segments.findIndex(m => m.elementId === lastElement.elementId && lastElement.type === 'TEXT' && lastElement.parentElementId === paragraphSegment.elementId)
  if (currentIndex === -1) return 0
  let segmentCount = 0
  let nextParagraph
  let loop = 0
  while (currentIndex < segments.length && !(nextParagraph && nextParagraph.type === 'PARAGRAPH') && loop < 100) {
    ++currentIndex
    if (segments[currentIndex] && segments[currentIndex].type === 'PARAGRAPH') {
      nextParagraph = segments[currentIndex]
    } else {
      segmentCount++
    }
    loop++
  }
  return segmentCount
}

export const updateChapterWithTextStyle_DOM = (textStyleEntries) => {
  //1. Replace the Body tag with font-family and font-size 
  //2. Replace the Paragraph segments with line-height, text-align, margin-bottom and text-indent
  let bodyStyle = "margin-top: 0pt; ";
  let paragraphStyle = "";
  let outlineStyle = "";
  textStyleEntries?.forEach(entry => {
    if (entry.code == "fontFamily") bodyStyle += "font-family: '" + entry.value + "'; ";
    else if (entry.code == "fontSize") bodyStyle += "font-size: " + entry.value + "px; ";
    else if (entry.code == "lineHeight") {
      paragraphStyle += "line-height: " + entry.value + "; ";
      outlineStyle += "line-height: " + entry.value + "; ";
    
    } else if (entry.code == "textAlign") {
      paragraphStyle += "text-align: " + entry.value + "; ";
      //outlineStyle no!
    
    } else if (entry.code == "marginBottom") {
      paragraphStyle += "margin-bottom: " + Number(entry.value / 2) + "px; margin-top: " + Number(entry.value / 2) + "px; "
      outlineStyle += "margin-bottom: " + Number(entry.value / 2) + "px; margin-top: " + Number(entry.value / 2) + "px; "
    
    } else if (entry.code == "textIndent") {
      paragraphStyle += "text-indent: " + Math.round(entry.value * 10 / 22) + "em; ";
      //outlineStyle no!
    }
  })

  //Body tag
  var bodyElement = document.querySelector(`div[id="1"]`)
  if (bodyElement) bodyElement.setAttribute('style', bodyStyle)

  //Paragraph tags
  let paragraphs = document.querySelectorAll(`p`)
  paragraphs?.forEach(paragraph => {
    paragraph.setAttribute('style', paragraphStyle)
  })

  //OL tags tags
  let orderedLists = document.querySelectorAll(`ol`)
  orderedLists?.forEach(orderedList => {
    orderedList.setAttribute('style', outlineStyle)
  })

  //UL tags tags
  let unorderedLists = document.querySelectorAll(`ul`)
  unorderedLists?.forEach(unorderedList => {
    unorderedList.setAttribute('style', outlineStyle)
  })

  //LI tags tags
  let listItems = document.querySelectorAll(`li`)
  listItems?.forEach(listItem => {
    listItem.setAttribute('style', outlineStyle)
  })

  //span tags
  var loop = 0;
  var textLeftSide = "";
  let spans = bodyElement.querySelectorAll(`span`)
  spans?.forEach(span => {
    //font-family
    //Take out any font-size styles
    loop = 0;
    textLeftSide = "";
    let spanStyle = span.style.cssText //span.getAttribute('style')
    if (spanStyle) {
      if (spanStyle.indexOf("font-family") > -1) {
        textLeftSide = spanStyle.substring(0, spanStyle.indexOf("font-family"))
      }
      textRightSide = spanStyle
      while (textRightSide && textRightSide.indexOf("font-family") > -1 && loop < 10) {
        var textRightSide = spanStyle.substring(spanStyle.indexOf("font-family"))
        if (textRightSide.indexOf(";") > -1) {
          textRightSide = textRightSide.substring(textRightSide.indexOf(";"))
        }
        else  {
          textRightSide = ""
        }
        textRightSide = textRightSide.replace(/'/g, "").replace(/"/g, '');
        span.setAttribute('style', textLeftSide + textRightSide)
        loop++;
      }

      //font-size
      //Take out any font-size in any text field that might have html in the text itself.
      loop = 0;
      textLeftSide = "";
      spanStyle = span.getAttribute('style') //Then notice that we want to take the spanStyle after the changes above.
      if (spanStyle != null && spanStyle.indexOf("font-size") > -1) {
        textLeftSide = spanStyle.substring(0, spanStyle.indexOf("font-size"))
      }
      textRightSide = spanStyle
      while (textRightSide && textRightSide.indexOf("font-size") > -1 && loop < 10) {
        var textRightSide = spanStyle.substring(spanStyle.indexOf("font-size"))
        if (textRightSide.indexOf(";") > -1) {
          textRightSide = textRightSide.substring(textRightSide.indexOf(";"))
        } else {
          textRightSide = ""
        }
        textRightSide = textRightSide.replace(/'/g, "").replace(/"/g, '');
        span.setAttribute('style', textLeftSide + textRightSide)
        loop++;
      }
    }
  })
}

export const updateChapterWithTextStyle_Segments = (textStyleEntries, segments) => {
  //1. Replace the Body tag with font-family and font-size 
  //2. Replace the Paragraph segments with line-height, text-align, margin-bottom and text-indent
  let tempSegments = [...segments]
  let bodyStyle = "margin-top: 0pt; ";
  let paragraphStyle = "";
  textStyleEntries?.forEach(entry => {
    if (entry.code == "fontFamily") bodyStyle += "font-family: '" + entry.value + "'; ";
    else if (entry.code == "fontSize") bodyStyle += "font-size: " + entry.value + "px; ";
    else if (entry.code == "lineHeight") paragraphStyle += "line-height: " + entry.value + "; ";
    else if (entry.code == "textAlign") paragraphStyle += "text-align: " + entry.value + "; ";
    else if (entry.code == "marginBottom") paragraphStyle += "margin-bottom: " + Number(entry.value / 2) + "px; margin-top: " + Number(entry.value / 2) + "px; "
    else if (entry.code == "textIndent") paragraphStyle += "text-indent: " + Math.round(entry.value * 10 / 22) + "em; ";
  })

  tempSegments = tempSegments?.map(segment => {
    //Body tag
    if (segment.type === 'BODY') {
      segment.styleInline = bodyStyle

    } else if (segment.type === 'PARAGRAPH') {
      segment.styleInline = paragraphStyle

    } else if (segment.type === 'TEXT') {
      //font-family
      //Take out any font-size styles
      let loop = 0
      let textLeftSide = ""
      let spanStyle = segment.styleInline
      if (spanStyle) {
        if (spanStyle.indexOf("font-family") > -1) {
          textLeftSide = spanStyle.substring(0, spanStyle.indexOf("font-family"))
        }
        textRightSide = spanStyle
        while (textRightSide && textRightSide.indexOf("font-family") > -1 && loop < 10) {
          var textRightSide = spanStyle.substring(spanStyle.indexOf("font-family"))
          if (textRightSide.indexOf(";") > -1) {
            textRightSide = textRightSide.substring(textRightSide.indexOf(";"))
          }
          else {
            textRightSide = ""
          }
          textRightSide = textRightSide.replace(/'/g, "").replace(/"/g, '')
          segment.styleInline = textLeftSide + textRightSide
          loop++;
        }

        //font-size
        //Take out any font-size in any text field that might have html in the text itself.
        loop = 0;
        textLeftSide = "";
        spanStyle = segment.text
        if (spanStyle != null && spanStyle.indexOf("font-size") > -1) {
          textLeftSide = spanStyle.substring(0, spanStyle.indexOf("font-size"))
        }
        textRightSide = spanStyle
        while (textRightSide && textRightSide.indexOf("font-size") > -1 && loop < 10) {
          var textRightSide = spanStyle.substring(spanStyle.indexOf("font-size"))
          if (textRightSide.indexOf(";") > -1) {
            textRightSide = textRightSide.substring(textRightSide.indexOf(";"))
          } else {
            textRightSide = ""
          }
          textRightSide = textRightSide.replace(/'/g, "").replace(/"/g, '')
          segment.text = textLeftSide + textRightSide
          loop++;
        }
      }
    }
    return segment
  })
  return tempSegments
}

export const removeExtraSpaceLines = (htmlString) => {
  //0. Take the htmlString in order to manipulate it rather than the editorDiv so we don't wipe out those blank paragraph/spans at the end of the document which are placed there to let the editor to have more mobility to add lower on the doucment.
  //    --the extra space field that I put in the workSegment table is not working. We'll just go to the end of the document and go backwards to delete any spans that equal &nbsp; until we run into something valid.
  //1. Look for any spans which have a dataset.extraSpace set that is true. 
  //2. But we need to be sure that they are really empty.
  //3. If they are to be deleted, then look up to the parent paragraph and be sure that there aren't any other valid penspring-id children before deleting them, too.
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html')
  // var extraSpans = doc.querySelectorAll(`span[data-extra-space="true"]`)
  // extraSpans?.forEach(span => {
  //   if (span.innerHTML.replace(/&nbsp;/g, '').replace(/ /g, '') === '') {
  //     span.remove()
  //     const spanParagraph = span.parentElement;
  //     if (spanParagraph && spanParagraph.children.length === 0) spanParagraph.remove()
  //   }
  // })
  var paragraphs = doc.querySelectorAll(`p`)
  let foundText = false
  for (let i = paragraphs.length; i > 0 && !foundText; i--) {
    if (paragraphs[i] && paragraphs[i].children) {
      if (paragraphs[i].children.length > 1 || (paragraphs[i].firstChild && paragraphs[i].firstChild.id && paragraphs[i].firstChild.innerHTML.replace(/&nbsp;/, '').replace(/ /g, '') !== '')) {
        foundText = true
      } else {
        paragraphs[i].remove()
      }
    }
  }

  return doc.body.innerHTML;  

}

export const displayEditStatistics = (edits, wordCount) => {
  if (!(edits && edits.length > 0)) return
  let display = `<div>${wordCount} words</div><hr style="margin-top: 1px; margin-bottom: 0px;"/>`
  let count = 0
  if (edits.filter(m => m.type === 'TEXT').length > 0) {
    count = edits.filter(m => m.type === 'TEXT').length
    display += `<div>${count} ${count === 1 ? 'sentence updated' : 'sentences updated'}</div>`
  }
  if (edits.filter(m => m.type === 'MOVE').length > 0) {
    count = edits.filter(m => m.type === 'MOVE').length
    display += `<div>${count} ${count === 1 ? 'sentence moved' : 'sentences moved'}</div>`
  }
  if (edits.filter(m => m.type === 'CHANGESTYLE').length > 0) {
    count = edits.filter(m => m.type === 'CHANGESTYLE').length
    display += `<div>${count} ${count === 1 ? 'style set changed' : 'style sets changed'}</div>`
  }
  if (edits.filter(m => m.type === 'ADDPARAGRAPH').length > 0) {
    count = edits.filter(m => m.type === 'ADDPARAGRAPH').length
    display += `<div>${count} ${count === 1 ? 'paragraph break added' : 'paragraph breaks added'}</div>`
  }
  if (edits.filter(m => m.type === 'DELETEPARAGRAPH').length > 0) {
    count = edits.filter(m => m.type === 'DELETEPARAGRAPH').length
    display += `<div>${count} ${count === 1 ? 'paragraph break deleted' : 'paragraph breaks deleted'}</div>`
  }
  if (edits.filter(m => m.type === 'ADDPARAGRAPHSENTENCE').length > 0) {
    count = edits.filter(m => m.type === 'ADDPARAGRAPHSENTENCE').length
    display += `<div>${count} ${count === 1 ? 'paragraph/sentence pair added' : 'paragraph/sentence pairs added'}</div>`
  }
  if (edits.filter(m => m.type === 'ADDSENTENCE').length > 0) {
    count = edits.filter(m => m.type === 'ADDSENTENCE').length
    display += `<div>${count} ${count === 1 ? 'sentence added' : 'sentences added'}</div>`
  }
  if (edits.filter(m => m.type === 'DELETESENTENCE').length > 0) {
    count = edits.filter(m => m.type === 'DELETESENTENCE').length
    display += `<div>${count} ${count === 1 ? 'sentence deleted' : 'sentence deleted'}</div>`
  }
  if (edits.filter(m => m.type === 'ADDLIST').length > 0) {
    count = edits.filter(m => m.type === 'ADDLIST').length
    display += `<div>${count} ${count === 1 ? 'list added' : 'lists added'}</div>`
  }
  if (edits.filter(m => m.type === 'ADDLISTITEM').length > 0) {
    count = edits.filter(m => m.type === 'ADDLISTITEM').length
    display += `<div>${count} ${count === 1 ? 'list item added' : 'list items added'}</div>`
  }
  if (edits.filter(m => m.type === 'DELETELISTITEM').length > 0) {
    count = edits.filter(m => m.type === 'DELETELISTITEM').length
    display += `<div>${count} ${count === 1 ? 'list item deleted' : 'list items deleted'}</div>`
  }
  if (edits.filter(m => m.type === 'REORDERLISTITEMS').length > 0) {
    count = edits.filter(m => m.type === 'REORDERLISTITEMS').length
    display += `<div>${count} ${count === 1 ? 'list sequence changed' : 'list sequences changed'}</div>`
  }
  if (edits.filter(m => m.type === 'ADDTAB').length > 0) {
    count = edits.filter(m => m.type === 'ADDTAB').length
    display += `<div>${count} ${count === 1 ? 'tab added' : 'tab added'}</div>`
  }
  if (edits.filter(m => m.type === 'DELETETAB').length > 0) {
    count = edits.filter(m => m.type === 'DELETETAB').length
    display += `<div>${count} ${count === 1 ? 'tab deleted' : 'tab deleted'}</div>`
  }
  if (edits.filter(m => m.type === 'LISTLEVELMINUS').length > 0) {
    count = edits.filter(m => m.type === 'LISTLEVELMINUS').length
    display += `<div>${count} ${count === 1 ? 'list item moved left' : 'list items moved left'}</div>`
  }
  if (edits.filter(m => m.type === 'LISTLEVELPLUS').length > 0) {
    count = edits.filter(m => m.type === 'LISTLEVELPLUS').length
    display += `<div>${count} ${count === 1 ? 'list item moved right' : 'list items moved right'}</div>`
  }
  return display + '</div>'
}

export const removePenspringEditImages = (htmlString) => {
  //First, clean out the ADDSENTENCE generic text, if any:  &nbsp;____&nbsp;
  htmlString = htmlString.replace(/&nbsp;____&nbsp;/g, '')
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlString, 'text/html')
  const images = doc.querySelectorAll('img')
  images.forEach(img => {
    if (img.hasAttribute('data-type')) img.remove()
  })

  const spans = doc.querySelectorAll('span[data-type="TEXT"]')
  spans.forEach(span => {
    span.style.backgroundColor = ''
  })
  return doc.body.innerHTML
}

const calculateMean = (data) => {
  return data.reduce((acc, val) => acc + val, 0) / data.length
}

const calculateStdDeviation = (data, mean) => {
  const squareDiffs = data.map(value => Math.pow(value - mean, 2))
  return Math.sqrt(squareDiffs.reduce((acc, val) => acc + val, 0) / data.length)
}

export const getParagraphIndentStart = (data) => {
  //If the difference between the leftMargin start and the identRange end is not greater than 50, then there is not a paragraph indent range.
  let fetchedData = []

  data.lines.forEach((line) => {
    fetchedData.push({ x: line.baseline.x0 }) //Notice line.baseline.x0
  })
  const meanX = calculateMean(fetchedData.map(block => block.x))
  const stdX = calculateStdDeviation(fetchedData.map(block => block.x), meanX)
  const leftMargin = [meanX - stdX, meanX + stdX]
  const indentRange = [meanX + stdX, meanX + 2 * stdX]
  if (indentRange[1] - leftMargin[0] - 50 > 0) {
    return {
      indentSize: Math.round(indentRange[1] - indentRange[0]),
      paragraphIndentStart: Math.round(indentRange[0])
    }
  }
  return 0
}

export const getParagraphVerticalStart = (data) => {
  //If the difference between the DIFFERENCE of the upper bottom location compared to the current bottom location to find any paragraph vertical spacing.
  let fetchedData = []
  let previousBottom = 0

  data.lines.forEach((line, index) => {
    const difference = line.baseline.y1 - previousBottom
    if (index > 0) fetchedData.push({ x: difference })  //Notice line.baseline.y1.  And don't save the first one. We need to find the first line before we can start to compare.
    previousBottom = line.baseline.y1
  })
  const meanX = calculateMean(fetchedData.map(block => block.x))
  const stdX = calculateStdDeviation(fetchedData.map(block => block.x), meanX)
  const regularBottomMargin = [meanX - stdX, meanX + stdX]
  const paragraphBottomMargin = [meanX + stdX, meanX + 2 * stdX]

  //If there isn't a difference of more than 30, then there is not a paragraphVerticalStart since this must be a block paragraph document.
  if (paragraphBottomMargin[1] - regularBottomMargin[0] - 30 > 0) return Math.round(paragraphBottomMargin[0])
  return 0
}

const getPenspringSpan = (elementTarget) => {
  let loop = 0
  let penspringSpan = elementTarget
  while (!(penspringSpan && penspringSpan.nodeName === 'SPAN' && penspringSpan.id) && loop < 10) {
    penspringSpan = penspringSpan.parentElement
    loop++
  }
  if (penspringSpan && penspringSpan.nodeName === 'SPAN' && penspringSpan.id) return penspringSpan
}

export function detectParagraphs(data) {
  //0. Is this paragraphs with text-indent and no top/bottom space for paragraphs (book style), or is this blocked paragraphs without text-indent with top/bottom space to differentiate?
  //1. We need to determine where the left margin is so we avoid adding text-indent to blocked text. There might be blocked text without any indented paragraphs
  //2. If there is vertical space between paragraphs, then this would be a good way to determine paragraphs.
  let paragraphVerticalStart = getParagraphVerticalStart(data)
  let { indentSize, paragraphIndentStart } = getParagraphIndentStart(data)

  let processedText = ''
  let previousBottom = null
  //let previousRight = null
  //I'm not convinced that the averageLeft calculation is a good one to keep around, but if the text-indent calculations are looking acceptable, that's okay then.
  let averageLeft = data.lines.filter(m => m.baseline.x0 < 100).reduce((sum, line) => sum + line.baseline.x0, 0) / data.lines.length;

  data.lines.forEach((line, index) => {
    const currentBottom = line.baseline.y1
    //const currentRight = line.baseline.x1 
    const currentLeft = line.baseline.x0
    if (index > 0) {
      const gapBottom = currentBottom - previousBottom
      //const gapRight = currentRight - previousRight  We don't bother with the gapRight any more. 
      if ((paragraphVerticalStart && gapBottom > paragraphVerticalStart) || (paragraphIndentStart && currentLeft > paragraphIndentStart)) {
        if (paragraphIndentStart && currentLeft > paragraphIndentStart) {
          const textIndent = Math.round((currentLeft - paragraphIndentStart) / (indentSize || 1)) * 2 //2 is meant for 2em, which is about 5 characters of whatever size of font it is.
          processedText += `</p><p style="text-indent: ${textIndent}em;">`
        } else {
          processedText += '</p><p>'
        }
      }
    } else if (index === 0) { //If this is the first time, don't overlook the need for a text-indent if there is a paragraphIndentStart
      if (paragraphIndentStart && currentLeft > paragraphIndentStart) {
        const textIndent = Math.round((currentLeft - paragraphIndentStart) / (indentSize || 1)) * 2 //2 is meant for 2em, which is about 5 characters of whatever size of font it is.
        processedText += `<p style="text-indent: ${textIndent}em;">`
      } else {
        processedText += '<p>'
      }
    }
    //Also take out the end-of-line "\n"
    processedText += line.text.replace(/\n/g, "").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/\|/g, "I") + ' ' //Sanitize the text of dangerous html but maybe this could be a programming code file as well. Plus replace | with the I capital which is a Tesseract thing happening with good typed text that does look like a bar in Microsoft Word.
    previousBottom = currentBottom
    //previousRight = currentRight
  })
  return processedText + '</p>'
}

const getIsLastChild = (spanElement) => {
  const parent = spanElement && spanElement.parentElement
  let lastPenspringChild
  if (parent) {
    for(let i = 0; i < parent.children.length; i++) {
      if (parent.children[i].nodeName === 'SPAN' && !isNaN(parent.children[i].id)) {
        lastPenspringChild = parent.children[i]
      }
    }
  }
  return lastPenspringChild === spanElement
}

export const deleteSegments = (segments, deleteSentenceArray) => {
  //1. Loop through the deleteSentenceArray
  //3.    Find the first segment (foundSegment)
  //4.      If the foundSegment is the first child of the paragraph, keep track of that paragraph (trackParagraph)
  //5.    Delete segments 
  //6.    If the deletes pass by another paragraph, then we know that the entire trackParagraph is deleted
  //7. end loop
  //8. Send the resulting segments back.
  const segmentsCopy = [...segments]
  let foundStart = false
  let foundEnd = false
  let trackParagraphId 
  let tempSegments = [...segments]
  //1. Loop through the deleteSentenceArray
  segmentsCopy?.forEach((segment, index) => {
    //2.    In the process of deleting, keep track of the paragraph to see if the entire paragraph contents are deleted in order to delete the paragraph as well
    //3.    Find the first segment (foundSegment)
    if (!foundStart) {
      if (deleteSentenceArray.indexOf(segment.elementId.toString()) > -1) {
        foundStart = true
        //4.      If the foundStart is the first child of the paragraph, keep track of that paragraph (trackParagraph)
        if (segmentsCopy[index-1].type === 'PARAGRAPH') { //Go back one index to see if this is a paragraph so that the current spanElement is the firstChild.
          trackParagraphId = segmentsCopy[index-1].elementId  
        }
        tempSegments = tempSegments.filter(m => m.elementId !== segment.elementId)
        if (Number(deleteSentenceArray[deleteSentenceArray.length - 1]) === segment.elementId) foundEnd = true
      }
    } else if (foundStart && !foundEnd) {
      if (segment.type === 'PARAGRAPH') {
        if (trackParagraphId) { //If we have been tracking a trackParagraphId, then this means that all of the sentences in that paragraph have been deleted in the process
          tempSegments = tempSegments.filter(m => m.elementId !== segment.elementId)
        }
        trackParagraphId = segment.elementId
      } else if (deleteSentenceArray.indexOf(segment.elementId.toString()) > -1) { //The deleteSentenceArray only has two values in it: The start and the end. We have already passed by the first one up above in foundStart. Anything else in between that is a span element should be deleted. The start and end can be the same sentence
        foundEnd = true
        tempSegments = tempSegments.filter(m => m.elementId !== segment.elementId)
      } else {
        tempSegments = tempSegments.filter(m => m.elementId !== segment.elementId)
      }
    }
  })
  return tempSegments
}

const getParagraphSegmentOfSpan = (segments, spanSegment) => {
  //Look upwards in the segments until you come to a paragraph that the segment belongs to.
  let currentIndex = segments.findIndex(m => m.elementId === spanSegment.elementId && spanSegment.type === 'TEXT')
  let paragraphSegment
  let loop = 0
  while (currentIndex >= 0 && !(paragraphSegment && paragraphSegment.type === 'PARAGRAPH') && loop < 100) {
    --currentIndex
    if (segments[currentIndex] && segments[currentIndex].type === 'PARAGRAPH') paragraphSegment = segments[currentIndex]
    loop++
  }
  return paragraphSegment
}

const deleteNewListItemByAuthor = (segments, firstSpanElement) => {
  let listItemSegment
  let tempSegments = [...segments]
  if (firstSpanElement) {
    var segment = segments?.filter(m => m.elementId === Number(firstSpanElement.id))
    if (segment && segment.type === 'LI') {
      listItemSegment = segment
    } else {
      let currentIndex = segments.findIndex(m => m.elementId === firstSpanElement.elementId && firstSpanElement.type === 'TEXT')
      let loop = 0
      while (currentIndex >= 0 && !(listItemSegment && listItemSegment.type === 'LI') && loop < 100) {
        --currentIndex
        if (segments[currentIndex] && segments[currentIndex].type === 'LI') listItemSegment = segments[currentIndex]
        loop++
      }
    }
    if (listItemSegment && listItemSegment.type === 'LI') {
      tempSegments = segments.filter(m => m.parentElementId !== listItemSegment.elementId && m.type === 'TEXT')
      tempSegments = tempSegments.filter(m => m.elementId !== listItemSegment.elementId && m.type === 'LI')
    }
  }
  return tempSegments
}

export const compareChangeToAddOrUpdateEdit = ({ 
  currentSpan, 
  previousSpan, 
  addOrUpdateEdit, 
  segments, 
  edits, 
  personId, 
  editorName, 
  workSummary, 
  isAuthor, 
  handleSetChosenSegment, 
  editLanguageId 
}) => {
  //0. If previousSpan is empty, then the user probably just started and this is the first change, so set previousSpan to the current event.target
  //1. What is the latest version of the sentence before this potential change of a sentence?
  //2. Don't compare LISTLEVELMINUS, LISTLEVELPLUS, ADDTAB, and DELETETAB
  //3. If this is already an edited sentence
  //4.    Set the previousHtml to the "edit" record text
  //5. else
  //6.    Set the previousHtml to the "segment" record text
  //7. end if
  //8. Take off the ending space that is added on when the page is built
  //9. If there is a change of the sentence, then addOrUpdateEdit

  //1. What is the latest version of the sentence before this potential change of a sentence?
  //2. Don't compare LISTLEVELMINUS, LISTLEVELPLUS, ADDTAB, and DELETETAB
  if (!isAuthor && previousSpan && currentSpan && currentSpan.dataset.type !== 'LISTLEVELMINUS' && currentSpan.dataset.type !== 'LISTLEVELPLUS' && currentSpan.dataset.type !== 'ADDTAB' && currentSpan.dataset.type !== 'DELETETAB') {

    let previousSentence = ""
    let currentSentence = ""
    let prevSegment = segments && segments.length > 0 && segments.filter(m => m.elementId === Number(previousSpan.id) && ((m.type === 'TEXT' && previousSpan.dataset.type === 'TEXT') || (m.type === 'ADDLISTITEM' && previousSpan.dataset.type.indexOf('ADDLISTITEM') > -1)))[0]
    let prevEdit = edits && edits.length > 0 && edits.filter(m => Number(m.elementId) === Number(previousSpan.id) && ((m.type === 'TEXT' && previousSpan.dataset.type === 'TEXT') || (m.type === 'ADDLISTITEM' && previousSpan.dataset.type === 'ADDLISTITEM' && m.addListItemSequence === Number(previousSpan.dataset.addListItemSequence))))[0]
    //If neither prevSegment or prevEdit do not exist, then skip the addOrUpdateEdit since it will be a bad comparison.
    if (prevSegment || prevEdit) {
      //3. If this is already an edited sentence
      if (prevEdit) {
        //4.    Set the previousHtml to the "edit" record text
        previousSentence = prevEdit.text
        //5. else
      } else if (prevSegment) {
        //6.    Set the previousHtml to the "segment" record text
        previousSentence = prevSegment.text
        //7. end if
      }
      //8. Take off the ending space that is added on when the page is built
      currentSentence = previousSpan.innerHTML
      if (currentSentence !== '&nbsp;____&nbsp;' && (previousSpan.dataset.type === 'TEXT' || previousSpan.dataset.type.indexOf('ADDLISTITEM') > -1)
        && (currentSentence.lastIndexOf('&nbsp;') === currentSentence.length - 6 || currentSentence.lastIndexOf("\u00A0") === currentSentence.length - 6 || currentSentence.lastIndexOf(' ') === currentSentence.length - 1)) { //We don't want todo this to the ADDLISTITEM_TEXT since the originating text does contain underlines and bookend &nbsp;-s
        if (currentSentence.lastIndexOf('&nbsp;') === currentSentence.length - 6 || currentSentence.lastIndexOf("\u00A0") === currentSentence.length - 6) {
          currentSentence = currentSentence.substring(0, currentSentence.length - 6)
        } else if (currentSentence.lastIndexOf(' ') === currentSentence.length - 1) {
          currentSentence = currentSentence.substring(0, currentSentence.length - 1)
        }
      }

      //9. If there is a change of the sentence, then addOrUpdateEdit
      if (previousSentence !== currentSentence) {
        addOrUpdateEdit({
          editSegmentId: (prevEdit && prevEdit.editSegmentId) || 0,
          editSegmentTypeId: 0, //This is going to be filled in by the backend with the type given below.
          firstName: editorName && editorName.firstName,
          lastName: editorName && editorName.lastName,
          personId,
          chapterId: workSummary ? workSummary.chapterId_current && workSummary.chapterId_current !== guidEmpty ? workSummary.chapterId_current : workSummary.chapterOptions && workSummary.chapterOptions[0].chapterId : guidEmpty,
          elementId: previousSpan.id,
          languageId: editLanguageId,
          type: previousSpan.dataset.type.indexOf('ADDLISTITEM') > -1 ? 'ADDLISTITEM' : 'TEXT',  //This used to be 'TEXT' but now that it is used for the ADDLISTITEM text, we'll make this dynamic and hope that it is clean logic.
          text: currentSentence, //previousSpan.innerHTML,
          authorTextSnapshot: (prevEdit && prevEdit.authorTextSnapshot) || (prevSegment && prevSegment.text) || '',
          addListItemSequence: previousSpan.dataset.addListItemSequence || prevSegment?.addListItemSequence || '', //getNextAddListItemSequence(previousSpan, prevEdit), //This next thing was causing a problem where it was updating sequence 5 when the target was 4 for an update between clicking on a lower ADDLISTITEM to let the upper one change the text.
          comment: (prevEdit && prevEdit.comment) || '',
        }, currentSpan?.dataset?.addListItemSequence ? () => setTimeout(() => setCursorPositionByRecallAddListItem(currentSpan, currentSpan.dataset.addListItemSequence, handleSetChosenSegment), 500) : () => { })
      }
    }
  }
}