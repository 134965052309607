const addMarginLeft = 36

export const setListLevel = ({
                               currentElement,
                               elementListItem,
                               direction,
                               chapterId,
                               getNextId,
                               edits,
                               chapterListLevels,
                               listLevelGeneral,
                               addChapterListLevels,
                               chosenSegment,
                             }) => {

  //Ensure that the currentElement is a span
  if (!(currentElement && currentElement.nodeName === 'SPAN')) {
    let lastChosen = chosenSegment && chosenSegment[chosenSegment - 1]
    if (lastChosen && lastChosen.id) currentElement = document.querySelector(`span[id="${lastChosen.id}"][data-type='TEXT']`)
  }
  const structure = getListStructure(currentElement)
  const elementSpanIndex = getStructureIndex(structure, currentElement)
  const isTargetFirstLevel = elementSpanIndex && structure[elementSpanIndex] && structure[elementSpanIndex].level === 1
  const hasMultipleTabIndents = getTabMarginCount(elementListItem)
  const hasChildren = getHasChildren(currentElement, elementListItem)  //Children is relative. It could be that there is an OL or UL underneath the currentElement which is indented further than just one tab which looks like it really isn't a direct child. So we need to check for a larger marginLeft in order to decrease that marginLeft by one tab
  const isFirstChildOfList = getIsFirstChildOfList(currentElement)

  // if (chapterListLevels && chapterListLevels.length > 0) {
  // 	chapterListLevels = chapterListLevels.filter(m => m.topListElementId === Number(structure[0].element.id))
  // 	if (!(chapterListLevels && chapterListLevels.length > 0)) chapterListLevels = setChapterListLevels(chapterId, structure, addChapterListLevels)
  // } else {
  // 	chapterListLevels = setChapterListLevels(chapterId, structure, addChapterListLevels)
  // 	//setTimeout(() => getChapterListLevels('', chapterId), 500)
  // }
  // //Fill in the chapterListLevels with the other
  // if (!(chapterListLevels && chapterListLevels.length > 0)) {
  chapterListLevels = listLevelGeneral
  // } else {
  // 	let listType = chapterListLevels[0].listType
  // 	let maxLevel = chapterListLevels.reduce((acc, m) => acc < m.level ? m.level : acc, 0)
  // 	let addListLevels = listLevelGeneral && listLevelGeneral.length > 0 && listLevelGeneral.filter(m => m.listGroup === listType && m.level > maxLevel)
  // 	chapterListLevels = chapterListLevels.concat(addListLevels)
  // }

  if (direction === 'MINUS') {
    const hasAboveStructure = getAboveStructure(structure, elementSpanIndex)
    const hasSiblingsUpper = getSiblingsUpper(elementListItem)
    const hasSiblingsLower = getSiblingsLower(elementListItem)
    const hasUpperLeftElementOneIndent = getUpperLeftElementOneIndent(currentElement)
    const hasUpperLeftElementTwoIndent = getUpperLeftElementTwoIndent(currentElement)
    const hasUpperLeftElementMoreIndent = getUpperLeftElementMoreIndent(currentElement, elementListItem)
    const hasLowerLeftElementOneIndent = getLowerLeftElementOneIndent(currentElement)
    const hasGrandParent = getGrandParent(currentElement)

    // XXXX
    //             0000
    // XXXX
    if (hasMultipleTabIndents > 1 && isFirstChildOfList && !hasChildren && !hasSiblingsLower && !hasLowerLeftElementOneIndent) {
      leftHasMultipleTabIndentsIsFirstChNoChNoLwrSibNoLwrLeft({elementListItem, chapterListLevels})

      // XXXX
      //             0000
      //                   |XXXX|
      //             XXXX
    } else if (hasMultipleTabIndents > 1 && isFirstChildOfList && hasSiblingsLower && !hasLowerLeftElementOneIndent && !hasUpperLeftElementOneIndent && hasUpperLeftElementTwoIndent) {
      leftHasMultipleTabIndentsIsFirstChHasLwrSibNoLwrLeftHasUpLeftTwoIndent({
        currentElement,
        elementListItem,
        getNextId,
        chapterListLevels,
        hasUpperLeftElementTwoIndent
      })

      // XXXX
      //                    0000
      //                          |XXXX|
      //                    XXXX
    } else if (hasMultipleTabIndents > 1 && isFirstChildOfList && hasSiblingsLower && !hasLowerLeftElementOneIndent && hasUpperLeftElementTwoIndent) {
      leftHasMultipleTabIndentsIsFirstChHasLwrSibNoLwrLeft({
        currentElement,
        elementListItem,
        getNextId,
        chapterListLevels
      })

      // XXXX
      //             0000
      // XXXX or XXXX (siblings or children)
      // } else if (hasMultipleTabIndents > 1) {
      // 	leftHasMultipleTabIndents({currentElement, structure, elementListItem, elementSpanIndex, getNextId, chapterListLevels})

      //<LI>
      //  <UL> (or <OL>
      //    <LI>
      //      elementSpan
      // } else if (hasDirectDoubleIndent) {
      // 	leftHasDirectDoubleIndent({currentElement, elementListItem})

      //0. Find that there is preceding structure and if this is a first level target moving to the left (and outside of the structure)
      // XXXX
      // 0000
      // XXXX or XXXX (siblings or children)
    } else if (hasAboveStructure && isTargetFirstLevel) {
      leftHasUpStrIsFirstLvl({currentElement, structure, elementListItem, getNextId, chapterListLevels})

      //0. Find that there has no preceding structure and if this is a first level target moving to the left (and outside of the structure)
      // 0000
      // XXXX or XXXX (siblings or children)
    } else if (!hasAboveStructure && isTargetFirstLevel) {
      leftNoUpStrIsFirstLvl({currentElement, structure, elementListItem, getNextId})

      // XXXX
      //      0000
      //            XXXX
      //      XXXX
    } else if (hasChildren && hasUpperLeftElementOneIndent && !hasLowerLeftElementOneIndent && !hasUpperLeftElementMoreIndent) {
      leftHasChNoUpSibHasLwrSibHasUpLftOneIndentHasNoLowerLeft({
        currentElement,
        elementListItem,
        getNextId,
        hasUpperLeftElementOneIndent,
        chapterListLevels
      })

      // XXXX
      //      0000
      //            XXXX
      //      XXXX
    } else if (hasChildren && hasUpperLeftElementOneIndent && hasLowerLeftElementOneIndent) { // && !hasUpperLeftElementMoreIndent
      leftHasChNoUpSibHasLwrSibHasUpLftOneIndentHasLowerLeft({
        currentElement,
        elementListItem,
        getNextId,
        hasUpperLeftElementOneIndent,
        hasLowerLeftElementOneIndent,
        chapterListLevels
      })
      //0. Find that this target has no children but no siblings above or below, but has an upper left level that is one indent away (or more), but has one lower level that is one indent in.
      //This needs to be before the if condition: hasChildren && !hasSiblingsUpper && !hasSiblingsLower
      // XXXX
      //      XXXX
      //                  0000
      //            XXXX
    } else if (hasChildren && !hasSiblingsUpper && !hasSiblingsLower && hasLowerLeftElementOneIndent && hasUpperLeftElementMoreIndent) {
      leftHasChNoUpSibNoDnSibHasDnLftOneTabHasUpLftMoreTab({
        currentElement,
        elementListItem,
        hasLowerLeftElementOneIndent,
        hasUpperLeftElementMoreIndent,
        getNextId,
        chapterListLevels
      })

      //      XXXX
      //            XXXX
      //      0000
      // XXXX
    } else if (!hasChildren && hasSiblingsUpper && !hasSiblingsLower && hasLowerLeftElementOneIndent) {
      leftNoChHasUpSibNoDnSibHasLwrLftOneTab({currentElement, elementListItem, getNextId, chapterListLevels, hasLowerLeftElementOneIndent})

      //      XXXX
      //                  XXXX
      //            0000
      //      XXXX
    } else if (!hasChildren && !hasSiblingsUpper && !hasSiblingsLower && hasUpperLeftElementOneIndent) {
      leftNoChNoUpSibNoDnSibHasUpLftOneTab({currentElement, elementListItem, getNextId, hasUpperLeftElementOneIndent, chapterListLevels, hasLowerLeftElementOneIndent})

      //0. Find that this target has no children and no siblings above or below and not structure above
      //        0000
      //  XXXX
    } else if (!hasAboveStructure && !hasChildren && !hasSiblingsUpper && !hasSiblingsLower) {
      leftNoChNoUpSibNoDnSibNoUpperStr({currentElement, elementListItem, hasLowerLeftElementOneIndent})

      // XXXX
      //       XXXX
      //                  0000
      //            XXXX
    } else if (!hasChildren && !hasSiblingsUpper && !hasSiblingsLower && hasLowerLeftElementOneIndent) {
      leftNoChNoUpSibNoDnSibHasLowerOneIndent({
        elementListItem,
        chapterListLevels,
        getNextId,
        hasLowerLeftElementOneIndent
      })

      //0. Find that this target has no children and no siblings above or below
      // XXXX
      //       XXXX
      //                  0000
      //            XXXX
    } else if (!hasChildren && !hasSiblingsUpper && !hasSiblingsLower) {
      leftNoChNoUpSibNoDnSib({currentElement, elementListItem, chapterListLevels, chosenSegment})

      //            0000
      //            XXXX
      //            XXXX
    } else if (!isTargetFirstLevel && !hasSiblingsUpper && !hasUpperLeftElementOneIndent && !hasUpperLeftElementMoreIndent && !hasLowerLeftElementOneIndent) {
      leftNoFirstLvlNoUpLftMoreTabNoUpLftOneTabNoLwrLftOneTab({
        currentElement,
        elementListItem,
        chapterListLevels,
        getNextId
      })


      //This needs to be above leftNoChNoUpSibHasDnSibHasLwrLftOneTab
      // XXXX
      //      0000
      //      XXXX
      // XXXX
    } else if (!hasChildren && !hasSiblingsUpper && hasSiblingsLower && hasLowerLeftElementOneIndent && hasUpperLeftElementOneIndent) {
      leftNoChNoUpSibHasDnSibHasLwrLftOneTabHasUpLeftOneTab({
        currentElement,
        elementListItem,
        getNextId,
        hasUpperLeftElementOneIndent,
        hasLowerLeftElementOneIndent,
        chapterListLevels
      })


      //This needs to be above: !hasChildren && !hasSiblingsUpper && hasSiblingsLower
      // XXXX
      //      XXXX
      //                  0000
      //                  XXXX
      //           XXXX
    } else if (!hasChildren && !hasSiblingsUpper && hasSiblingsLower && hasLowerLeftElementOneIndent) {
      leftNoChNoUpSibHasDnSibHasLwrLftOneTab({
        currentElement,
        elementListItem,
        getNextId,
        hasLowerLeftElementOneIndent,
        chapterListLevels
      })

      //0. Find that this target has no children but has siblings below but not above, but has an upper left level that is one indent away (or more).
      //This needs to be above: !hasChildren && !hasSiblingsUpper && hasSiblingsLower
      // XXXX
      //      XXXX
      //                  0000
      //                  XXXX
    } else if (!hasChildren && !hasSiblingsUpper && hasSiblingsLower && hasUpperLeftElementMoreIndent) {
      leftNoChNoUpSibHasDnSibHasUpLftMoreTab({elementListItem, hasUpperLeftElementMoreIndent})

      //0. Find that this target has no children and no siblings above but has siblings below
      // XXXX
      //      XXXX
      //            0000
      //            XXXX
    } else if (!hasChildren && !hasSiblingsUpper && hasSiblingsLower && hasUpperLeftElementOneIndent) {
      leftNoChNoUpSibHasDnSibHasUpLeftOneTab({
        currentElement,
        elementListItem,
        hasUpperLeftElementOneIndent,
        getNextId,
        chapterListLevels
      })

      //0. Find that this target has one or more children but no siblings above or below, and that the upper left list is one more tab indent over (or more).
      //This needs to be above: hasChildren && !hasSiblingsUpper && !hasSiblingsLower
      // XXXX
      //      XXXX
      //                  0000
      //                        XXXX
    } else if (hasChildren && !hasSiblingsUpper && !hasSiblingsLower && hasUpperLeftElementMoreIndent) {
      leftHasChNoUpSibNoDnSibHasUpLftMoreTab({
        currentElement,
        elementListItem,
        hasUpperLeftElementMoreIndent,
        hasLowerLeftElementOneIndent,
        getNextId,
        chapterListLevels
      })

      //0. Find that this target has one or more children but no siblings above or below.
      // XXXX
      //      XXXX
      //            0000
      //                  XXXX
    } else if (hasChildren && !hasSiblingsUpper && !hasSiblingsLower) {
      leftHasChNoUpSibNoDnSib({
        currentElement,
        elementListItem,
        hasUpperLeftElementOneIndent,
        hasLowerLeftElementOneIndent,
        getNextId,
        chapterListLevels
      })

      //0. Find that this target has no children, but has siblings above but not below
      // XXXX
      //      XXXX
      //            XXXX
      //            0000
      //      XXXX
    } else if (!hasChildren && hasSiblingsUpper && !hasSiblingsLower && hasUpperLeftElementOneIndent) {
      leftNoChHasUpSibNoDnSib({
        elementListItem,
        hasUpperLeftElementOneIndent,
        chapterListLevels,
        hasLowerLeftElementOneIndent
      })

      //0. Find that this target has no children, but has siblings above but not below
      // XXXX
      //            XXXX
      //            0000
      //      XXXX
    } else if (!hasChildren && hasSiblingsUpper && !hasSiblingsLower && hasGrandParent && hasLowerLeftElementOneIndent) {
      leftNoChHasUpSibNoDnSibHasParentHasLwrLeftOneIndent({
        elementListItem,
        chapterListLevels,
        hasGrandParent,
        getNextId,
        hasLowerLeftElementOneIndent
      })

      //0. Find that this target has no children, but has siblings above but not below
      // XXXX
      //            XXXX
      //            0000
      // XXXX
    } else if (hasMultipleTabIndents > 1 && !hasChildren && hasSiblingsUpper && !hasSiblingsLower && !hasLowerLeftElementOneIndent) {
      leftHasMultTabsNoChHasUpSibNoDnSibNoLwrLeftOneIndent({
        currentElement,
        elementListItem,
        chapterListLevels,
        hasGrandParent,
        getNextId,
        hasLowerLeftElementOneIndent
      })

      //0. Find that this target has no children, but has siblings above (but could have siblings below or not)
      // XXXX
      //      XXXX
      //            XXXX
      //            0000
      //      XXXX | XXXX (could have siblings)
    } else if (!hasChildren && hasSiblingsUpper && hasUpperLeftElementOneIndent) {
      leftNoChHasUpSibHasUpLftOneTab({
        currentElement,
        elementListItem,
        hasUpperLeftElementOneIndent,
        getNextId,
        chapterListLevels,
        hasLowerLeftElementOneIndent
      })

      //0. Find that this target has children and has siblings above but not below
      // XXXX
      //      XXXX
      //            XXXX
      //            0000
      //                  XXXX
    } else if (hasChildren && hasSiblingsUpper && !hasSiblingsLower) {
      leftHasChHasUpSibNoDnSib({
        currentElement,
        elementListItem,
        hasUpperLeftElementOneIndent,
        getNextId,
        chapterListLevels,
        hasLowerLeftElementOneIndent
      })

      //0. Find that this target has children, but has siblings above but not below
      // XXXX
      //      XXXX
      //            XXXX
      //            0000
      //                  XXXX
      //            XXXX
    } else if (hasChildren && hasSiblingsUpper && hasSiblingsLower) {
      leftHasChHasUpSibHasDnSib({
        currentElement,
        elementListItem,
        hasUpperLeftElementOneIndent,
        getNextId,
        chapterListLevels,
        hasLowerLeftElementOneIndent
      })

      //0. Find that this target has no children and siblings below but not siblings above but has an upper right element with an upper left element.
      // XXXX
      //      XXXX
      //                  XXXX
      //            0000
      //            XXXX
    } else if (!hasChildren && hasSiblingsUpper && !hasSiblingsLower && hasUpperLeftElementOneIndent) {
      leftNoChHasUpSibNoDnSibHasUpLeftOneTab({
        elementListItem,
        hasUpperLeftElementOneIndent,
        chapterListLevels,
        hasLowerLeftElementOneIndent
      })

      //            XXXX
      //            0000
      //            XXXX
    } else if (!hasChildren && hasSiblingsUpper && hasSiblingsLower && !hasUpperLeftElementOneIndent) {
      leftNoChHasUpSibNoDnSibNoUpLeftOneTab({currentElement, elementListItem, chapterListLevels, getNextId})
    }

    //*******************************************    Right Moves    ************************************
    //*******************************************                   ************************************
    //*******************************************                   ************************************
    //*******************************************                   ************************************
  } else if (direction === 'PLUS') {
    const rightHasSiblingsUpper = elementListItem.previousElementSibling
    const rightHasSiblingsLower = elementListItem.nextElementSibling
    const hasUpperRightElementOneIndent = getUpperRightElementOneIndent(currentElement)
    const hasLowerRightElementOneIndent = getLowerRightElementOneIndent(currentElement, elementListItem) //{list, listItem}
     const hasUpperRightElementTwoIndent = getUpperRightElementTwoIndent(currentElement)
    const hasLowerRightElementTwoIndent = getLowerRightElementTwoIndent(currentElement, elementListItem) //{list, listItem}
    // 0000
    //      XXXX
    if (hasChildren && !rightHasSiblingsUpper && !rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightHasChNoLwrSibNoUpSibNoUpRtIndent({currentElement, elementListItem, getNextId, chapterListLevels})

      //XXXX
      //          0000
      //                XXXX
    } else if (isFirstChildOfList && !rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightFirstChildNoLwrSibNoUpRtOneTab({elementListItem, chapterListLevels, hasLowerRightElementOneIndent})

      //XXXX
      //          0000
      //          XXXX
    } else if (isFirstChildOfList && !hasChildren && rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightFirstChildNoChHasLwrSibNoUpRtOneTab({currentElement, elementListItem, getNextId, chapterListLevels})

      //XXXX
      //          0000
      //                  XXXX
      //          XXXX
    } else if (isFirstChildOfList && hasChildren && rightHasSiblingsLower && !hasUpperRightElementOneIndent && !hasLowerRightElementOneIndent) {
      rightFirstChildHasChHasLwrSibNoUpRtOneTab({currentElement, elementListItem, getNextId, chapterListLevels})

      // XXXX
      //      0000
    } else if (!hasChildren && !rightHasSiblingsUpper && !rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightNoChNoUpSibNoDnSibNoUpperRtIndent({currentElement, elementListItem, getNextId, chapterListLevels})

      //            XXXX
      //      0000
      // XXXX
    } else if (!hasChildren && !rightHasSiblingsLower && hasUpperRightElementOneIndent) { //&& !hasSiblingsUpper  this shouldn't mtter because the first thing that is going to be upper is a right element one intende
      rightNoChNoUpSibNoDnSibHasUpRtIndent({
        currentElement,
        elementListItem,
        hasUpperRightElementOneIndent,
        getNextId,
        chapterListLevels
      })

      // XXXX
      //      0000
      //            XXXX
    } else if (hasChildren && !rightHasSiblingsUpper && !rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightHasChNoUpSibNoDnSibNoUpRtIndent({
        currentElement,
        elementListItem,
        hasUpperRightElementOneIndent,
        getNextId
      })

      // XXXX
      //      0000
      //            XXXX
    } else if (hasChildren && !rightHasSiblingsUpper && !rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightHasChNoUpSibNoDnSibNoUpRtIndent({
        currentElement,
        elementListItem,
        hasUpperRightElementOneIndent,
        getNextId
      })

      // XXXX
      //      0000
      //            XXXX
      //      XXXX
    } else if (hasChildren && !rightHasSiblingsUpper && rightHasSiblingsLower && !hasUpperRightElementOneIndent && hasLowerRightElementOneIndent) {
      rightHasChNoUpSibHasDnSibNoUpRtIndent({currentElement, elementListItem, getNextId, chapterListLevels, hasLowerRightElementOneIndent})

      //            XXXX
      //      0000
      //      XXXX
    } else if (!hasChildren && !rightHasSiblingsUpper && rightHasSiblingsLower && hasUpperRightElementOneIndent) {
      rightNoChNoUpSibHasDnSibHasUpRtIndent({
        currentElement,
        elementListItem,
        hasUpperRightElementOneIndent,
        getNextId,
        chapterListLevels
      })

      //      XXXX
      //      0000
      //      XXXX
    } else if (!hasChildren && rightHasSiblingsUpper && rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightNoChHasUpSibHasDnSibNoUpRtIndent({currentElement, elementListItem, getNextId, chapterListLevels})

      //      XXXX
      //      0000
      // XXXX
    } else if (!hasChildren && rightHasSiblingsUpper && !rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightNoChHasUpSibNoDnSibNoUpRtIndent({currentElement, elementListItem, getNextId, chapterListLevels})

      //This needs to come before rightHasChHasUpSibHasLwrRtIndent
      //           XXXX
      //      0000
      //           XXXX
      //} else if (hasChildren && !rightHasSiblingsUpper && !rightHasSiblingsLower && hasUpperRightElementOneIndent) {
    } else if (hasChildren && hasUpperRightElementOneIndent && hasLowerRightElementOneIndent) {
      rightHasChNoUpSibNoDnSibHasUpRtIndent({
        currentElement,
        elementListItem,
        hasUpperRightElementOneIndent,
        hasLowerRightElementOneIndent,
        getNextId,
        chapterListLevels
      })

      //      XXXX
      //      0000
      //                XXXX
      //           XXXX
      //This needs to be before rightHasChHasUpSibNoDnSibNoUpRtIndent
    } else if (hasChildren && rightHasSiblingsUpper && hasLowerRightElementOneIndent && !hasUpperRightElementOneIndent) { //&& !hasSiblingsLower whether there is a sibling lower or not doesn't matter here since the next one down is a child.
      rightHasChHasUpSibHasLwrRtIndent({
        currentElement,
        elementListItem,
        getNextId,
        chapterListLevels,
        hasLowerRightElementOneIndent
      })

      // XXXX|XXXX
      //      0000
      //                XXXX
      //           XXXX
      //This needs to be before rightHasChHasUpSibNoDnSibNoUpRtIndent
    } else if (hasChildren && !hasUpperRightElementOneIndent && hasLowerRightElementOneIndent) { //&& !hasSiblingsLower whether there is a sibling lower or not doesn't matter here since the next one down is a child.
      rightHasChHasLwrRtIndent({
        currentElement,
        elementListItem,
        getNextId,
        chapterListLevels,
        hasLowerRightElementOneIndent
      })

      //This needs to be before rightHasChHasUpSibNoDnSibNoUpRtIndent
      //                  XXXX
      //      0000
      //                  XXXX
    } else if (!hasUpperRightElementOneIndent && !hasUpperRightElementOneIndent && hasUpperRightElementTwoIndent && hasLowerRightElementTwoIndent) { //&& !hasSiblingsLower whether there is a sibling lower or not doesn't matter here since the next one down is a child.
      rightNoUpRtIndentNoLwrRtIndentHasUpperTwoIndentHasLowerTwoIndent({
        elementListItem,
        getNextId,
        chapterListLevels,
        hasUpperRightElementTwoIndent,
      })

      //      XXXX
      //      0000
      //           XXXX
    } else if (hasChildren && rightHasSiblingsUpper && !hasUpperRightElementOneIndent) { //&& !hasSiblingsLower whether there is a sibling lower or not doesn't matter here since the next one down is a child.
      rightHasChHasUpSibNoDnSibNoUpRtIndent({
        currentElement,
        elementListItem,
        getNextId,
        chapterListLevels,
        hasLowerRightElementOneIndent
      })

      // XXXX
      //           0000
      //           XXXX
    } else if (!hasChildren && !rightHasSiblingsUpper && rightHasSiblingsLower && !hasUpperRightElementOneIndent) {
      rightNoChNoUpSibHasDnSibNoUpRtIndent({
        currentElement,
        elementListItem,
        hasUpperRightElementOneIndent,
        getNextId,
        chapterListLevels
      })

      // XXXX
      // 0000
      //            XXXX
    } else if (!hasChildren && rightHasSiblingsUpper && !rightHasSiblingsLower && !hasUpperRightElementOneIndent) { //&& !hasSiblingsUpper && !hasSiblingsLower can show up if there are records further below or upward, but really, we are just going to put in another list and listItem to push this over no matter what is above or below it in this case.
      rightNoChHasUpSibNoDnSibNoUpRtIndent({currentElement, elementListItem, getNextId, chapterListLevels})

      // XXXX
      //      0000
      // XXXX
    } else if (!hasChildren && !hasUpperRightElementOneIndent) { //&& !hasSiblingsUpper && !hasSiblingsLower can show up if there are records further below or upward, but really, we are just going to put in another list and listItem to push this over no matter what is above or below it in this case.
      rightNoChNoUpSibNoDnSibNoUpRtIndent({currentElement, elementListItem, getNextId, chapterListLevels})

      //      XXXX
      //            XXXX
      //      0000
      //      XXXX
    } else if (!hasChildren && rightHasSiblingsUpper && rightHasSiblingsLower && hasUpperRightElementOneIndent) {
      rightNoChHasUpSibHasDnSibHasUpRtIndent({
        currentElement,
        elementListItem,
        getNextId,
        chapterListLevels,
        hasUpperRightElementOneIndent
      })

    }
  }
}

export const getListStructure = (currentElement) => {
  //When looking for the topList, we actually need to go all the way to the main-body-div or the editorDiv. Then we can pick up the last Ol or UL before we hit that very top.
  //    The reason for that is that when we use this structure for shift-tab for the editor in order to put the cursor into the previous span, if we are just looking
  //    for the fist OL or UL then we are stopping short and not able to get the user up into the next span above the current OL or UL - which could be many of embedded lists.
  if (currentElement) {
    let structure = []
    let editorDivTop
    let topList = currentElement.nodeName === 'UL' || currentElement.nodeName === 'OL' ? currentElement : ''
    let pointerElement
    let parent = currentElement.parentElement
    //Once we find the editorDivTop going backwards up the next parent, we would have saved the last topList element of the current list structure, so we are good to go and use tha topList.
    while (!(editorDivTop && topList) && parent) {
      if (parent && (parent.nodeName === 'UL' || parent.nodeName === 'OL')) topList = parent
      if (parent && (parent.nodeName === 'editorDiv' || parent.dataset.mainBodyTag === 'yes')) editorDivTop = parent
      parent = parent.parentElement
    }
    if (topList) {
      structure.push({
        level: 0,
        marginLeft: topList.style['margin-left'],
        style: topList.style.cssText,
        listStyle: topList.style['list-style'],
        listStyleType: topList.style['list-style-type'],
        element: topList
      })  //I know that we are starting with level 0 on this UL/OL record. It is because we increment the level on LI and then step back to update the LI parent's UL/OL record to one more level up.\\
      pointerElement = topList
      structure = addListChildren(structure, pointerElement.children, currentElement)
    }
    return structure
  }
}

const addListChildren = (structure, children, currentElement) => {
  for (let i = 0; i < children.length; i++) {
    const level = getElementLevel(children[i])
    //If this is the LI record, then step back one record in order to update it's parent record.
    //if (children[i].nodeName === 'UL' || children[i].nodeName === 'OL') structure = incrementListItemParentLevelOne(structure)
    structure.push({
      level: level,
      marginLeft: children[i].style['margin-left'],
      style: children[i].style.cssText,
      listStyle: children[i].style['list-style'],
      listStyleType: children[i].style['list-style-type'],
      element: children[i]
    })
    structure = addListChildren(structure, children[i].children, currentElement)
  }
  return structure
}

export const getStructureIndex = (structure = [], currentElement) => {
  let index
  structure.forEach((m, i) => {
    if (m.element === currentElement) index = i
  })
  return index
}

const getAboveStructure = (structure, elementSpanIndex) => {
  if (structure) {
    let upperStructure = structure.slice(0, elementSpanIndex - 1)
    let content = upperStructure.filter(m => m.element.nodeName === 'SPAN')
    return content && content.length > 0
  }
}

const getHasChildren = (currentElement, elementListItem) => {
  //We are going to switch over to the DOM in order to see if this has an OL or UL list underneath the same listItem as the span
  if (!(elementListItem && elementListItem.nodeName === 'LI')) {
    if (currentElement && currentElement.nodeName === 'SPAN') {
      elementListItem = currentElement.parentElement
    }
  }
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') return true
  }
}

const getSiblingsUpper = (elementListItem) => {
  //We will check to see if ths previousSibling (an LI) actually has text below it.
  let previousSibling = elementListItem && elementListItem.previousSibling
  return previousSibling && previousSibling.firstChild && previousSibling.firstChild.nodeName === 'SPAN'
}

const getSiblingsLower = (elementListItem) => {
  return elementListItem.nextSibling
}

const getIsFirstChildOfList = (currentElement) => {
  if (currentElement) {
    const listItem = currentElement.parentElement
    const list = listItem.parentElement
    let isFirstListItem = list.children[0] === listItem
    let isFirstContents
    for (let i = 0; i < listItem.children.length; i++) {
      if (!isFirstContents && listItem.children[i].nodeName === 'SPAN' && listItem.children[i].id && listItem.children[i] == currentElement) {
        isFirstContents = true
        break
      }
    }
    return isFirstListItem && isFirstContents
  }
}

const getUpperLeftElementOneIndent = (currentElement) => {
  if (currentElement) {
    let currentLevel = getElementLevel(currentElement)
    let targetLevel = --currentLevel
    let listItem = currentElement.parentElement
    let list = listItem.parentElement
    let parentListItem = list.parentElement
    if (parentListItem.nodeName === 'LI' && getElementLevel(parentListItem) === targetLevel) {
      return parentListItem
    } else {
      //Second, we will loop through the children of the parentListItem to see if there are any elements that see if there is an upper listItem that is one tab to the left compared to the current listItem
      let foundCurrentListItem = false
      for(let i = 0; i < parentListItem.children.length; i++) {
        if (!foundCurrentListItem && parentListItem.children[i].nodeName === 'LI' && getElementLevel(parentListItem.children[i]) === targetLevel) {
          return parentListItem.children[i]
        } else {
          if (parentListItem.children[i] === listItem) foundCurrentListItem = true
        }
      }
    }
  }
}

const getUpperLeftElementTwoIndent = (currentElement) => {
  if (currentElement) {
    let currentLevel = getElementLevel(currentElement)
    let targetLevel = Number(currentLevel) - 2 //Two tabs to the left of the currentLevel.
    let listItem = currentElement.parentElement
    let list = listItem.parentElement
    let parentListItem = list.parentElement
    if (parentListItem.nodeName === 'LI' && getElementLevel(parentListItem) === targetLevel) {
      return parentListItem
    } else {
      //Second, we will loop through the children of the parentListItem to see if there are any elements that see if there is an upper listItem that is one tab to the left compared to the current listItem
      let foundCurrentListItem = false
      for(let i = 0; i < parentListItem.children.length; i++) {
        if (!foundCurrentListItem && parentListItem.children[i].nodeName === 'LI' && getElementLevel(parentListItem.children[i]) === targetLevel) {
          return parentListItem.children[i]
        } else {
          if (parentListItem.children[i] === listItem) foundCurrentListItem = true
        }
      }
    }
  }
}

const getGrandParent = (currentElement) => {
  if (currentElement) {
    let parent = currentElement.parentElement
    return parent && parent.parentElement
  }
}

const getUpperLeftElementMoreIndent = (currentElement, elementListItem) => {
  if (currentElement && elementListItem) {
    //If there is a parent to the target that is just one level less, then this is one indent back.
    const structure = getListStructure(currentElement)
    const elementSpanIndex = getStructureIndex(structure, currentElement)
    const targetLevel = structure[elementSpanIndex].level
    const grandParent = elementListItem.parentElement && elementListItem.parentElement.parentElement
    if (grandParent) {
      const parentStructure = structure.filter(m => m.element === grandParent)[0]
      if (parentStructure) {
        return parentStructure.level <= targetLevel - 2 ? parentStructure.element : ''
      }
    }
  }
}

const getLowerLeftElementOneIndent = (currentElement) => {
  //There may be a sibling of the parentElement but we need to be sure that it is on the same level as the currentElement
  //First, we will look at the list above to find a sibling of the given list of the currentElement
  //Second, we will go to the parentListItem to see if there is a lower listItem sibling that is one tab left in compared to the current listItem
  // Done: 6/27/2023
  if (currentElement) {
    let currentLevel = getElementLevel(currentElement)
    let targetLevel = --currentLevel
    let listItem = currentElement.parentElement
    let list = listItem.parentElement
    let siblingLevel = getElementLevel(listItem.nextSibling)
    if (siblingLevel === targetLevel) {
      return listItem.nextSibling
    } else {
      //Second, we will see if there is a lower listItem that is one tab in compared to the current listItem
      let nextSibling = list.nextElementSibling
      if (nextSibling) {
        siblingLevel = getElementLevel(nextSibling.firstChild)
        if (siblingLevel === targetLevel) return nextSibling.firstChild //This list.nextSibling should be a listItem?
      }
    }
    let parentListItem = list.parentElement
    let parentListItemNextSibling = parentListItem.nextSibling
    if (parentListItemNextSibling) {
      siblingLevel = getElementLevel(parentListItemNextSibling)
      if (siblingLevel === targetLevel) return parentListItemNextSibling //This list.nextSibling should be a listItem?
    }
  }
}

//Right moves
const getUpperRightElementOneIndent = (currentElement) => {
  let currentLevel = getElementLevel(currentElement)
  let targetLevel = ++currentLevel
  let listItem = currentElement.parentElement
  let list = listItem.parentElement
  let listItemPreviousSibling = listItem.previousElementSibling
  let lastMatchingLevelElement
  if (listItemPreviousSibling) {
    for(let i = 0; i < listItemPreviousSibling.children.length; i++) {
      let element = listItemPreviousSibling.children[i]
      if (element.nodeName === 'OL' || element.nodeName === 'UL') {
        for(let c = 0; c < element.children.length; c++) {
          if (element.children[c].nodeName === 'LI' && getElementLevel(element.children[c]) === targetLevel) {
            lastMatchingLevelElement = element.children[c]
          }
        }
      }
      //In the case I was testing, the lastMatchingLevelElement was found from the last, nested for loop above. This confused the detail and got the first one in the list when there were more LIs in the list which needs to be the last one in the list.
      // let listItem = element && (element.nodeName === 'OL' || element.nodeName === 'UL') ? element.firstChild : element
      // if (listItem.nodeName === 'LI' && getElementLevel(listItem) === targetLevel) {
      //   lastMatchingLevelElement = listItem
      // }
    }
    if (lastMatchingLevelElement) return lastMatchingLevelElement
  }
  let listPreviousSibling = list.previousElementSibling
  if (listPreviousSibling) {
    for(let i = 0; i < listPreviousSibling.children.length; i++) {
      let element = listPreviousSibling.children[i]
      if (element.nodeName === 'OL' || element.nodeName === 'UL') {
        for (let c = 0; c < element.children.length; c++) {
          if (element.children[c].nodeName === 'LI' && getElementLevel(element.children[c]) === targetLevel) {
            lastMatchingLevelElement = element.children[c]
          }
        }
      }
      let listItem = element && (element.nodeName === 'OL' || element.nodeName === 'UL') ? element.firstChild : element
      if (listItem.nodeName === 'LI' && getElementLevel(listItem) === targetLevel) {
        lastMatchingLevelElement = listItem
      }
    }
    if (lastMatchingLevelElement) return lastMatchingLevelElement
  }

  // const structure = getListStructure(currentElement)
  // const elementSpanIndex = getStructureIndex(structure, currentElement)
  // let currentLevel = getElementLevel(structure[elementSpanIndex].element)
  // let targetLevel = currentLevel + 1 * 1
  // let spanOrListItem
  // let levelCheck
  // if (!spanOrListItem && structure[elementSpanIndex - 3]) {
  //   levelCheck = getElementLevel(structure[elementSpanIndex - 3].element)
  //   if (levelCheck && levelCheck === targetLevel && structure[elementSpanIndex - 3].element.nodeName === 'LI') {
  //     spanOrListItem = structure[elementSpanIndex - 3].element.nodeName === 'SPAN' ? structure[elementSpanIndex - 3].element.parentElement : structure[elementSpanIndex - 3].element
  //   }
  //   if (!spanOrListItem && structure[elementSpanIndex - 4]) {
  //     levelCheck = getElementLevel(structure[elementSpanIndex - 4].element)
  //     if (levelCheck && levelCheck === targetLevel && structure[elementSpanIndex - 4].element.nodeName === 'LI') {
  //       spanOrListItem = structure[elementSpanIndex - 4].element.nodeName === 'SPAN' ? structure[elementSpanIndex - 4].element.parentElement : structure[elementSpanIndex - 4].element
  //     }
  //     if (!spanOrListItem && structure[elementSpanIndex - 5]) {
  //       levelCheck = getElementLevel(structure[elementSpanIndex - 5].element)
  //       if (levelCheck && levelCheck === targetLevel && structure[elementSpanIndex - 5].element.nodeName === 'LI') {
  //         spanOrListItem = structure[elementSpanIndex - 5].element.nodeName === 'SPAN' ? structure[elementSpanIndex - 5].element.parentElement : structure[elementSpanIndex - 5].element
  //       }
  //     }
  //   }
  // }
  // //This should be a listItem (LI)
  // return spanOrListItem && spanOrListItem.nodeName === 'SPAN' ? spanOrListItem.parentElement : spanOrListItem
}

const getUpperRightElementTwoIndent = (currentElement) => {
  //Reach up to the previous sibling and then crawl down any tree structure to get back to the currentElement.
  // Pick up the last listItem and see if it is two tabs away.
  let currentLevel = getElementLevel(currentElement)
  const targetLevel = Number(currentLevel) + 2
  const listItem = currentElement.parentElement
  const list = listItem.parentElement
  let previousSiblingTree = listItem.previousElementSibling
  if (!previousSiblingTree) {
    previousSiblingTree = list.previousElementSibling
  }
  let lastListItem = ""
  lastListItem = findLastListItem(previousSiblingTree, lastListItem)
  if (getElementLevel(lastListItem) === targetLevel) return lastListItem
}

const findLastListItem = (element, lastListItem) => {
  if (element && element.children) {
    for (let i = 0; i < element.children.length; i++) {
      if (element.children[i].nodeName === 'LI') lastListItem = element.children[i]
      if (element.children[i].children.length > 0) lastListItem = findLastListItem(element.children[i], lastListItem)
    }
  }
  return lastListItem
}

const getLowerRightElementTwoIndent = (currentElement) => {
  //Get the children of the currentElement and find the firstChild.
  let currentLevel = getElementLevel(currentElement)
  const targetLevel = Number(currentLevel) + 2
  const listItem = currentElement.parentElement
  let firstListItem
  for(let i = 0; i < listItem.children.length && !firstListItem; i++) {
    if (listItem.children[i].nodeName === 'LI') firstListItem = listItem.children[i]
    if (listItem.children[i].children.length > 0) {
      for(let c = 0; c < listItem.children[i].children.length && !firstListItem; c++) {
        if (listItem.children[i].children[c].nodeName === 'LI') firstListItem = listItem.children[i].children[c]
      }
    }
  }
  if (getElementLevel(firstListItem) === targetLevel) return firstListItem
}


const getLowerRightElementOneIndent = (currentElement, elementListItem) => {
  let currentLevel = getElementLevel(currentElement)
  let targetLevel = currentLevel + 1 * 1
  //Look first for the child under the elementList Item
  //Then further below, go up to the current list, look for the next sibling and see if that first child is on the target level
  for (let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'UL' || elementListItem.children[i].nodeName === 'OL') {
      let elementList = elementListItem.children[i]
      for (let c = 0; c < elementList.children.length; c++) {
        if (getElementLevel(elementList.children[c]) === targetLevel) {
          return {
            listItem: elementList.children[c],
            list: elementList
          }
        }
      }
    }
  }
  const list = elementListItem.parentElement
  const listNextSibling = list.nextSibling
  if (listNextSibling) {
    const listNextSiblingListItem = listNextSibling.firstChild
    if (listNextSiblingListItem && getElementLevel(listNextSiblingListItem) === targetLevel) {
      return {
        listItem: listNextSiblingListItem,
        list: listNextSibling
      }
    }
  }

}

const leftHasMultipleTabIndentsIsFirstChNoChNoLwrSibNoLwrLeft = ({elementListItem, chapterListLevels}) => {
  // XXXX
  //              0000
  //xxxx
  increaseMarginLeft(-1, elementListItem)
  setLevelCharacter(elementListItem.parentElement, chapterListLevels)
}

const leftHasMultipleTabIndentsIsFirstChHasLwrSibNoLwrLeftHasUpLeftTwoIndent = ({
    currentElement,
    elementListItem,
    getNextId,
    chapterListLevels,
    hasUpperLeftElementTwoIndent
  }) => {
  // XXXX
  //             0000
  //                   |XXXX|
  //             XXXX
  //This first child top just needs to go to the targetListItem as a first child and then whatever is left over in a list below this currentElement becomes the child of the currentElement
  //Done: 6/27/2023
  const list = elementListItem.parentElement
  const parentList = list.parentElement
  const targetListItem = hasUpperLeftElementTwoIndent
  //const targetList = targetListItem.parentElement
  let newList = document.createElement(list.nodeName)
  newList.id = getNextId()
  newList.setAttribute('style', list.style.cssText)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  newList.setAttribute('style', targetListItem.style.cssText)
  newList.append(newListItem)
  appendListItemSpans(newListItem, elementListItem)
  parentList.insertBefore(newList, list)
  //increaseMarginLeft(-1, newListItem)
  setLevelCharacter(newList, chapterListLevels)
  newListItem.append(list)
  for(let i = 0; i < list.children.length; i++) {
    increaseMarginLeft(-1, list.children[i])
  }
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }}

const leftHasMultipleTabIndentsIsFirstChHasLwrSibNoLwrLeft = ({
                                                                    currentElement,
                                                                    elementListItem,
                                                                    getNextId,
                                                                    chapterListLevels
                                                                  }) => {
  // XXXX
  //             0000
  //                   |XXXX|
  //             XXXX
  //get the upper list (to get ready to insertBefore the existing list)
  //  Create a new listItem
  //  append the currentElement(s) to the new List
  //  decrease the multiple tab indent by one tab value
  //  Set the level character for the new list.
  //Done: 6/26/2023
  const list = elementListItem.parentElement
  const parentList = list.parentElement
  let newList = document.createElement(list.nodeName)
  newList.id = getNextId()
  newList.setAttribute('style', list.style.cssText)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  newListItem.setAttribute('style', elementListItem.style.cssText)
  newList.append(newListItem)
  appendListItemSpans(newListItem, elementListItem)
  parentList.insertBefore(newList, list)
  increaseMarginLeft(-1, newListItem)
  setLevelCharacter(newListItem.parentElement, chapterListLevels)
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
}

const rightFirstChildNoLwrSibNoUpRtOneTab = ({elementListItem, chapterListLevels, hasLowerRightElementOneIndent}) => {
  //XXXX
  //          0000
  //                XXXX
  // Done: 6/26/2023
  if (hasLowerRightElementOneIndent) {
    //Take the elementListItem and insertBefore the beginning of the hasLowerRightElementOneIndent.list
    //Remove the list
    let list = elementListItem.parentElement
    hasLowerRightElementOneIndent.list.insertBefore(elementListItem, hasLowerRightElementOneIndent.listItem)
    increaseMarginLeft(1, elementListItem)
    list.remove()
  } else {
    increaseMarginLeft(1, elementListItem)
    setLevelCharacter(elementListItem.parentElement, chapterListLevels)
  }
}

const rightFirstChildNoChHasLwrSibNoUpRtOneTab = ({currentElement, elementListItem, getNextId, chapterListLevels}) => {
  //XXXX
  //          0000
  //          XXXX
  //Get the parent listItem
  //Create a new list for the target to go into since it will now be independent and indented one more tab value
  //move the target into the new list
  //Append the new list to the parent listItem just below th esibling below.
  //Done 6/24/2023
  let list = elementListItem.parentElement
  let parentListItem = list.parentElement
  let newList = document.createElement(list.nodeName)
  newList.id = getNextId()
  newList.setAttribute('style', list.style.cssText)
  newList.style['list-style-type'] = list.style['list-style-type']
  setLevelStyles(newList, chapterListLevels, elementListItem, 1)
  increaseMarginLeft(1, elementListItem)
  newList.append(elementListItem)
  parentListItem.insertBefore(newList, list)
  setLevelCharacter(newList, chapterListLevels)
}

const rightHasChHasUpSibHasLwrRtIndent = ({ currentElement, elementListItem, getNextId, chapterListLevels, hasLowerRightElementOneIndent }) => {
  //      XXXX
  //      0000
  //                XXXX
  //           XXXX
  // Needs to become a part of the lower right element list
  // Get the hasLowerRightElementOneIndent list
  // Get the hasLowerRightElementOneIndent listItem
  // Get the previousSibling of the elementListItem
  // Create a newListItem to match the style of the hasLowerRightElementOneIndent listItem
  // Move the currentElement(s) into the newlistItem from the current elementListItem
  // Move any children that are left in the current elementListItem (except the target element)
  //    decrement the tab value of the marginLeft of the childrenList children
  // remove the old elementListItem
  // Append the resulting target list to the end of the upper previousSibling of the elementListItem
  // Done: 6/26/2023
  const listItemPreviousSibling = elementListItem.previousElementSibling
  const targetList = hasLowerRightElementOneIndent.list
  const targetListItem = hasLowerRightElementOneIndent.listItem
  const childrenList = getListChildren(currentElement)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  newListItem.setAttribute('style', targetListItem.style.cssText)
  appendListItemSpans(newListItem, elementListItem)
  listItemPreviousSibling.append(targetList)
  targetList.insertBefore(newListItem, targetList.firstChild)
  if (childrenList && childrenList.length > 0) {
    for(let i = 0; i < childrenList.length; i++) {
      if (childrenList[0] !== targetList) {
        newListItem.append(childrenList[0])
        for (let c = 0; c < childrenList[0].children.length; c++) {
          setLevelStyles(childrenList[0].children[c], chapterListLevels, newListItem, 1)
        }
      }
    }
  }
  elementListItem.remove()
}


  const rightFirstChildHasChHasLwrSibNoUpRtOneTab = ({currentElement, elementListItem, getNextId, chapterListLevels}) => {
  //XXXX
  //          0000
  //                  XXXX
  //          XXXX
  //We will leave the sibling with the existing list
  //Create a new list to go above the sibling and just below the parent listItem
  //Place the currentElement's in their own listitem and at the top of children list
  //Move those children over one more tab due to the new listItem that exists.
  //DONE 6/24/2023
  let list = elementListItem.parentElement
  let parentListItem = list.parentElement
  // let newList = document.createElement(list.nodeName)
  // newList.id = getNextId()
  // newList.setAttribute('style', list.style.cssText)
  let newChildListItem = document.createElement('LI')
  newChildListItem.id = getNextId()
  appendListItemSpans(newChildListItem, elementListItem)
  let childList = elementListItem.firstChild
  let firstChildrenListItem = childList.firstChild
  newChildListItem.setAttribute('style', firstChildrenListItem.style.cssText)
  childList.insertBefore(newChildListItem, childList.firstChild)
  parentListItem.insertBefore(childList, list)
  //setLevelStyle(list.nodeName, newList, chapterListLevels)
  setLevelStyle(list.nodeName, newChildListItem, chapterListLevels)
  for (let i = 0; i < childList.children.length; i++) {
    increaseMarginLeft(1, childList.children[i])
  }
  setLevelCharacter(childList, chapterListLevels)
  //setLevelCharacter(newList, chapterListLevels)
  elementListItem.remove()
}

const leftHasUpStrIsFirstLvl = ({currentElement, structure, elementListItem, getNextId, chapterListLevels}) => {
  // XXXX
  // 0000
  // XXXX or XXXX (siblings or children)
  let elementList = elementListItem.parentElement
  let lowerSiblings = getLowerSiblings(elementListItem)
  let parentToStructure = structure[0].element.parentElement
  let topListSibling = elementList.nextSibling
  let newParagraph = document.createElement('p')
  newParagraph.id = getNextId()
  appendListItemSpans(newParagraph, elementListItem)
  parentToStructure.insertBefore(newParagraph, topListSibling)
  let newList = document.createElement(elementList.nodeName)
  newList.id = getNextId()
  setLevelStyles(newList, chapterListLevels, elementList, "", 1)  //Directly set to level 1
  newList.append(elementListItem)
  for (let i = 0; i < lowerSiblings.length; i++) {
    newList.append(lowerSiblings[i])
  }
  parentToStructure.insertBefore(newList, topListSibling)

  if (elementListItem.children && elementListItem.children.length > 0) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
}

const leftNoUpStrIsFirstLvl = ({currentElement, structure, elementListItem, getNextId}) => {
  //0. Find that there has no preceding structure and if this is a first level target moving to the left (and outside of the structure)
  // 0000
  // XXXX or XXXX (siblings or children)
  // 1. Create a paragraph to be insertBefore the OL/UL structure
  // 2. Insert the currentElement (span) inside hte paragraph
  // 3. Set the style's list-style-type attribute to 'none' (which keeps the structure there without a bullet despite the currentElement's absence)
  let childrenList = getListChildren(currentElement)  //Done 6/26/2023
  let parentToStructure = structure[0].element.parentElement
  let topStructureList = structure[0].element
  let newParagraph = document.createElement('p')
  newParagraph.id = getNextId()
  appendListItemSpans(newParagraph, elementListItem)
  parentToStructure.insertBefore(newParagraph, topStructureList)
  if (childrenList && childrenList.length > 0) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
}


const leftNoFirstLvlNoUpLftMoreTabNoUpLftOneTabNoLwrLftOneTab = ({
                                                                   currentElement,
                                                                   elementListItem,
                                                                   chapterListLevels,
                                                                   getNextId
                                                                 }) => {
  //                   XXXX
  //            0000
  //            XXXX
  //            XXXX
  // Get the list element
  // Get the parentListItem
  // Get the parentListItemNextSibling
  // Get the parentList
  // NO: Create a new listItem to match the parentListItem (style)
  // insert the elementListItem before the parentListItemNextSibling
  // if the original list is NOT empty,
  //    append the original to the new listItem with whatever is left there
  // else
  //    delete the original list
  // end if
  // If there are any children left below the parentListItem, they will remain in place.
  // Done: 6/26/2023
  let list = elementListItem.parentElement
  let parentListItem = list.parentElement
  let parentListItemNextSibling = parentListItem.nextElementSibling
  let parentList = parentListItem.parentElement
  elementListItem.setAttribute('style', parentListItem.style.cssText)
  parentList.insertBefore(elementListItem, parentListItemNextSibling)
  if (list.children.length > 0) {
    elementListItem.append(list)
  } else {
    list.remove()
  }
}

const leftHasChNoUpSibNoDnSibHasDnLftOneTabHasUpLftMoreTab = ({
                                                                currentElement,
                                                                elementListItem,
                                                                hasLowerLeftElementOneIndent,
                                                                hasUpperLeftElementMoreIndent,
                                                                getNextId,
                                                                chapterListLevels
                                                              }) => {
  //0. Find that this target has no children but no siblings above or below, but has an upper left level that is one indent away (or more), but has one lower level that is one indent in.
  //This needs to be before the if condition: hasChildren && !hasSiblingsUpper && !hasSiblingsLower
  // XXXX
  //      XXXX
  //                  0000
  //            XXXX
  // 1. Save off the target itself (the span - not the listItem) - we already have that in currentElement
  // 2. Save off the target's listItem - we already have that in elementListItem
  // 3. Save off the children of the target
  // 4. Get the style of the lower left listItem level
  // 5. Get the list element of the lower less level
  // 6. Set the listItem style to the style of the lower less level
  // 7. Insert the target at the beginning of the lower list on the left
  // 8. Delete the target's list element
  // 9. If there are children of the target, loop through and increase their margin-left by one indent value (36pt)
  //******
  let siblings = getLowerSiblings(elementListItem)
  let childrenList = getListChildren(currentElement)  //Done 6/26/2023
  let styleLowerLessLevel = hasLowerLeftElementOneIndent && hasLowerLeftElementOneIndent.getAttribute('style')
  let listStyleType = hasLowerLeftElementOneIndent && hasLowerLeftElementOneIndent.parentElement && hasLowerLeftElementOneIndent.parentElement.style['list-style-type']
  let grandParent = hasUpperLeftElementMoreIndent.parentElement
  let indexInsert
  for (let i = 0; i < grandParent.children.length; i++) {
    if (grandParent.children[i] === hasUpperLeftElementMoreIndent) indexInsert = i + 1 * 1
  }
  if (styleLowerLessLevel) {
    setLevelStyles(elementListItem, chapterListLevels, hasLowerLeftElementOneIndent)
    //elementListItem.setAttribute('style', styleLowerLessLevel)
  } else {
    increaseMarginLeft(-1, elementListItem)
  }
  if (elementListItem.parentElement) elementListItem.parentElement.style['list-style-type'] = listStyleType
  appendCurrentElementAndSiblings(currentElement, elementListItem)

  if (siblings.length > 0 || (childrenList && childrenList.length > 0)) {
    let elementList = elementListItem.parentElement
    if (elementList) {
      let newList = document.createElement(elementList.nodeName)
      newList.id = getNextId()
      setLevelStyles(newList, chapterListLevels, elementListItem, 1)
      elementListItem.append(newList)
      const childrenListLength = childrenList.length
      for (let i = 0; i < childrenListLength; i++) {
        newList.append(childrenList[i])
        for (let c = 0; c < childrenList[i].children.length; c++) {
          increaseMarginLeft(1, childrenList[i].children[c])
        }
      }
      for (let i = 0; i < siblings.length; i++) {
        newList.append(siblings[i])
      }
    }
  }
}

const leftNoChHasUpSibNoDnSibHasLwrLftOneTab = ({currentElement, elementListItem, getNextId, chapterListLevels, hasLowerLeftElementOneIndent}) => {
  //      XXXX
  //            XXXX
  //      0000
  // XXXX
  let targetListItem = hasLowerLeftElementOneIndent.nodeName === 'LI' ? hasLowerLeftElementOneIndent : hasLowerLeftElementOneIndent.firstChild
  let targetList = hasLowerLeftElementOneIndent.parentElement
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  newListItem.setAttribute('style', targetListItem.style.cssText)
  appendListItemSpans(newListItem, elementListItem)
  targetList.insertBefore(newListItem, targetListItem)
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
}


  const leftNoChNoUpSibNoDnSibNoUpperStr = ({currentElement, elementListItem, hasLowerLeftElementOneIndent}) => {
  //0. Find that this target has no children and no siblings above or below and not structure above
  //        0000
  //  XXXX
  //Move this element into the top of the lower left list.
  //Done 6/26/2023
  let elementList = elementListItem.parentElement
  let targetListItem = !hasLowerLeftElementOneIndent ? '' : hasLowerLeftElementOneIndent.nodeName === 'LI' ? hasLowerLeftElementOneIndent : <hasLowerLeftElementOneIndent className="firstChild"></hasLowerLeftElementOneIndent>
  let targetList = targetListItem.parentElement
  if (targetList) {
    targetList.insertBefore(elementListItem, targetListItem)
    increaseMarginLeft(-1, elementListItem)
  }
  elementList.remove()
}

const leftNoChNoUpSibNoDnSibHasLowerOneIndent = ({
                                                   elementListItem,
                                                   chapterListLevels,
                                                   getNextId,
                                                   hasLowerLeftElementOneIndent
                                                 }) => {
  //0. Find that this target has no children and no siblings above or below and not structure above
  // XXXX
  //       XXXX
  //                  0000
  //            XXXX
  //Move this element into the top of the lower left list.
  //******
  //The new listItem to match the lower left element listItems
  //Done 6/26/2023
  let targetList
  let targetListItem
  if (hasLowerLeftElementOneIndent) {
    if (hasLowerLeftElementOneIndent.nodeName === 'LI') {
      targetListItem = hasLowerLeftElementOneIndent
      targetList = hasLowerLeftElementOneIndent.parentElement
    } else if (hasLowerLeftElementOneIndent.nodeName === 'OL' || hasLowerLeftElementOneIndent.nodeName === 'UL') {
      targetList = hasLowerLeftElementOneIndent
      targetListItem = hasLowerLeftElementOneIndent.firstChild
    }
    let siblings = getLowerSiblings(elementListItem)
    let currentList = elementListItem.parentElement
    //The list item to join the lower left element
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId()
    newListItem.setAttribute('style', targetListItem.style.cssText)
    newListItem.style['margin-bottom'] = '0pt' // Just in case we are getting the style from the last listItem in the structure which gives a margin bottom to give space from the next element out of the list.
    //setLevelStyles(newListItem, chapterListLevels, targetListItem)
    newListItem.style['list-style-type'] = targetListItem.style['list-style-type']
    setLevelCharacter(newListItem.parentElement, chapterListLevels)
    appendListItemSpans(newListItem, elementListItem)
    newListItem.append(currentList)
    targetList.insertBefore(newListItem, targetListItem)
    for (let i = 0; i < siblings.length; i++) {
      increaseMarginLeft(-1, siblings[i])
    }
    elementListItem.remove()
    currentList.remove()
  }
}

const leftNoChNoUpSibNoDnSibHasUpLftOneTab = ({currentElement, elementListItem, getNextId, hasUpperLeftElementOneIndent, chapterListLevels, hasLowerLeftElementOneIndent}) => {
  //Needs to be above: !hasChildren && !hasSiblingsUpper && !hasSiblingsLower
  // XXXX
  //      XXXX
  //                  XXXX
  //            0000
  //      XXXX
  // Done: 6/26/2023
  let list = elementListItem.parentElement
  let parentListItem = list.parentElement
  let parentListItemNextSibling = parentListItem.nextSibling
  let parentList = parentListItem.parentElement
  let newListItem = document.createElement(parentListItem.nodeName)
  newListItem.id = getNextId()
  newListItem.setAttribute('style', parentListItem.style.cssText)
  parentList.insertBefore(newListItem, parentListItemNextSibling)
  appendListItemSpans(newListItem, elementListItem)
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
  if (hasListItemElements(list)) {
    list.style['list-style-type'] = 'none'
  } else {
    list.remove()
  }
}

const leftNoChNoUpSibNoDnSib = ({currentElement, elementListItem, chapterListLevels, chosenSegment}) => {
  //0. Find that this target has no children and no siblings above or below
  // XXXX
  //      XXXX
  //            0000
  //      XXXX
  if (!currentElement) {
    let lastChosen = chosenSegment[chosenSegment.length - 1]
    if (lastChosen) currentElement = document.querySelector(`span[id="%{lastChosen.id}"][data-type='TEXT']`)
  }
  if (currentElement) {
    let listItem = currentElement.parentElement
    let list = listItem.parentElement
    let parentListItem = list.parentElement
    let parentList = parentListItem.parentElement
    let indexInsert
    for (let i = 0; i < parentList.children.length; i++) {
      if (parentList.children[i] === parentListItem) indexInsert = i + 1 * 1
    }
    list.remove()
    //parentListItem.remove()
    setLevelStyles(listItem, chapterListLevels, parentListItem)
    if (parentList.children.length === indexInsert) {
      parentList.append(listItem)
    } else {
      parentList.insertBefore(listItem, parentList.children[indexInsert])
    }
  }
}

const leftNoChNoUpSibHasDnSibHasLwrLftOneTabHasUpLeftOneTab = ({currentElement, elementListItem, getNextId, hasUpperLeftElementOneIndent, hasLowerLeftElementOneIndent, chapterListLevels }) => {
  // XXXX             XXXXX
  //      0000    OR                0000
  //      XXXX                      XXXX
  // XXXX                     XXXX
  //Done 6/27/2023
  let targetList
  let targetListItem
  if (hasLowerLeftElementOneIndent) {
    if (hasLowerLeftElementOneIndent.nodeName === 'LI') {
      targetListItem = hasLowerLeftElementOneIndent
      targetList = hasLowerLeftElementOneIndent.parentElement
    } else if (hasLowerLeftElementOneIndent.nodeName === 'OL' || hasLowerLeftElementOneIndent.nodeName === 'UL') {
      targetList = hasLowerLeftElementOneIndent
      targetListItem = hasLowerLeftElementOneIndent.firstChild
    }
    let list = elementListItem.parentElement
    let newListItem = document.createElement('li')
    newListItem.id = getNextId()
    newListItem.setAttribute('style', targetListItem.style.cssText)
    appendListItemSpans(newListItem, elementListItem)
    targetList.insertBefore(newListItem, targetListItem)
    newListItem.append(list)
    let currentListItem = newListItem
    let targetLevel = getElementLevel(newListItem)
    for(let i = 0; i < elementListItem.children.length;) {
      if (getElementLevel(elementListItem.children[0].firstChild) > targetLevel) {
        increaseMarginLeft(-1, elementListItem.children[0].firstChild)
        currentListItem.append(elementListItem.children[0])
      } else if (getElementLevel(elementListItem.children[0].firstChild) === targetLevel) {
        let anotherListItem = document.createElement('LI')
        anotherListItem.id = getNextId()
        anotherListItem.setAttribute('style', targetListItem.style.cssText)
        appendListItemSpans(anotherListItem, elementListItem.children[0].firstChild)
        targetList.append(anotherListItem)
        elementListItem.children[0].remove()
        currentListItem = anotherListItem
      }
    }
    if (hasListItemElements(elementListItem)) {
      elementListItem.style['list-style-type'] = 'none'
    } else {
      elementListItem.remove()
    }
  }
}

const leftNoChNoUpSibHasDnSibHasLwrLftOneTab = ({
                                                  currentElement,
                                                  elementListItem,
                                                  getNextId,
                                                  hasLowerLeftElementOneIndent,
                                                  chapterListLevels
                                                }) => {
  // XXXX
  //      XXXX
  //                  0000
  //                  XXXX
  //           XXXX
  // There are at least two lists involved under a main list.
  // The first list has two or more elements which are pushed over two tabs
  // The lower list has an element to the left.
  // We need to place the upper elements into the lower list by making the lower siblings of the currentElement children to the moved currentElement(s)
  //Done 6/24/2023
  let targetList
  let targetListItem
  if (hasLowerLeftElementOneIndent) {
    if (hasLowerLeftElementOneIndent.nodeName === 'LI') {
      targetListItem = hasLowerLeftElementOneIndent
      targetList = hasLowerLeftElementOneIndent.parentElement
    } else if (hasLowerLeftElementOneIndent.nodeName === 'OL' || hasLowerLeftElementOneIndent.nodeName === 'UL') {
      targetList = hasLowerLeftElementOneIndent
      targetListItem = hasLowerLeftElementOneIndent.firstChild
    }
    if (targetList && targetListItem) {
      let siblings = getLowerSiblings(elementListItem)
      let currentList = elementListItem.parentElement
      //The list item to join the lower left element
      let newListItem = document.createElement('LI')
      newListItem.id = getNextId()
      newListItem.setAttribute('style', targetListItem.style.cssText)
      newListItem.style['list-style-type'] = targetListItem.style['list-style-type']
      setLevelCharacter(newListItem, chapterListLevels)
      appendListItemSpans(newListItem, elementListItem)
      newListItem.append(currentList)
      targetList.insertBefore(newListItem, targetList.firstChild)
      for (let i = 0; i < siblings.length; i++) {
        increaseMarginLeft(-1, siblings[i])
      }
      elementListItem.remove()
    }
  }
}

const leftNoChNoUpSibHasDnSibHasUpLftMoreTab = ({elementListItem, hasUpperLeftElementMoreIndent}) => {
  //0. Find that this target has no children but has siblings below but not above, but has an upper left level that is one indent away (or more).
  //This needs to be above: !hasChildren && !hasSiblingsUpper && hasSiblingsLower
  // XXXX
  //      XXXX
  //                  0000
  //                  XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. Clone the target's list
  // 4. Decrease the clone's margin-left by one indent value (36pt)
  // 5. Insert the cloned target's list at the beginning of the upper left list
  // 6. Append the target in the cloned target's list
  let targetListClone = elementListItem.parentElement.cloneNode(true)
  increaseMarginLeft(-1, targetListClone)
  hasUpperLeftElementMoreIndent.insertBefore(targetListClone, hasUpperLeftElementMoreIndent.firstChild)
  targetListClone.append(elementListItem)
}

const leftNoChNoUpSibHasDnSibHasUpLeftOneTab = ({
                                                  currentElement,
                                                  elementListItem,
                                                  hasUpperLeftElementOneIndent,
                                                  getNextId,
                                                  chapterListLevels
                                                }) => {
  //0. Find that this target has no children and no siblings above but has siblings below
  // XXXX
  //      XXXX
  //            0000
  //            XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. If the listItem has more than one tabValue in margin-left,
  //      a. decrement by one
  //      b. decrement the level style
  //    Else
  //      4. Get the style of the upper less level
  //      5. Get the index of the upper less level and add one where the target is to be moved
  //      6. Set the listItem style to the style of the upper less level
  //      7. Insert the target at the index found above of the upper list on the left (which will take this target ouf of its sibling's list)
  //******
  //Create a new listItem to put below the upper left element
  //Move the currentElement(s) into the new listItem
  //Move the list of children and siblings under the currentElements in that new list item.
  let list = elementListItem.parentElement
  let parentListItem = list.parentElement
  let parentList = parentListItem.parentElement
  let parentListItemNextSibling = parentListItem.nextSibling
  let newListItem = document.createElement(parentListItem.nodeName)
  newListItem.id = getNextId()
  newListItem.setAttribute('style', parentListItem.style.cssText)
  if (parentListItemNextSibling) {
    parentList.insertBefore(newListItem, parentListItemNextSibling)
  } else {
    parentList.append(newListItem)
  }
  appendListItemSpans(newListItem, elementListItem)
  newListItem.append(list)
  elementListItem.remove()
}

const leftHasChNoUpSibHasLwrSibHasUpLftOneIndentHasNoLowerLeft = ({
                                                                    currentElement,
                                                                    elementListItem,
                                                                    getNextId,
                                                                    hasUpperLeftElementOneIndent,
                                                                    chapterListLevels
                                                                  }) => {
  // XXXX
  //      |XXXX|
  //      0000
  //            XXXX
  //      XXXX
  //Move the currentElement to a new listItem underneath hasUpperLeftElementOneIndent
  //Children stay in the original list
  //But the siblings need to go into another list since the children lost their base and need to be indented unnaturally more than once.
  //Done 6/24/2023
  //9/30/2024:  The top item (parent) could have children that need to be put under the parent stlil after the target move.
  let siblings = getLowerSiblings(elementListItem)
  let elementList = elementListItem.parentElement
  let parentListItem = elementList.parentElement
  let parentList = parentListItem.parentElement
  let parentListItemSibling = parentListItem.nextSibling

  if (parentListItem) {
    let childrenList = getListChildren(currentElement)  //Done 6/26/2023
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId()
    setLevelStyles(newListItem, chapterListLevels, parentListItem)
    appendListItemSpans(newListItem, elementListItem)
    parentList.insertBefore(newListItem, parentListItemSibling)
    const childrenListLength = childrenList.length
    for (let i = 0; i < childrenListLength; i++) {
      newListItem.append(childrenList[i])
      for (let c = 0; c < childrenList[i].children.length; c++) {
        increaseMarginLeft(1, childrenList[i].children[c])
      }
    }
    let newList = document.createElement(elementList.nodeName)
    newList.id = getNextId()
    newList.setAttribute('style', elementList.style.cssText)
    for (let i = 0; i < siblings.length; i++) {
      newList.append(siblings[i])
    }
    newListItem.append(newList)
    setLevelCharacter(newList, chapterListLevels)
    if (elementList.children.length === 0) elementList.remove()
    elementListItem.remove()
  }
  elementListItem.style['list-style'] = 'none'
}

const leftHasChNoUpSibHasLwrSibHasUpLftOneIndentHasLowerLeft = ({
                                                                  currentElement,
                                                                  elementListItem,
                                                                  getNextId,
                                                                  hasUpperLeftElementOneIndent,
                                                                  hasLowerLeftElementOneIndent,
                                                                  chapterListLevels
                                                                }) => {
  // XXXX
  //      0000
  //            XXXX
  //      XXXX
  // Done: 6/27/2023
  let list = elementListItem.parentElement
  let parentListItem = list.parentElement
  let parentListItemNextSibling = parentListItem.nextSibling
  let parentList = parentListItem.parentElement
  // let newListItem = document.createElement(parentListItem.nodeName)
  // newListItem.id = getNextId()
  // newListItem.setAttribute('style', parentListItem.style.cssText)
  parentList.insertBefore(elementListItem, parentListItemNextSibling)
  //appendListItemSpans(newListItem, elementListItem)
  //if (list.children && list.children.length > 0) newListItem.append(list)
  setLevelCharacter(elementListItem, chapterListLevels)
  for(let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'OL' || elementListItem.children[i].nodeName === 'UL') {
      for(let c = 0; c < elementListItem.children[i].children.length; c++) {
        increaseMarginLeft(1, elementListItem.children[i].children[c])
      }
    }
  }
  list.remove()
}

const leftHasChNoUpSibNoDnSibHasUpLftMoreTab = ({
                                                  currentElement,
                                                  elementListItem,
                                                  hasUpperLeftElementMoreIndent,
                                                  hasLowerLeftElementOneIndent,
                                                  getNextId,
                                                  chapterListLevels
                                                }) => {
  //0. Find that this target has one or more children but no siblings above or below, and that the upper left list is one more tab indent over (or more).
  //This needs to be above: hasChildren && !hasSiblingsUpper && !hasSiblingsLower
  // XXXX
  //      XXXX
  //                  0000
  //                        XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. Save off the children of the target
  // 4. Decrease the target's list margin-left by one indent value (36pt)
  // 5. Set the target's children's margin-left to add one indent value (36pt)
  //******
  let siblings = getLowerSiblings(elementListItem)
  let childrenList = getListChildren(currentElement)  //Done 6/26/2023
  let styleLowerLessLevel = hasLowerLeftElementOneIndent && hasLowerLeftElementOneIndent.getAttribute('style')
  let listStyleType = hasLowerLeftElementOneIndent && hasLowerLeftElementOneIndent.style['list-style-type']
  let grandParent = hasUpperLeftElementMoreIndent.parentElement
  let indexInsert
  for (let i = 0; i < grandParent.children.length; i++) {
    if (grandParent.children[i] === hasUpperLeftElementMoreIndent) indexInsert = i + 1 * 1
  }
  if (styleLowerLessLevel) {
    setLevelStyles(elementListItem, chapterListLevels, hasLowerLeftElementOneIndent)
  } else {
    increaseMarginLeft(-1, elementListItem)
  }
  elementListItem.style['list-style-type'] = listStyleType
  appendCurrentElementAndSiblings(currentElement, elementListItem)
  if (siblings.length > 0 || (childrenList && childrenList.length > 0)) {
    let elementList = elementListItem.parentElement
    if (elementList) {
      let newList = document.createElement(elementList.nodeName)
      newList.id = getNextId()
      setLevelStyles(newList, chapterListLevels, elementListItem, 1)
      elementListItem.append(newList)
      const childrenListLength = childrenList.length
      for (let i = 0; i < childrenListLength; i++) {
        newList.append(childrenList[i])
        for (let c = 0; c < childrenList[i].children.length; c++) {
          increaseMarginLeft(1, childrenList[i].children[c])
        }
      }
      for (let i = 0; i < siblings.length; i++) {
        newList.append(siblings[i])
      }
    }
  }
}

const leftHasChNoUpSibNoDnSib = ({
                                   currentElement,
                                   elementListItem,
                                   hasUpperLeftElementOneIndent,
                                   hasLowerLeftElementOneIndent,
                                   getNextId,
                                   chapterListLevels
                                 }) => {
  //0. Find that this target has one or more children but no siblings above or below.
  // XXXX
  //      XXXX
  //            0000
  //                  XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in elementCurrent
  // 2. Save off the target's listItem - we already have this in elementListItem
  // 3. Save off the children of the target
  // 4. Get the style of the upper less level
  // 5. Get the index of the upper less level and add one where the target is to be moved
  // 6. Set the listItem style to the style of the upper less level
  // 7. Insert the target at the index found above of the upper list on the left (which will take this target ouf of its sibling's list)
  // 8. Set the target's children's margin-left to add one indent value (36pt)
  let siblings = getLowerSiblings(elementListItem)
  let childrenList = getListChildren(currentElement)  //Done 6/26/2023
  let grandParent = hasUpperLeftElementOneIndent.parentElement
  let indexInsert
  for (let i = 0; i < grandParent.children.length; i++) {
    if (grandParent.children[i] === hasUpperLeftElementOneIndent) indexInsert = i + 1 * 1
  }
  setLevelStyles(elementListItem, chapterListLevels, hasLowerLeftElementOneIndent)
  if (grandParent.children.length === indexInsert) {
    grandParent.append(elementListItem)
  } else {
    grandParent.insertBefore(elementListItem, grandParent.children[indexInsert])
  }
  appendCurrentElementAndSiblings(currentElement, elementListItem)
  if (siblings.length > 0 || (childrenList && childrenList.length > 0)) {
    let elementList = elementListItem.parentElement
    if (elementList) {
      let newList = document.createElement(elementList.nodeName)
      newList.id = getNextId()
      setLevelStyles(newList, chapterListLevels, elementListItem, 1)
      elementListItem.append(newList)
      const childrenListLength = childrenList.length
      for (let i = 0; i < childrenListLength; i++) {
        newList.append(childrenList[i])
        for (let c = 0; c < childrenList[i].children.length; c++) {
          increaseMarginLeft(1, childrenList[i].children[c])
        }
      }
      for (let i = 0; i < siblings.length; i++) {
        newList.append(siblings[i])
      }
    }
  }
}

const leftNoChHasUpSibNoDnSib = ({
                                   elementListItem,
                                   hasUpperLeftElementOneIndent,
                                   chapterListLevels,
                                   hasLowerLeftElementOneIndent
                                 }) => {
  //0. Find that this target has no children, but has siblings above but not below
  // XXXX
  //      XXXX
  //            XXXX
  //            0000
  //      XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. Get the style of the upper less level
  // 4. Get the index of the upper less level and add one where the target is to be moved
  // 5. Set the listItem style to the style of the upper less level
  // 6. Insert the target at the index found above of the upper list on the left (which will take it out of the populated list that it is leaving)
  let grandParent = hasUpperLeftElementOneIndent.parentElement
  let indexInsert
  for (let i = 0; i < grandParent.children.length; i++) {
    if (grandParent.children[i] === hasUpperLeftElementOneIndent) indexInsert = i + 1 * 1
  }
  setLevelStyles(elementListItem, chapterListLevels, hasLowerLeftElementOneIndent)
  if (grandParent.children.length === indexInsert) {
    grandParent.append(elementListItem)
  } else {
    grandParent.insertBefore(elementListItem, grandParent.children[indexInsert])
  }
}

const leftNoChHasUpSibNoDnSibHasParentHasLwrLeftOneIndent = ({
                                                               elementListItem,
                                                               chapterListLevels,
                                                               hasGrandParent,
                                                               getNextId,
                                                               hasLowerLeftElementOneIndent
                                                             }) => {
  //0. Find that this target has no children, but has siblings above but not below
  // XXXX
  //      XXXX
  //            XXXX
  //            0000
  //      XXXX
  //Done 6/24/2023
  let targetList
  let targetListItem
  if (hasLowerLeftElementOneIndent) {
    if (hasLowerLeftElementOneIndent.nodeName === 'LI') {
      targetListItem = hasLowerLeftElementOneIndent
      targetList = hasLowerLeftElementOneIndent.parentElement
    } else if (hasLowerLeftElementOneIndent.nodeName === 'OL' || hasLowerLeftElementOneIndent.nodeName === 'UL') {
      targetList = hasLowerLeftElementOneIndent
      targetListItem = hasLowerLeftElementOneIndent.firstChild
    }
    let siblings = getLowerSiblings(elementListItem)
    //let currentList = elementListItem.parentElement
    //The list item to join the lower left element
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId()
    newListItem.setAttribute('style', targetListItem.style.cssText)
    newListItem.style['list-style-type'] = targetListItem.style['list-style-type']
    setLevelCharacter(newListItem, chapterListLevels)
    appendListItemSpans(newListItem, elementListItem)
    //newListItem.append(currentList)
    targetList.insertBefore(newListItem, targetList.firstChild)
    for (let i = 0; i < siblings.length; i++) {
      increaseMarginLeft(-1, siblings[i])
    }
    elementListItem.remove()
  }
}

const leftHasMultTabsNoChHasUpSibNoDnSibNoLwrLeftOneIndent = ({
                                                                currentElement,
                                                                elementListItem,
                                                                chapterListLevels,
                                                                hasGrandParent,
                                                                getNextId,
                                                                hasLowerLeftElementOneIndent
                                                              }) => {
  // XXXX
  //            XXXX
  //            0000
  // XXXX
  //A single list that has children with at least two indentations.
  //We will make two lists out of the one with the current target moved over with one tab less in marginLeft of the listItem
  //Done 6/26/2023
  let list = elementListItem.parentElement
  let parentListItem = list.parentElement
  let listNextSibling = list.nextElementSibling
  let childrenList = getListChildren(currentElement)
  let newList = document.createElement(list.nodeName)
  newList.id = getNextId()
  newList.setAttribute('style', list.style.cssText)
  if (listNextSibling) {
    parentListItem.insertBefore(newList, listNextSibling)
  } else {
    parentListItem.append(newList)
  }
  newList.append(elementListItem)
  increaseMarginLeft(-1, elementListItem)
  setLevelCharacter(newList, chapterListLevels)
  if (childrenList && childrenList.length > 0) {
    for(let i = 0; i < childrenList.children.length; i++) {
      for(let c = 0; c < childrenList[i].children.length; c++) {
        increaseMarginLeft(1, childrenList[i].children[c])
      }
    }
  }
}

const leftNoChHasUpSibHasUpLftOneTab = ({
                                          currentElement,
                                          elementListItem,
                                          hasUpperLeftElementOneIndent,
                                          getNextId,
                                          chapterListLevels,
                                          hasLowerLeftElementOneIndent
                                        }) => {
  //0. Find that this target has no children, but has siblings above (but could have siblings below or not)
  // XXXX
  //      XXXX
  //            XXXX
  //            0000
  //      XXXX | XXXX (could have siblings)
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. Save off any siblings (of the elementListItem), if any
  // 4. Get the style of the upper less level
  // 5. Get the index of the upper less level and add one where the target is to be moved
  // 6. Set the listItem style to the style of the upper less level
  // 7. Insert the target at the index found above of the upper list on the left (which will take it out of the populated list that it is leaving)
  // 8. If there are siblings
  //      Create a new list with an id
  //      Append the list to the currentElement
  //      Loop through the siblings, if any, and append them under the new list
  let siblings = getLowerSiblings(elementListItem)
  let childrenList = getListChildren(currentElement)  //Done 6/26/2023
  let grandParent = hasUpperLeftElementOneIndent.parentElement
  let indexInsert
  for (let i = 0; i < grandParent.children.length; i++) {
    if (grandParent.children[i] === hasUpperLeftElementOneIndent) indexInsert = i + 1 * 1
  }
  setLevelStyles(elementListItem, chapterListLevels, elementListItem, -1)
  if (grandParent.children.length === indexInsert) {
    grandParent.append(elementListItem)
  } else {
    grandParent.insertBefore(elementListItem, grandParent.children[indexInsert])
    grandParent.children[indexInsert].style['list-style'] = ''
    grandParent.children[indexInsert].style['list-style-type'] = ''
  }
  //appendCurrentElementAndSiblings(currentElement, elementListItem)
  if (siblings.length > 0 || (childrenList && childrenList.length > 0)) {
    let elementList = elementListItem.parentElement
    if (elementList) {
      let newList = document.createElement(elementList.nodeName)
      newList.id = getNextId()
      setLevelStyles(newList, chapterListLevels, elementListItem, 1)
      elementListItem.append(newList)
      const childrenListLength = childrenList.length
      for (let i = 0; i < childrenListLength; i++) {
        newList.append(childrenList[i])
        for (let c = 0; c < childrenList[i].children.length; c++) {
          increaseMarginLeft(1, childrenList[i].children[c])
        }
      }
      //Siblings go under the elementListItem of the currentElement
      for (let i = 0; i < siblings.length; i++) {
        //setLevelStyles(siblings[i], chapterListLevels, siblings[i], 1)
        newList.append(siblings[i])
      }
    }
  }
}

const leftHasChHasUpSibNoDnSib = ({
                                    currentElement,
                                    elementListItem,
                                    hasUpperLeftElementOneIndent,
                                    getNextId,
                                    chapterListLevels,
                                    hasLowerLeftElementOneIndent
                                  }) => {
  //0. Find that this target has children and has siblings above but not below
  // XXXX
  //      XXXX
  //            XXXX
  //            0000
  //                  XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. Save off the children of the target
  // 3. Get the style of the upper less level
  // 4. Get the index of the upper less level and add one where the target is to be moved
  // 5. Set the listItem style to the style of the upper less level
  // 6. Insert the target at the index found above of the upper list on the left (which will take it out of the populated list that it is leaving)
  // 7. Add one more margin-left tab indent value (36pt) to the children's listItems
  let siblings = getLowerSiblings(elementListItem)
  let childrenList = getListChildren(currentElement)  //Done 6/26/2023
  let grandParent = hasUpperLeftElementOneIndent.parentElement
  let indexInsert
  for (let i = 0; i < grandParent.children.length; i++) {
    if (grandParent.children[i] === hasUpperLeftElementOneIndent) indexInsert = i + 1 * 1
  }
  setLevelStyles(elementListItem, chapterListLevels, hasLowerLeftElementOneIndent)
  if (grandParent.children.length === indexInsert) {
    grandParent.append(elementListItem)
  } else {
    grandParent.insertBefore(elementListItem, grandParent.children[indexInsert])
  }
  appendCurrentElementAndSiblings(currentElement, elementListItem)
  if (siblings.length > 0 || (childrenList && childrenList.length > 0)) {
    let elementList = elementListItem.parentElement
    if (elementList) {
      let newList = document.createElement(elementList.nodeName)
      newList.id = getNextId()
      setLevelStyles(newList, chapterListLevels, elementListItem, 1)
      elementListItem.append(newList)
      const childrenListLength = childrenList.length
      for (let i = 0; i < childrenListLength; i++) {
        newList.append(childrenList[i])
        for (let c = 0; c < childrenList[i].children.length; c++) {
          increaseMarginLeft(1, childrenList[i].children[c])
        }
      }
      for (let i = 0; i < siblings.length; i++) {
        newList.append(siblings[i])
      }
    }
  }
}

const leftHasChHasUpSibHasDnSib = ({
                                     currentElement,
                                     elementListItem,
                                     hasUpperLeftElementOneIndent,
                                     getNextId,
                                     chapterListLevels,
                                     hasLowerLeftElementOneIndent
                                   }) => {
  //0. Find that this target has children and has siblings above but not below
  // XXXX
  //      XXXX
  //            XXXX
  //            0000
  //                  XXXX
  //            XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. Save off the siblings below this currentElement (elementListItem, actually)
  // 4. Save off the children of the target
  // 5. Get the style of the upper less level
  // 6. Get the index of the upper less level and add one where the target is to be moved
  // 7. Set the listItem style to the style of the upper less level
  // 8. Insert the target at the index found above of the upper list on the left (which will take it out of the populated list that it is leaving)
  // 9. Add one more margin-left tab indent value (36pt) to the children's listItems
  // 10. PLace the siblings underneath the moved elementListItem
  let elementList = elementListItem.parentElement
  let siblings = getLowerSiblings(elementListItem)
  let childrenList = getListChildren(currentElement)  //Done 6/26/2023
  let parentListItem = elementList.parentElement
  let parentList = parentListItem.parentElement
  let indexInsert
  for (let i = 0; i < parentList.children.length; i++) {
    if (parentList.children[i] === parentListItem) indexInsert = i + 1 * 1
  }
  if (parentList.children.length === indexInsert) {
    parentList.append(elementListItem)
  } else {
    parentList.insertBefore(elementListItem, parentList.children[indexInsert])
  }
  setLevelStyles(elementListItem, chapterListLevels, parentListItem)

  if (siblings.length > 0 || (childrenList && childrenList.length > 0)) {
    let newList = document.createElement(elementList.nodeName)
    newList.id = getNextId()
    setLevelStyles(newList, chapterListLevels, elementListItem, 1)
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId()
    setLevelStyles(newListItem, chapterListLevels, elementListItem, 1)
    newList.append(newListItem)
    const childrenListLength = childrenList.length
    for (let i = 0; i < childrenListLength; i++) {
      newListItem.append(childrenList[i])
      for (let c = 0; c < childrenList[i].children.length; c++) {
        increaseMarginLeft(1, childrenList[i].children[c])
      }
    }
    elementListItem.append(newList)
    for (let i = 0; i < siblings.length; i++) {
      newList.append(siblings[i])
    }
    newListItem.style['list-style'] = 'none'
  }

}

const leftNoChHasUpSibNoDnSibHasUpLeftOneTab = ({
                                                  elementListItem,
                                                  hasUpperLeftElementOneIndent,
                                                  chapterListLevels,
                                                  hasLowerLeftElementOneIndent
                                                }) => {
  //0. Find that this target has no children and siblings below but not siblings above but has an upper right element with an upper left element.
  // XXXX
  //      XXXX
  //                  XXXX
  //            0000
  //            XXXX
  // 1. Save off the target itself (the span - not the listItem) - We already have this in currentElement
  // 2. Save off the target's listItem - We already have this in elementListItem
  // 3. Get the style of the upper less level
  // 4. Get the index of the upper less level and add one where the target is to be moved
  // 5. Set the listItem style to the style of the upper less level
  // 6. Insert the target at the index found above of the upper list on the left (which will take it out of the populated list that it is leaving)
  // 7. Do NOT delete the target's list element since there is a sibling.
  let grandParent = hasUpperLeftElementOneIndent.parentElement
  let indexInsert
  for (let i = 0; i < grandParent.children.length; i++) {
    if (grandParent.children[i] === hasUpperLeftElementOneIndent) indexInsert = i + 1 * 1
  }
  setLevelStyles(elementListItem, chapterListLevels, hasLowerLeftElementOneIndent)
  if (grandParent.children.length === indexInsert) {
    grandParent.append(elementListItem)
  } else {
    grandParent.insertBefore(elementListItem, grandParent.children[indexInsert])
  }
  // 7. Do NOT delete the target's list element since there is a sibling.
}

const leftNoChHasUpSibNoDnSibNoUpLeftOneTab = ({currentElement, elementListItem, chapterListLevels, getNextId}) => {
  //            XXXX
  //            0000
  //            XXXX
  //Get the list of the currentElement
  //Create two lists to match the style of the existing list
  //  The currentElement will go into the first list (but decrement the marginLeft by one tab value)
  //  The lower siblings will go in the second list (as children of the currentElement - and decrement the marginLeft by one tab value)
  //Get the nextSibling of the list
  //If there is a nextSibling, place the currentElement list before it
  //Otherwise, append the currentElement list on the end of the list.
  let siblings = getLowerSiblings(elementListItem)
  let list = elementListItem.parentElement
  let parentListItem = list.parentElement
  // let parentList = parentListItem.parentElement
  // let parentListItemNextSibling = parentListItem.nextElementSibling
  let newCurrentList = document.createElement(list.nodeName)
  newCurrentList.id = getNextId()
  newCurrentList.setAttribute('style', list.style.cssText)
  let newChildList = document.createElement(list.nodeName)
  newChildList.id = getNextId()
  newChildList.setAttribute('style', list.style.cssText)
  newCurrentList.append(elementListItem)
  elementListItem.append(newChildList)
  increaseMarginLeft(-1, elementListItem)
  // if (parentListItemNextSibling) {
  //   parentList.insertBefore(newCurrentList, parentListItemNextSibling)
  // } else {
    parentListItem.append(newCurrentList)
  //}
  for(let i = 0; i < siblings.length; i++) {
    newChildList.append(siblings[i])
    increaseMarginLeft(-1, siblings[i])
    setLevelCharacter(siblings[i], chapterListLevels)
  }
  setLevelCharacter(newCurrentList, chapterListLevels)

  // let siblings = getLowerSiblings(elementListItem)
  // let childrenList = getListChildren(currentElement)  //Done 6/26/2023
  // let elementList = elementListItem.parentElement
  // let parentListItem = elementList.parentElement
  // let parentList = parentListItem.parentElement
  // let indexInsert
  // for (let i = 0; i < parentList.children.length; i++) {
  //   if (parentList.children[i] === parentListItem) indexInsert = i + 1 * 1
  // }
  // if (parentList.children.length === indexInsert) {
  //   parentList.append(elementListItem)
  // } else {
  //   parentList.insertBefore(elementListItem, parentList.children[indexInsert])
  // }
  // setLevelStyles(elementListItem, chapterListLevels, parentListItem)
  //
  // if (siblings.length > 0 || (childrenList && childrenList.length > 0)) {
  //   let elementList = elementListItem.parentElement
  //   if (elementList) {
  //     let newList = document.createElement(elementList.nodeName)
  //     newList.id = getNextId()
  //     setLevelStyles(newList, chapterListLevels, elementListItem, 1)
  //     elementListItem.append(newList)
  //     const childrenListLength = childrenList.length
  //     for (let i = 0; i < childrenListLength; i++) {
  //       newList.append(childrenList[i])
  //       for (let c = 0; c < childrenList[i].children.length; c++) {
  //         increaseMarginLeft(1, childrenList[i].children[c])
  //       }
  //     }
  //     for (let i = 0; i < siblings.length; i++) {
  //       newList.append(siblings[i])
  //     }
  //   }
  // }
}

const increaseMarginLeft = (tabCount = 1, element) => {
  let localAddMarginLeft = addMarginLeft * tabCount
  if (element) {
    let marginLeft = element.style && element.style['margin-left']
    marginLeft = marginLeft.match(/\d+/)
    marginLeft = String(Number(marginLeft) + Number(localAddMarginLeft))
    if (marginLeft < 0) marginLeft = 0
    element.style['margin-left'] = marginLeft + 'pt'
  }
}

const getLowerSiblings = (elementListItem) => {
  if (elementListItem) {
    let siblings = []
    let nextSibling = elementListItem.nextSibling
    while (nextSibling) {
      siblings.push(nextSibling)
      nextSibling = nextSibling.nextSibling
    }
    return siblings
  }
}

const getUpperSiblings = (elementListItem) => {
  let siblings = []
  let previousSibling = elementListItem.previousSibling
  while (previousSibling) {
    siblings.unshift(previousSibling)
    previousSibling = previousSibling.previousSibling
  }
  return siblings
}

const getListChildren = (currentElement) => {
  //It is actually the elementListItem type children that we are getting.
  //But we can't include the currentElement since that is intended to be moved elsewhere and referring to it would cause reference problems.
  //The structure is:
  //<list...
  //  <listItem...
  //     ...maybe some spans
  //     <current span element... so we will need to determine when we find the span and then keep looping until we get to an OL or UL
  //        <list for children - which is what we are returning as a whole (OL or UL)
  let listItem = currentElement && currentElement.parentElement
  let childrenList = []
  if (listItem) {
    let foundElement = false
    for (let i = 0; i < listItem.children.length; i++) {
      if (!foundElement && listItem.children[i] === currentElement) {
        foundElement = true
      } else if (foundElement && (listItem.children[i].nodeName === 'UL' || listItem.children[i].nodeName === 'OL')) {
        childrenList.push(listItem.children[i])
      }
    }
  }
  return childrenList
}

const getTabMarginCount = (element) => {
  if (element && element.style && element.style['margin-left']) {
    let marginLeft = element.style['margin-left']
    marginLeft = marginLeft.match(/\d+/)
    let result = Math.round(marginLeft / addMarginLeft)
    return result === 0 ? 1 : result
  }
}

export const getElementLevel = (element) => {
  //How many UL or OL parents exist?
  if (element) {
    let level = element.nodeName === 'OL' || element.nodeName === 'UL' ? 1 : 0 //We don't want to skip the very listItem that we might be on to start with
    let marginLeft = element.style['margin-left'].match(/\d+/)
    let tabsAvailable = Math.round(marginLeft / addMarginLeft) - 1
    level += tabsAvailable <= 0 ? 0 : tabsAvailable
    let parent = element && element.parentElement
    while (parent && parent.id !== '1' && parent.id !== 'editorDiv') {
      //If the element itself has tabs greater than one (36pt) then add onto the level count. We are rounding just to be sure that we aren't counting a margin-left value that might just be over 36pt but not enough to be considered an additional tab
      marginLeft = parent.style['margin-left'].match(/\d+/)
      tabsAvailable = Math.round(marginLeft / addMarginLeft) - 1
      level += tabsAvailable <= 0 ? 0 : tabsAvailable
      if (parent.nodeName === 'UL' || parent.nodeName === 'OL') {
        level++ //We used to look at getTabMarginCount(parent) but if the first margin is bigger than 36, it is going to round up to 2.
      }
      parent = parent.parentElement
    }
    return level
  }
}

//***************** Functions for RIGHT Moves  ************************************

const rightHasChNoLwrSibNoUpSibNoUpRtIndent = ({currentElement, elementListItem, getNextId, chapterListLevels}) => {
  // 0000
  //      XXXX
  //1. Get the elementList for the child
  //2. Get the elementListItem for the child
  //3. Get the elementListItemStyle
  //4. Create an elementListItem to match the child element
  //5. Move the currentElement to the elementListItem
  //6. Put the elementListItem at the top of the child list
  //7. Make sure that the currentElementListItem's list-style-type is removed
  let childrenList = getListChildren(currentElement)  //Done 6/26/2023
  if (childrenList && childrenList.length > 0) {
    let newListItem = document.createElement('LI')
    newListItem.id = getNextId()
    setLevelStyles(newListItem, chapterListLevels, elementListItem, 1)
    appendListItemSpans(newListItem, elementListItem)
    elementListItem.insertBefore(newListItem, childrenList[0])
    elementListItem.style['list-style'] = 'none'
  }
}

const rightNoChNoUpSibNoDnSibNoUpperRtIndent = ({currentElement, elementListItem, getNextId, chapterListLevels}) => {
  // XXXX
  //      0000
  let elementList = elementListItem.parentElement
  let newList = document.createElement(elementList.nodeName)
  newList.id = getNextId()
  setLevelStyles(newList, chapterListLevels, elementListItem, 1)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  setLevelStyles(newListItem, chapterListLevels, elementListItem, 1)
  newList.append(newListItem)
  appendCurrentElementAndSiblings(currentElement, newListItem)
  if (elementListItem.firstChild) {
    elementListItem.insertBefore(newList, elementListItem.firstChild) //Insert here BEFORE moving the currentElement so that we put this newList before it. Then move it to the newListItem
  } else {
    elementListItem.append(newList)
  }
  elementListItem.style['list-style'] = 'none'
}

const rightNoChNoUpSibNoDnSibHasUpRtIndent = ({
                                                currentElement,
                                                elementListItem,
                                                hasUpperRightElementOneIndent,
                                                getNextId,
                                                chapterListLevels
                                              }) => {
  //            XXXX
  //      0000
  // XXXX
  //1. Get the hasUpperRightElementOneIndent which is an elementListItem
  //2. Get the targetElementList
  //3. Create an elementListItem to match the hasUpperRightElementOneIndent
  //4. Move the currentElement to the elementListItem
  //5. Append the elementListItem to the bottom of the parent of hasUpperRightElementOneIndent
  //Done 6/27/2023
  let list = elementListItem.parentElement
  let targetElementListItem = hasUpperRightElementOneIndent
  let targetElementList = targetElementListItem.parentElement
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  newListItem.setAttribute('style', targetElementListItem.style.cssText)
  appendListItemSpans(newListItem, elementListItem)
  targetElementList.append(newListItem)
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
  const listItem = list.nodeName === 'OL' || list.nodeName === 'UL' ? list.firstChild : list.nodeName === 'LI' ? list : ''
  if (hasListItemElements(listItem)) {
    list.style['list-style-type'] = 'none'
  } else {
    list.remove()
  }
}

const rightHasChNoUpSibNoDnSibNoUpRtIndent = ({
                                                currentElement,
                                                elementListItem,
                                                hasUpperRightElementOneIndent,
                                                getNextId
                                              }) => {
  // XXXX
  //      0000
  //            XXXX
  //1. Get the elementList for the child
  //2. Get the elementListItem for the child
  //3. Get the elementListItemStyle
  //4. Create an elementListItem to match the child element
  //5. Move the currentElement to the elementListItem
  //6. Place the elementListItem at the top of the child list
  //7. Make sure that the currentElementListItem's list-style-type is removed

}

const rightHasChNoUpSibHasDnSibNoUpRtIndent = ({currentElement, elementListItem, getNextId, chapterListLevels, hasLowerRightElementOneIndent}) => {
  // XXXX
  //      0000
  //            XXXX
  //      XXXX
  const list = elementListItem.parentElement
  const parentList = list.parentElement
  const targetList = hasLowerRightElementOneIndent.list
  const targetListItem = hasLowerRightElementOneIndent.listItem
  const childrenList = getListChildren(currentElement)
  let newList = document.createElement(list.nodeName)
  newList.id = getNextId()
  newList.setAttribute('style', list.style.cssText)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  newListItem.setAttribute('style', targetListItem.style.cssText)
  newList.append(newListItem)
  appendListItemSpans(newListItem, elementListItem)
  parentList.insertBefore(newList, list)
  if (childrenList && childrenList.length > 0) {
    for(let i = 0; i < childrenList.length; i++) {
      let childrenLength = childrenList[i].children.length
      for (let c = 0; c < childrenLength; c++) {
        newList.append(childrenList[i].children[0])
        //setLevelStyles(childrenList[0].children[c], chapterListLevels, newListItem, 1)
      }
    }
  }
  //parentList.insertBefore(targetList, list)
  for(let i = 0; i < newList.children.length; i++) {
    increaseMarginLeft(1, newList.children[i])
  }
  setLevelCharacter(newList, chapterListLevels)
  elementListItem.remove()
  // let upperList = elementListItem.parentElement
  // let childrenList = getListChildren(currentElement)
  // let newListItem = document.createElement('LI')
  // newListItem.id = getNextId()
  // setLevelStyles(newListItem, chapterListLevels, childrenList.firstChild, 1, '', true)
  // appendListItemSpans(newListItem, elementListItem, true)
  // for (let i = 0; i < childrenList.children.length; i++) {
  //   increaseMarginLeft(1, childrenList.children[i])
  // }
  // upperList.insertBefore(childrenList, upperList.firstChild)
  // elementListItem.remove()
  // childrenList.insertBefore(newListItem, childrenList.firstChild)
}

const rightNoChNoUpSibHasDnSibHasUpRtIndent = ({
                                                 currentElement,
                                                 elementListItem,
                                                 hasUpperRightElementOneIndent,
                                                 getNextId,
                                                 chapterListLevels
                                               }) => {
  //            XXXX
  //      0000
  //      XXXX
  //1. Get the hasUpperRightElementOneIndent which is an elementListItem
  //2. Get the elementListItemStyle
  //3. Create an elementListItem to match the hasUpperRightElementOneIndent
  //4. Append the elementListItem to the bottom of the parent of hasUpperRightElementOneIndent
  //5. Move the currentElement to the elementListItem
  //6. Delete the old currentElementListItem
  let targetElementListItem = hasUpperRightElementOneIndent
  let targetElementList = targetElementListItem.parentElement
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  //setLevelStyles(newListItem, chapterListLevels, targetElementListItem)
  newListItem.setAttribute('style', targetElementListItem.style.cssText)
  appendListItemSpans(newListItem, elementListItem)
  targetElementList.append(newListItem)
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style'] = 'none'
  } else {
    elementListItem.remove()
  }
}

const rightNoChNoUpSibNoDnSibNoUpRtIndent = ({currentElement, elementListItem, getNextId, chapterListLevels}) => {
  // XXXX
  //      0000
  // XXXX
  //1. Add another elementList and elementListItem
  //2. Move the currentElement to the elementListItem
  //3. Make sure that the currentElementListItem's list-style-type is removed
  let elementList = elementListItem.parentElement
  let newList = document.createElement(elementList.nodeName)
  newList.id = getNextId()
  setLevelStyles(newList, chapterListLevels, elementList, 1)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  setLevelStyles(newListItem, chapterListLevels, elementListItem, 1)
  appendCurrentElementAndSiblings(currentElement, newListItem)
  newList.append(newListItem)
  elementListItem.append(newList)
  elementListItem.style['list-style'] = 'none'
  if (elementList && !(elementList.children && elementList.children.length > 0)) elementList.remove()
}

const rightNoChHasUpSibNoDnSibNoUpRtIndent = ({currentElement, elementListItem, getNextId, chapterListLevels}) => {
  //      XXXX
  //      0000
  // XXXX
  //This was confused at first. The new list needs to replace right in the upper sibling. Then the existing listItem needs to be placed into that new list.
  //  But do not create another listItem and just create that structure under the existing listItem since it will be counted as a number and be out of count:
  // 			1.
  // 			2.
  // 					a.
  // 			4.
  let upperSibling = elementListItem.previousElementSibling
  let elementList = elementListItem.parentElement
  let newList = document.createElement(elementList.nodeName)
  newList.id = getNextId()
  setLevelStyles(newList, chapterListLevels, elementList, 1)
  setLevelStyles(elementListItem, chapterListLevels, upperSibling, 1)
  newList.append(elementListItem)
  if (upperSibling) upperSibling.append(newList)
}

const rightNoChHasUpSibHasDnSibNoUpRtIndent = ({currentElement, elementListItem, getNextId, chapterListLevels}) => {
  //      XXXX
  //      0000
  //      XXXX
  let previousSibling = elementListItem.previousElementSibling
  let elementList = elementListItem.parentElement
  let newList = document.createElement(elementList.nodeName)
  newList.id = getNextId()
  setLevelStyles(newList, chapterListLevels, elementList, 1)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  setLevelStyles(newListItem, chapterListLevels, previousSibling, 1)
  appendListItemSpans(newListItem, elementListItem)
  newList.append(newListItem)
  previousSibling.append(newList)
  elementListItem.remove()
}

const rightNoUpRtIndentNoLwrRtIndentHasUpperTwoIndentHasLowerTwoIndent = ({
    elementListItem,
    getNextId,
    chapterListLevels,
    hasUpperRightElementTwoIndent,
  }) => {
  //                  XXXX
  //      0000
  //                  XXXX
  let parentList = elementListItem.parentElement
  let upperListItem = hasUpperRightElementTwoIndent.parentElement
  let upperList = upperListItem.parentElement
  let newList = document.createElement(parentList.nodeName)
  newList.id = getNextId()
  newList.setAttribute('style', parentList.style.cssText)
  newList.append(elementListItem)
  upperList.append(newList)
  for(let i = 0; i < elementListItem.children.length; i++) {
    if (elementListItem.children[i].nodeName === 'UL' || elementListItem.children[i].nodeName === 'OL') {
      for(let c = 0; c < elementListItem.children[i].children.length; c++) {
        increaseMarginLeft(-1, elementListItem.children[i].children[c])
      }
    }
  }
  setLevelCharacter(newList, chapterListLevels)
}

const rightHasChHasUpSibNoDnSibNoUpRtIndent = ({
                                                 currentElement,
                                                 elementListItem,
                                                 getNextId,
                                                 chapterListLevels,
                                                 hasLowerRightElementOneIndent
                                               }) => {
  //      XXXX
  //      0000
  //           XXXX
  //Look below in the children (OL or UL lists that are underneath) that belong to this currentElement (underneath).
  // If there is a child that is just one more level up,
  //    attach to the child that belongs to the next level up (targetLevel).
  //    Any children in between are still under this currentElement bue decremented by one tab (marginLeft)
  // Else
  //    Just make the currentElement a child below the upper sibling,
  //    keep the other children (in between) as children but decrement each one by one.
  // End if
  //1. Get the elementList for the child
  //2. Get the elementListItem for the child
  //3. Get the elementListItemStyle
  // ...
  //7. Make sure that the currentElementListItem's list-style-type is removed
  //Done 6/26/2023
  //ToDo update childrenList
  let currentLevel = getElementLevel(currentElement)
  let targetLevel = ++currentLevel
  let upperSibling = elementListItem.previousElementSibling
  let list = elementListItem.parentElement
  let childrenList = getListChildren(currentElement)  //Done 6/26/2023
  if (childrenList) {
    let nextLevelChild
    for (let i = 0; i < childrenList.length; i++) {
      if (getElementLevel(childrenList[i]) === targetLevel) {
        nextLevelChild = childrenList[i]
      }
    }
    if (nextLevelChild) {
      //    attach to the child that belongs to the next level up (targetLevel).
      //    Any children in between are still under this currentElement bue decremented by one tab (marginLeft)
      let newListItem = document.createElement('LI')
      newListItem.id = getNextId()
      setLevelStyles(newListItem, chapterListLevels, nextLevelChild.firstChild)
      appendListItemSpans(newListItem, elementListItem)
      nextLevelChild.insertBefore(newListItem, nextLevelChild.firstChild)
      for (let i = 0; i < childrenList.length; i++) {
        if (childrenList[i] !== nextLevelChild) {
          newListItem.append(childrenList[i])
          for (let c = 0; c < childrenList[i].children.length; c++) {
            increaseMarginLeft(-1, childrenList[i].children[c])
          }
        }
      }
      upperSibling.append(nextLevelChild)
    } else {
      //    Just make the currentElement a child below the upper sibling,
      //    keep the other children (in between) as children but decrement each one by one.
      let newList = document.createElement(list.nodeName)
      newList.id = getNextId()
      newList.append(elementListItem)
      setLevelStyles(newList, chapterListLevels, list, 1)
      for (let i = 0; i < childrenList.children.length; i++) {
        for (let c = 0; c < childrenList.children[i].children.length; c++) {
          increaseMarginLeft(-1, childrenList.children[i].children[c])
        }
      }
      upperSibling.append(newList)
    }
    //elementListItem.style['list-style'] = 'none'
    elementListItem.remove()
  }
}

const rightHasChHasLwrRtIndent = ({
                                    currentElement,
                                    elementListItem,
                                    getNextId,
                                    chapterListLevels,
                                    hasLowerRightElementOneIndent
                                  }) => {
  // XXXX|XXXX
  //      0000
  //                XXXX
  //           XXXX
  let listItemPreviousSibling = elementListItem.previousElementSibling
  let childrenList = getListChildren(currentElement)
  let lowerList = hasLowerRightElementOneIndent.list
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  setLevelStyles(newListItem, chapterListLevels, hasLowerRightElementOneIndent.listItem)
  appendListItemSpans(newListItem, elementListItem)
  for(let i = 0; i < childrenList.length; i++) {
    if (childrenList[0] !== lowerList) {
      newListItem.append(childrenList[0])
    }
  }
  hasLowerRightElementOneIndent.list.insertBefore(newListItem, hasLowerRightElementOneIndent.listItem)
  listItemPreviousSibling.append(hasLowerRightElementOneIndent.list)
  elementListItem.remove()
}

const rightNoChNoUpSibHasDnSibNoUpRtIndent = ({
                                                currentElement,
                                                elementListItem,
                                                hasUpperRightElementOneIndent,
                                                getNextId,
                                                chapterListLevels
                                              }) => {
  //  XXXX
  //           0000
  //           XXXX
  let elementList = elementListItem.parentElement
  let newList = document.createElement(elementList.nodeName)
  newList.id = getNextId()
  setLevelStyles(newList, chapterListLevels, elementList, 1)
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  setLevelStyles(newListItem, chapterListLevels, elementListItem, 1)
  elementList.append(newList) //We append to the list (not the listItem) and then delete the listItem which should be empty by then.
  newList.append(newListItem)
  appendListItemSpans(newListItem, elementListItem)
  if (!(elementListItem && elementListItem.children.length > 0)) {
    elementListItem.remove()
  } else {
    elementListItem.style['list-style'] = 'none'
  }
}

const rightHasChNoUpSibNoDnSibHasUpRtIndent = ({
                                                 currentElement,
                                                 elementListItem,
                                                 hasUpperRightElementOneIndent,
                                                 hasLowerRightElementOneIndent,
                                                 getNextId,
                                                 chapterListLevels
                                               }) => {
  //           XXXX
  //      0000
  //           XXXX
  //Done 6/27/2023
  let targetList = hasUpperRightElementOneIndent.nodeName === 'LI' ? hasUpperRightElementOneIndent.parentElement : hasUpperRightElementOneIndent
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  newListItem.setAttribute('style', hasUpperRightElementOneIndent.style.cssText)
  targetList.append(newListItem)
  appendListItemSpans(newListItem, elementListItem)
  let currentListItem = newListItem
  let targetLevel = getElementLevel(newListItem)
  for(let i = 0; i < elementListItem.children.length;) {
    if (getElementLevel(elementListItem.children[0].firstChild) > targetLevel) {
      increaseMarginLeft(-1, elementListItem.children[0].firstChild)
      currentListItem.append(elementListItem.children[0])
    } else if (getElementLevel(elementListItem.children[0].firstChild) === targetLevel) {
      let anotherListItem = document.createElement('LI')
      anotherListItem.id = getNextId()
      anotherListItem.setAttribute('style', hasUpperRightElementOneIndent.style.cssText)
      appendListItemSpans(anotherListItem, elementListItem.children[0].firstChild)
      targetList.append(anotherListItem)
      elementListItem.children[0].remove()
      currentListItem = anotherListItem
    }
  }
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
}

const rightNoChHasUpSibHasDnSibHasUpRtIndent = ({
                                                  currentElement,
                                                  elementListItem,
                                                  getNextId,
                                                  chapterListLevels,
                                                  hasUpperRightElementOneIndent
                                                }) => {
  //      XXXX
  //           XXXX
  //      0000
  //      XXXX
  let targetListItem = hasUpperRightElementOneIndent.nodeName === 'SPAN' ? hasUpperRightElementOneIndent.parentElement : hasUpperRightElementOneIndent
  let targetList = targetListItem.parentElement
  let newListItem = document.createElement('LI')
  newListItem.id = getNextId()
  newListItem.setAttribute('style', targetListItem.style.cssText)
  appendListItemSpans(newListItem, elementListItem)
  targetList.append(newListItem)
  if (hasListItemElements(elementListItem)) {
    elementListItem.style['list-style-type'] = 'none'
  } else {
    elementListItem.remove()
  }
}

const addListLevelItem = (topListElementId, chapterId, level, element, listLevels) => {
  listLevels.push({
    chapterListLevelId: 0,
    //chapterId,
    topListElementId: Number(topListElementId),
    listType: element.nodeName,
    level,
    listStyle: element.style['list-style'] ? element.style['list-style'] : '',
    listStyleType: element.style['list-style-type'] ? element.style['list-style-type'] : '',
    marginLeft: element.style['margin-left'] ? element.style['margin-left'] : '',
    paddingLeft: element.style['padding-left'] ? element.style['padding-left'] : '',
    fontFamily: element.style['font-family'] ? element.style['font-family'] : '',
    numberFormat: element.style['number-format'] ? element.style['number-format'] : '',
    margin: element.style['margin'] ? element.style['margin'] : '',
    styleInline: element.style.cssText,
  })
  return listLevels
}

const mostFrequentStyle = (array) => {
  if (array.length === 0)
    return null;
  let modeMap = {};
  let maxEl = array[0], maxCount = 1;
  for (let i = 0; i < array.length; i++) {
    let el = array[i];
    if (modeMap[el] == null)
      modeMap[el] = 1;
    else
      modeMap[el]++;
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }
  return maxEl;
}

const setLevelCharacter = (list, chapterListLevels) => {
  //We need to dip down to the listItem in order to see how many tab values the marginLeft entry has in order to determine this level.
  if (list) {
    let listItem = list && list.firstChild
    let targetLevel = getElementLevel(listItem)
    let listItemTabs //= list && list.firstChild ? getTabMarginCount(list.firstChild) : ''
    targetLevel = listItemTabs && listItemTabs > 1 ? targetLevel + listItemTabs - 1 : targetLevel
    let listLevel = chapterListLevels.filter(m => m.level === targetLevel && m.listType === list.nodeName && m.listGroup === list.nodeName && m.listStyleType)[0]
    if (listLevel) list.style['list-style-type'] = listLevel.listStyleType
  }
}


const setLevelStyle = (listGroup, elementList, chapterListLevels, adjustLevel) => {
  let targetLevel = getElementLevel(elementList)
  targetLevel = adjustLevel ? targetLevel + adjustLevel : targetLevel
  let listLevel = chapterListLevels.filter(m => m.level === targetLevel && m.listType === elementList.nodeName && m.listGroup === listGroup && m.styleInline)[0]
  if (listLevel) elementList.setAttribute('style', listLevel.styleInline)
}

export const setLevelStyles = (elementToSet, chapterListLevels, targetLevelElement, levelChange, directTargetLevel, addMarginLeftOption) => {
  //1. get the target level by the targetLevelElement location
  //    a. Check to see if the intended level is greater than the greatest level of chapterListLevels. If so, start the list over again by get the remainder of the modulus of the two numbers
  //2. Find the chapterListLevel by the elementType and the target level
  //3. Set the style from chapterListLevels to the elementToSet

  //If there is a levelChange value, then we'll use the targetLevelElement's level (which is not yet the target level in this case, yet) and then add or subtract accordingly (because levelChange can be negative to go left)
  let targetLevel = directTargetLevel ? directTargetLevel : levelChange ? getElementLevel(targetLevelElement) + levelChange : getElementLevel(targetLevelElement)
  let listLevelByType = chapterListLevels.filter(m => m.listType === elementToSet.nodeName)
  let highestLevel = listLevelByType.reduce((acc = 0, m) => {
    return m.level > acc ? m.level : acc
  }, 0)
  if (targetLevel > highestLevel) targetLevel = targetLevel % highestLevel
  if (targetLevelElement) {
    let listGroup = targetLevelElement.nodeName === 'UL' || targetLevelElement.nodeName === 'OL' ? targetLevelElement.nodeName : targetLevelElement.parentElement ? targetLevelElement.parentElement.nodeName : 'LI'  //Using 'LI' here is a blatant throwing in the towel. Probably not smart, but it may never need to be used.
    let listLevel = chapterListLevels.filter(m => m.level === targetLevel && m.listType === elementToSet.nodeName && m.listGroup === listGroup)[0]
    if (listLevel) {
      elementToSet.setAttribute('style', listLevel.styleInline)
      if (listLevel.listStyle) elementToSet.style['list-style'] = listLevel.listStyle
      if (listLevel.listStyleType) elementToSet.style['list-style-type'] = listLevel.listStyleType
      if (listLevel.numberFormat) elementToSet.style['number-format'] = listLevel.numberFormat
      if (addMarginLeftOption) increaseMarginLeft(1, elementToSet)
    }
  }
}

const appendCurrentElementAndSiblings = (currentElement, newListItem) => {
  let listItem = currentElement.parentElement
  let siblings = getLowerSiblings(listItem)
  let elementListItem = currentElement.parentElement
  appendListItemSpans(newListItem, elementListItem)
  for (let i = 0; i < siblings.length; i++) {
    newListItem.append(siblings[i])
  }
}

const appendListItemSpans = (newListItem, moveFromListItem) => {
  let endOfSpansAndImages = false
  let moveChildren = moveFromListItem.children
  let loop = 0
  for (let i = 0; i < moveChildren.length; !endOfSpansAndImages && loop < 100) {
    loop++
    if (moveChildren[0].nodeName !== 'SPAN' && moveChildren[0].nodeName !== 'IMG') endOfSpansAndImages = true  //Notice that we use 0 for the index since the children are decreasing by one as they are being taken from the top to move to the new list Item
    if (!endOfSpansAndImages) {
      //if (addMarginLeftOption) increaseMarginLeft(1, moveChildren[0])
      newListItem.append(moveChildren[0])
    } else {
      break
    }
  }
}

const hasListItemElements = (listItem) => {
  for(let i = 0; i < listItem.children.length; i++) {
    let innerHTML = listItem.children[i].nodeName === 'SPAN' ? listItem.children[i].innerHTML.replace('&nbsp;','').replace(' ','') : ''
    if (listItem.children[i].nodeName === 'SPAN' && innerHTML.length > 0 && listItem.children[i].id) return true
    else if (listItem.children[i].nodeName === 'IMG') return true
    else if (listItem.children[i].nodeName === 'OL' || listItem.children[i].nodeName === 'UL') return true
  }
}

export const mergeSameLevelAfterDelete = (deletedListItemLevel, orphanList, orphanListItem, orphanSpan, listItemPreviousSibling, listItemParent, listItem, getNextId) => {
  //=> Merge the same-level list structures after a list structure in between has been deleted
  //Get the level of the list item being deleted.  (firstSpanLevel)
  //Get the level of the element below the deleted list item (we will call the orphan).
  //Get the children of the orphanSpan
  //Get the siblings of the orphanList
  //If the two levels are the same,
  //  there is nothing to do.
  //Otherwise,
  //  Get the previous sibling of the list item being deleted
  //  Get the next sibling(s) of the list item being deleted
  //  If no previous sibling and there are next sibling(s),
  //    Get the grandparent (the parent of listItemParent)
  //    Create a new OL or UL (matching the current OL or UL) from listItemParent
  //    Move the siblings into the new OL or UL and assign it to the listItemGrandParent
  //  else 
  //    Get the last child of the OL or UL list
  //    continue to loop until the last child of the last child is found.
  //    Get the level of the last child
  //    If the level is the same as the orphan element
  //      Move the list item of the orphan at the end of that list of the last child.
  //    Else if the level of the last child is less than the orphan
  //      Delete the spans of the listItem
  //      Set the listItem's list-style-type to none
  //    Else if the level of the last child is greater than the orphan
  //      leave the orphan alone in its list.
  //    End if
  //    If the orphanList is now empty, delete it. Otherwise, clear the list style type.
  //  End if
  //  Note: If the if or else are not the case here, just leave the list item as it is without doing anything else (which might be a problem later if the user wants to add another list item underneath this orphan item)
  //End if
  let siblings = getLowerSiblings(orphanListItem)
  let orphanSpanLevel = getElementLevel(orphanSpan)
  let listSiblings = getLowerSiblings(listItem)

  if (!listItemPreviousSibling && listSiblings && listSiblings.length > 0) {
    const listItemGrandparent = listItemParent.parentElement
    let newList = document.createElement(listItemParent.nodeName)
    newList.setAttribute('style', listSiblings[0].parentElement.style.cssText)
    newList.id = getNextId()
    const length = listSiblings.length
    for(let i=0; i <= length; i++) {
      newList.append(listSiblings[0])
    }
    listItemGrandparent.append(newList);
  } else {
    if (orphanSpanLevel !== deletedListItemLevel) {
      let parent = listItemPreviousSibling
      if (!parent) {
        parent = listItemParent
      }
      let loop = 0
      let lastChild = parent.lastChild
      while (lastChild && !(lastChild && lastChild.nodeName === 'LI') && loop < 20) {
        lastChild = lastChild.lastChild
        loop++
      }
      let lastChildLevel = getElementLevel(lastChild)
      if (lastChildLevel === orphanSpanLevel) {
        //    Move the list item of the orphan at the end of that list of the last child.
        let lastChildParent = lastChild && lastChild.parentElement
        if (lastChildParent) {
          lastChildParent.append(orphanListItem)
          for (let i = 0; i < siblings.length; i++) {
            lastChildParent.append(siblings[0]) //Notice the zeroeth index since the length is going to be decreased each time.
          }
        }
        if (orphanList) {
          if (orphanList.children.length === 0) {
            orphanList.remove()
          } else {
            orphanList.style['list-style-type'] = 'none'
          }
        }
        if (listItem.children.length === 0) {
          listItem.remove()
        } else {
          listItem.style['list-style-type'] = 'none'
        }
      } else if (lastChildLevel < orphanSpanLevel) {
        //    Delete the spans of the listItem
        //    Set the listItem's list-style-type to none
        for(let i = 0; i < orphanListItem.children.length; i++) {
          orphanListItem.children[i].remove()
        }
        //orphanListItem.style['list-style-type'] = 'none'
      }
    }
  }
}

export const authorDeleteListItem = (element, getNextId) => {
  let img = document.querySelector(`[id="${element.id}"][data-type="DELETELISTITEM"]`)
  if (img) img.remove()
  img = document.querySelector(`[id="${element.id}~tabView"][data-type="DELETELISTITEM"]`)
  if (img) img.remove()

  let firstSpan = document.querySelector(`[id="${element.id}"][data-type="TEXT"]`)
  let listItem = firstSpan.parentElement
  let deletedListItemLevel = getElementLevel(listItem)
  let listItemPreviousSibling = listItem.previousSibling
  let listItemParent = listItem.parentElement
  let list = listItem.parentElement

  let children = listItem.children
  for (let i = 0; i < children.length; i++) {
    if (children[i].nodeName !== 'LI' && children[i].nodeName !== 'OL' && children[i].nodeName !== 'UL') {
      children[i].remove()
    }
  }
  let orphanList, orphanListItem, orphanSpan
  let foundFirstId = false
  for(let i = 0; i < listItem.children.length; i++) {
    if (!foundFirstId) {
      if (listItem.children[i].id && (listItem.children[i].nodeName === 'OL' || listItem.children[i].nodeName === 'UL')) {
        orphanList = listItem.children[i]
        foundFirstId = true
      } else if (listItem.children[i].id && listItem.children[i].nodeName === 'IL') {
        orphanListItem = listItem.children[i]
        foundFirstId = true
      }
    }
  }
  if (orphanList) {
    orphanListItem = orphanList.firstChild
  }
  if (orphanListItem) {
    let foundSpan = false
    for(let i = 0; i < orphanListItem.children.length; i++) {
      if (!foundSpan && orphanListItem.children[i].nodeName === 'SPAN' && orphanListItem.children[i].id) {
        orphanSpan = orphanListItem.children[i]
        foundSpan = true
      }
    }
  }

  //If there are any children left over, set the li list-style to 'none'
  //Else delete the LI record itself (otherwise it needs to be preserved to keep children in their place)
  if (listItem.children.length === 0) {
    listItem.remove()
  } else {
    listItem.style['list-style-type'] = 'none'
  }
  //And the list.
  if (list.children.length === 0) {
    list.remove()
  }
  //Also delete the tabView listItem and list, if they are empty.
  let firstSpanTabView = document.querySelector(`[id="${element.id}~tabView"][data-type="TEXT"]`)
  if (firstSpanTabView) {
    let listItemTabView = firstSpanTabView.parentElement
    let listTabView = listItemTabView.parentElement
    let childrenTabView = listItemTabView.children
    for (let i = 0; i < childrenTabView.length; i++) {
      if (childrenTabView[i].nodeName !== 'LI' && childrenTabView[i].nodeName !== 'OL' && childrenTabView[i].nodeName !== 'UL') {
        childrenTabView[i].remove()
      }
    }

    if (listItemTabView.children.length === 0) {
      listItemTabView.remove()
    } else {
      listItemTabView.style['list-style-type'] = 'none'
    }
    //And the list.
    if (listTabView.children.length === 0) {
      listTabView.remove()
    }
    mergeSameLevelAfterDelete(deletedListItemLevel, orphanList, orphanListItem, orphanSpan, listItemPreviousSibling, listItemParent, listItem, getNextId)
  }
}

const getElementIndex = (parentList, childElement) => {
  let indexFound 
  for(let i = 0; i < parentList.children.length; i++) {
    if (parentList.children[i] === childElement) {
      indexFound = i;
    }
  }
  return indexFound;
}