import React, { useState, useEffect } from 'react';
import styles from './EditorDivFormatControls.module.css';
import EditListChoice from '../EditListChoice'
import HeadlessSelectList from '../HeadlessSelectList'
import Undo from "../../assets/Edit Icons/undo.png";
import Redo from "../../assets/Edit Icons/redo.png";
import Bold from "../../assets/Edit Icons/bold.png";
import Italics from "../../assets/Edit Icons/italics.png";
import Underline from "../../assets/Edit Icons/underline.png";
import Strikeout from "../../assets/Edit Icons/strikeout.png";
import LeftAlign from "../../assets/Edit Icons/left align.png";
import CenterAlign from "../../assets/Edit Icons/center align.png";
import RightAlign from "../../assets/Edit Icons/right align.png";
import FullJustify from "../../assets/Edit Icons/full justify.png";
import LeftAlignArrow from "../../assets/Edit Icons/LeftAlignArrow.png";
import IndentArrow from "../../assets/Edit Icons/IndentArrow.png";
import BlockIndentLeftArrow from "../../assets/Edit Icons/BlockIndentLeftArrow.png";
import BlockIndentRightArrow from "../../assets/Edit Icons/BlockIndentRightArrow.png";
import UrlLink from "../../assets/Edit Icons/url link.png";
import DocScrollMatch from "../../assets/Edit Icons/doc-scroll-match.png";
import NumberedList from "../../assets/Edit Icons/numbered list.png";
import BulletList from "../../assets/Edit Icons/bullet list.png";
import Download from "../../assets/Edit Icons/download.png";
import FontName from "../../assets/Edit Icons/font name.png";
import FontSize from "../../assets/Edit Icons/font size.png";
import ColorPicker from "../../assets/Edit Icons/color picker.png";
import PaintersPallet from "../../assets/PaintersPallet.png";
import EditorSaveButton from '../EditorSaveButton'
import ColorPickerModal from '../ColorPickerModal'
import ButtonWithIcon from '../ButtonWithIcon'
import TextStyleChangeModal from '../TextStyleChangeModal'
import OriginatingEditorSendModal from '../OriginatingEditorSendModal'

function EditorDivFormatControls(props) {
	const {
		saveSelection,
		setFormatChoice,
		setParagraphAlign,
		setListChoice,
		undo,
		redo,
		setParagraphIndentChoice,
		setIsOpenLinkEntry,
		workSummary,
		saveWorkSpaceTime,
		isOpenLinkEntry,
		saveByButtonPress,
		editChosen,
		handleSetEditChosen,
		scrollDocumentToMatch,
		tabViewElement,
		isAuthor,
		handleDownloadChoice,
		handleFontNameChoice,
		handleFontSizeChoice,
		handleFontColorChoice,
		isTranslation,
		changeCounts,
		fontHeaderOptions,
		fontHeaderSizes } = props

	const [openTextStyleModal, setOpenTextStyleModal] = useState(false)
	const [openOriginatingAuthorSend, setOpenOriginatingAuthorSend] = useState()

	return (
		<div className={styles.iconRow}>
			<div className={styles.liftIcon}>
				<HeadlessSelectList
					iconAvatar={<img src={Download} className={styles.logo} alt={'edit'} title={'Download'} />}
					onSelect={handleDownloadChoice}
					listOptions={props.downloadOptions}>
					{workSummary.originatingEditorPersonId === props.personId &&
						<div className={styles.topDivSpacing}>
							<div className={styles.originatingEditor}>Originating Editor:</div>
							<div className={styles.instructions}>Are you ready to send this to the author?</div>
							<div className={styles.buttonSpacing}>
								<ButtonWithIcon icon={'arrow_right'}
									isPremiumIcon={false}
									onClick={() => setOpenOriginatingAuthorSend(true)}
									label={`Next`}
									submitSuccess={false}
									smaller />
							</div>
							<hr />
						</div>
					}
				</HeadlessSelectList>
			</div>
			<img src={Undo} className={styles.logo} alt={'undo'} title={'Undo (CTRL+Z)'} onClick={undo} />
			<img src={Redo} className={styles.logo} alt={'redo'} title={'Redo (CTRL+SHIFT+Z)'} onClick={redo} />
			{/*<div className={styles.dividerLine}>|</div>*/}
			{!isTranslation &&
				<img src={Bold} className={styles.logoSmaller} alt={'edit'} title={'Bold (CTRL+B)'} onClick={() => setFormatChoice('bold')} />
			}
			{!isTranslation &&
				<img src={Italics} className={styles.logoSmaller} alt={'edit'} title={'Italics (CTRL+I)'} onClick={() => setFormatChoice('italic')} />
			}
			{!isTranslation &&
				<img src={Underline} className={styles.logoSmaller} alt={'edit'} title={'Underline (CTRL+U)'} onClick={() => setFormatChoice('underline')} />
			}
			{!isTranslation &&
				<img src={Strikeout} className={styles.logoSmaller} alt={'edit'} title={'Strikeout (CTRL+SHIFT+K'} onClick={() => setFormatChoice('strikeout')} />
			}
			{!isTranslation && 
				<div className={styles.dividerLine}>|</div>
			}
			{!isTranslation && isAuthor &&
				<img src={PaintersPallet} className={styles.logoSmaller} alt={'edit'} title={'Change styles'} onClick={() => setOpenTextStyleModal(!openTextStyleModal)} />
			}
			<div className={styles.liftIcon}>
				<HeadlessSelectList
					iconAvatar={<img src={FontName} className={styles.logo} alt={'edit'} title={'Font name'} />}
					onSelect={handleFontNameChoice}
					listOptions={fontHeaderOptions}>
					{/* <div>  This is to give them options, but they might be more confusing than good anyway.
						<RadioGroup
							data={[
								{ id: 'ENTIREDOCUMENT', label: 'Apply to the entire document' },
								{ id: 'CURRENTSENTENCE', label: 'Apply to the current sentence' },
								{ id: 'SELECTEDTEXT', label: 'Apply to the selected text' }]}
							name={`applyFontFamily`}
							horizontal={false}
							initialValue={applyFontFamily}
							onClick={(value) => setApplyFontFamily(value)} />
						<hr />
					</div> */}
				</HeadlessSelectList>
			</div>
			<div className={styles.liftIcon}>
				<HeadlessSelectList
					iconAvatar={<img src={FontSize} className={styles.logo} alt={'edit'} title={'Font size'} />}
					onSelect={handleFontSizeChoice}
					listOptions={fontHeaderSizes} />
			</div>
			<div className={styles.liftIcon}>
				<ColorPickerModal
					iconAvatar={<img src={ColorPicker} className={styles.logo} alt={'edit'} title={'Font color'} />}
					onSelect={handleFontColorChoice} 
					saveSelection={saveSelection} />
			</div>
			<div className={styles.textAlignOptions}>
				<HeadlessSelectList
					iconAvatar={<img src={LeftAlignArrow} className={styles.logo} alt={'edit'} />}
					onSelect={() => {}}
					listOptions={[
						{
							id: 'left',
							label: <img src={LeftAlign} className={styles.logo} alt={'edit'} title={'Paragraph left align'} onClick={() => setParagraphAlign('left')} />
						},
						{
							id: 'center',
							label: <img src={CenterAlign} className={styles.logo} alt={'edit'} title={'Paragraph center align'} onClick={() => setParagraphAlign('center')} />
						},
						{
							id: 'right',
							label: <img src={RightAlign} className={styles.logo} alt={'edit'} title={'Paragraph right align'} onClick={() => setParagraphAlign('right')} />
						},
						{
							id: 'justify',
							label: <img src={FullJustify} className={styles.logo} alt={'edit'} title={'Paragraph full justify'} onClick={() => setParagraphAlign('justify')} />
						},
					]} />
			</div>
			<div className={styles.liftIcon}>
				<HeadlessSelectList
					iconAvatar={<img src={IndentArrow} className={styles.logo} alt={'edit'} title={'Indent'}/>}
					onSelect={(value) => setParagraphIndentChoice('textIndent', value)}
					listOptions={Array.from({ length: 30 }, (v, i) => ({ id: i, label: `${i} characters` }))} />
			</div>
			<div className={styles.liftIcon}>
				<HeadlessSelectList
					iconAvatar={<img src={BlockIndentLeftArrow} className={styles.logo} alt={`edit`} title={'Block indent left side'} />}
					onSelect={(value) => setParagraphIndentChoice('marginLeft', value)}
					listOptions={Array.from({ length: 30 }, (v, i) => ({ id: i, label: `${i} characters` }))} />
			</div>
			<div className={styles.liftIcon}>
				<HeadlessSelectList
					iconAvatar={<img src={BlockIndentRightArrow} className={styles.logo} alt={'edit'} title={'Block indent right side'} />}
					onSelect={(value) => setParagraphIndentChoice('marginRight', value)}
					listOptions={Array.from({ length: 30 }, (v, i) => ({ id: i, label: `${i} characters` }))} />
			</div>
			{!isTranslation && isAuthor &&
				<img src={NumberedList} className={styles.logo} alt={'edit'} title={'numbered list'} onClick={() => setListChoice('OL')} />
			}
			{!isTranslation && isAuthor &&
				<img src={BulletList} className={styles.logo} alt={'edit'} title={'Bullet list'} onClick={() => setListChoice('UL')} />
			}
			{/*<div className={styles.dividerLine}>|</div>*/}
			{/*<img src={Images} className={styles.logo} alt={'edit'} title={'Upload image'}/>*/}
			<img src={UrlLink} className={styles.logoSmaller} alt={'edit'} title={'Internet link'} onClick={() => setIsOpenLinkEntry(!isOpenLinkEntry)} />
			{!isTranslation && props.personId === workSummary.authorPersonId &&
				<EditorSaveButton
					changeCounts={changeCounts}
					label={'UPDATE'}
					saveWorkSpaceTime={saveWorkSpaceTime}
					saveByButtonPress={saveByButtonPress}
					addClassName={styles.editorSaveButton} />
			}
			<div className={styles.editList}>
				<EditListChoice
					editOptions={props.editOptions}
					handleSetEditChosen={handleSetEditChosen}
					editChosen={editChosen}
					scrollDocumentToMatch={scrollDocumentToMatch} />
				{isTranslation &&
					<div className={styles.translationMode}>Translation Mode</div>
				}
			</div>
			{tabViewElement && tabViewElement.innerHTML.length > 100 &&
				<img src={DocScrollMatch} className={styles.docScrollMatch} alt={'edit'} title={'Scroll documents to match location'} onClick={() => scrollDocumentToMatch()} />
			}
			<TextStyleChangeModal
				isOpen={!!openTextStyleModal}
				onClose={() => setOpenTextStyleModal('')}
				onCancel={() => { setOpenTextStyleModal('') }}
				updateChangeStyleEdit={() => []}
				changeStyleEditArray={[]}
				label='Text Style Change Edit' {...props} />
			<OriginatingEditorSendModal
				isOpen={openOriginatingAuthorSend}
				onCancel={() => setOpenOriginatingAuthorSend(false)}
				setOpenOriginatingAuthorSend={setOpenOriginatingAuthorSend}
				downloadOptions={props.downloadOptions}
				workSummary={workSummary}
				sendOriginatingEditorContents={props.sendOriginatingEditorContents}
				{...props} />

		</div>
	)
}

export default EditorDivFormatControls
