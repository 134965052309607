import React, { useEffect, useState } from 'react';
import styles from './ToastInputText.module.css'
import toast from "react-hot-toast";
import InputText from '../InputText';
import ButtonWithIcon from '../ButtonWithIcon';

const ToastInputText = ({ show, message, onSubmit, onClose, label = 'Enter name' }) => {
	const [error, setError] = useState('');

	useEffect(() => {
		let toastId = null;

		if (show) {
			toastId = toast((t) => (
				<div className="row">
					<div className="col-auto my-auto">
						<i className="bi bi-question-circle-fill"></i>
					</div>
					<div className="col my-auto border rounded p-1 text-white" dangerouslySetInnerHTML={{ __html: message }} />
					<div className={styles.column}>
						<label className={styles.label}>{label}</label>
						<input type='text' id="inputValue" className={styles.inputText}/>
					</div>
					<div className={styles.error}>{error}</div>
					<div className={styles.rowRight}>
						<span className={styles.cancelButton} onClick={() => {closeHandler(t); onClose()}}>
							Cancel
						</span>
						<ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={() => processForm(t)} />
					</div>
				</div>
			), {
				duration: Infinity,
				style: { maxWidth: "75em" },
			});
		}

		return () => {
			if (toastId) {
				toast.dismiss(toastId);
				// setInputText(''); // Reset input text
				// setError(''); // Clear any errors
			}
		};
	}, [show, message, label]);

	const closeHandler = (t) => {
		toast.dismiss(t.id);
	};

	const processForm = (t) => {
		const element = document.getElementById('inputValue')
		if (!element.value) {
			setError("`" + label + "` is missing.");
		} else {
			onSubmit(element.value);
			closeHandler(t);
			onClose()
		}
	};

	return null; // This component does not render anything itself
};

export default ToastInputText;
