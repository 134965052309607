import React from 'react';
import DearJohnCamera from '../../assets/QuickSend/DearJohnCameraFinal.png'
import ChooseUploadTextImage from '../../assets/QuickSend/ChooseUploadTextImage.png'
import AuthorOrEditor from '../../assets/QuickSend/AuthorOrEditor.png'
import EditorChangeText from '../../assets/QuickSend/EditorChangeText.png'
import SendBackToAuthor from '../../assets/QuickSend/SendBackToAuthor.png'
import AuthorGetsEmail from '../../assets/QuickSend/AuthorGetsEmail.png'
import Logo from "../../assets/logos/penspring_reg.png";
import styles from './QuickSend.module.css';
import classes from 'classnames';

export default (props) => {
	const { isMobile } = props
	return (
		<div className={styles.container	}>
			<div>
				<div className={isMobile ? styles.columnSpace : isMobile ? styles.columnSpace : styles.row}>
					<div>
						<div className={styles.yourMission}>Your Mission:</div>
						<div className={styles.ifYouChoose}>If you choose to accept it</div>
					</div>
					<div className={styles.missionText}>Save your friend from her badly written Dear John letter!</div>
				</div>
			</div>
			<hr/>
			<div className={isMobile ? styles.columnSpace : styles.row}>
				<div className={classes(styles.topAlign, styles.leftWidth)}>
					<div className={classes(styles.rowRight, styles.rightAlignText)}>
						<div className={styles.stepText}>step</div>
						<div className={styles.stepNumber}>1</div>
					</div>
					<div className={styles.rightAlignText}>Choose to add a new <img src={Logo} alt={'penspring'} style={{ height: '18px', position: 'relative', top: '3px' }} /> file by taking a picture of text.</div>
				</div>
				<div className={styles.rightWidth} style={{marginTop: '9px'}}>
					<img src={ChooseUploadTextImage} alt={'Choose upload text image'} />
				</div>
			</div>
			<div className={isMobile ? styles.columnSpace : styles.row}>
				<div className={classes(styles.topAlign, styles.leftWidth)}>
					<div className={classes(styles.rowRight, styles.rightAlignText)}>
						<div className={styles.stepText}>step</div>
						<div className={styles.stepNumber}>2</div>
					</div>
					<div className={styles.rightAlignText}>Choose to be the editor uploading someone else's text</div>
				</div>
				<div className={styles.rightWidth} style={{ marginTop: '9px', marginLeft: '3px' }}>
					<img src={AuthorOrEditor} alt={'Are you the author or editor'} height={133} />
				</div>
			</div>
			<div className={isMobile ? classes(styles.columnSpace, styles.moreLeft) : styles.row}>
				<div className={classes(styles.topAlign, styles.leftWidth)}>
					<div className={classes(styles.rowRight, styles.rightAlignText)}>
						<div className={styles.stepText}>step</div>
						<div className={styles.stepNumber}>3</div>
					</div>
					<div className={styles.rightAlignText}>Take a picture of your friend's Dear John letter</div>
				</div>
				<div className={styles.rightWidth} style={{ marginTop: '-5px', marginLeft: '-6px' }}>
					<img src={DearJohnCamera} alt={'Dear john camera photo'} />
				</div>
			</div>
			<div className={isMobile ? styles.columnSpace : styles.row}>
				<div className={classes(styles.topAlign, styles.leftWidth)}>
					<div className={classes(styles.rowRight, styles.rightAlignText)}>
						<div className={styles.stepText}>step</div>
						<div className={styles.stepNumber}>4</div>
					</div>
					<div className={styles.rightAlignText}>Fix up the text and save your friend's sanity</div>
				</div>
				<div className={styles.rightWidth} style={{ marginTop: '9px', marginLeft: '9px' }}>
					<img src={EditorChangeText} alt={'Change text in the editor'} height={122} />
				</div>
			</div>
			<div className={isMobile ? styles.columnSpace : styles.row}>
				<div className={classes(styles.topAlign, styles.leftWidth)}>
					<div className={classes(styles.rowRight, styles.rightAlignText)}>
						<div className={styles.stepText}>step</div>
						<div className={styles.stepNumber}>5</div>
					</div>
					<div className={styles.rightAlignText}>With the press of a couple of buttons, send the final text to her by email or text - or both! </div>
				</div>
				<div className={styles.rightWidth} style={{ marginTop: '0px', marginLeft: '2px' }}>
					<img src={SendBackToAuthor} alt={'Send her your final text by email or text - or both!'}  />
				</div>
			</div>
			<div className={isMobile ? styles.columnSpace : styles.row}>
				<div className={classes(styles.topAlign, styles.leftWidth)}>
					<div className={classes(styles.rowRight, styles.rightAlignText)}>
						<div className={styles.stepText} style={{marginBottom: '8px'}}>She gets the final text by email or text</div>
					</div>
					<div className={styles.rightAlignText}>She can take your text as is. Or, she can click on the <img src={Logo} alt={'penspring'} style={{ height: '18px', position: 'relative', top: '3px' }}/> link 
					to accept specific edits and make her own changes - now that you made improvements and gave her better ideas. <br/><br/>(Thank you for saving her from some bad communication that could have back-fired on her!)</div>
				</div>
				<div className={styles.rightWidth} style={{ marginTop: '9px', marginLeft: '13px' }}>
					<img src={AuthorGetsEmail} alt={'The author gets the final text.'} height={310}/>
				</div>
			</div>
			<div className={styles.sideSpace}>
				<p>Let's call this the QuickSend.</p>			
				<p>As fast and easy as that can be, there are deeper purposes of <img src={Logo} alt={'penspring'} style={{ height: '18px', position: 'relative', top: '3px' }}/> for efficiency, control, 
				and the shelf life of your document.</p>
				<p>As an example: When it comes to translating and managing the native, original writing to ensure that translation keeps up to date, any changed sentence is 
					tracked so that translators can jump right to each sentence and see the before-and-after to make appropriate updates.</p>
				<p>Then! There is the multiple editor feature - way better than Google Docs. Thank you! Bring it on!</p>			
			</div>
		</div>
	)
};
