import React, {useState, useEffect} from 'react'
import styles from './TextStyleChangeModal.module.css'
import TextStyleSettingInputControls from '../TextStyleSettingInputControls'
import TextStyleSettingGroupTable from '../TextStyleSettingGroupTable'
import ButtonWithIcon from '../ButtonWithIcon'
import MessageModal from '../MessageModal'
import RadioGroup from '../RadioGroup'
import { useMediaQuery } from "react-responsive"
import { createInfoToastAuto } from '../../services/queryClient'
import classes from 'classnames'
import * as editorService from '../../services/editor-dom'

const TextStyleChangeModal = (props) => {
	const { 
		isOpen, 
		onClose, 
		onCancel,
		textStyleGroups, 
		fontOptions, 
		fontSizes, 
		lineHeights, 
		textAlign, 
		bottomSpace, 
		updateChangeStyleEdit,
		editLanguageId,
		segments,
		textIndent } = props

	let {changeStyleEditArray} = props

	const isMobile = useMediaQuery({ query: '(max-width: 500px)' })
	const [settings, setSettings] = useState({})
	const [submitSuccess, setSubmitSuccess] = useState(false)
	const [applyStyle, setApplyStyle] = useState(props.isAuthor ? '' : 'SENTENCERANGE')
	const [openSentenceRangeMessage, setOpenSentenceRangeMessage] = useState(false)
	
	const handleSetApplyStyle = (value) => {
		if (value === 'SENTENCERANGE') {
			setOpenSentenceRangeMessage(true)
		} else {
			setApplyStyle(value)
		}
	}

	const processForm = (event) => {
		event.stopPropagation()
		event.preventDefault()
		let missingFields = ''

		if (!settings.fontFamily) missingFields += "\nFont-family"
		if (!settings.fontSize) missingFields += "\nFont-size"
		if (!settings.lineHeight) missingFields += "\nLine-height"
		if (!settings.textAlign) missingFields += "\nText-align"
		if (!settings.marginBottom) missingFields += "\nParagragh-height"
		if (!settings.textIndent) missingFields += "\nParagraph-indent"

		if (missingFields) {
			createInfoToastAuto(`<div>Information is missing:<br/><div className="ms-2">${missingFields}</div></div>`)
		} else {
			//Change settings into an array of code/value pairs to update to the database.
			setSubmitSuccess(true)
			const settingsArray = Object.entries(settings).map(([code, value]) => ({ code, value }))
			if (props.isAuthor && applyStyle === 'ENTIREDOCUMENT') {
				const tempSegments = editorService.updateChapterWithTextStyle_Segments(settingsArray, props.segments)
				props.updateSegmentsLocal(tempSegments)
			} else if (applyStyle === 'SENTENCERANGE' || (props.changeStyleEditArray.length > 0 && props.changeStyle)) {
				updateChangeStyleEdit('ChoseSettings', null, editLanguageId, settingsArray	, '') //moveEndParagraph ???
			}
			changeStyleEditArray = []
			setSettings()
			setSubmitSuccess(false)
			onClose()
		}
	}

	const resetSettings = () => {
		setSettings()
	}

	const handleSetEditSettings = (settings) => {
		let convertSettings = settings.entries.reduce((acc, m) => {
			let code = m.code.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase())
			acc = { ...acc, [code]: m.value }
			return acc
		}, {})
		setSettings(convertSettings)
		setSubmitSuccess(false)
	}

	const handleSetting = (event) => {
		const value = event.target.value
		const name = event.target.id
		setSettings({ ...settings, [name]: value })
	}

	return (
		<div className={classes(styles.overlay, isOpen ? styles.show : styles.hide)}>
			<div className={isMobile ? styles.mainDivMobile : styles.mainDiv}>
				<div className={styles.background}>
					<div className={styles.titleWhite}>
						Text Style Settings
					</div>
					{props.isAuthor && 
						<RadioGroup
							data={[
								{ id: 'ENTIREDOCUMENT', label: 'Apply to the entire document' },
								{ id: 'SENTENCERANGE', label: 'Apply to one or more sentences' }]}
							name={`applyStyle`}
							labelClass={styles.textWhite}
							initialValue={applyStyle || ''}
							personId={props.personId}
							onClick={(event) => handleSetApplyStyle(event)}/>
					}
					<TextStyleSettingGroupTable
						isMobile={isMobile}
						hideEdit={true}
						fillControlsWithChoice={true}
						settings={settings}
						textStyleGroups={textStyleGroups}
						handleSetEditSettings={handleSetEditSettings} />
					<hr />
					<div className={styles.titleCustomStyle}>Add a custom text style group</div>
					<div className={styles.centered}>
						<TextStyleSettingInputControls
							isOpen={isOpen}
							settings={settings}
							setSettings={setSettings}
							setStyleByCurrentElement={true}
							changeStyleEditArray={props.changeStyleEditArray}
							handleSetting={handleSetting}
							fontOptions={fontOptions}
							fontSizes={fontSizes}
							lineHeights={lineHeights}
							textAlign={textAlign}
							bottomSpace={bottomSpace}
							textIndent={textIndent} {...props} />
					</div>
					<div className={styles.buttonsCenter}>
						<div className={styles.buttonPosition}>
							<span className={styles.cancelButton} onClick={resetSettings}>
								Clear
							</span>
							<span className={styles.cancelButton} onClick={onCancel}>
								Cancel
							</span>
								<ButtonWithIcon label={'Submit'} icon={'checkmark0'} onClick={processForm} submitSuccess={submitSuccess} />
						</div>
					</div>
				</div>
			</div>
			<MessageModal
				show={openSentenceRangeMessage} handleClose={() => setOpenSentenceRangeMessage(false)}
				explain={`In order to set styles for one or more sentences, I will turn on the 'Change Style' edit option and return you to the editing page so you can click on the first sentence. Then click on the end icon that is the range you want for the last sentence.`}
				isConfirmType={true}
				onClick={() => { setOpenSentenceRangeMessage(false); props.setChangeStyle(true); onClose() }} />
		</div>	
	)
}

export default TextStyleChangeModal
