import React, {useState, useEffect} from 'react';
import SelectSingleDropDown from '../../components/SelectSingleDropDown'
import styles from './EditorDivFormatControlsMobile.module.css'


function EditorDivFormatControlsMobile(props) {
	const { personId, undo, redo, setFormatChoice, setParagraphAlign, setListChoice, setParagraphIndentChoice, setIsOpenLinkEntry, isOpenLinkEntry, isTranslation } = props
	const [filteredOptions, setFilteredOptions] = useState([])
	const toolOptions = [
		{
			id: 'UNDO',
			label: 'Undo (ctrl + z)',
			action: () => undo(),
			hideIfTranslation: false
		},
		{
			id: 'REDO',
			label: 'Redo (ctrl + shift + z)',
			action: () => redo(),
			hideIfTranslation: false
		},
		{
			id: 'BOLD',
			label: 'Bold (ctrl + b)',
			action: () => setFormatChoice('bold'),
			hideIfTranslation: false
		},
		{
			id: 'ITALIC',
			label: 'Italic (ctrl + i)',
			action: () => setFormatChoice('italic'),
			hideIfTranslation: false
		},
		{
			id: 'UNDERLINE',
			label: 'Underline (ctrl + u)',
			action: () => setFormatChoice('underline'),
			hideIfTranslation: false
		},
		{
			id: 'STRIKEOUT',
			label: 'Strikeout (ctrl + shift + k)',
			action: () => setFormatChoice('strikeout'),
			hideIfTranslation: false
		},
		{
			id: 'PARAGRAPHLEFTALIGN',
			label: 'Paragraph left align',
			action: () => setParagraphAlign('left'),
				hideIfTranslation: true
		},
		{
			id: 'PARAGRAPHCEMTERALIGN',
			label: 'Paragraph center align',
			action: () => setParagraphAlign('center'),
			hideIfTranslation: true
		},
		{
			id: 'PARAGRAPHRIGHTALIGN',
			label: 'Paragraph right align',
			action: () => setParagraphAlign('right'),
			hideIfTranslation: true
		},
		{
			id: 'PARAGRAPHJUSTIFYALIGN',
			label: 'Paragraph justify align',
			action: () => setParagraphAlign('justify'),
			hideIfTranslation: true
		},
		{
			id: 'TEXTINDENT',
			label: 'Indent',
			action: () => setParagraphIndentChoice('textindent'),
			hideIfTranslation: true
		},
		{
			id: 'MARGINLEFT',
			label: 'Block indent',
			action: () => setParagraphIndentChoice('marginleft'),
			hideIfTranslation: true
		},
		{
			id: 'MARGINRIGHT',
			label: 'Undo block indent',
			action: () => setListChoice('marginright'),
			hideIfTranslation: true
		},
		{
			id: 'NUMBEREDLIST',
			label: 'Numbered list',
			action: () => setFormatChoice('OL'), //Help ToDo: Does this really work? Or what does this do?
			hideIfTranslation: true
		},
		{
			id: 'BULLETLIST',
			label: 'Bullet list',
			action: () => setFormatChoice('UL'), //Help ToDo: Does this really work? Or what does this do?
			hideIfTranslation: true
		},
		{
			id: 'INTERNETLINK',
			label: 'Internet link',
			action: () => setIsOpenLinkEntry(!isOpenLinkEntry),
			hideIfTranslation: false
		},
	]

	const handleSetTool = (event) => {
		if (event.target && event.target.value !== '') {
			const toolOption = toolOptions.filter(m => m.id === event.target.value)[0]
			if (toolOption) toolOption.action()
		}
	}

	useEffect(() => {
		let newFiltered = toolOptions
		if (isTranslation) {
			newFiltered = toolOptions.filter(m => !m.hideIfTranslation)
		}
		setFilteredOptions(newFiltered)
	}, [isTranslation])

	return (
		<div className={styles.container}>
			<SelectSingleDropDown
				label={``}
				zeroethLabel={'B U I'}
				zeroethValue={personId}
				includeDownArrow
				value={0}
				options={filteredOptions || []}
				height={`medium-short`}
				className={styles.narrowList}
				onChange={handleSetTool} />
		</div>
	)
}

export default EditorDivFormatControlsMobile
