import React, { useState } from 'react';
import styles from './TextStyleSettingGroupTable.module.css';
import Icon from '../../components/Icon'
import ReviewTextModal from '../../components/ReviewTextModal'
import classes from 'classnames';

export default ({ 
	isMobile,
	hideEdit,
	fillControlsWithChoice,
	settings,
	textStyleGroups,
	handleSetEditSettings,
	setDeleteTextStyleGroupId }) => {

	const [settingsToReview, setSettingsToReview] = useState('')

	const handleSetSettingsToReview = (settings) => {
		let bodyStyle = ''
		let paragraphStyle = ''
		let toView = `<div><div style="font-size: 10pt">Name <div style="font-size: 13pt; font-weight:bold;">${settings.group.name}</div><br/><br/><div style="display: flex; flex-direction:row"><div style="width: 80px; font-size: 8pt;margin-top: 5px;">BODY</div>`
		let setting = settings.entries.filter(m => m.code === 'font-family')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 125px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}</strong></div></div>`
			bodyStyle += `${setting.code}: ${setting.value}; `
		}
		setting = settings.entries.filter(m => m.code === 'font-size')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 125px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}pt</strong></div></div>`
			bodyStyle += `${setting.code}: ${setting.value}pt; `
		}
		setting = settings.entries.filter(m => m.code === 'line-height')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 125px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}</strong></div></div>`
			bodyStyle += `${setting.code}: ${setting.value}; `
		}
		toView += `<div style="display: flex; flex-direction:row"><div style="width: 80px; font-size: 8pt;margin-top: 15px;">PARAGRAPH</div>`
		setting = settings.entries.filter(m => m.code === 'text-align')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 125px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value}</strong></div></div>`
			paragraphStyle += `${setting.code}: ${setting.value}; `
		}
		setting = settings.entries.filter(m => m.code === 'margin-bottom')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 125px; text-align: right; margin-right: 7px;">Paragraph space:</div><div><strong>${setting.value}px</strong></div></div>`
			paragraphStyle += `${setting.code}: ${setting.value}px; `
		}
		setting = settings.entries.filter(m => m.code === 'text-indent')[0]
		if (setting) {
			toView += `</div><div style="display: flex; flex-direction:row"><div style="width: 125px; text-align: right; margin-right: 7px;">${setting.code}:</div><div><strong>${setting.value} characters</strong></div></div>`
			paragraphStyle += `${setting.code}: ${setting.value / 2.2}em; `
		}

		toView += "<br/>EXAMPLE:"
		toView += `<div style="${bodyStyle}"><p style="${paragraphStyle}">Lorem ipsum dolor sit amet, consectetur adipiscing elit.Vivamus vitae sem eget libero vehicula facilisis.Sed in sapien at lectus tristique congue.Fusce auctor tincidunt nisi, nec sodales turpis faucibus in.</p>`
		toView += `<p style="${paragraphStyle}">Praesent ut leo eget libero tincidunt luctus.Maecenas accumsan, risus ut cursus pharetra, felis libero fermentum sapien.</p>`
		setSettingsToReview(toView + "</div></div>")
	}


	return (
		<div>
			{isMobile && 
				<div>
					{textStyleGroups?.map((m, i) =>
						<div onClick={fillControlsWithChoice ? () => handleSetEditSettings(m) : () => { }} className={fillControlsWithChoice ? styles.chosenBackground : ''} key={i}>
							<div className={styles.groupType}>
								{m.group.standard ? 'Standard' : 'Custom'}
							</div>
							<div className={classes(styles.textWhite, styles.wrap)} onClick={() => handleSetEditSettings(m)} style={{cursor: 'pointer'}}>
								{m.group.name}
							</div>
							<div className={styles.rowLeft}>
								{!hideEdit &&
									<div onClick={() => handleSetEditSettings(m)} className={styles.linkWhite}>
										<Icon pathName={'pencil0'} premium={true} fillColor={'white'} className={styles.pencilEdit} smaller />
									</div>
								}
								<div onClick={() => handleSetSettingsToReview(m)} className={styles.linkWhite}>
									view
								</div>
								{!m.group.standard && !hideEdit &&
									<div onClick={() => setDeleteTextStyleGroupId(m.group.textStyleGroupId)}>
										<Icon pathName={'trash2'} premium={true} fillColor={'pink'} />
									</div>
								}
							</div>
							<hr/>
						</div>
					)}

				</div>
			}
			{!isMobile && 
				<table className={styles.tableStyle}>
					<thead>
						<tr>
							<th className={styles.hdr}>TYPE</th>
							<th className={classes(styles.hdr, isMobile ? styles.textCellMobile : styles.textCell)}>NAME</th>
						</tr>
					</thead>
					<tbody>
						{textStyleGroups?.map((m, i) =>
							<tr key={i} onClick={fillControlsWithChoice ? () => handleSetEditSettings(m) : () => { }} className={fillControlsWithChoice ? styles.chooseRecord : ''}>
								<td>
									<div className={styles.groupType}>
										{m.group.standard ? 'Standard' : 'Custom'}
									</div>
								</td>
								<td>
									<div className={styles.textWhite} onClick={() => handleSetEditSettings(m)} style={{ cursor: 'pointer' }}>
										{m.group.name}
									</div>
								</td>
								<td>
									<div className={styles.row}>
										{!hideEdit && 
											<div onClick={() => handleSetEditSettings(m)} className={styles.linkWhite}>
												<Icon pathName={'pencil0'} premium={true} fillColor={'white'} className={styles.pencilEdit} smaller />
											</div>
										}
										<div onClick={() => handleSetSettingsToReview(m)} className={styles.linkWhite}>
											{isMobile ? <div style={{ marginTop: '5px' }}><Icon pathName={'magnifier'} premium={true} fillColor={'white'} /></div> : 'view'}
										</div>
									</div>
								</td>
								<td className={styles.rightCell}>
									{!m.group.standard && !hideEdit &&
										<div onClick={() => setDeleteTextStyleGroupId(m.group.textStyleGroupId)}>
											<Icon pathName={'trash2'} premium={true} fillColor={'pink'} />
										</div>
									}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			}
			<ReviewTextModal isOpen={!!settingsToReview} onClose={() => setSettingsToReview('')} text={settingsToReview} label='View Settings' />
		</div>
	)
};
